import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Resource, Role, Scope } from "../../../types/NendaTypes";
import MainLayout from "./MainLayout";
import NavigationScroll from "./NavigationScroll";
import { checkPermission } from "./components/helpers/permissions";
import { store } from "./store";
import {
  GetPermissions,
  getPermissions,
  selectUserCompany,
  selectUserRole,
} from "./store/reducers/authReducer";
import { GetCompanies, GetCompany } from "./store/reducers/companyReducer";
import { GetContentChannels } from "./store/reducers/contentChannelReducer";
import { GetPremises } from "./store/reducers/organizationUnitReducer";
import { getUsers, getUsersByCompany } from "./store/reducers/userReducer";

export default function App() {
  const role = useSelector(selectUserRole);

  const company = useSelector(selectUserCompany);
  const permissions = useSelector(getPermissions);

  useEffect(() => {
    store.dispatch(GetPermissions());
  }, []);

  useEffect(() => {
    if (!permissions) {
      return;
    }

    checkPermission(
      role,
      {
        resource: Resource.Premise,
        scopes: [Scope.CanView],
      },
      permissions
    ) && store.dispatch(GetPremises());

    if (company) {
      checkPermission(
        role,
        {
          resource: Resource.Company,
          scopes: [Scope.CanView],
        },
        permissions
      ) && store.dispatch(GetCompany(company));
    }

    checkPermission(
      role,
      {
        resource: Resource.ContentChannel,
        scopes: [Scope.CanView],
      },
      permissions
    ) && store.dispatch(GetContentChannels());

    if (company || [Role.Admin, Role.Superuser].includes(role)) {
      checkPermission(
        role,
        {
          resource: Resource.User,
          scopes: [Scope.CanAdministrate],
        },
        permissions
      ) &&
        store.dispatch(
          [Role.Admin, Role.Superuser].includes(role)
            ? getUsers()
            : getUsersByCompany(company!)
        );
    }

    checkPermission(
      role,
      {
        resource: Resource.Premise,
        scopes: [Scope.CanAdministrate],
      },
      permissions
    ) && store.dispatch(GetContentChannels());

    checkPermission(
      role,
      {
        resource: Resource.Company,
        scopes: [Scope.CanAdministrate],
      },
      permissions
    ) && store.dispatch(GetCompanies());
  }, [permissions]);

  return (
    <NavigationScroll>
      <MainLayout />
    </NavigationScroll>
  );
}
