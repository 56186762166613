import { Login } from "@mui/icons-material";
import { Button, useTheme } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { store } from "../store";
import { Logout } from "../store/reducers/authReducer";
import { OpenSettings } from "../store/reducers/customizationReducer";

export default function SettingsDropDown({
  open,
  anchorEl,
  handleClick,
}: {
  open: boolean;
  anchorEl;
  handleClick: (value: any) => void;
}) {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClose = ({ path }) => {
    if (path) navigate(path);
    handleClick(null);
  };

  function handleLogout() {
    store.dispatch(Logout());
  }

  const handleShowSettings = () => {
    store.dispatch(OpenSettings());
    handleClick(null);
  };

  return (
    <Menu
      id="basic-menu"
      sx={{
        "& .MuiPopover-paper": {
          minWidth: "15rem",
          borderRadius: "0.6rem",
          background: theme.palette.common.white,
          boxShadow: "20px 20px 50px rgba(0, 0, 0, 0.1)",
        },
        "& .MuiMenuItem-root": {
          background: "transparent",
          "&:hover": {
            background: theme.palette.transparent.blue,
          },
        },
        "& .Mui-focusVisible": {
          backgroundColor: "transparent !important",
        },
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={handleShowSettings}>
        {t("customerportal.pages.settings.account_settings.title")}
      </MenuItem>
      <MenuItem>
        <Button
          startIcon={<Login style={{ transform: "rotate(180deg)" }} />}
          variant="contained"
          onClick={handleLogout}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {t("common.logout")}
        </Button>
      </MenuItem>
    </Menu>
  );
}
