import * as React from "react";
import { Box, List } from "@mui/material";
import { Company, IRoom } from "../../../../../../types/NendaTypes";
import ScreenItem from "./ScreenItem";

interface ScreenListProps {
  screens: IRoom[];
  company?: Company;
  premiseId?: string;
}

const ScreenList: React.FC<ScreenListProps> = ({ screens }) => {
  return (
    <Box>
      <List>
        {screens &&
          screens.map((screen) => (
            <ScreenItem screen={screen} key={screen._id} />
          ))}
      </List>
    </Box>
  );
};

export default ScreenList;
