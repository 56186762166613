import { Box, Button, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  FrontLayerInformationImageItem,
  IFrontLayerInformationImageContent,
  LoadingStatus,
} from "../../../../../../types/NendaTypes";
import { CustomerPortalState, store } from "../../../store";
import {
  ClearUploadResponse,
  UploadImageOrPdf,
} from "../../../store/reducers/frontlayerConfigReducer";
import { selectNavigatedPremiseId } from "../../../store/reducers/workspaceReducer";
import SimpleImageUpload from "./SimpleImageUpload";
import SortableImageList from "./SortableImageList";

const ImageInformationItem = ({
  item,
  onImagesChange,
}: {
  item: FrontLayerInformationImageItem;
  onImagesChange: (
    images: IFrontLayerInformationImageContent["images"]
  ) => void;
}) => {
  const premiseId = useSelector(selectNavigatedPremiseId);
  const loadingStatus = useSelector(
    (state: CustomerPortalState) => state.frontlayerConfig.imageUploadStatus
  );
  const [showImageUpload, setShowImageUpload] = useState(false);
  const handleToggleImageUpload = () => {
    setShowImageUpload(!showImageUpload);
  };

  const onUploadImage = (file: File) => {
    if (!premiseId) return;
    store.dispatch(UploadImageOrPdf(premiseId, file));
  };
  const uploadResponse = useSelector(
    (state: CustomerPortalState) =>
      state.frontlayerConfig.informationItemUploadResponse
  );

  useEffect(() => {
    const images = [...item.content.images];
    if (uploadResponse?.length > 0) {
      uploadResponse.map((image) => {
        if (images.find((img) => img.locator === image.fileUrl)) return;
        return images.push({
          sortOrder: image.page + item.content.images.length,
          locator: image.fileUrl,
        });
      });
      onImagesChange(images);
      if (loadingStatus === LoadingStatus.SUCCEEDED) {
        setTimeout(() => {
          handleToggleImageUpload();
          store.dispatch(ClearUploadResponse());
        }, 2000);
      }
    }
  }, [uploadResponse]);
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
        <Typography variant="body2">
          {t("customerportal.pages.frontlayer.image")}
        </Typography>
        <SimpleImageUpload
          onClose={handleToggleImageUpload}
          onUploadImage={onUploadImage}
          loadingStatus={loadingStatus}
          open={showImageUpload}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={handleToggleImageUpload}
        >
          {t("customerportal.pages.frontlayer.upload_image")}
        </Button>
      </Box>
      <Box>
        <SortableImageList
          images={item.content.images}
          setImages={onImagesChange}
        />
      </Box>
    </Box>
  );
};

export default ImageInformationItem;
