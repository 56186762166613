import React from "react";
import { useState } from "react";
import { parseISO } from "date-fns";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IContract } from "../../../../../../types/NendaTypes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { sv } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextInput from "../../../ui-components/input/TextInput";
import { t } from "i18next";

const style = {
  textField: {
    maxWidth: "50ch",
  },
};

export interface SlotComponentProps {}

interface ContractProps {
  contract?: IContract | undefined;
  onChange: (contract: IContract) => void;
}

const Contract: React.FC<ContractProps> = ({ contract, onChange }) => {
  const [currentContract, setCurrentContract] = useState(contract);

  React.useEffect(() => {
    if (currentContract) onChange(currentContract);
  }, [JSON.stringify(currentContract)]);

  React.useEffect(() => {
    setCurrentContract(contract);
  }, [contract]);

  const setStartDate = (date: Date | null) => {
    if (date) setCurrentContract({ ...currentContract, startDate: date });
  };

  const setEndDate = (date: Date | null | undefined) => {
    if (date && currentContract && currentContract.startDate)
      setCurrentContract({ ...currentContract, endDate: date });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
      <Box component="form" noValidate autoComplete="off">
        <Grid container columns={2} spacing={"0.5rem"}>
          <Grid item xs={1}>
            <DatePicker
              label={t("common.start_date")}
              value={new Date(currentContract?.startDate || "") || null}
              onChange={(e) => setStartDate(e || null)}
              slots={{ textField: TextInput }}
              slotProps={{
                field: {
                  fullWidth: true,
                  variant: "standard",
                  //@ts-ignore
                  InputProps: { disableUnderline: true },
                },
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <DatePicker
              label={t("common.end_date")}
              disabled={!currentContract || !currentContract.startDate}
              value={
                (currentContract?.endDate &&
                  new Date(currentContract?.endDate?.toString() || "")) ||
                null
              }
              slotProps={{
                field: {
                  fullWidth: true,
                  variant: "standard",
                  //@ts-ignore
                  InputProps: { disableUnderline: true },
                },
              }}
              slots={{ textField: TextInput }}
              onChange={(e) =>
                setEndDate(e)
              } /* Known bug on mobile: The value will be set even if the user presses Cancel */
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default Contract;
