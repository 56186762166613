import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
} from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import constants from "@shared/constants";
import {
  Company,
  IOrganizationUnit,
  IRoom,
  ISignagePromotion,
  NendaProduct,
  Resource,
  Scope,
  SignageSlotAmountValues,
} from "../../../../../../types/NendaTypes";
import { capitalizeString } from "../../../../../utils/string";
import { CustomerPortalState, store } from "../../../store";
import { getCompany } from "../../../store/reducers/companyReducer";
import {
  GetContentChannels,
  selectContentChannels,
} from "../../../store/reducers/contentChannelReducer";
import { selectPremiseById } from "../../../store/reducers/organizationUnitReducer";
import InputSelect from "../../../ui-components/input/InputSelect";
import TextInput from "../../../ui-components/input/TextInput";
import { ScreenInfoType } from "../premise/EditScreenDialog";
import PromotionSelect from "./PromotionSelect";
import PermissionGate from "../../PermissionGate";
import { Number } from "mongoose";

interface EditScreenProps {
  screen: IRoom;
  screenInfoState: ScreenInfoType;
  setScreenInfoState: (value: ScreenInfoType) => void;
  availablePromotions: ISignagePromotion[];
  onPromotionChange: (selectedPromotions: string[]) => void;
  onNumberOfScreensChange: (numberOfScreens: number) => void;
  numberOfScreens: number | undefined;
}

const EditScreen: React.FC<EditScreenProps> = ({
  screen,
  screenInfoState,
  setScreenInfoState,
  onPromotionChange,
  onNumberOfScreensChange,
  availablePromotions,
  numberOfScreens,
}) => {
  const contentChannels = useSelector(selectContentChannels);

  const { name, description, soundEnabled } = screenInfoState;

  const premise = useSelector((state: CustomerPortalState) =>
    selectPremiseById(state, screen.hotel)
  );

  const filteredChannels = premise?.contentChannels?.map((pc) =>
    contentChannels.find((cc) => cc._id === pc)
  );

  const handleNumberOfScreensChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newNumber = parseInt(e.target.value);
    if (isNaN(newNumber)) {
      return;
    }

    onNumberOfScreensChange(newNumber);
  };

  const company = useSelector((state: CustomerPortalState) => {
    if (premise)
      return getCompany(
        state,
        premise.company instanceof Object
          ? premise.company._id
          : premise.company
      );
    return undefined;
  });

  useEffect(() => {
    store.dispatch(GetContentChannels());
  }, [premise, company]);

  const hasEffectProduct = screen.nendaProducts.includes(NendaProduct.SIGNAGE);

  return (
    <Box>
      <Stack direction={"column"}>
        <TextInput
          fullWidth
          id="screen-name"
          label={t("Name")}
          variant="standard"
          placeholder={name}
          value={name}
          onChange={(e) =>
            setScreenInfoState({ ...screenInfoState, name: e.target.value })
          }
        />
        <TextInput
          fullWidth
          id="screen-description"
          label={t("Description")}
          variant="standard"
          placeholder={description}
          value={description}
          onChange={(e) =>
            setScreenInfoState({
              ...screenInfoState,
              description: e.target.value,
            })
          }
        />
        {
          <PermissionGate
            restriction={{
              resource: Resource.Premise,
              scopes: [Scope.CanAdministrate],
            }}
          >
            <TextInput
              fullWidth
              id="number-of-connected-screens"
              label={t(
                "customerportal.pages.settings.screens.number_of_screens"
              )}
              variant="standard"
              type="number"
              value={numberOfScreens}
              onChange={handleNumberOfScreensChange}
            />
          </PermissionGate>
        }
        <Stack direction={"column"} spacing={"1rem"}>
          {hasEffectProduct && (
            <InputSelect
              id="signage-slots-select"
              value={
                typeof screenInfoState.signageSlotsPerHour === "number"
                  ? screenInfoState.signageSlotsPerHour
                  : getInheritedSignageSlotValue({ premise, company })
              }
              title={`${t(
                "customerportal.pages.settings.screens.signage_slots_per_hour"
              )} (${getInheritedSignageSlotValue({
                premise,
                company,
              })} default)`}
              variant="outlined"
              onChange={(e) =>
                setScreenInfoState({
                  ...screenInfoState,
                  signageSlotsPerHour: e.target.value,
                })
              }
            >
              {SignageSlotAmountValues.map((signageSlot, index) => (
                <MenuItem key={index} value={signageSlot}>
                  {signageSlot}
                </MenuItem>
              ))}
            </InputSelect>
          )}
          {hasEffectProduct && (
            <PromotionSelect
              activePromotions={screenInfoState.promotions}
              onChange={onPromotionChange}
              availablePromotions={availablePromotions}
            />
          )}
          {filteredChannels && hasEffectProduct && (
            <InputSelect
              id="channel-select"
              value={screenInfoState.defaultChannel || ""}
              title="Default Channel"
              variant="outlined"
              onChange={(e) =>
                setScreenInfoState({
                  ...screenInfoState,
                  defaultChannel: e.target.value,
                })
              }
            >
              {filteredChannels.map((channel, index) => (
                <MenuItem key={index} value={channel?._id}>
                  {capitalizeString(channel?.name || "")}
                </MenuItem>
              ))}
            </InputSelect>
          )}
        </Stack>
        {hasEffectProduct && (
          <FormControlLabel
            control={
              <Checkbox
                checked={soundEnabled}
                id="screen-sound-enabled"
                onChange={(e) =>
                  setScreenInfoState({
                    ...screenInfoState,
                    soundEnabled: !soundEnabled,
                  })
                }
              />
            }
            label={t("Sound Enabled")}
          />
        )}
      </Stack>
    </Box>
  );
};

export default EditScreen;

export const getInheritedSignageSlotValue = ({
  premise,
  company,
}: {
  premise?: IOrganizationUnit;
  company?: Company;
}) => {
  if (premise?.signageConfig?.numberOfHourlySignageSlots) {
    return premise?.signageConfig?.numberOfHourlySignageSlots;
  }

  if (company?.signageConfig?.numberOfHourlySignageSlots) {
    return company?.signageConfig?.numberOfHourlySignageSlots;
  }

  const { SIGNAGE_SLOTS_PER_HOUR } = constants.Signage();
  return SIGNAGE_SLOTS_PER_HOUR;
};
