import React from "react";
import { Box, Button } from "@mui/material";
import { IAdCampaign, IRoom } from "../../../../../types/NendaTypes";
import Campaign from "./Campaign";
import CampaignForm from "./CampaignForm";
import { GetScreensResponse } from "../../../../../server/api/internal/ad";

interface CampaignListProps {
  campaigns: IAdCampaign[];
  screens: GetScreensResponse;
}

const CampaignList: React.FC<CampaignListProps> = ({ campaigns, screens }) => {
  const [showForm, setShowForm] = React.useState(false);

  const handleClose = () => setShowForm(false);

  return (
    <Box>
      {showForm ? (
        <CampaignForm onClose={handleClose} screens={screens} />
      ) : (
        <Button variant="outlined" onClick={() => setShowForm(true)}>
          Create campaign
        </Button>
      )}
      {campaigns.map((campaign) => (
        <Campaign campaign={campaign} key={campaign._id} screens={screens} />
      ))}
    </Box>
  );
};

export default CampaignList;
