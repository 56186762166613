import { useState } from "react";
import DefaultDialog from "../../../ui-components/dialog/dialog";
import { FileUpload } from "../../FileUpload";
import {
  Box,
  CircularProgress,
  Fade,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { LoadingStatus } from "../../../../../../types/NendaTypes";
import { CheckCircleOutline } from "@mui/icons-material";

export const VALID_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/svg",
  "image/webp",
  "application/pdf",
];

type SimpleImageUploadProps = {
  onClose: () => void;
  open: boolean;
  onUploadImage: (file: File) => void;
  loadingStatus: LoadingStatus;
};

const SimpleImageUpload = ({
  onClose,
  open,
  onUploadImage,
  loadingStatus,
}: SimpleImageUploadProps) => {
  const uploadStatusText = () => {
    switch (loadingStatus) {
      case LoadingStatus.LOADING:
        return t("common.file.file_upload.loading_status_text.loading");
      case LoadingStatus.FAILED:
        return t("common.file.file_upload.loading_status_text.error");
      case LoadingStatus.SUCCEEDED:
        return t("common.file.file_upload.loading_status_text.success");
      default:
        return "";
    }
  };
  const [file, setFile] = useState<File | undefined>(undefined);
  const handleUpload = () => {
    if (file) {
      onUploadImage(file);
    }
  };

  const succeeded = loadingStatus === LoadingStatus.SUCCEEDED;
  const isLoading = loadingStatus === LoadingStatus.LOADING;
  const theme = useTheme();
  return (
    <DefaultDialog
      onClose={onClose}
      open={open}
      title={t("customerportal.pages.frontlayer.upload_image") as string}
      defaultSaveButton={false}
      customActions={[
        {
          disabled: !file || loadingStatus !== LoadingStatus.IDLE,
          label: t("customerportal.pages.frontlayer.upload_image"),
          onClick: handleUpload,
        },
      ]}
    >
      {uploadStatusText() ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "20rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
              flexDirection: "column",
            }}
          >
            {isLoading && <CircularProgress />}
            {succeeded && (
              <>
                <Fade in={succeeded} timeout={1000}>
                  <CheckCircleOutline
                    sx={{ color: theme.palette.success.main, fontSize: "5rem" }}
                  />
                </Fade>
              </>
            )}
            <Box>
              <Typography variant="body1">{uploadStatusText()}</Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <FileUpload
          files={file ? [file] : []}
          onFileChange={(files: File[]) => setFile(files[0])}
          allowedFileTypes={VALID_FILE_TYPES}
        />
      )}
    </DefaultDialog>
  );
};

export default SimpleImageUpload;
