import { ArrowBack, LockReset } from "@mui/icons-material";
import { Box, Button, FormGroup, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { redirect, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { store } from "../CustomerPortal/store";
import {
  ResetPassword,
  Status,
  getResetPasswordStatus,
} from "../CustomerPortal/store/reducers/authReducer";
import TextInput from "../CustomerPortal/ui-components/input/TextInput";
import { LoadingButton } from "@mui/lab";

export const ResetPasswordForm: React.FC<{
  title?: string;
  description?: string;
}> = ({ title, description }) => {
  const { hash } = useParams();
  const navigate = useNavigate();

  const theme = useTheme();
  const [resetStatus, setResetStatus] = React.useState<Status>("idle");

  const reset = (password: string) => {
    hash
      ? store.dispatch(ResetPassword(password, hash))
      : store.dispatch(ResetPassword(password));
  };
  const status = useSelector(getResetPasswordStatus);

  if (resetStatus == "succeeded" && hash) redirect("/login");

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(t("form.validation.password_required") as string)
      .min(8, t("form.validation.password_min_length") as string)
      .matches(
        /\d/,
        t("form.validation.password_must_contain_number") as string
      ),
    passwordConfirmation: yup
      .string()
      .required(t("form.validation.password_reenter_required") as string)
      .oneOf(
        [yup.ref("password"), ""],
        t("form.validation.passwords_must_match") as string
      ),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ password }) => {
      setResetStatus("loading");
      reset(password);
    },
  });

  useEffect(() => {
    if (status === "succeeded") {
      setResetStatus(status);
    } else {
      setResetStatus("idle");
    }
  }, [status]);

  return (
    <>
      <Box
        textAlign={"center"}
        mb={"1rem"}
        color={theme.palette.text.secondary}
      >
        {title && (
          <Typography variant="h6" sx={{ py: "1rem" }}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="subtitle1">{description}</Typography>
        )}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup sx={{ display: "flex", gap: 2 }}>
          <TextInput
            label={t("customerportal.pages.reset_password.password1")}
            type="password"
            variant={"outlined"}
            placeholder="New Password"
            fullWidth
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextInput
            label={t("customerportal.pages.reset_password.password2")}
            type="password"
            placeholder={
              t("customerportal.pages.reset_password.repeat_password") || ""
            }
            fullWidth
            variant={"outlined"}
            id="passwordConfirmation"
            name="passwordConfirmation"
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            error={
              formik.touched.passwordConfirmation &&
              Boolean(formik.errors.passwordConfirmation)
            }
            helperText={
              formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation
            }
          />
          <LoadingButton
            loading={resetStatus === "loading"}
            type="submit"
            variant="contained"
            fullWidth
            endIcon={<LockReset />}
            disabled={resetStatus === "succeeded"}
          >
            {resetStatus === "succeeded"
              ? t("customerportal.pages.reset_password.password_updated")
              : t("customerportal.pages.reset_password.save_password")}
          </LoadingButton>
          <Box>
            <Button
              variant="outlined"
              onClick={() => navigate("/")}
              fullWidth
              sx={{
                color: `${theme.palette.common.white} !important`,
                borderColor: theme.palette.common.white,
                fontSize: "1rem",
                "&:hover": {
                  borderColor: theme.palette.background.paper,
                },
              }}
              startIcon={<ArrowBack />}
            >
              {t("customerportal.pages.forgot_password.back_to_login")}
            </Button>
          </Box>
        </FormGroup>
      </form>
    </>
  );
};
