export type UpsalesProductId = number;

export interface User {
  role?: any;
  name?: string;
  id: number;
  email?: string;
}

export interface Client {
  name?: string;
  id: number;
  users?: User[];
}

export interface Stage {
  name?: string;
  id: number;
}

export interface Product {
  name?: string;
  id: number;
  category?: any;
  listPrice: number;
}

export interface OrderRow {
  id?: number;
  quantity: number;
  price: number;
  discount?: number;
  custom?: any[];
  productId?: number;
  sortId?: number;
  listPrice?: number;
  purchaseCost?: number;
  product?: Product;
}

export interface OrderData {
  id: number;
  description: string;
  closeDate: string;
  date: string;
  notes?: any;
  user: User;
  client: Client;
  contact?: any;
  project?: any;
  regDate: Date;
  stage: Stage;
  probability: number;
  modDate: Date;
  clientConnection?: any;
  currencyRate: number;
  currency: string;
  //locked: number; DEPRICATED
  custom: any[];
  orderRow: OrderRow[];
  value: number;
  weightedValue: number;
  valueInMasterCurrency: number;
  weightedValueInMasterCurrency: number;
  agreement?: any;
  userRemovable: boolean;
  userEditable: boolean;
  regBy: User;
}

export interface Metadata {
  total: number;
  limit: number;
  offset: number;
}

export interface OrderResponse {
  error?: any;
  data: OrderData;
}

export interface CreateOrderRequest {
  description: string;
  closeDate?: string;
  date: string;
  notes?: string;
  user: User;
  client: Client;
  contact?: any;
  projects?: any;
  stage: Stage;
  probability: number; // Between 0-100
  clientConnection?: any;
  currencyRate?: number;
  currency?: string;
  custom?: any[];
  quantity: number;
  price: number;
  listPrice: number;
  purchaseCost?: number;
  product: Product;
  competitorId?: number;
  lostReason?: number;
  orderRow: OrderRow[];
}

export interface OrdersResponse {
  error?: any;
  metadata: Metadata;
  data: OrderData[];
}

export interface Address {
  zipcode: string;
  city?: any;
  state: string;
  address: string;
  type: string;
  country?: any;
  latitude?: any;
  longitude?: any;
}

export interface MailAddress {
  zipcode: string;
  city?: any;
  state: string;
  address: string;
  type: string;
  country?: any;
  latitude?: any;
  longitude?: any;
}

export interface Soliditet {}

export interface ExternalClientData {}

export interface Ads {
  clicks: number;
  impressions: number;
}

export interface CompanyData {
  id: number;
  name: string;
  phone: string;
  fax: string;
  webpage: string;
  notes?: any;
  parent?: any;
  active: number;
  currency?: any;
  users: any[];
  regDate: Date;
  regBy?: any;
  modDate: Date;
  dunsNo?: any;
  projects: any[];
  categories: any[];
  addresses: Address[];
  mailAddress: MailAddress;
  custom: any[];
  userRemovable: boolean;
  userEditable: boolean;
  connectedClients: any[];
  soliditet: Soliditet;
  score: number;
  scoreUpdateDate?: any;
  isExternal: number;
  externalClientData: ExternalClientData;
  hasOrder?: any;
  hadOrder?: any;
  hasOpportunity?: any;
  hadOpportunity?: any;
  hasActivity?: any;
  hadActivity?: any;
  hasMail: boolean;
  hasForm: boolean;
  hasVisit: boolean;
  hasAppointment?: any;
  hadAppointment?: any;
  assigned: any[];
  ads: Ads;
  adCampaign: any[];
  importId?: any;
  orgNo?: any;
  registrationDate?: any;
  status?: any;
  sniCode?: any;
  companyForm?: any;
  creditRating?: any;
  turnover?: any;
  profit?: any;
  noEmployees?: any;
  facebook?: any;
  twitter?: any;
  linkedin?: any;
  sicCode?: any;
  ukSicCode?: any;
  naicsCode?: any;
  journeyStep: string;
  deactivationReason?: any;
  deactivationDate?: any;
  deactivatedBy?: any;
  cfar?: any;
  prospecting?: any;
  about?: any;
  headquarters: number;
  prospectingId?: any;
  prospectingUpdateDate?: any;
}

export interface CompanyResponse {
  error?: any;
  data: CompanyData;
}

export interface CompaniesResponse {
  error?: any;
  metadata: Metadata;
  data: CompanyData[];
}

export interface SubscriptionData {
  id: number;
  description: string;
  notes: string;
  user: User;
  client: Client;
  contact?: any;
  project?: any;
  regDate: Date;
  stage: Stage;
  modDate: Date;
  clientConnection?: any;
  currencyRate: number;
  currency: string;
  custom: any[];
  orderRow: OrderRow[];
  value: number;
  contributionMargin: number;
  purchaseCost: number;
  yearlyContributionMargin: number;
  valueInMasterCurrency: number;
  yearlyValueInMasterCurrency: number;
  yearlyValue: number;
  metadata: {
    activeVersionId?: number;
    versionNo: number;
    agreementDescription: string;
    agreementStartdate: Date;
    agreementEnddate?: Date;
    agreementInvoiceStartdate: Date;
    agreementRenewalDate: Date;
    agreementRenewalActivityCreated?: Date;
    agreementNextOrderDate: Date;
    agreementIntervalType: number;
    agreementIntervalPeriod: number;
    agreementOrderCreationTime: number;
    custom: any[];
    orderSequenceNr: number;
    latestOrderId: number;
    agreementNotes: string;
    periodLength: number;
    willCreateMoreOrders: boolean;
  };
  userRemovable: boolean;
  userEditable: boolean;
  regBy: User;
}

export interface SubscriptionResponse {
  error?: any;
  data: SubscriptionData;
}

export interface SubscriptionsResponse {
  error?: any;
  metadata: Metadata;
  data: SubscriptionData[];
}

export enum UpsalesProduct {
  PLATFORM_FEE = 25,
  PLATFORM_FEE_HLS = 92,
  STARTING_FEE = 26,
  INVOICING_FEE = 24,
  PROVISION_ROLLING = 38,
  PROVISION = 101,
  FREIGHT = 34,
  EFFECT_BOX_ACTIVATION_FEE = 61,
  EFFECT_STARTING_FEE = 59,
  EFFECT_OTHER_HARDWARE = 86,
  AMINO_BOX = 35,
}

export enum UpsalesProductCategory {
  ADS = 10,
  DAYPASS = 3,
  SALES_COSTS = 8,
  HARDWARE = 4,
  CONTENT = 10,
  INSTALLATION = 2,
  NENDA_EFFECT = 9,
  TECH = 6,
  ADDITIONAL_SERVICES = 5,
  OTHER = 12,
}
