import { Add, CheckCircle, Warning } from "@mui/icons-material";
import {
  Box,
  Fade,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { useSelector } from "react-redux";
import Selecto from "react-selecto";
import { SelectionAddedRemovedProps } from "../../Pages/WeeklyPlanner";
import {
  ScreenSelection,
  selectPlaylists,
  selectStatus,
} from "../../store/reducers/dailyContentPlaylistReducer";
import { stringToColor } from "../../../../utils/string";
import { useState } from "react";
import { LoadingStatus } from "../../../../../types/NendaTypes";

const days = [
  "common.days_of_week.monday",
  "common.days_of_week.tuesday",
  "common.days_of_week.wednesday",
  "common.days_of_week.thursday",
  "common.days_of_week.friday",
  "common.days_of_week.saturday",
  "common.days_of_week.sunday",
];

const WeekSchedule = ({
  selectoRef,
  screens,
  onChange,
}: {
  selectoRef: React.RefObject<Selecto>;
  screens: ScreenSelection[];
  onChange: (items: SelectionAddedRemovedProps) => void;
}) => {
  return (
    <MultiSelect
      screens={screens}
      onChange={onChange}
      selectoRef={selectoRef}
    />
  );
};

const MultiSelect = ({
  selectoRef,
  screens,
  onChange,
}: {
  selectoRef: React.RefObject<Selecto>;
  screens: ScreenSelection[];
  onChange: (items: SelectionAddedRemovedProps) => void;
}) => {
  const [selectedDay, setSelectedDay] = useState(1); // Default to Monday
  const dailyContentPlaylists = useSelector(selectPlaylists);
  const playlistLoadingStatus = useSelector(selectStatus);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const cubeHeight = screens.length > 3 ? "5rem" : "7rem";
  return (
    <Fade appear in={playlistLoadingStatus === LoadingStatus.SUCCEEDED}>
      <Box className="selecto-window">
        <Selecto
          ref={selectoRef}
          container={document.body}
          dragContainer={".selecto-window"}
          selectableTargets={[".selecto-area .cube"]}
          selectByClick={true}
          selectFromInside={true}
          preventDefault={true}
          continueSelect={true}
          toggleContinueSelect={"shift"}
          keyContainer={window}
          hitRate={0}
          onSelect={(e) => {
            const added = e.added.map((el) => {
              const [id, day] = el.id.split("_");
              return { id, day: Number(day) };
            });
            const removed = e.removed.map((el) => {
              const [id, day] = el.id.split("_");
              return { id, day: Number(day) };
            });

            onChange({ added, removed });
          }}
        />
        <Box
          sx={(theme) => ({
            display: "flex",
            width: "100%",
            alignItems: "center",
            padding: "1rem",
          })}
        >
          <Box
            sx={(theme) => ({
              minWidth: "200px",
              px: "1rem",
              [theme.breakpoints.down("lg")]: { minWidth: "150px" },
            })}
          >
            <Typography
              variant="subtitle1"
              sx={(theme) => ({
                fontSize: "1rem",
                [theme.breakpoints.down("lg")]: { fontSize: "0.8rem" },
              })}
              textAlign={"left"}
            >
              {t("common.screens")}
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              width: "100%",
              gap: "0.5rem",
              [theme.breakpoints.up("lg")]: { display: "none" },
            })}
          >
            <FormControl fullWidth sx={{ display: { lg: "none" } }}>
              <Select
                value={selectedDay}
                onChange={(e) => {
                  if (e.target.value && typeof e.target.value === "number") {
                    setSelectedDay(e.target.value);
                  }
                }}
                displayEmpty
                inputProps={{ "aria-label": "Select day" }}
              >
                {days.map((day, index) => (
                  <MenuItem key={day} value={index + 1}>
                    {t(day)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              width: "100%",
              gap: "0.5rem",
              [theme.breakpoints.down("lg")]: { display: "none" },
            })}
          >
            {days.map((day) => (
              <Box
                key={day}
                sx={(theme) => ({
                  transition: "background 0.2s ease",
                  width: "100%",
                  boxShadow: "8px 7px 47px 7px rgba(0,0,0,0.05)",
                  height: "auto",
                  padding: "0.5rem",
                  background: theme.palette.transparent.blue,
                  borderRadius: "8px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "thin",
                })}
              >
                <Typography
                  variant="body1"
                  sx={(theme) => ({
                    fontSize: "0.7rem",
                    color: theme.palette.primary.main,
                  })}
                >
                  {t(day)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          className="selecto-area"
          sx={(theme) => ({
            width: "100%",
            background: theme.palette.background.paper,

            padding: "1rem",
            borderRadius: "8px",
            "& .cube": {
              width: "100%",
              boxShadow: "8px 7px 47px 7px rgba(0,0,0,0.05)",
              height: cubeHeight,
              padding: "1rem 0.5rem",
              borderRadius: "8px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "thin",
              cursor: "pointer",
            },
          })}
        >
          {screens.map((screen) => (
            <Box
              key={screen._id}
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                my: "1rem",
              }}
            >
              <Box
                sx={(theme) => ({
                  px: "1rem",
                  minWidth: "200px",
                  [theme.breakpoints.down("lg")]: { minWidth: "150px" },
                })}
              >
                <Typography
                  variant="body1"
                  sx={(theme) => ({
                    fontSize: "1rem",
                    [theme.breakpoints.down("lg")]: { fontSize: "0.8rem" },
                  })}
                  textAlign={"left"}
                >
                  {screen.name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", width: "100%", gap: "0.5rem" }}>
                {[1, 2, 3, 4, 5, 6, 0].map((day) => {
                  const isSelected = screens.find(
                    (s) => s._id === screen._id && s.selected.includes(day)
                  );
                  const currentPlaylist = screens.find(
                    (s) => s._id === screen._id
                  )?.schedule[day];
                  const dailyContentPlaylist = dailyContentPlaylists.find(
                    (p) => p._id === currentPlaylist
                  );

                  if (isMobile && day !== selectedDay) {
                    return null;
                  }

                  return (
                    <Box
                      key={day}
                      id={`${screen._id + "_" + day}`}
                      className="cube"
                      sx={(theme) => ({
                        overflow: "hidden",
                        background: isSelected
                          ? theme.palette.primary.light
                          : currentPlaylist
                          ? theme.palette.gradient.darkBlue
                          : theme.palette.common.white,
                        transition: "background 0.2s ease",
                        position: "relative",
                        outline: isSelected
                          ? `3px solid ${theme.palette.primary.light}`
                          : "none",
                        "& svg": {
                          color: isSelected
                            ? theme.palette.common.white
                            : currentPlaylist
                            ? theme.palette.common.white
                            : theme.palette.primary.light,
                        },
                      })}
                    >
                      {currentPlaylist && (
                        <Box
                          sx={{
                            background: isSelected
                              ? "transparent"
                              : stringToColor(currentPlaylist),
                            p: "0.15rem",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            bottom: "0",
                          }}
                        />
                      )}
                      {currentPlaylist && !isSelected ? (
                        <>
                          {!dailyContentPlaylist &&
                            playlistLoadingStatus !== LoadingStatus.LOADING && (
                              <Tooltip
                                title={t(
                                  "customerportal.pages.week_planner.missing_playlist_tooltip"
                                )}
                              >
                                <Warning
                                  sx={(theme) => ({
                                    position: "absolute",
                                    top: "0.5rem",
                                    right: "0.5rem",
                                    height: "1rem",
                                    fill: theme.palette.warning.light,
                                  })}
                                />
                              </Tooltip>
                            )}
                          <Typography
                            fontStyle={
                              !dailyContentPlaylist ? "italic" : "normal"
                            }
                            textAlign={"center"}
                            variant="body1"
                            fontSize="0.7rem"
                            width={"100%"}
                            overflow="hidden"
                          >
                            {dailyContentPlaylist?.name || "Unknown"}
                          </Typography>
                        </>
                      ) : (
                        <>{isSelected ? <CheckCircle /> : <Add />}</>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Fade>
  );
};

export default WeekSchedule;
