import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { selectUserSession } from "../store/reducers/authReducer";

export function isLoggedIn(): boolean {
  const session = useSelector(selectUserSession);

  return !!(
    session &&
    session.expires &&
    new Date(session.expires).getTime() > Date.now()
  );
}

export const CustomerProtectedRoute = (props) => {
  const userIsLoggedIn = isLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    !userIsLoggedIn && navigate("login");
  }, [userIsLoggedIn]);

  if (!userIsLoggedIn) return null;

  return (
    <Routes>
      <Route {...props} />
    </Routes>
  );
};
