import { get } from "http";
import { Epic, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { upsalesService } from "../http/upsales.service";
import {
  AppState,
  GetCompaniesAction,
  GetCompanyAction,
  GetOrderAction,
  GetOrdersAction,
  GetSubscriptionAction,
  GetSubscriptionsAction,
  SetCompaniesAction,
  SetOrdersAction,
  SetSubscriptionsAction,
  UpsalesActions,
  UPSALES_ACTIONS,
} from "../types/redux";
import { handleError } from "./error.redux";

export function GetCompanies(): GetCompaniesAction {
  return { type: UPSALES_ACTIONS.GET_COMPANIES };
}

export function SetCompanies(data: any): SetCompaniesAction {
  return { type: UPSALES_ACTIONS.SET_COMPANIES, companies: data };
}

export function GetCompany(id: number): GetCompanyAction {
  return { type: UPSALES_ACTIONS.GET_COMPANY, id };
}

export function GetSubscriptions(): GetSubscriptionsAction {
  return { type: UPSALES_ACTIONS.GET_SUBSCRIPTIONS };
}

export function SetSubscriptions(data: any): SetSubscriptionsAction {
  return { type: UPSALES_ACTIONS.SET_SUBSCRIPTIONS, subscriptions: data };
}

export function GetSubscription(id: number): GetSubscriptionAction {
  return { type: UPSALES_ACTIONS.GET_SUBSCRIPTION, id };
}

export function GetOrders(): GetOrdersAction {
  return { type: UPSALES_ACTIONS.GET_ORDERS };
}

export function SetOrders(data: any): SetOrdersAction {
  return { type: UPSALES_ACTIONS.SET_ORDERS, orders: data };
}

export function GetOrder(id: number): GetOrderAction {
  return { type: UPSALES_ACTIONS.GET_ORDER, id };
}

export default function upsalesReducer(
  state: AppState["upsales"] = { companies: [], subscriptions: [], orders: [] },
  action: UpsalesActions
): AppState["upsales"] {
  switch (action.type) {
    case UPSALES_ACTIONS.SET_COMPANIES:
      return {
        ...state,
        companies: action.companies,
      };
    case UPSALES_ACTIONS.SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.subscriptions,
      };
    case UPSALES_ACTIONS.SET_ORDERS:
      return {
        ...state,
        orders: action.orders,
      };
    default:
      return state;
  }
}

const getCompanies$: Epic = (action$) => {
  return action$.pipe(
    ofType(UPSALES_ACTIONS.GET_COMPANIES),
    switchMap(() => {
      return upsalesService.getCompanies().pipe(
        map((result) => {
          return SetCompanies(result.response);
        }),
        catchError(handleError)
      );
    })
  );
};

const getSubscriptions$: Epic = (action$) => {
  return action$.pipe(
    ofType(UPSALES_ACTIONS.GET_SUBSCRIPTIONS),
    switchMap(() => {
      return upsalesService.getSubscriptions().pipe(
        map((result) => {
          return SetSubscriptions(result.response);
        }),
        catchError(handleError)
      );
    })
  );
};

const getOrders$: Epic = (action$) => {
  return action$.pipe(
    ofType(UPSALES_ACTIONS.GET_ORDERS),
    switchMap(() => {
      return upsalesService.getOrders().pipe(
        map((result) => {
          return SetOrders(result.response);
        }),
        catchError(handleError)
      );
    })
  );
};

export const upsalesEpics = [getCompanies$, getSubscriptions$, getOrders$];
