import { Box, CircularProgress } from "@mui/material";

const CircularLoadingIndicator = ({
  color,
  background,
}: {
  color?: string;
  background?: string;
}) => (
  <Box
    sx={{
      backgroundColor: background || "transparent",
      width: "100%",
      minHeight: "5rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress sx={{ color: color }} />
  </Box>
);
export default CircularLoadingIndicator;
