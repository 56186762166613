import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  NavigationLevel,
  NendaProduct,
  OrganizationType,
  Resource,
  Role,
  Scope,
} from "../../../../types/NendaTypes";
import { getCompanyIdByPremise } from "../../../utils/company";
import CompanyDashboard from "../components/Dashboard/company/CompanyDashboard";
import PremiseDashboard from "../components/Dashboard/premise/PremiseDashboard";
import {
  getPermissions,
  selectUserCompany,
  selectUserRole,
} from "../store/reducers/authReducer";
import { getPremises } from "../store/reducers/organizationUnitReducer";

const Dashboard = () => {
  //Dont look here!!!!
  const { id, type } = useParams<{ id: string; type: OrganizationType }>();

  if (id && type) {
    return type === Resource.Company ? (
      <CompanyDashboard />
    ) : (
      <PremiseDashboard />
    );
  }
  const permissions = useSelector(getPermissions);
  const role = useSelector(selectUserRole);
  const scopeLevel = permissions.company?.[role]?.includes(Scope.CanEdit)
    ? NavigationLevel.COMPANY
    : NavigationLevel.PREMISE;

  const navigate = useNavigate();
  const premises = useSelector(getPremises);
  const company = useSelector(selectUserCompany);
  const companyReferenceId = getCompanyIdByPremise(premises[0]?._id);
  const premiseId = premises[0]?._id || id;
  const premise = premises.find((p) => p._id === premiseId);

  const premiseRoles = [Role.PremiseUser, Role.PremiseAdmin];
  const companyRoles = [Role.CompanyAdmin, Role.CompanyUser];
  const adminRoles = [Role.Admin, Role.Superuser];

  const products =
    scopeLevel === NavigationLevel.PREMISE
      ? premise?.nendaProducts
      : [...new Set(premises.flatMap((premise) => premise.nendaProducts))];

  const premiseDashboardRoute = `/dashboard/premise/${premises[0]?._id}`;
  const companyDashboardRoute = `/dashboard/company/${
    companyReferenceId || company
  }`;
  const adminDashboardRoute = "/";

  useEffect(() => {
    if (!products?.length) return;
    if (
      products.length === 2 &&
      products.includes(NendaProduct.FRONTLAYER) &&
      products.includes(NendaProduct.STREAMING_APP)
    ) {
      navigate(`/frontlayer/premise/${premises[0]._id}`);
      return;
    }
    if (premiseRoles.includes(role) && premises.length > 0) {
      navigate(premiseDashboardRoute);
    } else if (companyRoles.includes(role) && company) {
      navigate(companyDashboardRoute);
    } else if (adminRoles.includes(role) && company) {
      navigate(adminDashboardRoute);
    }
  }, [JSON.stringify([role, premises, company, companyReferenceId, products])]);

  if (!role || !premises || !company) return null;

  return <></>;
};

export default Dashboard;
