import { ArrowBack } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { CustomerPortalState } from "../../../store";
import { CustomizationState } from "../../../store/reducers/customizationReducer";

const ToggleSidebarButton = ({ onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { opened }: CustomizationState = useSelector<
    CustomerPortalState,
    CustomizationState
  >((state) => state.customization);
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: theme.palette.background.paper,
        display: "inline-flex",
        borderRadius: isMobile ? "0 0 0.6rem 0" : "50%",
        left: isMobile ? "0px" : "initial",
        top: isMobile ? "0px" : "initial",
        width: isMobile ? "4rem" : "2rem",
        height: isMobile ? "4rem" : "2rem",
        padding: "0.5rem",
        position: "fixed",
        cursor: "pointer",
      }}
    >
      {isMobile ? (
        <MenuIcon
          style={{
            height: "100%",
            width: "100%",
            transform: opened ? "scaleX(1)" : "scaleX(-1)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      ) : (
        <ArrowBack
          style={{
            height: "100%",
            width: "100%",
            transform: opened ? "scaleX(1)" : "scaleX(-1)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      )}
    </Box>
  );
};

export default ToggleSidebarButton;
