import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "../../components/Grid/Grid";
import { Modal } from "../../components/Modal/Modal";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { SectionWrapper } from "../../components/UI/SectionWrapper/SectionWrapper";
import { store } from "../../redux/store";
import { CreateTemplate } from "../../redux/templates.redux";
import { AppState } from "../../types/redux";

export const TemplatesOverview: React.FC = () => {
  const templates = useSelector(({ templates }: AppState) => templates);
  const [showForm, setShowForm] = React.useState(false);
  const [name, setName] = React.useState("");

  function createTemplate() {
    store.dispatch(CreateTemplate(name));
  }

  return (
    <>
      <PageHeader>
        <h2>Templates</h2>
        <button className="btn btn-secondary" onClick={() => setShowForm(true)}>
          Create a new template
        </button>
      </PageHeader>
      <Modal
        disableSubmit={
          !(name?.length && !templates.find((t) => t.name === name))
        }
        visible={showForm}
        onClose={() => setShowForm(false)}
        title={"Create new template"}
        onSubmit={createTemplate}
      >
        <>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">Name</span>
            </div>
            <input
              onChange={(e) => setName(e.target.value)}
              name="name"
              value={name}
              type="text"
              className="form-control"
            />
          </div>
        </>
      </Modal>
      <SectionWrapper>
        <Grid
          items={templates.map((page) => ({
            name: page.name,
            id: page["_id"],
            link: `/templates/${page["_id"]}`,
          }))}
        />
      </SectionWrapper>
    </>
  );
};
