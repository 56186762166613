import React, { useEffect } from "react";
import assetUtils from "@shared/assetUtils";
import { CarouselUtils } from "@shared/carousel";
import { ICarouselWithAsset } from "../../../../types/NendaTypes";
import { Asset } from "../../../models/asset";
import { SetAutocomplete } from "../../../redux/asset.redux";
import { store } from "../../../redux/store";
import { AutocompleteAssetsComponent } from "../../AutocompleteAssets/AutocompleteAssets";
import { StringArrayEdit } from "../../StringArrayEdit/StringArrayEdit";
import {
  ConditionalSectionWrapper,
  SectionWrapper,
} from "../../UI/SectionWrapper/SectionWrapper";

interface ICuratedWidgetProps {
  carousel: ICarouselWithAsset;
  onChange(value: { assetIds: Array<string>; query: string }): void;
  assets: Array<Asset>;
}

export const CuratedWidget: React.FC<ICuratedWidgetProps> = ({
  carousel,
  onChange,
  assets,
}) => {
  useEffect(() => {
    // When the carousel component changes, close autocomplete
    store.dispatch(SetAutocomplete([]));
  }, [carousel]);
  return (
    <>
      <ConditionalSectionWrapper>
        <SectionWrapper>
          <h4 className="card-title">Assets</h4>
          <AutocompleteAssetsComponent
            carouselAssetIds={carousel.assetIds || []}
            onSelect={(v) =>
              onChange({
                assetIds: [...carousel.assetIds, v],
                query: CarouselUtils.assetListToQuery([
                  ...carousel.assetIds,
                  v,
                ]),
              })
            }
          />
          <StringArrayEdit
            draggable
            onChange={(values) =>
              onChange({
                assetIds: values,
                query: CarouselUtils.assetListToQuery(values),
              })
            }
            values={carousel.assetIds.map((assetId) => {
              const asset = assets.find((a) => a.assetId === assetId);
              if (asset && asset.localized) {
                return {
                  name: assetUtils.getTitle(asset, ["sv", "en"]),
                  value: asset.assetId,
                };
              } else {
                return {
                  name: assetId,
                  value: assetId,
                };
              }
            })}
          />
        </SectionWrapper>
      </ConditionalSectionWrapper>
    </>
  );
};
