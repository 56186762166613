import { Epic, ofType } from "redux-observable";
import { catchError, debounceTime, map, switchMap } from "rxjs/operators";
import { assetService } from "../http/asset.service";
import { autocompleteService } from "../http/autocomplete.service";
import { Asset } from "../models/asset";
import {
  AppState,
  AssetActions,
  ASSET_ACTIONS,
  AutoCompleteAction,
  GetAssetsForCarouselAction,
  SetAssetsForCarouselAction,
  SetAutocompleteAction,
} from "../types/redux";
import { handleError } from "./error.redux";

export function AutoComplete(query: any): AutoCompleteAction {
  return { type: ASSET_ACTIONS.AUTOCOMPLETE, query };
}

export function SetAutocomplete(assets: any): SetAutocompleteAction {
  return { type: ASSET_ACTIONS.SET_AUTOCOMPLETE, assets };
}

export function GetAssetsForCarousel(
  query: string,
  sort: string,
  carouselId: string
): GetAssetsForCarouselAction {
  return {
    type: ASSET_ACTIONS.GET_ASSETS_FOR_CAROUSEL,
    query,
    sort,
    carouselId,
  };
}

export function SetAssetsForCarousel(
  assets: Asset[],
  carouselId: string
): SetAssetsForCarouselAction {
  return { type: ASSET_ACTIONS.SET_ASSETS_FOR_CAROUSEL, assets, carouselId };
}

export default function assetReducer(
  state: AppState["assets"] = { assetsPerCarousel: [], autocomplete: [] },
  action: AssetActions
): AppState["assets"] {
  switch (action.type) {
    case ASSET_ACTIONS.SET_AUTOCOMPLETE:
      return {
        ...state,
        autocomplete: action.assets,
      };
    case ASSET_ACTIONS.SET_ASSETS_FOR_CAROUSEL:
      return {
        ...state,
        assetsPerCarousel: [
          ...state?.assetsPerCarousel?.filter(
            (a) => a.carouselId != action.carouselId
          ),
          { carouselId: action.carouselId, assets: action.assets },
        ],
      };
    default:
      return state;
  }
}

// Epics
const autoComplete$: Epic = (action$) => {
  return action$.pipe(
    ofType(ASSET_ACTIONS.AUTOCOMPLETE),
    debounceTime(500),
    switchMap((a) => {
      return autocompleteService.autocomplete(a.query).pipe(
        map((assets) => {
          return SetAutocomplete(assets);
        }),
        catchError(handleError)
      );
    })
  );
};

const getAssetsForCarousel$: Epic = (action$) => {
  return action$.pipe(
    ofType(ASSET_ACTIONS.GET_ASSETS_FOR_CAROUSEL),
    debounceTime(200),
    switchMap((a) => {
      return assetService.getAssetsByQuery(a.query, a.sort).pipe(
        map((assets) => {
          return SetAssetsForCarousel(assets, a.carouselId);
        }),
        catchError(handleError)
      );
    })
  );
};

export const assetEpics = [autoComplete$, getAssetsForCarousel$];
