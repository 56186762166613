import { Box, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { SetStateAction, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  IDailyContentPlaylist,
  ITimeInterval,
} from "../../../../../types/NendaTypes";
import { store } from "../../store";
import {
  selectError,
  setError,
} from "../../store/reducers/dailyContentPlaylistReducer";
import TextInput from "../../ui-components/input/TextInput";
import SmallContentChannelCarousel from "../ContentChannels/SmallContentChannelCarousel";
import ChannelRangeSelect from "./ChannelRangeSelect";
import DailyTimeScale from "./DailyTimeScale";
import PlaylistTimeline from "./PlaylistTimeline";
import { findAvailableSlot } from "./helpers/findSlot";
import { isAllTimeIntervalsValidWithoutOverlaps } from "./helpers/validation";
import { playlistToContentTimelineIntervals } from "../../../../utils/timeline";

const CreateEditPlaylist = ({
  playlistState,
  setPlaylistState,
}: {
  playlistState: IDailyContentPlaylist;
  setPlaylistState: (value: SetStateAction<IDailyContentPlaylist>) => void;
}) => {
  const error = useSelector(selectError);
  const firstFreeInterval = findAvailableSlot(
    playlistState?.timeline?.map((t) => t.interval),
    60,
    240
  );

  //Handle warnings
  useEffect(() => {
    if (playlistState) {
      const isValid = isAllTimeIntervalsValidWithoutOverlaps(
        playlistState.timeline.map((t) => t.interval)
      );
      if (!isValid) {
        store.dispatch(
          setError({
            message: t("customerportal.pages.daily_playlists.overlap_error"),
            type: "info",
          })
        );
      }
      if (isValid) {
        store.dispatch(
          setError({
            message: "",
            type: null,
          })
        );
      }
    }
  }, [JSON.stringify(playlistState.timeline)]);

  const handleAddChannel = (contentChannel: string) => {
    if (!firstFreeInterval) {
      return store.dispatch(
        setError({
          message: t("customerportal.pages.daily_playlists.no_free_slots"),
          type: "info",
        })
      );
    }
    setPlaylistState((prevState) => {
      const newTimeline = [...prevState.timeline];
      newTimeline.push({
        contentChannel,
        interval: firstFreeInterval as ITimeInterval,
      });
      return { ...prevState, timeline: newTimeline };
    });
    return;
  };

  const handleChangeName = (e) => {
    setPlaylistState((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };
  return (
    <Stack gap={"1rem"} sx={{ position: "relative" }}>
      {error?.message && (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            py: "1rem",
            height: "4rem",
            backgroundColor: "white",
            zIndex: 999,
          }}
        >
          <Typography variant="body1" textAlign={"center"} color="error">
            {error.message}
          </Typography>
        </Box>
      )}
      <Box>
        <Box sx={{ px: "1rem" }}>
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={"1rem"}
          >
            <Typography variant="subtitle1">
              {t("customerportal.pages.daily_playlists.playlist_name")}
            </Typography>
            <TextInput
              variant="standard"
              value={playlistState?.name}
              onChange={handleChangeName}
              placeholder={t(
                "customerportal.pages.daily_playlists.playlist_name_placeholder"
              )}
            />
          </Stack>
          <Stack direction={"column"} gap="0.5rem">
            <Typography variant="subtitle1">
              {t("customerportal.pages.daily_playlists.timeline_preview")}
            </Typography>
            <Box>
              <DailyTimeScale />
              {playlistState && (
                <PlaylistTimeline
                  playlist={playlistToContentTimelineIntervals(playlistState)}
                  height="4rem"
                />
              )}
            </Box>
          </Stack>
        </Box>
        <ChannelRangeSelect
          timeline={playlistState.timeline}
          handleChange={setPlaylistState}
        />
        <SmallContentChannelCarousel
          handleAddChannel={handleAddChannel}
          channelSelectTitle={t(
            "customerportal.pages.daily_playlists.add_channel"
          )}
        />
      </Box>
    </Stack>
  );
};

export default CreateEditPlaylist;
