import { AnyAction, Reducer, combineReducers } from "redux";
import { CustomerPortalState } from ".";
import { AUTH_ACTIONS } from "../../../types/redux";
import adsReducer from "./reducers/adsReducer";
import assetReducer from "./reducers/assetReducer";
import authReducer from "./reducers/authReducer";
import companyReducer from "./reducers/companyReducer";
import contentChannelReducer from "./reducers/contentChannelReducer";
import customizationReducer from "./reducers/customizationReducer";
import errorReducer from "./reducers/errorReducer";
import frontlayerConfigReducer from "./reducers/frontlayerConfigReducer";
import notificationReducer from "./reducers/notificationReducer";
import organizationUnitReducer from "./reducers/organizationUnitReducer";
import screenReducer from "./reducers/screenReducer";
import { streamChannelReducer } from "./reducers/streamChannelReducer";
import userReducer from "./reducers/userReducer";
import workspaceReducer from "./reducers/workspaceReducer";
import dailyContentPlaylistReducer from "./reducers/dailyContentPlaylistReducer";
import promotionReducer from "./reducers/promotionReducer";
import customerSetupReducer from "./reducers/customerSetupReducer";

const reducer: Reducer<CustomerPortalState, AnyAction> = (
  state: CustomerPortalState | undefined,
  action: AnyAction
) => {
  const appReducer = combineReducers({
    auth: authReducer,
    workspace: workspaceReducer,
    customization: customizationReducer,
    error: errorReducer,
    organizationUnits: organizationUnitReducer,
    notification: notificationReducer,
    user: userReducer,
    company: companyReducer,
    screen: screenReducer,
    ads: adsReducer,
    contentChannel: contentChannelReducer,
    asset: assetReducer,
    dailyContentPlaylist: dailyContentPlaylistReducer,
    frontlayerConfig: frontlayerConfigReducer,
    streamChannel: streamChannelReducer,
    promotion: promotionReducer,
    customerSetup: customerSetupReducer,
  });

  if (action.type === AUTH_ACTIONS.SET_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default reducer;
