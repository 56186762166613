import { Box, Skeleton } from "@mui/material";
import themeOptions from "../../theme/theme";

const skeletonStyles = {
  background: themeOptions.palette?.transparent.purple,
  my: "0.5rem",
  borderRadius: "0.3rem",
  opacity: 0.6,
};
const TableSkeleton = () => {
  return (
    <Box>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="3rem"
        sx={{ ...skeletonStyles }}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="20rem"
        sx={{ ...skeletonStyles }}
      />
    </Box>
  );
};

export default TableSkeleton;
