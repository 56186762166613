import { MenuItem, Select } from "@mui/material";
import React from "react";
import { ajaxPost, ajaxPut } from "rxjs/internal-compatibility";

const logoUrl =
  "https://www.nenda.com/wp-content/uploads/2019/05/nenda-loggo-white-1.svg";

const doLoadRooms = (premiseId, password) =>
  ajaxPost(
    "/api/app/room-management",
    {
      premiseId,
      password,
    },
    {
      "Content-Type": "application/json",
    }
  ).toPromise();

const doRoomUpdate = (premiseId, body, authHeader) =>
  ajaxPut(`/api/app/room-management/${premiseId}`, body, {
    "Content-Type": "application/json",
    Authorization: `Basic ${btoa(authHeader)}`,
  }).toPromise();

export const RoomManagementPage = () => {
  const [state, setState] = React.useState<{
    premiseId: string;
    password: string;
    error?: string;
    rooms: any[];
    selectedRooms: boolean[];
    status: string;
  }>({
    premiseId: "",
    password: "",
    error: "",
    rooms: [],
    selectedRooms: [],
    status: "inactive",
  });

  const loadRooms = () =>
    doLoadRooms(state.premiseId, state.password)
      .then((r) => {
        const newRooms = r.response.sort(
          (a, b) => +a.roomIdentifier - +b.roomIdentifier
        );
        setState({
          ...state,
          selectedRooms: new Array(newRooms.length).fill(false),
          rooms: newRooms,
        });
      })
      .catch((e) => {
        let errorMessage = e.response.message;
        switch (e.status) {
          case 401:
          case 404:
            errorMessage = "Invalid credentials";
            break;
        }
        setState({
          ...state,
          error: errorMessage,
        });
        console.error(e);
      });

  const doLogout = () =>
    setState({
      ...state,
      premiseId: "",
      password: "",
      rooms: [],
      selectedRooms: [],
    });

  const updateSelectedRooms = () => {
    const { status, premiseId, password, selectedRooms, rooms } = state;
    const body = {
      status: status,
      rooms: rooms
        .map((room, index) => (selectedRooms[index] ? room._id : null))
        .filter(Boolean),
    };
    doRoomUpdate(premiseId, body, `${premiseId}:${password}`).then((r) =>
      setState({
        ...state,
        rooms: r.response.sort((a, b) => +a.roomIdentifier - +b.roomIdentifier),
      })
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 px-3 py-2 text-white side-menu">
          <a href="/">
            <img id="logo" src={logoUrl} />
          </a>
          {!state.rooms.length ? (
            <div className="my-4">
              <div className="input-group my-">
                <div className="input-group-prepend">
                  <span className="input-group-text">Premise ID</span>
                </div>
                <input
                  value={state.premiseId}
                  onChange={({ target: { value } }) =>
                    setState({ ...state, premiseId: value })
                  }
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="input-group my-1">
                <div className="input-group-prepend">
                  <span className="input-group-text">Password</span>
                </div>
                <input
                  value={state.password}
                  onChange={({ target: { value } }) =>
                    setState({ ...state, password: value })
                  }
                  type="password"
                  className="form-control"
                />
              </div>
              {state.error && (
                <div className="alert alert-danger" role="alert">
                  {state.error}
                </div>
              )}

              <button className="btn btn-primary col-md-12" onClick={loadRooms}>
                Load rooms
              </button>
            </div>
          ) : (
            <div>
              <p>Logged in with {state.premiseId}</p>
              <button className="btn btn-secondary" onClick={() => doLogout()}>
                Logout
              </button>
            </div>
          )}
        </div>
        <div className="col-md-8">
          <h4>Rooms</h4>
          {!!state.rooms.length && (
            <>
              <div className="my-4 d-flex">
                <Select
                  onChange={({ target: { value } }) =>
                    setState({ ...state, status: value })
                  }
                  defaultValue="inactive"
                  className="form-control col-md-6"
                >
                  <MenuItem value="inactive">inactive</MenuItem>
                  <MenuItem value="active">active</MenuItem>
                </Select>
                <button
                  className="btn btn-secondary"
                  onClick={() => updateSelectedRooms()}
                  disabled={!state.selectedRooms.some(Boolean)}
                >
                  Apply
                </button>
              </div>

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">
                      <input
                        type="checkbox"
                        checked={state.selectedRooms.every(Boolean)}
                        onChange={({ target: { checked } }) =>
                          setState({
                            ...state,
                            selectedRooms: new Array(
                              state.selectedRooms.length
                            ).fill(checked),
                          })
                        }
                      />
                    </th>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {state.rooms.map((room, index) => (
                    <tr key={room._id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={!!state.selectedRooms[index]}
                          onChange={({ target: { checked } }) => {
                            const newSelectedRooms = [...state.selectedRooms];
                            newSelectedRooms[index] = checked;
                            setState({
                              ...state,
                              selectedRooms: newSelectedRooms,
                            });
                          }}
                        />
                      </td>
                      <td>{room.roomIdentifier}</td>
                      <td>{room.name}</td>
                      <td>
                        {room.status === "active" ? (
                          <span
                            className="badge badge-success ml-2"
                            style={{ margin: "auto" }}
                          >
                            Active
                          </span>
                        ) : (
                          <span
                            className="badge badge-dark ml-2"
                            style={{ margin: "auto" }}
                          >
                            Inactive
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
