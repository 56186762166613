import React from "react";
import { useSelector } from "react-redux";
import { SetError } from "../../redux/error.redux";
import { store } from "../../redux/store";
import { AppState } from "../../types/redux";
import "./error_bar.scss";

interface IErrorBarProps {}

export const ErrorBar: React.FC<IErrorBarProps> = () => {
  const error = useSelector((state: AppState) => state.error?.currentError);
  React.useEffect(() => {
    setTimeout(() => {
      store.dispatch(SetError(null));
    }, 10000);
  }, []);

  function close() {
    store.dispatch(SetError(null));
  }

  return (
    <span className="bg-danger text-white p-2" id="error-bar">
      <span>{`An error occured: ${error}`}</span>
      <button onClick={close} className="btn btn-secondary float-right">
        Close
      </button>
    </span>
  );
};
