import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AdminPortalIndex } from "./components/Index/AdminPortalIndex";
import "./index.scss";
import { CustomerPortalIndex } from "./components/Index/CustomerPortalIndex";
import { getSubDomain } from "./utils/url";

enum SubDomain {
  ADMIN = "admin", // Production
  ADMIN2 = "admin2", // Pre-production (master)
  ADMIN_STAGE = "admin-stage", // Stage
  ADMIN_DEV = "admin-dev", // Development
  CUSTOMER = "customer", // Production
  CUSTOMER2 = "customer2", // Pre-production (master)
  CUSTOMER_STAGE = "customer-stage", // Stage
  CUSTOMER_DEV = "customer-dev", // Development
  PORTAL = "portal", // Production
  PORTAL_STAGE = "portal-stage", // Stage
  PORTAL_DEV = "portal-dev", // Development
  PORTAL3 = "portal3",
  CUSTOMER_TEMP = "customer-effect",
}

enum PortalType {
  ADMIN = "admin",
  CUSTOMER = "customer",
}

const subDomainMapping: Record<PortalType, SubDomain[]> = {
  admin: [
    SubDomain.ADMIN,
    SubDomain.ADMIN2,
    SubDomain.ADMIN_DEV,
    SubDomain.ADMIN_STAGE,
    SubDomain.PORTAL,
    SubDomain.PORTAL_DEV,
    SubDomain.PORTAL_STAGE,
    SubDomain.PORTAL3,
  ],
  customer: [
    SubDomain.CUSTOMER,
    SubDomain.CUSTOMER2,
    SubDomain.CUSTOMER_DEV,
    SubDomain.CUSTOMER_STAGE,
    SubDomain.CUSTOMER_TEMP,
  ],
};

const Router = () => {
  return (
    <Routes>
      {subDomainMapping.admin.includes(getSubDomain() as SubDomain) && (
        <Route path="*" element={<AdminPortalIndex />} />
      )}
      {subDomainMapping.customer.includes(getSubDomain() as SubDomain) && (
        <Route path="*" element={<CustomerPortalIndex />} />
      )}
    </Routes>
  );
};

const App = () => {
  return <Router />;
};

const root = ReactDOM.createRoot(document.getElementById("app")!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
