import React from "react";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  useTheme,
} from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteIcon from "@mui/icons-material/Delete";
import VideocamIcon from "@mui/icons-material/Videocam";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  AssetSource,
  AssetType,
  ILeanAsset,
} from "../../../../types/NendaTypes";

interface LeanAssetProps {
  asset: ILeanAsset;
  assetIndex: number;
  onDelete: (index: number) => void;
  dragHandleListeners: any;
}

const styles = {
  dragHandle: {
    verticalAlign: "middle",
    display: "inline-flex",
    paddingLeft: "10px",
    borderLeft: "1px solid #cccccc",
  },
};

const LeanAsset: React.FC<LeanAssetProps> = ({
  asset,
  assetIndex,
  onDelete,
  dragHandleListeners,
}) => {
  const theme = useTheme();

  const openPreviewInNewTab = (asset: ILeanAsset) => {
    let url = asset.locator;
    if (asset.source == AssetSource.REDBEE)
      url = "https://redbee.live/portal/#/cms/videos/" + url;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    /* @ts-ignore */
    <ListItem disablePadding>
      <ListItemButton disableRipple>
        <ListItemAvatar>
          <Avatar
            sx={{
              color: theme.palette.primary.light,
              bgcolor: theme.palette.primary.dark,
            }}
          >
            {asset.type == AssetType.VIDEO && (
              <Tooltip title="Video asset">
                <VideocamIcon />
              </Tooltip>
            )}
            {asset.type == AssetType.LINEAR_CHANNEL && (
              <Tooltip title="Linear channel asset">
                <VoicemailIcon />
              </Tooltip>
            )}
            {asset.type == AssetType.IMAGE && (
              <Tooltip title="Image asset">
                <PhotoCameraIcon />
              </Tooltip>
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ color: theme.palette.primary.dark }}
          secondaryTypographyProps={{ color: theme.palette.primary.light }}
          primary={asset.name}
          secondary={`Source: ${asset.source}`}
        />
        <ListItemSecondaryAction>
          <Tooltip title={asset.locator}>
            <IconButton
              aria-label="preview"
              onClick={() => openPreviewInNewTab(asset)}
            >
              <PreviewIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete from playlist">
            <IconButton
              aria-label="delete"
              onClick={() => onDelete(assetIndex)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Drag and drop to reorder">
            <ListItemIcon style={styles.dragHandle} {...dragHandleListeners}>
              <DragHandleIcon />
            </ListItemIcon>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );
};

export default LeanAsset;
