import { Box, Button, Paper, Stack, Typography, useTheme } from "@mui/material";
import { ActionGridItemProps } from ".";
import { t } from "i18next";
import { ArrowForward } from "@mui/icons-material";

const ActionGridItem = ({ item }: { item: ActionGridItemProps }) => {
  const theme = useTheme();
  return (
    <Stack
      direction={"row"}
      sx={(theme) => ({
        display: item.isVisible ? "flex" : "none",
        py: "0.5rem",
        borderRadius: "0.3rem",
        background: theme.palette.transparent.blue,
        borderLeft: `5px solid ${item.color || theme.palette.secondary.main}`,
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
          transform: "translateY(-2px)",
        },
        [theme.breakpoints.down("md")]: {
          ...item.mobileStyle,
        },
        [theme.breakpoints.up("md")]: {
          ...item.desktopStyle,
        },
      })}
      onClick={item.onClick}
    >
      <Box
        sx={{
          width: "45%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <item.icon
          sx={{
            width: "4rem",
            height: "4rem",
            color: theme.palette.primary.light,
          }}
        />
      </Box>
      <Stack
        justifyContent={"space-between"}
        direction={"column"}
        sx={{ pr: "1rem" }}
      >
        <Box>
          <Typography variant="caption" sx={{ lineHeight: "0rem" }}>
            {t(item.title)}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "0.8rem", mt: "0.5rem" }}>
            {t(item.description)}
          </Typography>
        </Box>
        <ArrowForward sx={{ alignSelf: "flex-end" }} />
      </Stack>
    </Stack>
  );
};

export default ActionGridItem;
