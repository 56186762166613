import React from "react";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { CreateOrganizationUnit } from "../../redux/organizationUnit.redux";
import { Modal } from "../Modal/Modal";
import { AppState } from "../../types/redux";
import { NendaProduct } from "../../../types/NendaTypes";
import { OrderData, SubscriptionData } from "@shared/types/Upsales.types";
import { FaPlus, FaSync } from "react-icons/fa";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";

interface ICreatePremiseButtonProps {
  hotelChainId?: string;
  upsalesSubscription?: SubscriptionData;
  upsalesOrder?: OrderData;
  iconOnly?: boolean;
}

export const CreatePremiseButton: React.FC<ICreatePremiseButtonProps> = ({
  hotelChainId,
  upsalesSubscription,
  upsalesOrder,
  iconOnly,
}) => {
  const [showForm, setShowForm] = React.useState(false);
  const [state, setState] = React.useState<{
    name: string;
    nendaProducts: NendaProduct[];
    parent: string | null;
    password: string | undefined;
    notifyEmailAddress: string | undefined;
  }>({
    name: upsalesSubscription?.description || upsalesOrder?.description || "",
    nendaProducts: [NendaProduct.STREAMING_APP],
    parent: null,
    password: undefined,
    notifyEmailAddress: undefined,
  });
  const hotelChains = useSelector((state: AppState) =>
    state.organizationUnits.organizationUnits.filter(
      (ou) => ou.type === "hotelChain"
    )
  );

  React.useEffect(() => {
    setState({
      name: upsalesSubscription?.description || "",
      nendaProducts: [NendaProduct.STREAMING_APP],
      parent: null,
      password: undefined,
      notifyEmailAddress:
        upsalesSubscription?.regBy.email ||
        upsalesOrder?.regBy.email ||
        undefined,
    });
    generatePassword();
  }, [upsalesSubscription]);

  function submitNewPremise() {
    store.dispatch(
      CreateOrganizationUnit(
        {
          type: "hotel",
          ...state,
          parent: hotelChainId ? hotelChainId : state.parent,
          upsalesCompanyId:
            upsalesSubscription?.client?.id ||
            upsalesOrder?.client?.id ||
            undefined,
          upsalesSubscriptionId: upsalesSubscription?.id,
          password: state.password,
        },
        state.notifyEmailAddress
      )
    );
  }

  function generatePassword() {
    const password = (Math.random() * 10000).toFixed(0).padStart(4, "0");
    setState({ ...state, password });
  }

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    let updatedProducts = [...state.nendaProducts];
    if (checked) {
      updatedProducts.push(name as NendaProduct);
    } else {
      updatedProducts = updatedProducts.filter((x) => x != name);
    }
    setState({ ...state, nendaProducts: [...new Set(updatedProducts)] });
  };

  const handleNotifyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      setState({
        ...state,
        notifyEmailAddress: upsalesSubscription?.regBy.email,
      });
    } else {
      setState({ ...state, notifyEmailAddress: undefined });
    }
  };

  return (
    <>
      <Button
        onClick={() => setShowForm(true)}
        variant="contained"
        color="secondary"
      >
        {iconOnly ? <FaPlus /> : "Add premise"}
      </Button>
      <Modal
        visible={showForm}
        onClose={() => setShowForm(false)}
        title={"Create new premise"}
        onSubmit={submitNewPremise}
      >
        <Box component="form">
          <div>
            {upsalesSubscription?.client || upsalesOrder?.client ? (
              <p>
                Upsales company:{" "}
                {upsalesSubscription?.client.name || upsalesOrder?.client.name}{" "}
                ({upsalesSubscription?.client.id || upsalesOrder?.client.id})
              </p>
            ) : null}
            {upsalesSubscription ? (
              <p>
                Upsales subscription: {upsalesSubscription.description} (
                {upsalesSubscription.id})
              </p>
            ) : null}
            {upsalesOrder ? (
              <p>
                Upsales order: {upsalesOrder.description} ({upsalesOrder.id})
              </p>
            ) : null}
          </div>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              onChange={(e) => setState({ ...state, name: e.target.value })}
              name="name"
              label="Name"
              value={state.name}
              type="text"
              required
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
            <InputLabel htmlFor="password">Password *</InputLabel>
            <OutlinedInput
              onChange={(e) => setState({ ...state, password: e.target.value })}
              id="password"
              name="password"
              label="Password *"
              type="text"
              value={state.password}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => generatePassword()}
                    onMouseDown={(e) => generatePassword()}
                    edge="end"
                  >
                    <FaSync />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {!hotelChainId ? (
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="parent-label">Premise group *</InputLabel>
              <Select
                labelId="parent-label"
                onChange={(e) => setState({ ...state, parent: e.target.value })}
                defaultValue={state.parent || undefined}
                name="parent"
                label="Premise group *"
                required
              >
                {hotelChains.map((hc) => (
                  <MenuItem key={hc._id} value={hc._id}>
                    {hc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
          {upsalesSubscription && upsalesSubscription.regBy ? (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox defaultChecked onChange={handleNotifyChange} />
                }
                label={`Notify ${upsalesSubscription?.regBy.name}`}
              />
            </FormGroup>
          ) : null}
          <FormControl fullWidth sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Switch
                  name={NendaProduct.STREAMING_APP}
                  id={NendaProduct.STREAMING_APP}
                  checked={state.nendaProducts.includes(
                    NendaProduct.STREAMING_APP
                  )}
                  onChange={handleProductChange}
                />
              }
              label="Streaming"
            />
            <FormControlLabel
              control={
                <Switch
                  name={NendaProduct.FRONTLAYER}
                  id={NendaProduct.FRONTLAYER}
                  checked={state.nendaProducts.includes(
                    NendaProduct.FRONTLAYER
                  )}
                  onChange={handleProductChange}
                />
              }
              label="Frontlayer"
            />
            <FormControlLabel
              control={
                <Switch
                  name={NendaProduct.SIGNAGE}
                  id={NendaProduct.SIGNAGE}
                  checked={state.nendaProducts.includes(NendaProduct.SIGNAGE)}
                  onChange={handleProductChange}
                />
              }
              label="Signage"
            />
            <FormControlLabel
              control={
                <Switch
                  name={NendaProduct.SIGNAL_DELIVERY}
                  id={NendaProduct.SIGNAL_DELIVERY}
                  checked={state.nendaProducts.includes(
                    NendaProduct.SIGNAL_DELIVERY
                  )}
                  onChange={handleProductChange}
                />
              }
              label="Signal delivery"
            />
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};
