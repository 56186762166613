import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import FileUpload from "./FileUpload";
import ColorPicker from "../../ColorPicker";
import { FrontLayerBackgroundType } from "../../../../../../types/NendaTypes";

interface BackgroundSelectProps {
  onColorChange: (value: string) => void | undefined;
  onTypeChange: (type: FrontLayerBackgroundType, isEnabled: boolean) => void;
  onUpload: (file: File) => void;
  activeTypes: FrontLayerBackgroundType[];
  selectedColor?: string;
  selectedImageUrl?: string;
  canReset?: boolean;
}

const BackgroundSelect: React.FC<BackgroundSelectProps> = ({
  onColorChange,
  onTypeChange,
  onUpload,
  activeTypes,
  selectedColor,
  selectedImageUrl,
  canReset,
}) => {
  const [fileForUpload, setFileForUpload] = useState<File | undefined>(
    undefined
  );

  const handleTypeChange = (
    type: FrontLayerBackgroundType,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onTypeChange(type, event.target.checked);
  };

  const handleFileSelect = (event: any) => {
    const file = event.target.files[0];
    setFileForUpload(file);
  };

  const handleUpload = () => {
    if (!fileForUpload) return;

    onUpload(fileForUpload);
  };

  return (
    <Box sx={{ display: "flex", gap: "1rem" }}>
      <FormGroup>
        {Object.values(FrontLayerBackgroundType).map(
          (type: FrontLayerBackgroundType) => (
            <FormControlLabel
              key={type}
              control={
                <Checkbox
                  checked={activeTypes.includes(type)}
                  onChange={(e) => handleTypeChange(type, e)}
                  value={type}
                />
              }
              label={type}
            />
          )
        )}
      </FormGroup>
      {activeTypes.includes(FrontLayerBackgroundType.COLOR) && (
        <ColorPicker
          color={selectedColor || ""}
          onChange={onColorChange}
          canReset={canReset}
        />
      )}
      {activeTypes.includes(FrontLayerBackgroundType.IMAGE) && (
        <Box>
          {selectedImageUrl ? (
            <img
              style={{ maxWidth: "400px", margin: "10px 0" }}
              src={selectedImageUrl}
            ></img>
          ) : (
            <Typography>No background image uploaded</Typography>
          )}
          <FileUpload
            onFileSelect={handleFileSelect}
            onUpload={handleUpload}
            file={fileForUpload}
          />
        </Box>
      )}
    </Box>
  );
};

export default BackgroundSelect;
