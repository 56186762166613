import React from "react";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ColorPicker from "../../../ColorPicker";
import BackgroundSelect from "../BackgroundSelect";
import { FrontLayerBackgroundType } from "../../../../../../../types/NendaTypes";

export type ColorControlType = "none" | "font" | "background" | "border";
export type ColorTypeAttribute = "default" | "selected";

interface ColorPopupProps {
  type: ColorControlType;
  color: string;
  selectedColor: string;
  backgroundImageUrl?: string;
  activeBackgroundTypes: FrontLayerBackgroundType[];
  onClose: () => void;
  onFileUpload: (file: File) => void;
  onBackgroundTypeChange: (
    type: FrontLayerBackgroundType,
    isEnabled: boolean
  ) => void;
  onColorChange: (
    type: ColorControlType,
    attribute: ColorTypeAttribute,
    color: string
  ) => void;
}

export const ColorPopup: React.FC<ColorPopupProps> = ({
  type,
  color,
  selectedColor,
  backgroundImageUrl,
  activeBackgroundTypes,
  onClose,
  onColorChange,
  onFileUpload,
  onBackgroundTypeChange,
}) => {
  const handleColorChange =
    (attribute: ColorTypeAttribute) => (newColor: string) => {
      onColorChange(type, attribute, newColor);
    };

  const displayVal = type === "none" ? "none" : "block";
  return (
    <Box
      sx={(theme) => ({
        display: displayVal,
        backgroundColor: "#FFF",
        p: "20px",
        zIndex: 9999,
        position: "absolute",
        top: "100%",
        left: "80%",
        transform: "translate(-50%, -50%)",
        borderRadius: "0.5rem",
        border: `1px solid ${theme.palette.grey[300]}}`,
      })}
    >
      <Typography variant="body2">{type}</Typography>
      {type === "background" ? (
        <BackgroundSelect
          activeTypes={activeBackgroundTypes}
          selectedImageUrl={backgroundImageUrl}
          selectedColor={color}
          onColorChange={handleColorChange("default")}
          onTypeChange={onBackgroundTypeChange}
          onUpload={onFileUpload}
          canReset={true}
        />
      ) : (
        <ColorPicker
          color={color}
          onChange={handleColorChange("default")}
          canReset={true}
        />
      )}

      <Typography variant="body2">selected</Typography>
      <ColorPicker
        color={selectedColor}
        onChange={handleColorChange("selected")}
      />
      <CloseIcon
        sx={{ position: "absolute", top: 5, right: 5, cursor: "pointer" }}
        onClick={onClose}
      />
    </Box>
  );
};
