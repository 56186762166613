import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Typography,
  capitalize,
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  FrontLayerBackgroundType,
  IFrontLayerOptions,
  IFrontlayerConfigValues,
} from "../../../../../../types/NendaTypes";
import InputSelect from "../../../ui-components/input/InputSelect";
import TextInput from "../../../ui-components/input/TextInput";
import ColorPicker from "../../ColorPicker";
import BackgroundSelect from "./BackgroundSelect";
import FileUpload from "./FileUpload";
import FontSelect from "./FontSelect";

const GlobalConfig = ({
  state,
  setState,
  initialFrontlayerConfig,
  frontLayerConfigOptions,
  upload,
  onBorderSelect,
}: {
  setState: (value: IFrontlayerConfigValues) => void;
  state: IFrontlayerConfigValues;
  initialFrontlayerConfig: IFrontlayerConfigValues;
  frontLayerConfigOptions: IFrontLayerOptions;
  upload: (file: File, imageCategory: string) => void;
  onBorderSelect: (
    event: React.ChangeEvent<HTMLInputElement>,
    context: "home" | "subpages" | "backButton"
  ) => void;
}) => {
  const [logoForUpload, setLogoForUpload] = useState(undefined);
  const [imageItemForUpload, setImageItemForUpload] = useState(undefined);

  const selectFileForUpload = (imageCategory: string) => (event) => {
    switch (imageCategory) {
      case "logo":
        setLogoForUpload(event.target.files[0]);
        break;
      case "imageItem":
        setImageItemForUpload(event.target.files[0]);
        break;
      default:
        break;
    }
  };

  const handleViewItemSelect = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    let currentViewItems = [...state.homeScreenItems];
    const selectedItems = typeof value === "string" ? value.split(",") : value;

    // iterate the selected home screen views
    for (const view of selectedItems) {
      const viewItem = currentViewItems.find((x) => x.id === view);
      const defaultItem = frontLayerConfigOptions.availableHomeScreenItems.find(
        (x) => x.id === view
      );
      // if the view is selected but doesnt exist as an item create a default version
      if (!viewItem) {
        if (defaultItem) currentViewItems.push({ ...defaultItem });
      } else {
        // if it already exists as an item set is as enabled and keep any customisation
        // while also updating the default values
        currentViewItems = currentViewItems.map((x) => {
          if (x.id === view) {
            return { ...defaultItem, ...x, enabled: true };
          }
          return x;
        });
      }

      // disable all existing items that doesn't exist in the selection
      // we dont remove it as to keep any customisation done for the specific item
      currentViewItems.forEach((x) => {
        x.enabled = selectedItems.includes(x.id);
      });

      currentViewItems.sort((a, b) => Number(b.enabled) - Number(a.enabled));

      setState({
        ...state,
        homeScreenItems: [...currentViewItems],
      });
    }
  };

  const uploadImage = (imageCategory: string) => () => {
    let image: File | undefined;
    switch (imageCategory) {
      case "logo":
        image = logoForUpload;
        break;
      case "imageItem":
        image = imageItemForUpload;
        break;
    }
    if (image) upload(image, imageCategory);
  };

  const handleFontChange = (value: string) => {
    setState({
      ...state,
      font: value,
    });
  };

  const handleFontColorChange = (value: string) => {
    setState({
      ...state,
      fontColor: value,
    });
  };

  const enabledHomeScreenItems = () => {
    return state.homeScreenItems.filter((x) => x.enabled);
  };

  const handleHomeScreenBackgroundTypeChange = (
    type: FrontLayerBackgroundType,
    isEnabled: boolean
  ) => {
    const backgroundTypes = [...state.backgroundTypes];
    if (isEnabled) {
      backgroundTypes.push(type);
    } else {
      const index = backgroundTypes.indexOf(type);
      backgroundTypes.splice(index, 1);
    }

    setState({
      ...state,
      backgroundTypes: [...new Set(backgroundTypes)],
    });
  };

  const handleColorChange = (value) => {
    setState({
      ...state,
      backgroundColor: value,
    });
  };

  useEffect(() => {
    setLogoForUpload(undefined);
    setImageItemForUpload(undefined);
  }, [initialFrontlayerConfig]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t("customerportal.settings.premise_settings.frontlayer.globals")}
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          display: "grid",
          width: "100%",
          gridTemplateColumns: "1fr 1fr",
          [theme.breakpoints.down("md")]: {
            dipslay: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
          },
          gap: "2rem",
        })}
      >
        <Box sx={{ gridColumn: "span 2" }}>
          <Typography variant="body2">Logo</Typography>
          {state.logoUrl ? (
            <Box>
              <img
                style={{ maxWidth: "200px", marginBottom: "10px" }}
                src={state.logoUrl}
              />
            </Box>
          ) : (
            <Typography variant="body2">No logo uploaded</Typography>
          )}
          <FileUpload
            onFileSelect={selectFileForUpload("logo")}
            onUpload={uploadImage("logo")}
            file={logoForUpload}
          />
        </Box>
        <Box>
          <FontSelect selectedFont={state.font} onSelect={handleFontChange} />
        </Box>
        <Box>
          <Typography variant="body2">Font color</Typography>
          <ColorPicker
            color={state.fontColor || ""}
            onChange={handleFontColorChange}
          />
        </Box>
        <Box>
          <Typography variant="body2">Default item color</Typography>
          <ColorPicker
            color={state.defaultItemColor}
            onChange={(value) =>
              setState({
                ...state,
                defaultItemColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Default selected item color</Typography>
          <ColorPicker
            color={state.defaultSelectedItemColor}
            onChange={(value) =>
              setState({
                ...state,
                defaultSelectedItemColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">
            Default selected item font color
          </Typography>
          <ColorPicker
            color={state.defaultSelectedFontColor}
            onChange={(value) =>
              setState({
                ...state,
                defaultSelectedFontColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Default item border color</Typography>
          <ColorPicker
            color={state.defaultItemBorderColor}
            onChange={(value) =>
              setState({
                ...state,
                defaultItemBorderColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">
            Default selected item border color
          </Typography>
          <ColorPicker
            color={state.defaultSelectedItemBorderColor}
            onChange={(value) =>
              setState({
                ...state,
                defaultSelectedItemBorderColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">
            Change border color on selection
          </Typography>
          <RadioGroup
            row
            name="borderSelectionEnabled"
            value={state.isBorderSelectionEnabled ? "enabled" : "disabled"}
            onChange={(event) => onBorderSelect(event, "home")}
          >
            <FormControlLabel
              value={"enabled"}
              control={<Radio />}
              label="Enabled"
            />
            <FormControlLabel
              value={"disabled"}
              control={<Radio />}
              label="Disabled"
            />
          </RadioGroup>
        </Box>
        <Box>
          <FormControl>
            <Typography id="home-screen-select-label" variant="body2">
              {t(
                "customerportal.settings.premise_settings.frontlayer.home_page_items"
              )}
            </Typography>
            <InputSelect
              labelId="home-screen-select-label"
              id="home-screen-select"
              name="homeScreenItems"
              multiple
              slotProps={{
                textFieldProps: {
                  id: "home-screen-select",
                  label: "Chip",
                  variant: "standard",
                },
              }}
              slot={{ textField: TextInput }}
              value={enabledHomeScreenItems().map((x) => x.id)}
              onChange={handleViewItemSelect}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={capitalize(value)} />
                  ))}
                </Box>
              )}
            >
              {frontLayerConfigOptions.availableHomeScreenItems.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.id}
                </MenuItem>
              ))}
            </InputSelect>
          </FormControl>
        </Box>
        <Box sx={{ width: 300 }}>
          <Typography variant="body2">Default Background</Typography>

          <BackgroundSelect
            onColorChange={handleColorChange}
            onTypeChange={handleHomeScreenBackgroundTypeChange}
            onUpload={(file: File) => upload(file, "homeScreenBackground")}
            activeTypes={state.backgroundTypes || []}
            selectedColor={state.backgroundColor}
            selectedImageUrl={state.backgroundImageUrl}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default GlobalConfig;
