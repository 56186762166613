import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import { CustomerPortalState } from "..";
import { NavigationLevel } from "../../../../../types/NendaTypes";

export interface NavigationScope {
  company: string | undefined;
  premise: string | undefined;
}

export interface WorkspaceState {
  scope: NavigationScope;
}

export const initialState: WorkspaceState = {
  scope: {
    company: "",
    premise: "",
  },
};

const workspaceSlice: Slice<WorkspaceState> = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setScope: (state, action: PayloadAction<NavigationScope>) => {
      state.scope = action.payload;
    },
    setCompanyScope: (state, action: PayloadAction<string>) => {
      state.scope.company = action.payload;
      state.scope.premise = "";
    },
    setPremiseScope: (state, action: PayloadAction<string>) => {
      state.scope.premise = action.payload;
    },
  },
});

export const selectNavigationScope = (
  state: CustomerPortalState
): WorkspaceState["scope"] => {
  return state.workspace.scope;
};

export const selectCurrentNavigationLevel = (
  state: CustomerPortalState
): NavigationLevel => {
  const { company, premise } = state.workspace.scope;
  if (company && premise) {
    return NavigationLevel.PREMISE;
  }
  if (company) {
    return NavigationLevel.COMPANY;
  }
  return NavigationLevel.ADMIN;
};

export const selectNavigatedCompanyId = (state: CustomerPortalState) =>
  state.workspace.scope.company;
export const selectNavigatedPremiseId = (state: CustomerPortalState) =>
  state.workspace.scope.premise;

export const { setScope, setCompanyScope, setPremiseScope } =
  workspaceSlice.actions;

export default workspaceSlice.reducer;
