import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { NestedKeys } from "../../../../../types/HelperTypes";
import {
  IContentChannel,
  Resource,
  Scope,
} from "../../../../../types/NendaTypes";
import AddEditChannel from "../../components/Content/AddEditChannel";
import PermissionsGate from "../../components/PermissionGate";
import { CustomerPortalState, store } from "../../store";
import {
  AddContentChannel,
  GetContentChannels,
  UpdateContentChannel,
  selectContentChannel,
  selectContentChannels,
} from "../../store/reducers/contentChannelReducer";

const ContentChannel = () => {
  const { channelId }: any = useParams();

  React.useEffect(() => {
    store.dispatch(GetContentChannels());
  }, []);

  const channel = useSelector((state: CustomerPortalState) => {
    return selectContentChannel(state, channelId);
  });

  const channels = useSelector((state: CustomerPortalState) => {
    return selectContentChannels(state);
  });

  const handleSave = (channel: IContentChannel) => {
    const unsetFields: NestedKeys<IContentChannel>[] = [];
    if (channel.logo === undefined) {
      unsetFields.push("logo");
    }
    if (channel.posterUrl === undefined) {
      unsetFields.push("posterUrl");
    }

    store.dispatch(UpdateContentChannel(channel, unsetFields));
  };

  const handleAdd = (channel: IContentChannel) => {
    store.dispatch(AddContentChannel(channel));
  };

  return (
    <PermissionsGate
      restriction={{
        resource: Resource.ContentChannel,
        scopes: [Scope.CanAdministrate],
      }}
    >
      <Box>
        <Button href="/admin/content/" startIcon={<ArrowBackIcon />}>
          Back
        </Button>
        <Typography
          mb={2}
          variant="h3"
          sx={{ display: "flex", justifyContent: "start", py: "1rem" }}
        >
          {t("customerportal.pages.content_channels.edit_channel")}
        </Typography>
        <AddEditChannel
          categories={channels
            .map((c) => c.category)
            .filter((v, i, a) => a.indexOf(v) === i)}
          onAdd={handleAdd}
          editChannel={channel}
          showPlaylist={true}
          onSave={handleSave}
        />
      </Box>
    </PermissionsGate>
  );
};

export default ContentChannel;
