import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { AutoComplete, SetAutocomplete } from "../../redux/asset.redux";
import { store } from "../../redux/store";
import { AppState } from "../../types/redux";
import "./AutocompleteAssets.scss";

interface IAutocompleteAssetsComponentProps {
  carouselAssetIds: Array<string>;
  onSelect(assetId: string): void;
}
export const AutocompleteAssetsComponent: React.FC<
  IAutocompleteAssetsComponentProps
> = ({ carouselAssetIds, onSelect }) => {
  const suggestions = useSelector(
    (state: AppState) => state.assets?.autocomplete
  );

  function onInputChange(e) {
    if (e.target.value.length > 1) store.dispatch(AutoComplete(e.target.value));
  }

  return (
    <>
      <div className="input-group">
        <input className="form-control" type="text" onChange={onInputChange} />
      </div>
      <ul className="list-group">
        {suggestions?.map((s) => (
          <li
            className="list-group-item d-flex justify-content-between align-items-center"
            key={s.assetId}
          >
            {s.type}: {s.text}
            <button
              className={`btn btn-secondary float-right ${
                carouselAssetIds.includes(s.assetId) ? "btn-success" : ""
              }`}
              onClick={() => {
                onSelect(s.assetId);
                store.dispatch(SetAutocomplete([]));
              }}
            >
              {!carouselAssetIds.includes(s.assetId) ? <FaPlus /> : <FaCheck />}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};
