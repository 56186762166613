import {
  FormControl,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { Company, CompanyType } from "../../../../../types/NendaTypes";
import { getCompanies } from "../../store/reducers/companyReducer";
import InputSelect from "../../ui-components/input/InputSelect";

interface CompanySelectProps {
  company: string;
  setCompany: (value: string) => void;
}

export default function CompanySelect({
  company,
  setCompany,
}: CompanySelectProps) {
  const companies = useSelector(getCompanies);

  const handleCompanyChange = (event: SelectChangeEvent<string>) => {
    setCompany(event.target.value);
  };

  const filteredCompanies = companies.filter(
    (c: Company) => c.type === CompanyType.CUSTOMER
  );

  return (
    <Paper sx={{ px: 1 }}>
      <Stack sx={{ width: "fit-content", mt: "0.5rem" }} gap={1}>
        <FormControl sx={{ minWidth: "30ch" }}>
          {filteredCompanies && (
            <InputSelect
              labelId="company-select-label"
              label={t("form.address.select_company")}
              variant="standard"
              defaultValue={company || ""}
              id="company-select"
              value={company}
              onChange={handleCompanyChange}
            >
              {filteredCompanies.map((c) => (
                <MenuItem key={c._id} value={c._id}>
                  {c?.name}
                </MenuItem>
              ))}
            </InputSelect>
          )}
        </FormControl>
      </Stack>
    </Paper>
  );
}
