import InfoIcon from "@mui/icons-material/Info";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TVIcon from "@mui/icons-material/Tv";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IRoom,
  NendaProduct,
  Resource,
  Scope,
} from "../../../../../../types/NendaTypes";
import { getCompanyIdByPremise } from "../../../../../utils/company";
import { CustomerPortalState, store } from "../../../store";
import { getCompany } from "../../../store/reducers/companyReducer";
import {
  selectPremiseById,
  selectRoomTypesByPremiseGroupId,
} from "../../../store/reducers/organizationUnitReducer";
import { updateScreen } from "../../../store/reducers/screenReducer";
import { selectStreamChannels } from "../../../store/reducers/streamChannelReducer";
import PermissionsGate from "../../PermissionGate";
import NendaProducts from "../NendaProducts";
import StreamChannelSelect from "./SignalDelivery/StreamChannelSelect";

interface ScreenItemProps {
  screen: IRoom;
}

const ScreenItem: React.FC<ScreenItemProps> = ({ screen }) => {
  const [state, setState] = useState(screen);
  const premise = useSelector((state: CustomerPortalState) =>
    selectPremiseById(state, screen.hotel)
  );

  const company = useSelector((state: CustomerPortalState) => {
    return getCompany(state, getCompanyIdByPremise(premise));
  });

  const { nendaProducts: productsOnPremise } = premise!;

  const availableStreamChannels = useSelector(selectStreamChannels);

  const handleProductChange = (productName: NendaProduct) => {
    let updatedProducts = [...state.nendaProducts];
    if (updatedProducts.indexOf(productName) === -1) {
      updatedProducts.push(productName);
    } else {
      updatedProducts = updatedProducts.filter((x) => x != productName);
    }
    setState({ ...state, nendaProducts: updatedProducts });
  };

  const handleStreamChannelChange = (selectedChannelIds: string[]) => {
    const updatedState: IRoom = JSON.parse(JSON.stringify(state));

    updatedState.streamChannels = selectedChannelIds;
    setState(updatedState);
  };

  const saveChanges = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    store.dispatch(updateScreen(state));
  };

  const clearDeviceId = () => {
    store.dispatch(updateScreen({ ...screen, deviceId: "" }));
  };

  const theme = useTheme();

  useEffect(() => {
    setState(screen);
  }, [screen]);
  const roomTypes = useSelector((state: CustomerPortalState) =>
    selectRoomTypesByPremiseGroupId(state, screen.hotelChain)
  );
  const currentRoomType = roomTypes.find((rt) => rt._id === screen.roomType);

  return (
    <React.Fragment>
      <Card sx={{ p: 2, mb: 2, boxShadow: theme.shadows[24] }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1, gap: 1 }}>
          <TVIcon /> <Typography variant="h6">{screen.name}</Typography>{" "}
          {screen.description && (
            <Tooltip title={screen.description}>
              <InfoIcon color="primary" />
            </Tooltip>
          )}
          <Chip
            label={currentRoomType?.name}
            sx={(theme) => ({
              background: theme.palette.transparent.blue,
              color: theme.palette.text.secondary,
            })}
          />
        </Box>
        <Divider />
        <Box sx={{ pt: 2 }}>
          <NendaProducts
            selectedProducts={state.nendaProducts}
            availableProducts={productsOnPremise}
            onProductSelect={handleProductChange}
          />
          {state.nendaProducts?.includes(NendaProduct.SIGNAL_DELIVERY) && (
            <PermissionsGate
              restriction={{
                resource: Resource.StreamChannel,
                scopes: [Scope.CanAdministrate],
              }}
            >
              <StreamChannelSelect
                availableChannels={availableStreamChannels}
                selectedChannelIds={state.streamChannels}
                onChange={handleStreamChannelChange}
              />
            </PermissionsGate>
          )}
          <Box sx={{ display: "flex", alignItems: "baseline", gap: "1rem" }}>
            <Button
              type="button"
              onClick={saveChanges}
              variant="contained"
              sx={{ mt: 2 }}
              endIcon={<SaveAltIcon />}
            >
              {t("common.save")}
            </Button>
            <Button
              type="button"
              onClick={clearDeviceId}
              disabled={!state.deviceId}
              variant="outlined"
              sx={{ mt: 2 }}
            >
              {t(
                "customerportal.pages.dashboard.screen.edit_screen.clear_device_id"
              )}
            </Button>
            {state.deviceId && (
              <Typography
                variant="caption"
                sx={{ color: theme.palette.grey[700] }}
              >
                {t(
                  "customerportal.pages.dashboard.screen.edit_screen.device_id"
                )}
                : {state.deviceId}
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default ScreenItem;
