import { IOrganizationUnit } from "../../types/NendaTypes";
import { OrganizationUnit } from "../models/organizationUnit";

export const getCompanyIdByPremise = (
  premise: IOrganizationUnit | undefined
) => {
  if (!premise) return null;
  const companyId =
    premise?.company instanceof Object
      ? premise?.company._id
      : premise?.company;

  return companyId;
};
