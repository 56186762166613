import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import FrontlayerSettings from "../components/Frontlayer/FrontlayerSettings";
import { CustomerPortalState, store } from "../store";
import {
  GetFrontlayerConfigByPremiseId,
  selectCopyFromConfig,
  selectFrontlayerConfig,
} from "../store/reducers/frontlayerConfigReducer";
import { selectPremiseById } from "../store/reducers/organizationUnitReducer";
import { selectNavigatedPremiseId } from "../store/reducers/workspaceReducer";
import HelpWidget from "../ui-components/display/HelpWidget";
import PermissionsGate from "../components/PermissionGate";
import { NendaProduct, Resource, Scope } from "../../../../types/NendaTypes";

const FrontlayerPage = () => {
  const theme = useTheme();
  const frontlayerConfig = useSelector(selectFrontlayerConfig);
  const copyFromConfig = useSelector(selectCopyFromConfig);

  const premiseId = useSelector(selectNavigatedPremiseId);

  useEffect(() => {
    if (premiseId) {
      store.dispatch(GetFrontlayerConfigByPremiseId(premiseId || ""));
    }
  }, [premiseId]);

  const premise = useSelector((state: CustomerPortalState) =>
    selectPremiseById(state, premiseId || "")
  );

  if (
    !frontlayerConfig ||
    !premise?.nendaProducts.includes(NendaProduct.FRONTLAYER)
  ) {
    return (
      <Box>
        <Typography>No config found</Typography>
      </Box>
    );
  }

  return (
    <PermissionsGate
      restriction={{
        resource: Resource.FrontlayerConfig,
        scopes: [Scope.CanEdit],
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "baseline",
            justifyContent: "space-between",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              gap: "0",
            },
          }}
        >
          <Typography mb={2} variant="h3">
            {t("customerportal.pages.frontlayer.title")}
          </Typography>
          <HelpWidget
            title={t("customerportal.pages.frontlayer.help.title")}
            details={t("customerportal.pages.frontlayer.help.description")}
            placement="bottom-end"
          />
        </Box>
        <Box
          sx={{
            py: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">
            {t("customerportal.pages.frontlayer.information_items")}
          </Typography>
          {frontlayerConfig && premise && (
            <FrontlayerSettings
              frontlayerConfig={frontlayerConfig}
              premise={premise}
              copyFromConfig={copyFromConfig}
            />
          )}
        </Box>
      </Box>
    </PermissionsGate>
  );
};

export default FrontlayerPage;
