import { Hour, Minute } from "../../types/HelperTypes";
import {
  ContentScheduleType,
  IDailyContentPlaylist,
  Time,
} from "../../types/NendaTypes";
import { ContentTimelineInterval } from "../components/CustomerPortal/store/reducers/screenReducer";
import { dateWithoutTimezone } from "./timeUtil";

export const getSlotPositions = ({
  mode,
  startDate,
  endDate,
  slotStartDate,
  slotEndDate,
}: {
  mode: "day" | "week" | "month";
  startDate: Date;
  endDate: Date;
  slotStartDate: Date;
  slotEndDate: Date;
}) => {
  let startPosition: number, width: number;
  slotStartDate = dateWithoutTimezone(slotStartDate);
  slotEndDate = dateWithoutTimezone(slotEndDate);

  startPosition =
    ((slotStartDate.getTime() - startDate.getTime()) /
      (endDate.getTime() - startDate.getTime())) *
    100;
  width =
    ((slotEndDate.getTime() - slotStartDate.getTime()) /
      (endDate.getTime() - startDate.getTime())) *
    100;

  return {
    startPosition,
    width,
  };
};

interface PrettyTimeObject {
  value: Time;
  prettyTime: string;
}

export function getHoursAndHalfHoursOfDay(): PrettyTimeObject[] {
  const hoursAndHalfHoursOfDay: PrettyTimeObject[] = [];

  for (let hour = 0 as Hour; hour < 24; hour++) {
    for (const minute of [0, 30] as Minute[]) {
      const timeObject: Time = { hour, minute };
      const prettyTime = `${hour < 10 ? "0" : ""}${hour}:${
        minute === 0 ? "00" : "30"
      }`;
      const prettyTimeObject: PrettyTimeObject = {
        value: timeObject,
        prettyTime,
      };

      hoursAndHalfHoursOfDay.push(prettyTimeObject);
    }
  }

  return hoursAndHalfHoursOfDay;
}

type MidnightHour = 0 | 24;

export const getPrettyTimeFromTimeObject = (
  timeObject: Time,
  midnight: MidnightHour = 0
) => {
  let { hour, minute } = timeObject;
  hour = (hour === 0 ? midnight : hour) as Hour;
  return `${hour < 10 ? "0" : ""}${hour}:${minute < 10 ? "0" : ""}${minute}`;
};

export const getPrettyTimeRange = (timeRange: { start: Time; end: Time }) => {
  return `${getPrettyTimeFromTimeObject(
    timeRange.start
  )} - ${getPrettyTimeFromTimeObject(timeRange.end, 24)}`;
};

export const playlistToContentTimelineIntervals = (
  playlist: IDailyContentPlaylist
): ContentTimelineInterval[] => {
  if (!playlist.timeline) return [];
  return playlist.timeline.map((interval) => {
    return {
      start: new Date(
        new Date().setUTCHours(
          interval.interval.start.hour,
          interval.interval.start.minute
        )
      ),
      end: new Date(
        new Date().setUTCHours(
          interval.interval.end.hour,
          interval.interval.end.minute
        )
      ),
      contentChannel: interval.contentChannel,
      type: ContentScheduleType.SCHEDULED,
    };
  });
};
