import React, { useEffect, useState } from "react";
import { CacheLevel } from "../../../types/NendaTypes";
import { ClearCacheButton } from "../../components/Buttons/ClearCacheButton/ClearCacheButton";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { configService } from "../../http/config.service";
import { UpdateSettingsForm } from "./UpdateSettingsForm";

export const SettingsOverview = () => {
  const [failedJobs, setFailedJobs] = useState<any[]>([]);
  useEffect(() => {
    configService
      .getFailedJobs()
      .toPromise()
      .then((res) => {
        setFailedJobs(res);
      });
  }, []);

  return (
    <>
      <PageHeader>
        <h2>Settings</h2>
        <div className="d-flex my-4">
          <ClearCacheButton cacheLevel={CacheLevel.All} />
        </div>
      </PageHeader>
      <UpdateSettingsForm />
      <h3 className="my-4">Failed jobs</h3>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Time</th>
            <th scope="col">Body</th>
            <th scope="col">Reason</th>
            <th scope="col">Id</th>
          </tr>
        </thead>
        <tbody>
          {failedJobs.map((job, index) => {
            return (
              <tr key={`${job.messageId} + ${index}`}>
                <td>{job.enqueuedTimeUtc}</td>
                <td>{JSON.stringify(job.body)}</td>
                <td>{job.deadLetterReason}</td>
                <td>{job.messageId}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
