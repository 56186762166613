import React, { ReactNode } from "react";
import "./PageHeader.scss";

interface IPageHeaderProps {
  children: ReactNode;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({ children }) => {
  return <nav className="page-header">{children}</nav>;
};
