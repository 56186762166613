import React from "react";
import { useSelector } from "react-redux";
import { CreatePremiseButton } from "../../components/CreatePremiseButton/CreatePremiseButton";
import { CreatePremiseGroupButton } from "../../components/CreatePremiseGroupButton/CreatePremiseGroupButton";
import { Grid } from "../../components/Grid/Grid";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { SectionWrapper } from "../../components/UI/SectionWrapper/SectionWrapper";
import { AppState } from "../../types/redux";
import { Link } from "react-router-dom";
import { organizationUnitService } from "../../http/organizationUnit.service";
import { BaseConfigEditor } from "../../components/OrganizationUnit/BaseConfigEditor";
import { store } from "../../redux/store";
import { GetOrders, GetSubscriptions } from "../../redux/upsales.redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  Chip,
} from "@mui/material";
import { format } from "date-fns";
import { ExpandMore } from "@mui/icons-material";
import {
  UpsalesProduct,
  UpsalesProductCategory,
} from "@shared/types/Upsales.types";
import { stringToColor } from "../../utils/string";

interface IOrganizationUnitsOverviewProps {}
export const OrganizationUnitsOverview: React.FC<
  IOrganizationUnitsOverviewProps
> = () => {
  const organizationUnits = useSelector(
    (state: AppState) => state.organizationUnits?.organizationUnits
  );
  const upsalesSubscriptions = useSelector(
    (state: AppState) => state.upsales.subscriptions
  );
  const upsalesOrders = useSelector((state: AppState) => state.upsales.orders);

  const [customerBaseConfig, setCustomerBaseConfig] = React.useState("");

  const hiddenProducts: Array<number> = [
    UpsalesProduct.FREIGHT,
    UpsalesProduct.INVOICING_FEE,
    UpsalesProduct.PLATFORM_FEE,
    UpsalesProduct.PLATFORM_FEE_HLS,
    UpsalesProduct.PROVISION,
    UpsalesProduct.PROVISION_ROLLING,
    UpsalesProduct.STARTING_FEE,
    UpsalesProduct.EFFECT_STARTING_FEE,
    UpsalesProduct.EFFECT_OTHER_HARDWARE,
    UpsalesProduct.EFFECT_BOX_ACTIVATION_FEE,
    UpsalesProduct.AMINO_BOX,
  ];

  const hiddenProductCategories: Array<number> = [
    UpsalesProductCategory.ADDITIONAL_SERVICES,
    UpsalesProductCategory.HARDWARE,
    UpsalesProductCategory.INSTALLATION,
    UpsalesProductCategory.OTHER,
    UpsalesProductCategory.SALES_COSTS,
    UpsalesProductCategory.TECH,
  ];

  const saveConfig = async () => {
    try {
      await organizationUnitService
        .updateCustomerBaseConfig(customerBaseConfig)
        .toPromise();
      organizationUnitService
        .getCustomerBaseConfig()
        .toPromise()
        .then((config) =>
          setCustomerBaseConfig(JSON.stringify(config, null, 2))
        );
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    organizationUnitService
      .getCustomerBaseConfig()
      .toPromise()
      .then((config) => setCustomerBaseConfig(JSON.stringify(config, null, 2)));
    store.dispatch(GetSubscriptions());
    store.dispatch(GetOrders());
  }, []);

  return (
    <>
      <PageHeader>
        <h2>Premises overview</h2>
        <CreatePremiseGroupButton />
        <CreatePremiseButton />
        <Link to={`/organizationunits/report-all`}>
          <Button variant="outlined" color="secondary">
            Report all
          </Button>
        </Link>
      </PageHeader>
      <SectionWrapper>
        <BaseConfigEditor
          configContent={customerBaseConfig}
          handleChange={setCustomerBaseConfig}
          handleSave={saveConfig}
        />
      </SectionWrapper>
      <SectionWrapper>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="accordion-summary-gray"
          >
            Premise Groups (
            {organizationUnits?.filter((o) => o.type === "hotelChain").length})
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              items={organizationUnits
                .filter((o) => o.type === "hotelChain")
                .map((hotelChain) => ({
                  name: hotelChain.name,
                  id: hotelChain._id,
                  link: `/organizationunits/hotelchain/${hotelChain._id}`,
                }))}
            />
          </AccordionDetails>
        </Accordion>
      </SectionWrapper>
      <SectionWrapper>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="accordion-summary-gray"
          >
            Premises (
            {organizationUnits?.filter((o) => o.type === "hotel").length})
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              items={organizationUnits
                .filter((o) => o.type === "hotel")
                .map((hotel) => ({
                  name: hotel.name,
                  id: hotel._id,
                  link: `/organizationunits/hotelchain/${hotel.parent}/hotel/${hotel._id}`,
                }))}
            />
          </AccordionDetails>
        </Accordion>
      </SectionWrapper>
      <SectionWrapper>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="accordion-summary-gray"
          >
            New orders ({upsalesOrders?.length})
          </AccordionSummary>
          <AccordionDetails>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">Company</th>
                  <th scope="col">Order</th>
                  <th scope="col">Created</th>
                  <th scope="col">Summary</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {upsalesOrders ? (
                  upsalesOrders
                    .sort((a, b) => (a.regDate < b.regDate ? -1 : 1))
                    .map((order) => {
                      return (
                        <tr key={order.id}>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {order.client.name}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {order.description}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {format(new Date(order.regDate), "yyyy-MM-dd")}
                          </td>
                          <td>
                            {order.orderRow
                              .filter(
                                (row) =>
                                  row.product &&
                                  !hiddenProducts.includes(row.product.id) &&
                                  !hiddenProductCategories.includes(
                                    row.product.category?.id
                                  )
                              )
                              .map((row) => {
                                return (
                                  <Badge
                                    key={row.product!.id}
                                    badgeContent={row.quantity}
                                    sx={{
                                      "& .MuiBadge-badge": {
                                        backgroundColor: stringToColor(
                                          row.product!.category?.name
                                        ),
                                      },
                                    }}
                                    className="mr-4 mb-2"
                                  >
                                    <Chip label={row.product!.name} />
                                  </Badge>
                                );
                              })}
                          </td>
                          <td>
                            <CreatePremiseButton
                              iconOnly={true}
                              upsalesOrder={order}
                            />
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={4}>Loading...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
      </SectionWrapper>
      <SectionWrapper>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="accordion-summary-gray"
          >
            Unassigned active subscriptions ({upsalesSubscriptions?.length})
          </AccordionSummary>
          <AccordionDetails>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">Company</th>
                  <th scope="col">Subscription</th>
                  <th scope="col">Created</th>
                  <th scope="col">Summary</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {upsalesSubscriptions ? (
                  upsalesSubscriptions
                    .filter(
                      (sub) =>
                        organizationUnits.findIndex(
                          (o) => o.upsalesSubscriptionId == sub.id
                        ) == -1
                    )
                    .sort((a, b) => (a.regDate < b.regDate ? -1 : 1))
                    .map((sub) => {
                      return (
                        <tr key={sub.id}>
                          <td>{sub.client.name}</td>
                          <td>{sub.description}</td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {format(new Date(sub.regDate), "yyyy-MM-dd")}
                          </td>
                          <td>
                            {sub.orderRow
                              .filter(
                                (row) =>
                                  row.product &&
                                  !hiddenProducts.includes(row.product.id) &&
                                  !hiddenProductCategories.includes(
                                    row.product.category?.id
                                  )
                              )
                              .map((row) => {
                                return (
                                  <Badge
                                    key={row.product!.id}
                                    badgeContent={row.quantity}
                                    color="primary"
                                    className="mr-4 mb-2"
                                  >
                                    <Chip label={row.product!.name} />
                                  </Badge>
                                );
                              })}
                          </td>
                          <td>
                            <CreatePremiseButton
                              iconOnly={true}
                              upsalesSubscription={sub}
                            />
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={4}>Loading...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
      </SectionWrapper>
    </>
  );
};
