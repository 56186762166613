import { httpClient, Service } from "./client";

class ProductOfferingService extends Service {
  getProductOfferings(hotelChainId: string) {
    return httpClient.get(
      `/productofferings/${hotelChainId}`,
      this.authHeader()
    );
  }
  getProductOfferingById(hotelChainId: string, productOfferingId: string) {
    return httpClient.get(
      `/productofferings/${hotelChainId}/${productOfferingId}`,
      this.authHeader()
    );
  }
  editProductOffering(
    hotelChainId: string,
    productOfferingId: string,
    labels: any
  ) {
    return httpClient.put(
      `/productofferings/${hotelChainId}/${productOfferingId}`,
      labels,
      this.authHeader()
    );
  }
}

export const productOfferingService = new ProductOfferingService();
