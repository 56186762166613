import { Button } from "@mui/material";
import React, { ReactNode } from "react";
import { OrganizationUnitType } from "../../../types/NendaTypes";
import {
  DeleteOrganizationUnit,
  DeleteRoom,
} from "../../redux/organizationUnit.redux";
import { store } from "../../redux/store";
import { Confirm } from "../Confirm/Confirm";

interface IDeleteOUWidgetProps {
  ouId: string;
  type?: OrganizationUnitType;
  children?: ReactNode;
  onConfirmDelete?(): void;
}

export const DeleteOUWidget: React.FC<IDeleteOUWidgetProps> = ({
  ouId,
  type,
  children,
  onConfirmDelete,
}) => {
  const [show, setShow] = React.useState(false);
  function deleteOu() {
    if (onConfirmDelete) {
      onConfirmDelete();
      return;
    }
    if (type === "room") {
      store.dispatch(DeleteRoom(ouId));
    } else {
      store.dispatch(DeleteOrganizationUnit(ouId));
    }
  }
  return (
    <>
      {show && <Confirm onCancel={() => setShow(false)} onConfirm={deleteOu} />}
      <Button onClick={() => setShow(true)} variant="contained" color="error">
        {children}
      </Button>
    </>
  );
};
