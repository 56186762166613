import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import {
  FrontLayerBackgroundType,
  IRadioPage,
} from "../../../../../../../types/NendaTypes";
import ColorPicker from "../../../ColorPicker";
import constants from "@shared/constants";
import BackgroundSelect from "../BackgroundSelect";
import { ensureArray } from "@shared/arrayUtils";

interface IRadioProps {
  config: IRadioPage;
  onConfigChange: (config: IRadioPage) => void;
  onUpload: (file: File) => void;
}

const RadioStations: React.FC<IRadioProps> = ({
  config,
  onConfigChange,
  onUpload,
}) => {
  const handleRadioStationChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;

    onConfigChange({ ...config, selectedLocalP4Station: value });
  };

  const handleBackgroundTypeChange = (
    type: FrontLayerBackgroundType,
    isEnabled: boolean
  ) => {
    const backgroundTypes = [...ensureArray(config.backgroundTypes)];
    if (isEnabled) {
      backgroundTypes.push(type);
    } else {
      const index = backgroundTypes.indexOf(type);
      backgroundTypes.splice(index, 1);
    }

    onConfigChange({
      ...config,
      backgroundTypes,
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "2rem" }}
      >
        <BackgroundSelect
          canReset={true}
          onColorChange={(value) =>
            onConfigChange({
              ...config,
              backgroundColor: value,
            })
          }
          onTypeChange={handleBackgroundTypeChange}
          onUpload={onUpload}
          activeTypes={config.backgroundTypes || []}
          selectedColor={config.backgroundColor}
          selectedImageUrl={config.backgroundImageUrl}
        />
        <Box>
          <Typography variant="body2">P4 Default Station</Typography>
          <FormControl sx={{ width: 300 }} margin="dense">
            <InputLabel id="p4-station-label">P4 station</InputLabel>
            <Select
              labelId="p4-station-label"
              label="P4 station"
              value={config.selectedLocalP4Station}
              onChange={handleRadioStationChange}
              name="selectedLocalP4Station"
            >
              {constants.LOCAL_RADIO_STATIONS.map((station) => (
                <MenuItem key={station.id} value={station.id}>
                  {station.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Typography variant="body2">Color</Typography>
          <ColorPicker
            canReset={true}
            color={config.defaultItemColor || ""}
            onChange={(value) =>
              onConfigChange({
                ...config,
                defaultItemColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Selected color</Typography>
          <ColorPicker
            canReset={true}
            color={config.defaultSelectedItemColor || ""}
            onChange={(value) =>
              onConfigChange({
                ...config,

                defaultSelectedItemColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Border color</Typography>
          <ColorPicker
            canReset={true}
            color={config.defaultItemBorderColor || ""}
            onChange={(value) =>
              onConfigChange({
                ...config,

                defaultItemBorderColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Selected border color</Typography>
          <ColorPicker
            canReset={true}
            color={config.defaultSelectedItemBorderColor || ""}
            onChange={(value) =>
              onConfigChange({
                ...config,

                defaultSelectedItemBorderColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Font color</Typography>
          <ColorPicker
            canReset={true}
            color={config.fontColor}
            onChange={(value) =>
              onConfigChange({
                ...config,
                fontColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Selected font color</Typography>
          <ColorPicker
            canReset={true}
            color={config.selectedFontColor}
            onChange={(value) =>
              onConfigChange({
                ...config,
                selectedFontColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">
            Change border color on selection
          </Typography>
          <RadioGroup
            row
            name="borderSelectionEnabled"
            value={config.isBorderSelectionEnabled ? "enabled" : "disabled"}
            onChange={(event) => {
              const value = (event.target as HTMLInputElement).value;
              onConfigChange({
                ...config,
                isBorderSelectionEnabled: value === "enabled" ? true : false,
              });
            }}
          >
            <FormControlLabel
              value={"enabled"}
              control={<Radio />}
              label="Enabled"
            />
            <FormControlLabel
              value={"disabled"}
              control={<Radio />}
              label="Disabled"
            />
          </RadioGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default RadioStations;
