import React from "react";
import "./notification_bar.scss";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { SetNotification } from "../../redux/notification.redux";

export const NotificationBar = () => {
  const currentNotification = useSelector(
    (state) => state.notification.currentNotification
  );
  React.useEffect(() => {
    setTimeout(() => {
      store.dispatch(SetNotification(null));
    }, 10000);
  }, [currentNotification]);
  function close() {
    store.dispatch(SetNotification(null));
  }
  if (!currentNotification || !currentNotification.message) {
    return <></>;
  }
  const { message, bgClass } = currentNotification;
  return (
    <span className={`${bgClass} text-white p-2"`} id="notification-bar">
      <span>{message}</span>
      <button onClick={close} className="btn btn-secondary float-right">
        Close
      </button>
    </span>
  );
};
