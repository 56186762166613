import { createSelector } from "@reduxjs/toolkit";
import { Epic, ofType } from "redux-observable";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { CustomerPortalState } from "..";
import {
  ContentManagmentData,
  ContentOrder,
  ContentOrderResponse,
  IOrganizationUnit,
  IRoomType,
  LoadingStatus,
  NendaProduct,
  OrganizationUnitType,
} from "../../../../../types/NendaTypes";
import {
  IListResponse,
  IOrganizationUnitListRequest,
} from "../../../../../types/RequestTypes";
import { organizationUnitService } from "../../../../http/organizationUnit.service";
import { OrganizationUnit } from "../../../../models/organizationUnit";
import {
  GetPremisesAction,
  ORGANIZATION_UNITS_ACTIONS,
  SetPremiseAction,
  SetPremisesAction,
  UpdateOrganizationUnitAction,
  UpdateOrganizationUnitSuccessAction,
  UploadImageFailureAction,
} from "../../../../types/redux";
import { getCompanyIdByPremise } from "../../../../utils/company";
import { handleError, SetError } from "./errorReducer";
import { SetNotification } from "./notificationReducer";
import { selectNavigatedPremiseId } from "./workspaceReducer";
import { of } from "rxjs";

export interface OrganizationUnitAction {
  organizationUnits: any;
  type: ORGANIZATION_UNITS_ACTIONS;
  payload?: any;
}
export interface OrganizationUnitState {
  isLoading: boolean;
  isLogoUploadLoading: boolean;
  isBackgroundUploadLoading: boolean;
  isImageItemUploading: boolean;
  premises: IListResponse<IOrganizationUnit>;
  createPremiseStatus: LoadingStatus;
  createNewSetupStatus: LoadingStatus;
  contentPackages: ContentManagmentData[];
  contentOrder: {
    status: LoadingStatus;
    response: any;
  };
}

export const initialOrganizationUnitState: OrganizationUnitState = {
  isLoading: false,
  isLogoUploadLoading: false,
  isBackgroundUploadLoading: false,
  isImageItemUploading: false,
  createPremiseStatus: LoadingStatus.IDLE,
  createNewSetupStatus: LoadingStatus.IDLE,
  contentOrder: {
    status: LoadingStatus.IDLE,
    response: null,
  },
  premises: {
    data: [],
    filteredCount: 0,
    totalCount: 0,
    page: 0,
    pageSize: 0,
  },
  contentPackages: [],
};

// action factories

export function SendContentOrder(premiseId: string, data: ContentOrder) {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.SEND_CONTENT_ORDER,
    payload: {
      premiseId,
      data,
    },
  };
}

export function SendContentOrderSuccess(payload: ContentOrderResponse) {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.SEND_CONTENT_ORDER_SUCCESS,
    payload,
  };
}

export function SendContentOrderFailure() {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.SEND_CONTENT_ORDER_FAILURE,
  };
}

export function GetContentByPremiseId(premiseId: string) {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.GET_CONTENT_PACKAGES_BY_PREMISE,
    payload: premiseId,
  };
}
export function SetContentByPremiseId(premiseId: string, content: any) {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.SET_CONTENT_PACKAGES_BY_PREMISE,
    payload: { premiseId, content },
  };
}
export function GetContentByPremiseIdFailure() {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.GET_CONTENT_PACKAGES_BY_PREMISE_FAILURE,
  };
}

export function SetPremises(data: any): SetPremisesAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.SET_PREMISES,
    payload: data,
  };
}

export function GetPremises(
  payload?: IOrganizationUnitListRequest
): GetPremisesAction {
  return { type: ORGANIZATION_UNITS_ACTIONS.GET_PREMISES, payload };
}

export function SetPremise(data: IOrganizationUnit): SetPremiseAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.SET_PREMISE,
    payload: data,
  };
}

export function UpdateOrganizationUnit(
  organizationUnitId: string,
  data: Partial<IOrganizationUnit>
): UpdateOrganizationUnitAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNIT,
    organizationUnitId,
    payload: data,
  };
}

function UpdateOrganizationUnitSuccess(
  response: OrganizationUnit
): UpdateOrganizationUnitSuccessAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNIT_SUCCESS,
    payload: response,
  };
}

export function UploadImageFailure(error: any): UploadImageFailureAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.UPLOAD_IMAGE_FAILURE,
    error: error,
  };
}

// Selectors

export const selectPremiseGroups = createSelector(
  (state: CustomerPortalState) => state.organizationUnits.premises.data,
  (premises) => {
    return premises.filter((p) => p.type === OrganizationUnitType.HOTEL_CHAIN);
  }
);

export const selectRoomTypesByPremiseGroupId = createSelector(
  selectPremiseGroups,
  (_state: CustomerPortalState, premiseGroupId: string) => premiseGroupId,
  (premiseGroups, premiseGroupId) => {
    const premiseGroup = premiseGroups.find((pg) => pg._id === premiseGroupId);
    return premiseGroup?.roomTypes || [];
  }
);

export const selectRoomTypesByPremiseGroupIds = createSelector(
  selectPremiseGroups,
  (_state: CustomerPortalState, premiseGroupIds: string[]) => premiseGroupIds,
  (premiseGroups, premiseGroupIds): Record<string, IRoomType[]> | undefined => {
    if (!premiseGroups || premiseGroups.length === 0) {
      return undefined;
    }
    const roomTypes: Record<string, IRoomType[]> = {};
    premiseGroupIds.forEach((pgId) => {
      const premiseGroup = premiseGroups.find((pg) => pg._id === pgId);
      if (premiseGroup) {
        roomTypes[pgId] = premiseGroup.roomTypes;
      }
    });
    return roomTypes;
  }
);

export const getPremises = createSelector(
  (state: CustomerPortalState) => state.organizationUnits.premises.data,
  (premises) => {
    return premises.filter((p) => p.type === OrganizationUnitType.HOTEL);
  }
);

export const getOrganizationUnitsTags = (state: CustomerPortalState) => {
  const tags = getPremises(state).flatMap((ou) => ou.tags);
  return [...new Set(tags)];
};

export const selectOrganizationUnitsByProduct = createSelector(
  getPremises,
  (_state: CustomerPortalState, product: NendaProduct) => product,
  (items, product) => {
    return items.filter((ou) => ou.nendaProducts.includes(product));
  }
);

export const getPremisesByCompanyId = createSelector(
  (state: CustomerPortalState) => state.organizationUnits.premises.data,
  (_state: CustomerPortalState, id: string) => id,
  (items, companyId) => {
    return items.filter((item) => {
      const premiseCompanyId = getCompanyIdByPremise(item);
      return premiseCompanyId === companyId;
    });
  }
);

export const selectContentByPremiseId = createSelector(
  (state: CustomerPortalState) => state.organizationUnits.contentPackages,
  (_state: CustomerPortalState, premiseId: string) => premiseId,
  (contentPackages, premiseId) => {
    return contentPackages.find((p) => p.premiseId === premiseId);
  }
);

export const getAllPremiseCategories = createSelector(getPremises, (ous) => {
  const categories = ous
    .filter((p) => p.category != null && p.category != undefined)
    .map((p) => p.category!);

  return [...new Set(categories)];
});

export const selectPremiseById = createSelector(
  (state: CustomerPortalState) => state.organizationUnits.premises.data,
  (_state: CustomerPortalState, id: string) => id,
  (items, id) => {
    return items.find((item) => item._id === id);
  }
);

export const selectNavigatedPremise = createSelector(
  (state: CustomerPortalState) => state.organizationUnits.premises.data,
  selectNavigatedPremiseId,
  (items, id) => {
    return items.find((item) => item._id === id);
  }
);

export const selectNavigatedPremiseGroup = createSelector(
  selectPremiseGroups,
  selectNavigatedPremise,
  (premiseGroups, premise): IOrganizationUnit | undefined => {
    if (!premise) return undefined;
    return premiseGroups.find((pg) => pg._id === premise.parent);
  }
);

export const selectOrganizationUnitsByCompanyId = createSelector(
  (state: CustomerPortalState) => state.organizationUnits.premises.data,
  (_state: CustomerPortalState, companyId: string) => companyId,
  (items, companyId) => {
    return items.filter(
      (x: IOrganizationUnit) =>
        (x.company instanceof Object ? x.company._id : x.company) === companyId
    );
  }
);

//reducer
export default function organizationUnitReducer(
  state: OrganizationUnitState = initialOrganizationUnitState,
  action: OrganizationUnitAction
) {
  switch (action.type) {
    case ORGANIZATION_UNITS_ACTIONS.SEND_CONTENT_ORDER:
      return {
        ...state,
        contentOrder: {
          ...state.contentOrder,
          status: LoadingStatus.LOADING,
        },
      };
    case ORGANIZATION_UNITS_ACTIONS.SEND_CONTENT_ORDER_SUCCESS:
      return {
        ...state,
        contentOrder: {
          ...state.contentOrder,
          status: LoadingStatus.SUCCEEDED,
        },
      };
    case ORGANIZATION_UNITS_ACTIONS.SEND_CONTENT_ORDER_FAILURE:
      return {
        ...state,
        contentOrder: {
          ...state.contentOrder,
          status: LoadingStatus.FAILED,
        },
      };
    case ORGANIZATION_UNITS_ACTIONS.GET_PREMISES:
      return {
        ...state,
        isLoading: true,
      };
    case ORGANIZATION_UNITS_ACTIONS.SET_PREMISES:
      return {
        ...state,
        isLoading: false,
        premises: {
          ...state.premises,
          data: action.payload,
        },
      };
    /// SET PREMISE
    case ORGANIZATION_UNITS_ACTIONS.SET_PREMISE:
      return {
        ...state,
        premises: {
          ...state.premises,
          data: [...state.premises.data, action.payload],
        },
      };
    case ORGANIZATION_UNITS_ACTIONS.SET_ORGANIZATION_UNITS:
      return {
        ...state,
        organizationUnits: action.organizationUnits,
        isLoading: false,
      };
    case ORGANIZATION_UNITS_ACTIONS.GET_ORGANIZATION_UNITS:
      return {
        ...state,
        isLoading: true,
      };
    case ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNIT:
      return {
        ...state,
        isLoading: true,
      };
    case ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNIT_SUCCESS:
      const updatedIndex = state.premises.data.findIndex(
        (item) => item._id === action.payload._id
      );

      // Ensure the organization unit is found before updating the state
      if (updatedIndex !== -1) {
        const updatedOrganizationUnits = [...state.premises.data];
        updatedOrganizationUnits[updatedIndex] = action.payload;

        return {
          ...state,
          premises: {
            ...state.premises,
            data: updatedOrganizationUnits,
          },
          isLoading: false,
        };
      } else {
        // If the organization unit is not found, return the original state
        return state;
      }
    case ORGANIZATION_UNITS_ACTIONS.GET_CONTENT_PACKAGES_BY_PREMISE:
      return {
        ...state,
        isLoading: true,
      };
    case ORGANIZATION_UNITS_ACTIONS.SET_CONTENT_PACKAGES_BY_PREMISE:
      const { premiseId, content } = action.payload;
      const updatedPackages = state.contentPackages.filter(
        (p) => p.premiseId !== premiseId
      );
      updatedPackages.push(content);

      return {
        ...state,
        isLoading: false,
        contentPackages: updatedPackages,
      };
    case ORGANIZATION_UNITS_ACTIONS.GET_CONTENT_PACKAGES_BY_PREMISE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

// Epics

const getPremises$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.GET_PREMISES),
    switchMap((a) => {
      return organizationUnitService.getOrganizationUnits().pipe(
        map((premises) => {
          return SetPremises(premises);
        }),
        catchError(handleError)
      );
    })
  );
};

const updateOrganizationUnit$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNIT),
    switchMap((a) => {
      return organizationUnitService
        .updateOrganizationUnit(a.organizationUnitId, a.payload)
        .pipe(
          mergeMap((updatedOU) => {
            return [
              UpdateOrganizationUnitSuccess(updatedOU),
              SetNotification("Update successful!"),
            ];
          }),
          catchError(handleError)
        );
    })
  );
};

const getContentByPremiseId$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.GET_CONTENT_PACKAGES_BY_PREMISE),
    switchMap((a) => {
      return organizationUnitService.getContentByPremiseId(a.payload).pipe(
        map((content) => SetContentByPremiseId(a.payload.premiseId, content)),
        catchError((err) =>
          of(SetError(err.response.message), GetContentByPremiseIdFailure())
        )
      );
    })
  );
};

const sendContentOrder$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.SEND_CONTENT_ORDER),
    switchMap((a) => {
      return organizationUnitService
        .createContentOrder(a.payload.premiseId, a.payload.data)
        .pipe(
          map((response) => SendContentOrderSuccess(response)),
          catchError((err) => of(handleError(err), SendContentOrderFailure()))
        );
    })
  );
};

export const organizationUnitEpics = [
  updateOrganizationUnit$,
  getPremises$,
  sendContentOrder$,
  getContentByPremiseId$,
];
