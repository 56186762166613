import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  List,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import React from "react";
import { shuffle } from "@shared/arrayUtils";
import {
  AssetSource,
  AssetType,
  ILeanAsset,
  Playlist,
} from "../../../../../types/NendaTypes";
import AddEditAsset from "./AddEditAsset";
import LeanAsset from "../LeanAsset";
import SortableList from "../../ui-components/dnd/SortableList";

interface LeanAssetWithId extends ILeanAsset {
  id: string;
}
interface EditPlaylistProps {
  playlist: ILeanAsset[];
  allowedSources?: AssetSource[];
  onChange: (playlist: Playlist) => void;
}

const EditPlaylist: React.FC<EditPlaylistProps> = ({
  playlist,
  allowedSources = [AssetSource.NENDA],
  onChange,
}) => {
  const onDrop = (dropResult: ILeanAsset[]) => {
    onChange(dropResult);
  };

  const handleAddAsset = (asset: ILeanAsset) => {
    playlist.push(asset);
    onChange([...playlist]);
  };

  const handleShuffle = () => {
    const shuffled = shuffle(playlist);
    onChange(shuffled);
  };

  const getAllowedAssetTypes = () => {
    const allowedAssetTypes = [AssetType.VIDEO, AssetType.IMAGE];
    if (playlist.length === 0) {
      allowedAssetTypes.push(AssetType.LINEAR_CHANNEL);
    }
    return allowedAssetTypes;
  };

  const onDelete = (index: number) => {
    playlist.splice(index, 1);
    onChange([...playlist]);
  };
  const sortableItems = playlist.map((asset) => ({
    ...asset,
    id: asset.locator,
  }));

  const renderItem = (item: LeanAssetWithId, index: number, listeners: any) => {
    return (
      <LeanAsset
        asset={item}
        assetIndex={index}
        onDelete={onDelete}
        key={item.locator}
        dragHandleListeners={listeners}
      />
    );
  };

  return (
    <Box>
      <Typography
        mt={2}
        mb={1}
        variant="h3"
        sx={{ display: "flex", justifyContent: "left" }}
      >
        {t("customerportal.pages.content_channels.playlist")}
      </Typography>
      <Grid container spacing={2} columns={{ xs: 1, md: 2 }}>
        <Grid item xs={1}>
          {!playlist.some(
            (asset) => asset.type === AssetType.LINEAR_CHANNEL
          ) ? (
            <AddEditAsset
              onAdd={handleAddAsset}
              allowedSources={allowedSources}
              allowedAssetTypes={getAllowedAssetTypes()}
            />
          ) : (
            <Alert severity="info" className="mt-3">
              {t("customerportal.pages.content_channels.linear_channel_note")}
            </Alert>
          )}
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{ width: "100%", maxWidth: 500, bgcolor: "background.paper" }}
          >
            <SortableList
              items={sortableItems}
              renderItem={renderItem}
              onChange={onDrop}
            />
            <Button
              sx={{ margin: 1 }}
              variant="outlined"
              onClick={handleShuffle}
            >
              {t("common.shuffle")}
            </Button>
            <Divider />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditPlaylist;
