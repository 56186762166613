import { Box, Button } from "@mui/material";
import { t } from "i18next";
import { ReactNode, useState } from "react";
import CreateUnitForm from "./CreateUnitForm";

const CreateUnit = (): ReactNode => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const toggleOpen = (): void => {
    setIsFormOpen(!isFormOpen);
  };

  const handleCreateButtonClick = (): void => {
    toggleOpen();
  };

  const handleFormClose = (): void => {
    toggleOpen();
  };

  return (
    <Box>
      {!isFormOpen && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateButtonClick}
        >
          {t("customerportal.pages.unit_list.create_units")}
        </Button>
      )}
      {isFormOpen && (
        <CreateUnitForm isOpen={isFormOpen} onClose={handleFormClose} />
      )}
    </Box>
  );
};

export default CreateUnit;
