import { Box, Table } from "@mui/material";
import TableSkeleton from "../skeletons/TableSkeleton";
import ShowMoreButton from "./ShowMoreButton";
import TableHeader from "./TableHeader";
import TableMain from "./TableMain";
import { ColumnProps, TableProps } from "./types/table";

interface DefaultTableProps {
  hasClickableRows?: boolean;
  onSort?: (column: string) => void;
  onLastPage?: boolean;
  onShowMore?: () => void;
  tableData: {
    rows: TableProps["rows"];
    cols: ColumnProps[];
  };
  loading?: boolean;
  sort?: { column: string | null; direction: string | null };
  pagination?: boolean;
}

const DefaultTable: React.FC<DefaultTableProps> = ({
  hasClickableRows,
  onSort,
  onLastPage,
  onShowMore,
  tableData = { rows: [], cols: [] },
  loading,
  sort,
  pagination = true,
}) => {
  const { cols, rows } = tableData;

  if (loading) {
    return <TableSkeleton />;
  }
  return (
    <Box sx={{ width: "100%", overflow: "auto" }}>
      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
        <Table sx={{ width: "100%" }}>
          <TableHeader cols={cols} onSort={onSort} sort={sort} />
          <TableMain
            rows={rows}
            columns={cols}
            hasClickableRows={hasClickableRows}
          />
        </Table>
      </Box>
      {pagination && (
        <ShowMoreButton
          disabled={onLastPage}
          fullWidth
          onClick={onShowMore}
          sx={{ width: "100%", maxWidth: "none" }}
        />
      )}
    </Box>
  );
};

export default DefaultTable;
