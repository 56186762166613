import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { SetStateAction } from "react";
import { useSelector } from "react-redux";

import { formatDuration } from "date-fns";
import { IContentChannel, IRoom } from "../../../../../types/NendaTypes";
import placeHolderPoster from "../../assets/images/poster-placeholder.png";
import { CustomerPortalState } from "../../store";
import { selectContentChannelById } from "../../store/reducers/contentChannelReducer";
import { selectNavigatedPremiseId } from "../../store/reducers/workspaceReducer";
import SmallContentChannelCarousel from "../ContentChannels/SmallContentChannelCarousel";
import { ChannelOverrideState } from "./ChannelOverride";
import { adsDisabled, signageDisabled } from "./components/DisabledFeatures";
import { selectScreensForPremise } from "../../store/reducers/screenReducer";

const ChannelOverrideStepContent = ({
  setStep,
  activeStep,
  channelOverrideState,
  setChannelOverrideState,
}: {
  setStep: (value: number) => void;
  activeStep: number;
  channelOverrideState: ChannelOverrideState;
  setChannelOverrideState: (
    value: SetStateAction<ChannelOverrideState>
  ) => void;
}) => {
  const contentChannel = useSelector((state: CustomerPortalState) =>
    selectContentChannelById(state, channelOverrideState.channelId)
  );
  const handleChangeDuration = (value: number) => {
    setChannelOverrideState({
      ...channelOverrideState,
      duration: value,
    });
  };
  const handleChangeScreens = (value: string[]) => {
    setChannelOverrideState({
      ...channelOverrideState,
      screens: value,
    });
  };
  const handleChangeChannel = (value: string) => {
    setChannelOverrideState({
      ...channelOverrideState,
      channelId: value,
    });
    setStep(activeStep + 1);
  };
  switch (activeStep) {
    case 0:
      return <SelectChannel setSelectedChannel={handleChangeChannel} />;
    case 1:
      return (
        <SelectDuration
          selectedDuration={channelOverrideState.duration}
          setSelectedDuration={handleChangeDuration}
          channel={contentChannel}
        />
      );
    case 2:
      return (
        <SelectScreens
          selectedScreens={channelOverrideState.screens}
          setSelectedScreens={handleChangeScreens}
        />
      );
    case 3:
      return (
        <ConfirmContentOverride channelOverrideState={channelOverrideState} />
      );
    default:
      return <div />;
  }
};

const SelectChannel = ({
  setSelectedChannel,
}: {
  setSelectedChannel: (value: string) => void;
}) => {
  return (
    <SmallContentChannelCarousel
      handleAddChannel={setSelectedChannel}
      channelSelectTitle="Select Channel"
    />
  );
};

const MAX = 24;
const MIN = 1;

const SelectDuration = ({
  selectedDuration,
  setSelectedDuration,
  channel,
}: {
  selectedDuration: number;
  setSelectedDuration: (value: number) => void;
  channel: IContentChannel | undefined;
}) => {
  const handleChange = (_: Event, newValue: number | number[]) => {
    setSelectedDuration(newValue as number);
  };

  return (
    <Stack gap="4rem">
      <Typography variant="h6" sx={{ mx: "auto" }}>
        {t("customerportal.pages.content_channels.select_duration")}
      </Typography>
      <Stack direction={"row"} gap="1rem" alignItems={"center"}>
        <Typography variant="body1">1h</Typography>
        <Slider
          step={1}
          value={selectedDuration}
          valueLabelFormat={
            selectedDuration + " " + (selectedDuration === 1 ? "hr" : "hrs")
          }
          valueLabelDisplay="on"
          min={MIN}
          max={MAX}
          onChange={handleChange}
        />
        <Typography variant="body1">24h</Typography>
      </Stack>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        {channel && signageDisabled(channel) && (
          <Box>
            <Alert severity="info">
              {t("customerportal.notifications.signage_disabled")}
            </Alert>
          </Box>
        )}
        {channel && adsDisabled(channel) && (
          <Box>
            <Alert severity="info">
              {t("customerportal.notifications.ads_disabled")}
            </Alert>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

const SelectScreens = ({
  selectedScreens,
  setSelectedScreens,
}: {
  selectedScreens: string[];
  setSelectedScreens: (value: string[]) => void;
}) => {
  const premise = useSelector(selectNavigatedPremiseId);
  const screens = useSelector((state: CustomerPortalState) =>
    selectScreensForPremise(state, premise || "")
  );

  //Divide screens into 2 columns
  const halfLength = Math.ceil(screens.length / 2);
  const firstHalf = screens.slice(0, halfLength);
  const secondHalf = screens.slice(halfLength, screens.length);

  const handleToggle = (value: string) => {
    const currentIndex = selectedScreens.indexOf(value);
    const newChecked = [...selectedScreens];

    if (currentIndex === -1) {
      newChecked.push(value.toString());
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedScreens(newChecked);
  };

  const handleToggleAll = () => {
    if (selectedScreens.length === screens.length) {
      setSelectedScreens([]);
    } else {
      setSelectedScreens(screens.map((screen) => screen._id));
    }
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h6">
          {t("customerportal.pages.content_channels.select_screens")}
        </Typography>
        <Button variant={"outlined"} onClick={handleToggleAll}>
          {t("common.select_all")}
        </Button>
      </Box>
      <Grid container spacing={2} columns={{ xs: 1, md: 2 }}>
        <Grid item xs={1}>
          <ScreenColumnLists
            items={firstHalf}
            onScreenSelect={handleToggle}
            selectedScreens={selectedScreens}
          />
        </Grid>
        <Grid item xs={1}>
          <ScreenColumnLists
            items={secondHalf}
            onScreenSelect={handleToggle}
            selectedScreens={selectedScreens}
          />
        </Grid>
      </Grid>
    </>
  );
};

const ScreenColumnLists = ({
  items,
  onScreenSelect,
  selectedScreens,
}: {
  items: IRoom[];
  onScreenSelect: (value: string) => void;
  selectedScreens: string[];
}) => {
  return (
    <List
      dense
      dir="ltr"
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
    >
      {items.map((item) => {
        const labelId = `${item.name || item._id}`;
        const onScreenClick = () => {
          return onScreenSelect(item._id);
        };
        return (
          <ListItem key={item._id} onClick={onScreenClick}>
            <ListItemButton>
              <Checkbox
                edge="start"
                checked={selectedScreens.includes(item._id)}
                inputProps={{ "aria-labelledby": labelId }}
              />
              <ListItemText id={labelId} primary={`${item.name || item._id}`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

const ConfirmContentOverride = ({
  channelOverrideState,
}: {
  channelOverrideState: ChannelOverrideState;
}) => {
  const channel = useSelector((state: CustomerPortalState) =>
    selectContentChannelById(state, channelOverrideState.channelId)
  );
  const screens = useSelector((state: CustomerPortalState) =>
    selectScreensForPremise(state, channelOverrideState.premise)
  );
  const selectedScreens = channelOverrideState.screens.map((screenId) =>
    screens?.find((screen) => screen._id === screenId)
  );

  return (
    <>
      <Box sx={{ display: "flex", pb: "1rem" }}>
        <Typography variant="h6" sx={{ mx: "auto" }}>
          {"Confirm Selection"}
        </Typography>
      </Box>
      <Grid
        container
        spacing="1rem"
        columns={{ xs: 1, md: 2 }}
        sx={{ height: "20rem", pr: "4rem" }}
      >
        <Grid item xs={1}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={(theme) => ({
                height: "100%",
                width: "15rem",
                backgroundImage: `url(${
                  channel?.posterUrl ||
                  channel?.logo?.locator ||
                  placeHolderPoster
                })`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              })}
            />
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              pr="2rem"
              alignItems={"center"}
            >
              <Typography
                variant="body2"
                sx={(theme) => ({
                  color: theme.palette.grey[500],
                  textTransform: "uppercase",
                  fontSize: "0.8rem",
                })}
              >
                {t("customerportal.pages.content_channels.channel_name")}
              </Typography>
              <Typography variant="body1">{channel?.name}</Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              pr="2rem"
              alignItems={"center"}
            >
              <Typography
                variant="body2"
                sx={(theme) => ({
                  color: theme.palette.grey[500],
                  textTransform: "uppercase",
                  fontSize: "0.8rem",
                })}
              >
                {t("customerportal.pages.content_channels.play_duration")}
              </Typography>
              <Typography variant="body1">
                {formatDuration({ hours: channelOverrideState.duration })}
              </Typography>
            </Stack>
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              pr="2rem"
              alignItems={"flex-start"}
              gap="0.5rem"
            >
              <Typography
                variant="body2"
                sx={(theme) => ({
                  color: theme.palette.grey[500],
                  textTransform: "uppercase",
                  fontSize: "0.8rem",
                })}
              >
                {t("common.screens")}
              </Typography>
              <Box
                sx={{
                  maxHeight: "15rem",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "0.5rem",
                  alignItems: "flex-end",
                }}
              >
                {selectedScreens?.length === screens?.length ? (
                  <Chip label={"All screens"} />
                ) : (
                  <>
                    {selectedScreens?.map((screen) => (
                      <Chip label={screen?.name} />
                    ))}
                  </>
                )}
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ChannelOverrideStepContent;
