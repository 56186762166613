import { Epic, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { configService } from "../http/config.service";
import {
  AppState,
  ConfigActions,
  CONFIG_ACTIONS,
  GetConfigsAction,
  GetCountriesAction,
  GetLanguagesAction,
  SetConfigsAction,
  SetCountriesAction,
  SetLanguagesAction,
} from "../types/redux";
import { handleError } from "./error.redux";

export function GetCountries(): GetCountriesAction {
  return { type: CONFIG_ACTIONS.GET_AVAILABLE_COUNTRIES };
}

export function GetLanguages(): GetLanguagesAction {
  return { type: CONFIG_ACTIONS.GET_AVAILABLE_LANGUAGES };
}

export function GetConfigs(): GetConfigsAction {
  return { type: CONFIG_ACTIONS.GET_CONFIGS };
}

export function SetConfigs(configs: any): SetConfigsAction {
  return { type: CONFIG_ACTIONS.SET_CONFIGS, configs };
}

export function SetCountries(countryList: any): SetCountriesAction {
  return { type: CONFIG_ACTIONS.SET_AVAILABLE_COUNTRIES, countryList };
}

export function SetLanguages(languages: any): SetLanguagesAction {
  return { type: CONFIG_ACTIONS.SET_AVAILABLE_LANGUAGES, languages };
}

export default function configReducer(
  state: AppState["config"] = {
    availableCountries: [],
    languages: [],
    roomTypeCategories: [],
  },
  action: ConfigActions
): AppState["config"] {
  switch (action.type) {
    case CONFIG_ACTIONS.SET_AVAILABLE_COUNTRIES:
      return {
        ...state,
        availableCountries: action.countryList,
      };
    case CONFIG_ACTIONS.SET_AVAILABLE_LANGUAGES:
      return {
        ...state,
        languages: action.languages,
      };
    case CONFIG_ACTIONS.SET_CONFIGS:
      return {
        ...state,
        ...action.configs,
      };
    default:
      return state;
  }
}

// Selectors
export const getAvailableCountries = (state: AppState) =>
  state.config.availableCountries;

export const getAvailableLanguages = (state: AppState) =>
  state.config.languages;

export const getRoomTypeCategories = (state: AppState) =>
  state.config.roomTypeCategories;

// Epics
const getCountries$: Epic = (action$) => {
  return action$.pipe(
    ofType(CONFIG_ACTIONS.GET_AVAILABLE_COUNTRIES),
    switchMap(() => {
      return configService.getCountries().pipe(
        map((session) => {
          return SetCountries(session);
        }),
        catchError(handleError)
      );
    })
  );
};

const getLanguages$: Epic = (action$) => {
  return action$.pipe(
    ofType(CONFIG_ACTIONS.GET_AVAILABLE_LANGUAGES),
    switchMap(() => {
      return configService.getSettings().pipe(
        map(({ languages }) => {
          return SetLanguages(languages);
        }),
        catchError(handleError)
      );
    })
  );
};

const getConfig$: Epic = (action$) => {
  return action$.pipe(
    ofType(CONFIG_ACTIONS.GET_CONFIGS),
    switchMap(() => {
      return configService.getSettings().pipe(
        map((settings) => {
          return SetConfigs(settings);
        }),
        catchError(handleError)
      );
    })
  );
};

export const configEpics = [getCountries$, getLanguages$, getConfig$];
