import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { t } from "i18next";
import { SyntheticEvent } from "react";
import TextInput from "../../../../ui-components/input/TextInput";
import { CreatePromotionStateProps } from "./SelectRulesForm";
import { ScheduleRule } from "./StepperForm";

export const FOREVER_END_DATE: Date = new Date("2099-01-01");

type DateRangeSelect = {
  isCustomSelection: boolean;
  onToggleRuleApplied: (rule: ScheduleRule, isApplied: boolean) => void;
} & CreatePromotionStateProps;

const DateRangeSelect = ({
  state,
  isCustomSelection,
  setState,
  onToggleRuleApplied,
}: DateRangeSelect) => {
  const theme = useTheme();

  const handleFromSelect = (value: Date | null) => {
    if (!value) return;
    setState((prevState) => ({
      ...prevState,
      schedule: {
        ...state.schedule,
        date: {
          ...state.schedule.date,
          start: value,
        },
      },
    }));
  };

  const handleToSelect = (value: Date | null) => {
    if (!value) return;

    setState((prevState) => ({
      ...prevState,
      schedule: {
        ...state.schedule,
        date: {
          ...state.schedule.date,
          end: value,
        },
      },
    }));
  };

  const handleToggleCustomSelection = (
    _event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    onToggleRuleApplied("date", !checked);
  };

  return (
    <Box sx={{ pr: "1rem", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ minWidth: "100px", color: theme.palette.grey[700] }}
          variant="body2"
        >
          {t("Date")}
        </Typography>

        <FormControlLabel
          labelPlacement="start"
          control={<Switch checked={!isCustomSelection} />}
          label={t("form.validation.promotions.schedule.no_date_range_rule")}
          onChange={handleToggleCustomSelection}
          sx={{
            "&.MuiFormControlLabel-root": {
              margin: "0",
              color: theme.palette.grey[700],
            },
          }}
        />
      </Box>
      <Box
        display={"flex"}
        sx={{
          gap: "1rem",
          alignItems: "center",
          visibility: isCustomSelection ? "visible" : "hidden",
        }}
      >
        <DatePicker
          slots={{
            textField: TextInput,
          }}
          onAccept={handleFromSelect}
          value={state.schedule.date.start}
          slotProps={{
            textField: {
              InputProps: {
                disableUnderline: true,
                sx: {
                  maxWidth: "20ch",
                },
              },
              variant: "standard",
              placeholder: "From",
              sx: {
                maxWidth: "15ch",
              },
            },
            layout: {
              sx: {
                "& .MuiDayCalendar-weekDayLabel": {
                  color: theme.palette.grey[400],
                },
                "& .Mui-selected": {
                  backgroundColor: theme.palette.primary.dark,
                  color: `${theme.palette.common.white} !important`,
                },
              },
            },
          }}
        />
        <>-</>
        <DatePicker
          slots={{
            textField: TextInput,
          }}
          onAccept={handleToSelect}
          value={state.schedule.date.end}
          slotProps={{
            textField: {
              InputProps: {
                disableUnderline: true,
                sx: {
                  maxWidth: "20ch",
                },
              },
              variant: "standard",
              placeholder: "To",
            },
          }}
          sx={{ maxWidth: "15ch" }}
        />
      </Box>
    </Box>
  );
};

export default DateRangeSelect;
