import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Card,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { isInRange, withoutTime } from "@shared/dateTimeUtils";
import { IAdCampaign } from "../../../../../types/NendaTypes";
import ConfirmationDialog from "../../ConfirmationDialog";
import { CustomerPortalState, store } from "../../store";
import { DeleteCampaign } from "../../store/reducers/adsReducer";
import { getCompany } from "../../store/reducers/companyReducer";
import AssetFile from "../Premise/Signage/AssetFile";

interface CampaignProps {
  campaign: IAdCampaign;
  onToggleEdit: () => void;
}
const styles = {
  rowKey: {
    fontWeight: 700,
    lineHeight: "1.5",
  },
  rowValue: {
    lineHeight: "1.5",
  },
  active: {
    color: "success.dark",
  },
  inactive: {
    color: "error.main",
  },
};

const CampaignCard: React.FC<CampaignProps> = ({ campaign, onToggleEdit }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  const company = useSelector((state: CustomerPortalState) =>
    getCompany(state, campaign.company)
  );

  const today = new Date();

  const handleDeleteButtonClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = (isConfirmed: boolean) => {
    setIsDeleteDialogOpen(false);
    if (isConfirmed) {
      store.dispatch(DeleteCampaign(campaign._id));
    }
  };

  const isCampaignActive = (today: Date): boolean => {
    return isInRange(
      today,
      new Date(campaign.startDate),
      new Date(campaign.endDate)
    );
  };

  const isCampaingInTheFuture = (today: Date): boolean => {
    return withoutTime(new Date(campaign.startDate)) > withoutTime(today);
  };

  const isCampaignInThePast = (today: Date): boolean => {
    return withoutTime(new Date(campaign.endDate)) < withoutTime(today);
  };

  const startDateStyle = () => {
    if (isCampaignActive(today)) {
      return { ...styles.rowValue, ...styles.active };
    }
    if (isCampaingInTheFuture(today)) {
      return { ...styles.rowValue, ...styles.inactive };
    }
    return styles.rowValue;
  };

  const endDateStyle = () => {
    if (isCampaignActive(today)) {
      return { ...styles.rowValue, ...styles.active };
    }
    if (isCampaignInThePast(today)) {
      return { ...styles.rowValue, ...styles.inactive };
    }
    return styles.rowValue;
  };

  return (
    <Card sx={{ position: "relative", p: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Grid container spacing={2} sx={{ maxWidth: "400px" }}>
          <Grid item>
            <Typography sx={styles.rowKey}>Campaign name:</Typography>
            <Typography sx={styles.rowKey}>Advertiser:</Typography>
            <Typography sx={{ ...styles.rowKey }}>Start date:</Typography>
            <Typography sx={styles.rowKey}>End date:</Typography>
          </Grid>
          <Grid item>
            <Typography sx={styles.rowValue}>{campaign.name}</Typography>
            <Typography sx={styles.rowValue}>
              {company && company.name}
            </Typography>
            <Typography sx={startDateStyle()}>
              {new Date(campaign.startDate).toDateString()}
            </Typography>
            <Typography sx={endDateStyle()}>
              {new Date(campaign.endDate).toDateString()}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ maxWidth: "200px" }}>
          <AssetFile
            type={campaign.asset.type}
            src={campaign.asset.locator}
            onLoadedVideoMetaData={() => {}}
          />
        </Box>
      </Box>
      <Box sx={{ mt: 1, gap: 1, display: "flex", flexWrap: "wrap" }}>
        Premises:
        <Select>
          {campaign.screens.map((screen) => (
            <MenuItem key={screen} disabled={true}>
              {screen.split("-").slice(1, 3).join("-")}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Stack
        direction="row"
        gap={1}
        sx={{ position: "absolute", top: 10, right: 10 }}
      >
        <IconButton onClick={onToggleEdit}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDeleteButtonClick}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <ConfirmationDialog
        title="Are you sure you want to delete the campaign?"
        content="A deleted campaign cannot be recovered. Make sure that the campaign is not currently running."
        isOpen={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
      />
    </Card>
  );
};

export default CampaignCard;
