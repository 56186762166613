import React, { cloneElement } from "react";
import { useSelector } from "react-redux";
import { Restriction } from "../../../../types/NendaTypes";
import { getPermissions, selectUserRole } from "../store/reducers/authReducer";
import { checkPermission } from "./helpers/permissions";

interface PermissionGateProps {
  children: JSX.Element;
  restriction: Restriction;
  DeniedComponent?: React.FunctionComponent;
  deniedProps?: {};
}

export default function PermissionsGate({
  children,
  restriction,
  DeniedComponent = () => <React.Fragment />,
  deniedProps = undefined,
}: PermissionGateProps): JSX.Element {
  if (!restriction) {
    return children;
  }
  const role = useSelector(selectUserRole);
  const permissions = useSelector(getPermissions);

  const hasPermission = checkPermission(role, restriction, permissions);
  if (!hasPermission) {
    return deniedProps ? (
      cloneElement(children, { ...deniedProps })
    ) : (
      <DeniedComponent />
    );
  }
  return children;
}
