import { MoreVert } from "@mui/icons-material";
import { Box, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

type OptionsButtonProps = {
  options: { label: string; value: string; onClick: () => void }[];
};

const OptionsButton = ({ options }: OptionsButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box onClick={handleClick} sx={{ px: "0.5rem", cursor: "pointer" }}>
        <MoreVert />
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        elevation={20}
        sx={{ "& .MuiMenu-paper": { borderRadius: "0.2rem", p: 0 } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            sx={{
              fontSize: "0.875rem",
              color: option.value === "delete" ? "red" : "inherit",
            }}
            onClick={() => {
              option.onClick();
              handleClose();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OptionsButton;
