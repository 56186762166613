import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { t } from "i18next";
import Information from "./Information";
import {
  IFrontLayerInformationPage,
  IFrontlayerConfigValues,
  IFrontlayerConfiguration,
} from "../../../../../../../types/NendaTypes";
import { useEffect } from "react";

const InformationPageConfig = ({
  state,
  handleChange,
  upload,
  config,
}: {
  state: IFrontlayerConfiguration["information"];
  handleChange: (mutatedState: IFrontLayerInformationPage) => void;
  upload: (file: File, imageCategory: string) => void;
  config: IFrontlayerConfigValues;
}) => {
  const informationPageBackgroundUpload = (file: File) => {
    upload(file, "informationPageBackground");
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t("customerportal.settings.premise_settings.frontlayer.information")}
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          width: "100%",
          gap: "2rem",
          mb: "2rem",
        })}
      >
        <Box>
          <Information
            config={state}
            onConfigChange={handleChange}
            onUpload={informationPageBackgroundUpload}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default InformationPageConfig;
