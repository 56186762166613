export function fromJson(
  constructor: new () => any,
  jsonData: { [x: string]: any }
) {
  const obj = new constructor();
  Object.keys(jsonData).forEach((key) => {
    obj[key] = jsonData[key];
  });
  return obj;
}
