import {
  Box,
  FormControlLabel,
  Switch,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { t } from "i18next";
import { SyntheticEvent } from "react";
import TextInput from "../../../../ui-components/input/TextInput";
import { CreatePromotionStateProps } from "./SelectRulesForm";
import { ScheduleRule } from "./StepperForm";

const DEFAULT_TIME = new Date(new Date().setHours(0, 0, 0, 0));

type TimeRangeSelectProps = {
  isCustomSelection: boolean;
  onToggleRuleApplied: (rule: ScheduleRule, isApplied: boolean) => void;
} & CreatePromotionStateProps;

const TimeRangeSelect = ({
  state,
  isCustomSelection,
  setState,
  onToggleRuleApplied,
}: TimeRangeSelectProps) => {
  const theme = useTheme();
  const handleFromSelect = (value) => {
    if (!value) return;

    setState((prevState) => ({
      ...prevState,
      schedule: {
        ...state.schedule,
        time: {
          ...state.schedule.time,
          start: value,
        },
      },
    }));
  };

  const handleToSelect = (value) => {
    setState((prevState) => ({
      ...prevState,
      schedule: {
        ...state.schedule,
        time: {
          ...state.schedule.time,
          end: value,
        },
      },
    }));
  };
  const handleToggleCustomSelection = (
    _event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    onToggleRuleApplied("time", !checked);
  };

  return (
    <Box sx={{ pr: "1rem", width: "100%" }}>
      <Box
        sx={{
          display: "flex",

          alignItems: "center",
        }}
      >
        <Typography
          sx={{ minWidth: "100px", color: theme.palette.grey[700] }}
          variant="body2"
        >
          {t("Time")}
        </Typography>
        <FormControlLabel
          control={<Switch checked={!isCustomSelection} />}
          label={t("form.validation.promotions.schedule.no_time_rule")}
          onChange={handleToggleCustomSelection}
          labelPlacement="start"
          sx={{
            "&.MuiFormControlLabel-root": {
              margin: "0",
              color: theme.palette.grey[700],
            },
          }}
        />
      </Box>

      <Box
        display={"flex"}
        sx={{
          gap: "1rem",
          alignItems: "center",
          visibility: isCustomSelection ? "visible" : "hidden",
        }}
      >
        <TimePicker
          slots={{
            textField: TextInput,
          }}
          onChange={handleFromSelect}
          value={state.schedule.time.start}
          maxTime={
            state.schedule.time.end?.getHours() == 0
              ? undefined
              : state.schedule.time.end
          }
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: null,
            seconds: null,
          }}
          minutesStep={5}
          slotProps={{
            desktopPaper: {
              sx: {
                backgroundColor: (theme: Theme) => theme.palette.common.white,
                "& .MuiClockNumber-root": {
                  color: (theme: Theme) => theme.palette.grey[500],
                  "&.Mui-selected": {
                    backgroundColor: (theme: Theme) =>
                      theme.palette.primary.light,
                    color: (theme: Theme) => theme.palette.common.white,
                  },
                  "&.Mui-disabled": {
                    color: (theme: Theme) => theme.palette.grey[300],
                  },
                },
              },
            },

            textField: {
              InputProps: {
                disableUnderline: true,
                sx: {
                  maxWidth: "20ch",
                },
              },
              variant: "standard",
              placeholder: "From",
            },
          }}
          sx={{ maxWidth: "15ch" }}
        />
        <>-</>
        <TimePicker
          slots={{
            textField: TextInput,
          }}
          onChange={handleToSelect}
          value={state.schedule.time.end}
          minTime={state.schedule.time.start}
          minutesStep={5}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: null,
            seconds: null,
          }}
          slotProps={{
            desktopPaper: {
              sx: {
                backgroundColor: (theme: Theme) => theme.palette.common.white,
                "& .MuiClockNumber-root": {
                  color: (theme: Theme) => theme.palette.grey[500],
                  "&.Mui-selected": {
                    backgroundColor: (theme: Theme) =>
                      theme.palette.primary.light,
                    color: (theme: Theme) => theme.palette.common.white,
                  },
                  "&.Mui-disabled": {
                    color: (theme: Theme) => theme.palette.grey[300],
                  },
                },
              },
            },

            textField: {
              InputProps: {
                disableUnderline: true,
                sx: {
                  maxWidth: "20ch",
                },
              },
              variant: "standard",
              placeholder: "To",
            },
          }}
          sx={{ maxWidth: "15ch" }}
        />
      </Box>
    </Box>
  );
};

export default TimeRangeSelect;
