import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "../../components/Grid/Grid";
import { Modal } from "../../components/Modal/Modal";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { SectionWrapper } from "../../components/UI/SectionWrapper/SectionWrapper";
import { store } from "../../redux/store";
import { CreateSubPage } from "../../redux/subPages.redux";
import { AppState } from "../../types/redux";

export const SubPagesOverview: React.FC = () => {
  const [showForm, setShowForm] = React.useState(false);
  const [name, setName] = React.useState("");
  const subPages = useSelector((state: AppState) => state.subPages.subPages);
  function createSubPage() {
    store.dispatch(CreateSubPage(name));
  }
  return (
    <>
      <PageHeader>
        <h2>Pages</h2>
        <button className="btn btn-secondary" onClick={() => setShowForm(true)}>
          Create a new page
        </button>
      </PageHeader>
      <Modal
        visible={showForm}
        onClose={() => setShowForm(false)}
        title={"Create new page"}
        onSubmit={createSubPage}
      >
        <>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">Name</span>
            </div>
            <input
              onChange={(e) => setName(e.target.value)}
              name="name"
              value={name}
              type="text"
              className="form-control"
            />
          </div>
        </>
      </Modal>
      <SectionWrapper>
        <Grid
          items={subPages.map((page) => ({
            name: page.name,
            id: page._id,
            link: `/pages/${page._id}`,
          }))}
        />
      </SectionWrapper>
    </>
  );
};
