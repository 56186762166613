import { DayOfWeek } from "../../types/HelperTypes";
import {
  ICarousel,
  ICarouselWithAsset,
  IFrontlayerConfiguration,
  IChannelMap,
  IOrganizationUnit,
  IRegex,
  IRoomType,
  OrganizationUnitType,
  NendaProduct,
  IAddress,
  IContactPerson,
  IContract,
  Company,
  IBusinessHours,
  ISignageConfig,
  ITag,
} from "../../types/NendaTypes";

export class OrganizationUnit implements IOrganizationUnit {
  numberOfRooms: number;
  name: string;
  address: IAddress;
  contactPerson: IContactPerson;
  contract?: IContract;
  company?: string | Company;
  category?: string;
  numberOfEntries?: number;
  carousels: Array<ICarouselWithAsset | ICarousel>;
  parent: string;
  roomTypes: Array<IRoomType>;
  type: OrganizationUnitType;
  redBeeName?: string;
  defaultRoomType?: any;
  logoUrl?: string;
  hash?: string;
  upsalesCompanyId?: number;
  upsalesSubscriptionId?: number;
  pmsHotelId?: string;
  pmsToken?: string;
  apiKey?: string;
  regex: IRegex;
  allowContinuousStreaming?: boolean;
  showNonPurchasedContent?: boolean;
  reseller?: string;
  systemIntegrator?: string;
  frontlayerConfig?: string | undefined | null;
  channelMap: IChannelMap;
  tags: Pick<ITag, "_id" | "name">[];
  adminTags: Pick<ITag, "_id" | "name">[];
  nendaProducts: Array<NendaProduct>;
  contentChannels: string[];
  businessHours?: Partial<Record<DayOfWeek, IBusinessHours>>;
  signageConfig?: ISignageConfig;
  findRoomType: (roomTypeId: string) => IRoomType;
  created?: string | Date;
  updated?: string | Date;
  _id?: any;

  constructor() {
    this.name = "";
    this.carousels = [];
    this.type = OrganizationUnitType.GLOBAL;
    this.parent = "";
    this.roomTypes = [];
    this.numberOfRooms = 1; // Should be 0 and fetched from db
  }

  getRoomType(roomTypeId: string) {
    return this.roomTypes.find(({ _id }) => _id === roomTypeId);
  }

  isCustomer() {
    const nonCustomerOus = ["NendaLab", "NendaDemo", "Partners"];

    switch (this.type) {
      case "hotel":
        return !nonCustomerOus.includes(this.parent);
      case "hotelChain":
        return !nonCustomerOus.includes(this._id);
    }
    return true;
  }

  isInvalid() {
    switch (this.type) {
      case OrganizationUnitType.HOTEL:
        return (
          !this.hash ||
          !this.defaultRoomType ||
          !this.regex.roomRegex[0] ||
          !this.regex.roomRegex[1] ||
          (!this.upsalesCompanyId && this.isCustomer())
        );
      case OrganizationUnitType.HOTEL_CHAIN:
        return !this.logoUrl || !this.apiKey;
    }
    return false;
  }
}
