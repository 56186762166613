import { Alert, Box, capitalize, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getScreens,
  selectScreensForPremise,
} from "../store/reducers/screenReducer";
import { CustomerPortalState, store } from "../store";
import CircularLoadingIndicator from "../ui-components/loading/CircularLoadingIndicator";
import { useSelector } from "react-redux";
import UnitList from "../components/Unit/UnitList";
import PermissionsGate from "../components/PermissionGate";
import { Resource, Scope } from "../../../../types/NendaTypes";
import CreateUnit from "../components/Unit/Create/CreateUnit";

// make this a separate component like "CenteredLoadingIndicator"
const Loading = () => {
  return (
    <Box
      style={{
        position: "absolute",
        left: "50%",
        top: "50vh",
        transform: " translate(-50%, -50%)",
      }}
    >
      <CircularLoadingIndicator />
    </Box>
  );
};

const UnitListPage: React.FC = () => {
  const { premiseId } = useParams<{
    premiseId: string;
  }>();

  useEffect(() => {
    if (!premiseId) return;

    store.dispatch(getScreens(premiseId));
  }, [premiseId]);

  const isLoading = useSelector(
    (state: CustomerPortalState) => state.screen.isLoading
  );

  const screens = useSelector((state: CustomerPortalState) =>
    premiseId ? selectScreensForPremise(state, premiseId) : []
  );

  if (isLoading) return <Loading />;

  return (
    <PermissionsGate
      restriction={{
        resource: Resource.Premise,
        scopes: [Scope.CanAdministrate],
      }}
    >
      <Box>
        <Stack
          sx={{ display: "flex", alignItems: "center" }}
          spacing={3}
          direction="row"
        >
          <Typography variant="h2">
            {capitalize(t("glossary.unit", { count: 2 }))}
          </Typography>
          <CreateUnit />
        </Stack>
        <Box>
          {screens.length === 0 ? (
            <Alert severity="info">
              {t("customerportal.pages.unit.not_found")}
            </Alert>
          ) : (
            <UnitList units={screens} />
          )}
        </Box>
      </Box>
    </PermissionsGate>
  );
};

export default UnitListPage;
