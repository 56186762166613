import { Card } from "@mui/material";
import React from "react";
import { GetScreensResponse } from "../../../../../server/api/internal/ad";
import { IAdCampaign, IRoom } from "../../../../../types/NendaTypes";
import CampaignCard from "./CampaignCard";
import CampaignForm from "./CampaignForm";

interface CampaignProps {
  campaign: IAdCampaign;
  screens: GetScreensResponse;
}

const Campaign: React.FC<CampaignProps> = ({ campaign, screens }) => {
  const [isEditing, setIsEditing] = React.useState(false);

  const toggleEdit = () => setIsEditing(!isEditing);

  return (
    <Card sx={{ my: 2 }}>
      {isEditing ? (
        <CampaignForm
          campaign={campaign}
          onClose={toggleEdit}
          screens={screens}
        />
      ) : (
        <CampaignCard campaign={campaign} onToggleEdit={toggleEdit} />
      )}
    </Card>
  );
};

export default Campaign;
