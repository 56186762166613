import { Button, TextareaAutosize, TextField } from "@mui/material";
import React, { FC } from "react";
import { FaSave } from "react-icons/fa";
import { useSelector } from "react-redux";
import { ITemplate } from "../../../types/NendaTypes";
import { store } from "../../redux/store";
import { DeleteTemplate, UpdateTemplate } from "../../redux/templates.redux";
import { AppState } from "../../types/redux";
import { Confirm } from "../Confirm/Confirm";
import { PageHeader } from "../PageHeader/PageHeader";
import { SectionWrapper } from "../UI/SectionWrapper/SectionWrapper";
import { useParams } from "react-router-dom";

export const TemplatesComponent: FC = () => {
  const params = useParams();
  const template = useSelector((state: AppState) =>
    state.templates?.find((template) => template["_id"] === params.id)
  );
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [state, setState] = React.useState<ITemplate | null>(null);
  if (!template) return <>No template found</>;
  React.useEffect(() => {
    setState(template);
  }, [template]);

  const updateTemplate = () => {
    if (!state || !template) return;
    const { content, subject, name } = state;
    store.dispatch(UpdateTemplate(template["_id"], { content, subject, name }));
  };

  const deleteTemplate = () => {
    if (!template) return;
    store.dispatch(DeleteTemplate(template["_id"]));
  };

  if (!template || !state) return <></>;
  return (
    <>
      <PageHeader>
        <h2>{template.name}</h2>
        <Button
          variant="contained"
          color="error"
          onClick={() => setShowDeleteConfirm(true)}
        >
          Delete template
        </Button>
      </PageHeader>
      {showDeleteConfirm && (
        <Confirm
          onCancel={() => setShowDeleteConfirm(false)}
          onConfirm={deleteTemplate}
        />
      )}
      <SectionWrapper>
        <h4>Name</h4>
        <input
          onChange={(e) => setState({ ...state, name: e.target.value })}
          name="name"
          value={state.name}
          type="text"
          className="form-control"
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>
          Subject
          <span className="description">Used for mails</span>
        </h4>
        <input
          onChange={(e) => setState({ ...state, subject: e.target.value })}
          name="subject"
          value={state.subject}
          type="text"
          className="form-control"
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>Content</h4>
        <div>
          <TextareaAutosize
            style={{ width: "100%" }}
            maxRows={50}
            value={state.content}
            name="content"
            onChange={(e) => setState({ ...state, content: e.target.value })}
          />
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <Button
          endIcon={<FaSave />}
          variant="contained"
          color="primary"
          disabled={state == template}
          onClick={updateTemplate}
        >
          Save
        </Button>
      </SectionWrapper>
    </>
  );
};
