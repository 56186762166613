import { Components, Theme } from "@mui/material";
import GilroyMedium from "../../../assets/Gilroy-Medium.otf";
import GilroyRegular from "../../../assets/Gilroy-Regular.ttf";
import GilroyBold from "../../../assets/Gilroy-Bold.otf";

const fontImports: Components<Omit<Theme, "components">> = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Gilroy';
        font-style: normal;
        font-display: swap;
        src: local('Gilroy-Medium'), url(${GilroyMedium}) format('opentype');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      @font-face {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-display: swap;
        src: local('Gilroy-Medium'), url(${GilroyMedium}) format('opentype');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      @font-face {
        font-family: 'Gilroy-Regular';
        font-style: normal;
        font-display: swap;
        src: local('Gilroy-Regular'), url(${GilroyRegular}) format('opentype');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      @font-face {
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-display: swap;
        src: local('Gilroy-Bold'), url(${GilroyBold}) format('opentype');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
  `,
  },
};

export default fontImports;
