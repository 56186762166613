import { ReactNode } from "react";
import InputSelect from "../../../ui-components/input/InputSelect";
import { t } from "i18next";
import { Box, MenuItem } from "@mui/material";
import { IRoomType } from "../../../../../../types/NendaTypes";
import RoomTypeCategoryChip from "./RoomTypeCategoryChip";

interface RoomTypeSelectProps {
  roomTypes: IRoomType[];
  selectedRoomTypeId: string;
  onChange: (roomTypeId: string) => void;
}

const RoomTypeSelect: React.FC<RoomTypeSelectProps> = ({
  roomTypes,
  selectedRoomTypeId,
  onChange,
}): ReactNode => {
  const selectedRoomType = roomTypes.find(
    (roomType) => roomType._id === selectedRoomTypeId
  );

  if (!selectedRoomType) return null;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "end",
        marginBottom: "1rem",
        gap: "1rem",
      }}
    >
      <InputSelect
        id="company-type"
        value={selectedRoomTypeId}
        title={t("customerportal.pages.unit.room_type_select")}
        defaultValue={selectedRoomTypeId}
        onChange={(e) => onChange(e.target.value)}
        variant={"outlined"}
        sx={{ fontSize: "1rem", textTransform: "capitalize" }}
      >
        {roomTypes.map((roomType) => (
          <MenuItem value={roomType._id} key={roomType._id}>
            {roomType.name}
          </MenuItem>
        ))}
      </InputSelect>
      <RoomTypeCategoryChip roomTypeCategory={selectedRoomType?.category} />
    </Box>
  );
};

export default RoomTypeSelect;
