import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Resource, Scope } from "../../../../../types/NendaTypes";
import PermissionsGate from "../../components/PermissionGate";
import StreamChannelList from "../../components/StreamChannels/StreamChannelList";
import { store } from "../../store";
import {
  GetStreamChannels,
  selectStreamChannels,
} from "../../store/reducers/streamChannelReducer";

const StreamChannels: React.FC = () => {
  const channels = useSelector(selectStreamChannels);

  useEffect(() => {
    store.dispatch(GetStreamChannels());
  }, []);

  return (
    <PermissionsGate
      restriction={{
        resource: Resource.StreamChannel,
        scopes: [Scope.CanAdministrate],
      }}
    >
      <Box>
        <Typography mb={2} variant="h3">
          Stream channels
        </Typography>
        <StreamChannelList channels={channels} />
      </Box>
    </PermissionsGate>
  );
};

export default StreamChannels;
