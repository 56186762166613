import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useSelector } from "react-redux";
import ChangePasswordForm from "../components/ChangePasswordForm";
import { CustomerPortalState, store } from "../store";
import { CloseSettings } from "../store/reducers/customizationReducer";
import DefaultDialog from "../ui-components/dialog/dialog";
import { selectLoggedInUser } from "../store/reducers/authReducer";

const Profile = () => {
  const handleClose = () => {
    store.dispatch(CloseSettings());
  };

  const open = useSelector(
    (state: CustomerPortalState) => state.customization.settingsIsOpen
  );

  return (
    <DefaultDialog open={open || false} onClose={handleClose}>
      <Settings />
    </DefaultDialog>
  );
};

const Settings = () => {
  const userName = useSelector(selectLoggedInUser);

  return (
    <>
      <TableContainer sx={{ padding: "2rem 0" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: "1rem",
          }}
        >
          <Typography mb={"1rem"} variant="h6" align="left">
            {t("customerportal.pages.account_settings.title")}
          </Typography>
        </Box>
        <Table width={"100%"}>
          <TableBody>
            <TableRow>
              <TableCell>
                <SettingsTitle title={t("common.user_information")} />
              </TableCell>
            </TableRow>
            <LabelAndValueTableRow
              value={userName || ""}
              label={t("common.email")}
              align="right"
            />
            <TableRow>
              <TableCell>
                <Divider sx={{ pb: "1rem" }} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <SettingsTitle
                  title={t(
                    "customerportal.pages.account_settings.change_password"
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}>
                <ChangePasswordForm />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Profile;

type LabelAndValueProps = {
  label: any;
  value: string;
  align?: "left" | "right";
};

const SettingsTitle = ({ title }: { title: string }) => (
  <Typography
    mt={"1rem"}
    variant="subtitle1"
    align="left"
    color={(theme) => theme.palette.text.disabled}
  >
    {title}
  </Typography>
);

const LabelAndValueTableRow = ({
  label,
  value,
  align = "left",
}: LabelAndValueProps) => {
  return (
    <TableRow>
      <TableCell colSpan={2} align={align}>
        <LabelAndValue value={value || ""} label={label} />
      </TableCell>
    </TableRow>
  );
};

export const LabelAndValue = ({
  label,
  value,
}: {
  label: any;
  value: string;
}) => (
  <Box
    sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}
  >
    <Typography
      variant="body1"
      color={(theme) => theme.palette.text.disabled}
      sx={{ fontSize: "0.9rem" }}
    >
      {label}
    </Typography>
    <Typography variant="body1" sx={{ fontSize: "0.9rem" }}>
      {value}
    </Typography>
  </Box>
);
