import { Box, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CustomerPortalState } from "../../../store";
import { getPremisesByCompanyId } from "../../../store/reducers/organizationUnitReducer";
import {
  selectNavigatedCompanyId,
  selectNavigatedPremiseId,
} from "../../../store/reducers/workspaceReducer";
import DefaultDialog from "../../../ui-components/dialog/dialog";
import InputSelect from "../../../ui-components/input/InputSelect";
import { t } from "i18next";

type ImportConfigModalProps = {
  onClose: ({
    isConfirmed,
    selectedConfigId,
  }: {
    isConfirmed: boolean;
    selectedConfigId?: string;
  }) => void;
  open: boolean;
};

const ImportConfigModal = ({ onClose, open }: ImportConfigModalProps) => {
  const company = useSelector(selectNavigatedCompanyId);
  const premise = useSelector(selectNavigatedPremiseId);
  const premises = useSelector((state: CustomerPortalState) =>
    getPremisesByCompanyId(state, company || "")
  );

  const [selectedConfigId, setSelectedConfigId] = useState<string>("");

  const handleConfigChange = (event: SelectChangeEvent<string>) => {
    setSelectedConfigId(event.target.value);
  };

  const selectableOptions = premises.filter(
    (p) => p.frontlayerConfig && p._id !== premise
  );

  const handleImport = () => {
    onClose({ isConfirmed: true, selectedConfigId });
    setSelectedConfigId("");
  };

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title={t("customerportal.pages.frontlayer.import_config_from") || ""}
      defaultSaveButton={false}
      customActions={[
        {
          label: t("customerportal.pages.frontlayer.import_config") || "",
          onClick: handleImport,
          disabled: !selectedConfigId,
        },
      ]}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="body2" textAlign={"center"}>
            {t("customerportal.pages.frontlayer.import_config_desc")}
          </Typography>
          <Box>
            <InputSelect
              variant="outlined"
              defaultValue={""}
              id="config-select"
              value={selectedConfigId}
              onChange={handleConfigChange}
              sx={{}}
            >
              {selectableOptions?.map((p) => (
                <MenuItem key={p._id} value={p.frontlayerConfig || ""}>
                  {p?.name}
                </MenuItem>
              ))}
            </InputSelect>
          </Box>
        </Box>
      </Box>
    </DefaultDialog>
  );
};

export default ImportConfigModal;
