import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { CountryCode, getCountry, TimezoneName } from "countries-and-timezones";
import { t } from "i18next";
import * as React from "react";
import { useSelector } from "react-redux";
import { DayOfWeek } from "../../../../../../types/HelperTypes";
import {
  IAddress,
  IBusinessHours,
  IContactPerson,
  IContract,
  IOrganizationUnit,
  Resource,
  Scope,
} from "../../../../../../types/NendaTypes";
import { getAllPremiseCategories } from "../../../store/reducers/organizationUnitReducer";
import PermissionsGate from "../../PermissionGate";
import Address from "./Address";
import BusinessHours from "./BusinessHours";
import Commercial from "./Commercial";
import ContactPerson from "./ContactPerson";
import Contract from "./Contract";

interface PremiseInfoProps {
  premiseInfo: IOrganizationUnit;
  setPremiseInfo: (value: IOrganizationUnit) => void;
}

const PremiseInfo: React.FC<PremiseInfoProps> = ({
  premiseInfo,
  setPremiseInfo,
}) => {
  const existingCategories = useSelector(getAllPremiseCategories);

  const handleAddressChange = (address: IAddress) => {
    setPremiseInfo({ ...premiseInfo, address });
  };

  const handleCountryChange = (country: CountryCode) => {
    if (premiseInfo.address)
      setPremiseInfo({
        ...premiseInfo,
        address: {
          ...premiseInfo.address,
          country,
          timezone: getCountry(country).timezones[0] as TimezoneName,
        },
      });
  };

  const handleTimezoneChange = (timezone: TimezoneName) => {
    if (premiseInfo.address)
      setPremiseInfo({
        ...premiseInfo,
        address: { ...premiseInfo.address, timezone },
      });
  };
  const handleContactPersonChange = (contactPerson: IContactPerson) => {
    setPremiseInfo({ ...premiseInfo, contactPerson });
  };
  const handleContractChange = (contract: IContract) => {
    setPremiseInfo({ ...premiseInfo, contract });
  };

  const handleNumberOfEntriesChange = (entries: number) => {
    setPremiseInfo({ ...premiseInfo, numberOfEntries: entries });
  };
  const handleDwellTimeChange = (dwellTimeInMinutes: number) => {
    setPremiseInfo({ ...premiseInfo, dwellTimeInMinutes });
  };
  const handleCategoryChange = (category: string) => {
    setPremiseInfo({ ...premiseInfo, category });
  };

  const handleBusinessHoursChange = (
    businessHours: Partial<Record<DayOfWeek, IBusinessHours>>
  ) => {
    setPremiseInfo({ ...premiseInfo, businessHours: businessHours });
  };

  return (
    <Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t("form.address.label")}
        </AccordionSummary>
        <AccordionDetails>
          <Address
            address={premiseInfo.address}
            onAddressChange={handleAddressChange}
            onTimezoneChange={handleTimezoneChange}
            onCountryChange={handleCountryChange}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t("form.contact_person")}
        </AccordionSummary>
        <AccordionDetails>
          <ContactPerson
            contactPerson={
              premiseInfo.contactPerson || {
                fullName: "",
                emailAddress: "",
                phoneNumber: "",
              }
            }
            onChange={handleContactPersonChange}
          />
        </AccordionDetails>
      </Accordion>
      <PermissionsGate
        restriction={{
          resource: Resource.Premise,
          scopes: [Scope.CanAdministrate],
        }}
      >
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {t("form.contract")}
          </AccordionSummary>
          <AccordionDetails>
            <Contract
              contract={premiseInfo.contract}
              onChange={handleContractChange}
            />
          </AccordionDetails>
        </Accordion>
      </PermissionsGate>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t("form.commercial")}
        </AccordionSummary>
        <AccordionDetails>
          <Commercial
            entries={premiseInfo?.numberOfEntries}
            dwellTime={premiseInfo?.dwellTimeInMinutes}
            category={premiseInfo?.category}
            categories={existingCategories}
            onCategoryChange={handleCategoryChange}
            onEntriesChange={handleNumberOfEntriesChange}
            onDwellTimeChange={handleDwellTimeChange}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t("form.business_hours")}
        </AccordionSummary>
        <AccordionDetails>
          <BusinessHours
            businessHours={premiseInfo?.businessHours}
            onChange={handleBusinessHoursChange}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PremiseInfo;
