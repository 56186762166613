import React from "react";
import { AnalyticsPage } from "./pages/Analytics/Analytics";
import { CarouselPage } from "./pages/CarouselPage/CarouselPage";
import { OrganizationUnitsPage } from "./pages/OrganizationUnits/OrganizationUnits";
import { SettingsPage } from "./pages/Settings/Settings";
import { SubPage } from "./pages/SubPage/SubPage";
import { TemplatesPage } from "./pages/Templates/Templates";

export const routes = [
  {
    name: "Premises",
    path: "organizationunits/*",
    inMenu: true,
    element: <OrganizationUnitsPage />,
  },
  {
    name: "Settings",
    path: "settings/*",
    inMenu: true,
    element: <SettingsPage />,
  },
  {
    name: "Analytics",
    path: "analytics/*",
    inMenu: true,
    element: <AnalyticsPage />,
  },
  {
    name: "Carousels",
    path: "components/carousels/*",
    inMenu: true,
    element: <CarouselPage />,
  },
  {
    name: "Pages",
    path: "pages/*",
    inMenu: true,
    element: <SubPage />,
  },
  {
    name: "Templates",
    path: "templates/*",
    inMenu: true,
    element: <TemplatesPage />,
  },
] as any;
