import {
  DeviceOS,
  FrontLayerBackgroundType,
  FrontLayerItemType,
  IFrontLayerOptions,
} from "../../../../../../types/NendaTypes";

export type GenericFont =
  | "sans-serif"
  | "serif"
  | "monospace"
  | "cursive"
  | "fantasy";

export type Font = {
  family: string;
  generic: GenericFont;
};

/* List of fonts taken from https://www.w3schools.com/cssref/css_websafe_fonts.php */
export const FONTS: Font[] = [
  { family: "Gilroy", generic: "sans-serif" },
  { family: "Arial", generic: "sans-serif" },
  { family: "Verdana", generic: "sans-serif" },
  { family: "Tahoma", generic: "sans-serif" },
  { family: "Trebuchet MS", generic: "sans-serif" },
  { family: "Times New Roman", generic: "serif" },
  { family: "Georgia", generic: "serif" },
  { family: "Garamond", generic: "serif" },
  { family: "Courier New", generic: "monospace" },
  { family: "Georgia", generic: "serif" },
  { family: "Brush Script MT", generic: "cursive" },
];

export const defualtFrontLayerOptions: IFrontLayerOptions = {
  availableDeviceApps: [
    {
      id: "svtplay",
      name: { sv: "SVT Play" },
      application: {
        platformAppIds: { [DeviceOS.ANDROID_TV]: "se.svt.android.svtplay" },
      },
      iconType: "image",
      imageUrl: "https://static.nenda.com/assets/logo/svtplay.png",
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      enabled: true,
      type: FrontLayerItemType.APPLICATION,
    },
    {
      id: "youtube",
      name: { sv: "YouTube" },
      application: {
        platformAppIds: {
          [DeviceOS.ANDROID_TV]: "com.google.android.youtube.tv",
        },
      },
      iconType: "image",
      imageUrl: "https://static.nenda.com/assets/logo/youtube.png",
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      enabled: true,
      type: FrontLayerItemType.APPLICATION,
    },
    {
      id: "pluto_tv",
      name: { sv: "Pluto TV" },
      application: {
        platformAppIds: {
          [DeviceOS.ANDROID_TV]: "tv.pluto.android",
        },
      },
      iconType: "image",
      imageUrl: "https://static.nenda.com/assets/logo/plutotv.png",
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      enabled: true,
      type: FrontLayerItemType.APPLICATION,
    },
    {
      id: "spotify",
      name: { sv: "Spotify" },
      application: {
        platformAppIds: {
          [DeviceOS.ANDROID_TV]: "com.spotify.tv.android",
        },
      },
      iconType: "image",
      imageUrl: "https://static.nenda.com/assets/logo/spotify.png",
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      enabled: true,
      type: FrontLayerItemType.APPLICATION,
    },
    {
      id: "viaplay",
      name: { sv: "Viaplay" },
      application: {
        platformAppIds: {
          [DeviceOS.ANDROID_TV]: "com.viaplay.android",
        },
      },
      iconType: "image",
      imageUrl: "https://static.nenda.com/assets/logo/viaplay.png",
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      enabled: true,
      type: FrontLayerItemType.APPLICATION,
    },
    {
      id: "tv4_play",
      name: { sv: "TV4 Play" },
      application: {
        platformAppIds: {
          [DeviceOS.ANDROID_TV]: "se.tv4.tv4playtab",
        },
      },
      iconType: "image",
      imageUrl: "https://static.nenda.com/assets/logo/tv4play.png",
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      enabled: true,
      type: FrontLayerItemType.APPLICATION,
    },
  ],
  availableHomeScreenItems: [
    {
      id: "tv",
      name: { sv: "TV" },
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      iconType: "icon",
      application: {
        platformAppIds: {
          [DeviceOS.ANDROID_TV]: "com.noriginmedia.nenda.nm",
        },
      },
      enabled: false,
      iconName: "tv",
      type: FrontLayerItemType.APPLICATION,
      description: {
        sv: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed accumsan.",
      },
    },
    {
      id: "apps",
      name: { sv: "Nöje" },
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      iconType: "icon",
      enabled: false,
      iconName: "sunny",
      type: FrontLayerItemType.ROUTE,
      description: {
        sv: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed accumsan.",
      },
    },
    {
      id: "radio",
      name: { sv: "Radio" },
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      iconType: "icon",
      enabled: false,
      iconName: "radio",
      type: FrontLayerItemType.ROUTE,
      description: {
        sv: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed accumsan.",
      },
    },
    {
      id: "information",
      name: { sv: "Information" },
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      iconType: "icon",
      enabled: false,
      iconName: "info",
      type: FrontLayerItemType.ROUTE,
      description: {
        sv: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed accumsan.",
      },
    },
    {
      id: "cast",
      name: { sv: "Cast" },
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      iconType: "icon",
      enabled: false,
      iconName: "cast",
      type: FrontLayerItemType.ROUTE,
      description: {
        sv: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed accumsan.",
      },
    },
    {
      id: "image",
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
      iconType: "icon",
      enabled: false,
      type: FrontLayerItemType.IMAGE,
    },
  ],
};
