import { useEffect, useState } from "react";
import {
  IRoom,
  ISignagePromotion,
  SignageSlotAmount,
} from "../../../../../../types/NendaTypes";
import { store } from "../../../store";
import { updateScreen } from "../../../store/reducers/screenReducer";
import DefaultDialog from "../../../ui-components/dialog/dialog";
import EditScreen from "../screenCard/EditScreen";
import { t } from "i18next";

export type ScreenInfoType = {
  name: string;
  description?: string;
  defaultChannel?: string;
  soundEnabled?: boolean;
  signageSlotsPerHour?: SignageSlotAmount;
  promotions: string[];
  numberOfScreens?: number;
};

const initScreenInfo: ScreenInfoType = {
  name: "",
  description: "",
  defaultChannel: "",
  soundEnabled: false,
  signageSlotsPerHour: 0,
  promotions: [],
};

interface EditScreenDialogProps {
  screen: IRoom;
  onClose: () => void;
  availablePromotions: ISignagePromotion[];
}

const EditScreenDialog: React.FC<EditScreenDialogProps> = ({
  screen,
  onClose,
  availablePromotions,
}) => {
  const [editScreenInfo, setEditScreenInfo] = useState<ScreenInfoType>({
    name: screen?.name || "",
    description: screen?.description,
    defaultChannel: screen?.signage.config?.defaultChannel,
    soundEnabled: screen?.signage.config?.isSoundEnabled,
    signageSlotsPerHour: screen?.signage.config?.numberOfHourlySignageSlots,
    promotions: screen?.signage.schedule.promotions || [],
    numberOfScreens: screen?.signage.config?.numberOfSceens,
  });

  const handlePromotionChange = (selectedPromotions: string[]) => {
    setEditScreenInfo({ ...editScreenInfo, promotions: selectedPromotions });
  };

  const handleNumberOfScreensChange = (value: number) => {
    setEditScreenInfo({ ...editScreenInfo, numberOfScreens: value });
  };

  const updateScreenInfo = ({ isConfirmed }) => {
    if (isConfirmed && screen) {
      store.dispatch(
        updateScreen({
          ...screen,
          name: editScreenInfo.name,
          description: editScreenInfo.description,
          signage: {
            ...screen.signage,
            config: {
              ...screen.signage.config,
              isSoundEnabled: editScreenInfo.soundEnabled,
              numberOfHourlySignageSlots: editScreenInfo.signageSlotsPerHour,
              defaultChannel: editScreenInfo.defaultChannel,
              numberOfSceens: editScreenInfo.numberOfScreens,
            },
            schedule: {
              ...screen.signage.schedule,
              promotions: editScreenInfo.promotions,
            },
          },
        })
      );
    }
    onClose();
    setEditScreenInfo(initScreenInfo);
  };
  if (!screen) return null;
  return (
    <DefaultDialog
      open={screen._id !== ""}
      onClose={updateScreenInfo}
      title={t("customerportal.pages.dashboard.screen_settings") as string}
    >
      <EditScreen
        screen={screen}
        screenInfoState={editScreenInfo}
        setScreenInfoState={setEditScreenInfo}
        onPromotionChange={handlePromotionChange}
        availablePromotions={availablePromotions}
        onNumberOfScreensChange={handleNumberOfScreensChange}
        numberOfScreens={editScreenInfo.numberOfScreens}
      />
    </DefaultDialog>
  );
};
export default EditScreenDialog;
