import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import App from "../CustomerPortal/App";
import LoggedOutLayout from "../CustomerPortal/LoggedOutLayout";
import { CustomerProtectedRoute } from "../CustomerPortal/components/CustomerProtectedRoute";
import { ErrorNotification } from "../CustomerPortal/components/ErrorNotification";
import { Notification } from "../CustomerPortal/components/Notification";
import { store } from "../CustomerPortal/store";
import themeOptions from "../CustomerPortal/theme/theme";

const theme = createTheme(themeOptions);
const loggedOutRoutes = [
  {
    path: "login",
  },
  {
    path: "forgotpassword",
  },
  {
    path: "resetpassword/:hash",
  },
];

const CustomerPortal = () => {
  return (
    <>
      <ErrorNotification />
      <Notification />
      <Routes>
        <Route
          path="/*"
          element={<CustomerProtectedRoute path="/*" element={<App />} />}
        />
        {loggedOutRoutes.map((route) => (
          <Route
            path={route.path}
            key={route.path}
            element={<LoggedOutLayout />}
          />
        ))}
      </Routes>
    </>
  );
};

export const CustomerPortalIndex = () => {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <CustomerPortal />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};
