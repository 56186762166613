import { Box, useTheme } from "@mui/material";
import { IAsset } from "../../../../../types/NendaTypes";
import { IListResponse } from "../../../../../types/RequestTypes";
import AssetCard from "./AssetCard";

const AssetGrid = ({
  assets,
  onSelectAsset,
  isLoading,
}: {
  assets: IListResponse<IAsset>;
  onSelectAsset: (value: IAsset) => void;
  isLoading: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      display={"grid"}
      gap={"1.3rem"}
      sx={{
        padding: "1.3rem",
        borderRadius: "0 0.3rem 0.3rem 0.3rem",
        gridTemplateColumns: "repeat(6, 1fr)",

        [theme.breakpoints.down("xl")]: {
          gridTemplateColumns: "repeat(4, 1fr)",
        },
        [theme.breakpoints.down("lg")]: {
          gridTemplateColumns: "repeat(3, 1fr)",
        },
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      <>
        {assets.data.map((asset) => (
          <AssetCard key={asset._id} asset={asset} onClick={onSelectAsset} />
        ))}
      </>
    </Box>
  );
};

export default AssetGrid;
