import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppState } from "../../../types/redux";
import { ProductOfferingSelects } from "./ProductOfferingSelects";
import { ProductOfferingsSaveButton } from "./ProductOfferingsSaveButton";
import { flattenRules } from "@shared/productOfferingsUtils";

const ROOM_TYPE = "roomType";

interface IProductOfferingSelectsHandlerProps {
  offering: any;
}

export const ProductOfferingSelectsHandler: React.FC<
  IProductOfferingSelectsHandlerProps
> = ({ offering }) => {
  const { hotelChainId } = useParams<{ hotelChainId: string }>();

  const hotelChain = useSelector((state: AppState) =>
    state.organizationUnits.organizationUnits.find(
      ({ _id }) => _id === hotelChainId
    )
  );

  const [roomTypeValues, setRoomTypeValues] = useState<string[]>([]);
  const [originalRoomTypesValues, setOriginalRoomTypesValues] = useState<{
    values: string[];
    empty: boolean;
  }>({
    values: [],
    empty: true,
  });
  const [editedRoomTypes, setEditedRoomTypes] = useState([]);

  useEffect(() => {
    if (offering) {
      if (hotelChain?.roomTypes?.length) {
        if (offering.labelRule) {
          const offeringRoomTypesValues = returnInitialLabelRules(ROOM_TYPE);
          setOriginalRoomTypesValues({
            values: offeringRoomTypesValues,
            empty: offeringRoomTypesValues.length <= 0,
          });
          setRoomTypeValues(offeringRoomTypesValues);
          const currentRoomTypesValues = returnEditedLabelRules(
            offeringRoomTypesValues,
            ROOM_TYPE
          );
          setEditedRoomTypes(currentRoomTypesValues);
        } else {
          setRoomTypeValues([]);
        }
      }
    }
  }, [offering, hotelChain?.roomTypes || []]);

  const returnInitialLabelRules = (type) => {
    return flattenRules(offering.labelRule || [])
      .filter((rule) => rule.eq && rule.eq.key === type)
      .map((r) => r.eq!.value);
  };

  const returnEditedLabelRules = (values, type) => {
    const editedValues = values.map((value) => {
      return {
        eq: {
          key: type,
          value: value,
        },
      };
    });
    return editedValues;
  };

  const buttonDisabled = () => {
    if (
      originalRoomTypesValues.values === roomTypeValues ||
      (originalRoomTypesValues.empty && roomTypeValues.length <= 0)
    ) {
      return true;
    }
    return false;
  };

  const updateRoomTypes = (values) => {
    setRoomTypeValues(values);
    const labelRules = returnEditedLabelRules(values, ROOM_TYPE);
    setEditedRoomTypes(labelRules);
  };

  return (
    <div>
      <ProductOfferingSelects
        roomTypesValues={roomTypeValues}
        updateRoomTypes={updateRoomTypes}
      />
      <ProductOfferingsSaveButton
        disabled={buttonDisabled()}
        editedRoomTypes={editedRoomTypes}
      />
    </div>
  );
};
