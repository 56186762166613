import { Box, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { t } from "i18next";
import { LoadingStatus } from "../../../../../../../../../types/NendaTypes";
import ConfirmationCircle from "../../../../../../assets/images/svg/confirmation_circle.svg";
import CircularLoadingIndicator from "../../../../../../ui-components/loading/CircularLoadingIndicator";

const OrderConfirmation = ({
  loadingStatus,
}: {
  loadingStatus: LoadingStatus;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        px: isMobile ? "1rem" : "12rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loadingStatus === LoadingStatus.SUCCEEDED ? (
        <Box
          sx={{
            maxWidth: "500px",
            dipslay: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography textAlign={"center"} variant="h4">
            {t(
              "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.order_confirmation_title"
            )}
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ConfirmationCircle
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Box>
          <Typography textAlign={"center"} variant="body1">
            {t(
              "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.order_confirmation_description"
            )}
          </Typography>
          <br />
          <Typography textAlign={"center"} variant="body1">
            {t(
              "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.order_confirmation_support"
            )}
            <Link
              href={`mailto:${t(
                "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.order_confirmation_support_link"
              )}`}
            >
              {t(
                "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.order_confirmation_support_link"
              )}
            </Link>
          </Typography>
        </Box>
      ) : loadingStatus === LoadingStatus.FAILED ? (
        "Something went wrong"
      ) : (
        <CircularLoadingIndicator />
      )}
    </Box>
  );
};

export default OrderConfirmation;
