import { httpClient, Service } from "./client";
import { IErrorEntity } from "@shared/types/RedBee.types";
import { Observable } from "rxjs";

class AnalyticsService extends Service {
  getSessions() {
    return httpClient.get("/analytics/sessions", this.authHeader());
  }

  getErrors(): Observable<{
    summary: Map<string, number>;
    errors: Array<IErrorEntity>;
  }> {
    return httpClient.get("/analytics/errors", this.authHeader());
  }
}

export const analyticsService = new AnalyticsService();
