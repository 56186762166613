import { Autocomplete, Box, Grid } from "@mui/material";
import { t } from "i18next";
import React, { useState } from "react";
import DropdDownPaper from "../../../ui-components/DropdownPaper";
import TextInput from "../../../ui-components/input/TextInput";

const style = {
  textField: {
    maxWidth: "50ch",
  },
};

interface CommercialProps {
  entries: number | undefined;
  dwellTime: number | undefined;
  category: string | undefined;
  categories: string[];
  onEntriesChange: (entries: number) => void;
  onDwellTimeChange: (dwellTime: number) => void;
  onCategoryChange: (category: string) => void;
}

const Commercial: React.FC<CommercialProps> = ({
  entries,
  dwellTime,
  category,
  categories,
  onEntriesChange,
  onDwellTimeChange,
  onCategoryChange,
}) => {
  return (
    <Box component="form">
      <Grid container spacing={"0.5rem"} columns={2}>
        <Grid item xs={1}>
          <TextInput
            fullWidth
            variant="standard"
            id="entries"
            label={t("customerportal.pages.dashboard.entries_per_week")}
            type="number"
            value={entries}
            autoComplete="off"
            onChange={(e) => onEntriesChange(Number(e.target.value))}
          />
        </Grid>
        <Grid item xs={1}>
          <Autocomplete
            fullWidth
            freeSolo
            id="category"
            options={categories}
            value={category || ""}
            autoSelect={true}
            onChange={(e, v) => {
              onCategoryChange(v || "");
            }}
            PaperComponent={DropdDownPaper}
            sx={{
              "& .MuiAutocomplete-root": {
                width: "100%",
              },
            }}
            renderInput={(params) => (
              <TextInput
                {...params}
                variant="standard"
                id="category"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    position: "absolute !important",
                    top: "-1.2rem !important",
                    padding: "0.5rem !important",
                  },
                }}
                label={t("form.category")}
              />
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <TextInput
            fullWidth
            variant="standard"
            id="dwell-time"
            label={t("customerportal.pages.dashboard.dwell_time")}
            type="number"
            value={dwellTime}
            autoComplete="off"
            onChange={(e) => onDwellTimeChange(Number(e.target.value))}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Commercial;
