import { Button } from "@mui/material";
import React from "react";
import { CacheLevel } from "../../../../types/NendaTypes";
import { store } from "../../../redux/store";
import { ClearCache } from "../../../redux/util.redux";

interface IClearCacheButtonProps {
  cacheLevel: CacheLevel;
  id?: string;
}

export const ClearCacheButton: React.FC<IClearCacheButtonProps> = ({
  cacheLevel,
  id,
}) => {
  const doClearCache = () => {
    store.dispatch(ClearCache(cacheLevel, id ?? ""));
  };

  return (
    <Button onClick={() => doClearCache()} variant="contained" color="success">
      Clear cache
    </Button>
  );
};
