import { Grid } from "gridjs-react";
import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { analyticsService } from "../../http/analytics.service";
import { IErrorEntity } from "@shared/types/RedBee.types";

interface IAnalyticsOverviewProps {}

export const AnalyticsOverview: React.FC<IAnalyticsOverviewProps> = () => {
  //const [sessions, setSessions] = useState([]); // Not used yet
  const [errors, setErrors] = useState<{
    summary: Map<string, number>;
    errors: Array<IErrorEntity>;
  }>({ summary: new Map(), errors: [] });
  /*useEffect(() => {  // Not used yet
      analyticsService
        .getSessions()
        .toPromise()
        .then((res) => {
          setSessions(res);
        });
    }, []);*/
  useEffect(() => {
    analyticsService
      .getErrors()
      .toPromise()
      .then((res) => {
        setErrors(res);
      });
  }, []);
  return (
    <>
      <PageHeader>
        <h2>Analytics</h2>
      </PageHeader>
      <h3 className="my-4">Player errors</h3>
      <Chart
        type="line"
        height={150}
        options={{ maintainAspectRatio: true }}
        data={{
          labels: errors && errors.summary ? Object.keys(errors.summary) : [],
          datasets: [
            {
              label: "Stream errors",
              data:
                errors && errors.summary ? Object.values(errors.summary) : [],
              backgroundColor: ["rgba(255, 99, 132, 0.2)"],
              borderColor: ["rgba(255, 99, 132, 1)"],
              borderWidth: 1,
            },
          ],
        }}
      />
      <hr />
      <Grid
        data={
          errors && errors.errors && errors.errors.length > 0
            ? errors.errors.reverse().map((job) => {
                return [job.ErrorTime, job.AccountId, job.Message];
              })
            : []
        }
        columns={["Time", "User", "Message"]}
        pagination={true}
        search={true}
        className={{
          table: "table table-striped table-hover table-analytics",
          search: "form-group",
        }}
      />
      {/*<table className="table table-striped table-hover table-analytics">
        <thead>
          <tr>
            <th scope="col">Timestamp</th>
            <th scope="col">User</th>
            <th scope="col">Message</th>
          </tr>
        </thead>
        <tbody>
          {(errors && errors.errors && errors.errors.length > 0) ? errors.errors.map((job) => {
            return (
              <tr key={job.RowKey._}>
                <td>{job.ErrorTime._}</td>
                <td>{job.AccountId._}</td>
                <td>{job.Message._}</td>
              </tr>
            );
          }) : <tr></tr> }
        </tbody>
        </table>*/}
    </>
  );
};
