import { BorderColor } from "@mui/icons-material";
import { Theme } from "@mui/material";

export const textFieldStyles = (theme: Theme) => ({
  borderRadius: "0.5rem",
  label: {
    color: theme.palette.text.primary,
    fontSize: "0.7rem",
    letterSpacing: "0.07rem",
  },
  "& .MuiAutocomplete-input": {
    padding: "0.5rem 1rem !important",
    fontSize: "0.9rem !important",
    transformOrigin: "center !important",
  },
  "& .MuiInput-input": {
    padding: "0.5rem 1rem",
    fontSize: "0.9rem",
    borderRadius: "0.5rem",
  },
  "& .MuiStandardInput-input": {
    padding: "0.5rem 1rem",
    fontSize: "0.9rem",
    borderRadius: "0.5rem",
  },
  "& .MuiInputBase-input": {
    background: theme.palette.transparent.softBlue,
    borderRadius: "0.5rem",
    padding: "0.5rem 1rem",
    "&[aria-invalid=true]": {
      outline: "1px solid red",
      "&::placeholder": {
        color: "red",
      },
    },
  },
  "& .MuiSelect-select": {
    "&.Mui-error": {
      outline: "1px solid red",
      color: "red !important",
    },
  },
  "& .MuiOutlinedInput-root, MuiOutlinedInput-input": {
    background: theme.palette.background.paper,
    borderRadius: "0.5rem",
    "& fieldset": {
      span: {
        padding: 0,
      },
      borderColor: theme.palette.primary.dark, //Input border inactive
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.dark, //Input border hover
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.dark, //Input border focused
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      "& fieldset": {
        borderColor: theme.palette.action.disabledBackground + " !important",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.action.disabledBackground + " !important", // Ensure no change on hover
      },
    },
  },
});
