import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface IGridProps {
  items: Array<{
    name: string;
    sortIndex?: any;
    link: string;
    id: string;
    className?: string;
    labelTag?: ReactNode;
    label?: {
      className?: string;
      text: string;
    };
    badge?: ReactNode;
  }>;
}

export const Grid: React.FC<IGridProps> = ({ items }) => {
  const [searchTerm, setSerchTerm] = React.useState("");
  return (
    <>
      <input
        type="text"
        className="form-control my-2"
        value={searchTerm}
        onChange={(e) => setSerchTerm(e.target.value)}
        placeholder="Search"
      />
      <div className="d-flex flex-wrap">
        {items
          .filter((i) =>
            i.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .sort((a, b) => a.sortIndex - b.sortIndex)
          .map((item) => (
            <Link className="p-0" key={item.id} to={item.link}>
              <div
                className={
                  "text-center p-3 bg-secondary my-2 mr-2 text-white " +
                  (item.className || "")
                }
                key={item.id}
              >
                {item.badge}
                <span className="d-block">{item.name}</span>
                {item.labelTag ||
                  (item.label && (
                    <span
                      className={
                        "text-dark rounded py-1 px-1 ml-1 my-2 d-block " +
                        item.label.className
                      }
                    >
                      {item.label.text}
                    </span>
                  ))}
              </div>
            </Link>
          ))}
      </div>
    </>
  );
};
