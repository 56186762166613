import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutline,
  DragHandleOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { MouseEvent, useState } from "react";
import { FrontLayerInformationItem } from "../../../../../../types/NendaTypes";
import TextInput from "../../../ui-components/input/TextInput";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { t } from "i18next";

const InformationListItem = ({
  onNameChange,
  setSelectedItemIndex,
  informationItem,
  selectedInformationItemIndex,
  index,
  onDelete,
  dragHandleListeners,
}: {
  onNameChange: (newName: string, index: number) => void;
  onDelete: (index: number) => void;
  setSelectedItemIndex: (index: number) => void;
  informationItem: FrontLayerInformationItem;
  selectedInformationItemIndex: number;
  index: number;
  dragHandleListeners: any;
}) => {
  const theme = useTheme();
  const isSelected = index === selectedInformationItemIndex;
  const [name, setName] = useState<string>(informationItem.name);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const toggleEdit = () => {
    setShowEdit(!showEdit);
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const onNameChangeCompleted = () => {
    onNameChange(name === "" ? informationItem.name : name, index);
    setShowEdit(false);
  };
  const handleCloseEdit = () => {
    setShowEdit(false);
  };

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteDialogClose = (isConfirmed: boolean) => {
    if (isConfirmed) {
      onDelete(index);
    }
    setIsDeleteDialogOpen(false);
  };

  const handleShowDeleteDialog = (e: MouseEvent) => {
    e.stopPropagation();
    setIsDeleteDialogOpen(true);
  };

  const handleSetSelectedItem = () => {
    setSelectedItemIndex(index);
  };

  const onEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onNameChange(name === "" ? informationItem.name : name, index);
      setShowEdit(false);
    }
  };

  return (
    <Box
      sx={{
        height: "6rem",
        width: "100%",
        display: "flex",
        borderRadius: "0.2rem",
        alignItems: "center",
        justifyContent: "space-between",
        py: "1.8rem",
        px: "1rem",
        background: isSelected ? theme.palette.transparent.purple : "white",
        "& .action-icons": {
          visibility: showEdit ? "visible" : "hidden",
        },
        "&:hover": {
          background: "#f8f8f8",
          "& .action-icons": {
            visibility: "visible",
          },
        },
        borderBottom: isSelected
          ? `3px solid ${theme.palette.primary.light}`
          : "3px solid #eee",
      }}
      onClick={handleSetSelectedItem}
    >
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Box
          sx={{
            pl: "0.5rem",
            pr: "1rem",
            cursor: "grab",
            "&:active": {
              cursor: "grabbing",
            },
          }}
          {...dragHandleListeners}
        >
          <DragHandleOutlined />
        </Box>
        <Box sx={{ width: "100%" }}>
          {showEdit ? (
            <TextInput
              variant="standard"
              value={name}
              maxLength={10}
              fullWidth
              inputProps={{
                sx: {
                  width: "100%",
                },
              }}
              onChange={handleNameChange}
              onKeyUp={onEnter}
            />
          ) : (
            <Typography variant="body2">{informationItem.name}</Typography>
          )}
        </Box>
      </Box>
      <Box
        className={"action-icons"}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          pl: "1rem",
        }}
      >
        {showEdit && (
          <Box onClick={onNameChangeCompleted}>
            <CheckOutlined sx={{ fill: theme.palette.primary.light }} />
          </Box>
        )}
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          {showEdit ? (
            <Box onClick={handleCloseEdit}>
              <CloseOutlined sx={{ fill: theme.palette.error.light }} />
            </Box>
          ) : (
            <Box onClick={toggleEdit}>
              <EditOutlined />
            </Box>
          )}
        </Box>
        {!showEdit && (
          <Box onClick={(e) => handleShowDeleteDialog(e)}>
            <DeleteOutline />
            <ConfirmationDialog
              title={t(
                "customerportal.pages.frontlayer.delete_confirmation_title"
              )}
              content={t(
                "customerportal.pages.frontlayer.delete_confirmation_description"
              )}
              isOpen={isDeleteDialogOpen}
              onClose={handleDeleteDialogClose}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InformationListItem;
