import { CloseOutlined } from "@mui/icons-material";
import { Box, Button, Container, Drawer, useTheme } from "@mui/material";
import { Formik } from "formik";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "yet-another-react-lightbox/styles.css";
import {
  IAsset,
  NendaProduct,
  Resource,
  Scope,
} from "../../../../../types/NendaTypes";
import ConfirmationDialog from "../../ConfirmationDialog";
import { store } from "../../store";
import {
  DeleteCompanyAsset,
  DeletePremiseAsset,
  UpdateCompanyAsset,
  UpdatePremiseAsset,
} from "../../store/reducers/assetReducer";
import {
  getPermissions,
  selectUserRole,
} from "../../store/reducers/authReducer";
import { checkPermission } from "../helpers/permissions";
import AssetDetailsInfo from "./AssetDetailsInfo";
import AssetMedia from "./AssetMedia";

export interface ImageAdditionalProps {
  isLoading: boolean;
  resolution: string;
  fileSize: string;
  duration: string;
  fileType: string;
  created: string;
  updated: string;
}

const AssetDetailsDrawer = ({
  asset,
  open,
  onClose,
}: {
  asset: IAsset | null | undefined;
  open: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userRole = useSelector(selectUserRole);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const isCompanyAsset = !asset?.premise;
  const [mediaProps, setMediaProps] = useState<ImageAdditionalProps>({
    isLoading: false,
    fileSize: "",
    resolution: "",
    duration: "",
    fileType: "",
    created: "",
    updated: "",
  });

  const handleDeleteAsset = () => {
    if (!asset) return;
    const options = {
      ...(isCompanyAsset
        ? { companyId: asset?.company }
        : { premiseId: asset?.premise }),
      assetId: asset?._id,
      product: asset?.product,
    };
    store.dispatch(
      isCompanyAsset
        ? DeleteCompanyAsset({
            companyId: options["companyId"],
            assetId: options.assetId,
            product: options.product || NendaProduct.SIGNAGE,
          })
        : DeletePremiseAsset({
            premiseId: options["premiseId"],
            assetId: options.assetId,
            product: options.product || NendaProduct.SIGNAGE,
          })
    );
    setConfirmDelete(false);

    setTimeout(() => navigate(0), 1000);
  };
  const permissions = useSelector(getPermissions);
  const canEditCompany = checkPermission(
    userRole,
    {
      resource: Resource.Company,
      scopes: [Scope.CanEdit],
    },
    permissions
  );

  const canEditAsset = checkPermission(
    userRole,
    {
      resource: Resource.Asset,
      scopes: [Scope.CanEdit],
    },
    permissions
  );

  const canDeleteAsset = checkPermission(
    userRole,
    {
      resource: Resource.Asset,
      scopes: [Scope.CanDelete],
    },
    permissions
  );

  const canEdit = () => {
    if (isCompanyAsset) {
      return canEditCompany && canEditAsset;
    } else {
      return canEditAsset;
    }
  };

  const canDelete = () => {
    if (isCompanyAsset) {
      return canEditCompany && canDeleteAsset;
    } else {
      return canDeleteAsset;
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      transitionDuration={200}
      sx={{
        transition: "all 0.2s ease",
        "& .MuiDrawer-paper": {
          backgroundColor: theme.palette.common.white,
          opacity: 1,
          maxWidth: "35rem",
        },
      }}
    >
      <Container sx={{ paddingY: "1rem" }}>
        <Formik
          initialValues={{
            fileName: "",
          }}
          onSubmit={(v) => {
            if (!asset) return;
            if (!v.fileName) return;

            store.dispatch(
              asset?.premise
                ? UpdatePremiseAsset({
                    premiseId: asset.premise,
                    assetId: asset._id,
                    product: asset.product,
                    asset: {
                      name: v.fileName,
                    },
                  })
                : UpdateCompanyAsset({
                    companyId: asset?.company,
                    assetId: asset?._id,
                    product: asset?.product,
                    asset: {
                      name: v.fileName,
                    },
                  })
            );

            navigate(0);
          }}
        >
          {({ values, handleSubmit }) => (
            <>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <CloseOutlined onClick={onClose} sx={{ cursor: "pointer" }} />
              </Box>
              {asset && (
                <>
                  <AssetMedia
                    asset={asset}
                    setMediaProperties={setMediaProps}
                  />
                  <AssetDetailsInfo
                    asset={asset}
                    fileData={mediaProps}
                    canEdit={canEdit()}
                  />
                </>
              )}
              <Box
                display={"flex"}
                sx={{
                  bottom: 0,
                  right: 0,
                  width: "100%",
                  paddingY: "1rem",
                  gap: "1rem",
                  justifyContent: "space-between",
                }}
              >
                {canDelete() && (
                  <Button
                    variant={"danger"}
                    onClick={() => setConfirmDelete(true)}
                  >
                    {t("common.delete")}
                  </Button>
                )}

                <ConfirmationDialog
                  isOpen={confirmDelete}
                  title={t(
                    "customerportal.pages.assets.dialogs.delete_asset_title"
                  )}
                  content={t(
                    "customerportal.pages.assets.dialogs.delete_asset_description"
                  )}
                  onClose={handleDeleteAsset}
                ></ConfirmationDialog>
                <Button
                  size="small"
                  variant={"contained"}
                  onClick={() => handleSubmit()}
                  disabled={values.fileName == ""}
                >
                  {t("common.save")}
                </Button>
              </Box>
            </>
          )}
        </Formik>
      </Container>
    </Drawer>
  );
};

export default AssetDetailsDrawer;
