import { Box, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { t } from "i18next";
import {
  NendaProduct,
  OrganizationType,
  Restriction,
} from "../../../../../types/NendaTypes";
import PermissionsGate from "../PermissionGate";
import ActionGridItem from "./ActionGridItem";
import React from "react";

type ActionGridProps = {
  type: OrganizationType;
  items: ActionGridItemProps[];
};

export type ActionGridItemProps = {
  title: string;
  description: string;
  color: string | undefined;
  onClick?: () => void;
  buttonText: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  restriction?: Restriction;
  mobileStyle?: any;
  desktopStyle?: any;
  isVisible: boolean;
};

const ActionGrid = ({ type, items }: ActionGridProps) => {
  const hasVisibleItems = items.some((i) => i.isVisible);
  return (
    <>
      {hasVisibleItems && (
        <Box>
          <Typography variant="subtitle1">
            {t("customerportal.pages.dashboard.quick_actions_title")}
          </Typography>
          <Box
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "1rem",
              m: "1rem 0 2rem 0",
              [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "1fr",
              },
            })}
          >
            {items.map((i: ActionGridItemProps) => (
              <React.Fragment key={i.title}>
                {i.restriction ? (
                  <PermissionsGate restriction={i.restriction}>
                    <ActionGridItem key={i.title} item={i} />
                  </PermissionsGate>
                ) : (
                  <ActionGridItem key={i.title} item={i} />
                )}
              </React.Fragment>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ActionGrid;
