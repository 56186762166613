import { ArrowBack, EmailOutlined } from "@mui/icons-material";
import { Box, Button, FormGroup, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { store } from "../CustomerPortal/store";
import { SendPasswordResetLink } from "../CustomerPortal/store/reducers/authReducer";
import TextInput from "../CustomerPortal/ui-components/input/TextInput";
import { LoadingButton } from "@mui/lab";

export const ForgotPasswordForm: React.FC = () => {
  const theme = useTheme();
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const validationSchema = yup.object({
    email: yup
      .string()
      .transform((value) => value.toLowerCase())
      .email(t("form.validation.invalid_email") || "")
      .required(t("form.validation.email_required") || ""),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ email }) => {
      store.dispatch(SendPasswordResetLink(email.toLowerCase()));
      setSubmitted(true);
    },
  });

  return (
    <>
      <Box
        textAlign={"center"}
        mb={"1rem"}
        color={theme.palette.text.secondary}
      >
        <Typography variant="h6" sx={{ py: "1rem" }}>
          {t("customerportal.pages.forgot_password.title")}
        </Typography>
        <Typography variant="subtitle1">
          {t("customerportal.pages.forgot_password.subtitle")}
        </Typography>
        <Typography variant="subtitle1">
          {t("customerportal.pages.forgot_password.description")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup sx={{ display: "flex", gap: 2 }}>
          <TextInput
            label={t("common.email")}
            placeholder="Your email"
            fullWidth
            variant={"outlined"}
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={submitted}
            fullWidth
            endIcon={<EmailOutlined />}
          >
            {submitted
              ? t("customerportal.pages.forgot_password.check_inbox")
              : t("customerportal.pages.forgot_password.send_link")}
          </LoadingButton>
          <Box>
            <Button
              variant="outlined"
              onClick={() => navigate("/")}
              fullWidth
              sx={{
                color: `${theme.palette.common.white} !important`,
                borderColor: theme.palette.common.white,
                fontSize: "1rem",
                "&:hover": {
                  borderColor: theme.palette.background.paper,
                },
              }}
              startIcon={<ArrowBack />}
            >
              {t("customerportal.pages.forgot_password.back_to_login")}
            </Button>
          </Box>
        </FormGroup>
      </form>
    </>
  );
};
