import {
  FormControl,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { t } from "i18next";

export type ConditionalFeature = {
  label: string;
  onChange: (value: boolean) => void;
  checked: boolean;
  visible: boolean;
};
const ConditionalFeaturesSelect = ({
  features,
}: {
  features: ConditionalFeature[];
}) => {
  const anyFeatureIsVisible = features.some((feature) => feature.visible);
  return (
    <>
      {anyFeatureIsVisible && (
        <Paper sx={{ p: 1 }}>
          <Typography variant="body2" sx={{ my: "0.5rem" }}>
            {t("customerportal.settings.premise_settings.product_features")}
          </Typography>
          <Stack sx={{ width: "fit-content", mt: "0.5rem" }} gap={1}>
            {features?.map((feature) => {
              const handleChange = (
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                feature.onChange(event.target.checked);
              };
              return (
                <FormControl
                  sx={{
                    minWidth: "30ch",
                    display: feature.visible ? "block" : "none",
                  }}
                >
                  <FormControlLabel
                    label={feature.label}
                    control={
                      <Switch
                        size="small"
                        name={feature.label}
                        onChange={handleChange}
                        checked={feature.checked}
                      />
                    }
                  />
                </FormControl>
              );
            })}
          </Stack>
        </Paper>
      )}
    </>
  );
};

export default ConditionalFeaturesSelect;
