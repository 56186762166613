import { TypographyVariant } from "@mui/material";
import {
  TypographyOptions,
  TypographyStyle,
  TypographyStyleOptions,
} from "@mui/material/styles/createTypography";
import themeOptions from "./theme";
import { paletteLight } from "./palette";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    sectionTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    sectionTitle?: React.CSSProperties;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    sectionTitle: true;
  }
}
const typography: TypographyOptions = {
  h1: {
    fontSize: "4.41rem",
    fontFamily: "Gilroy-Bold",
  },
  h2: {
    fontFamily: "Gilroy-Bold",
    fontSize: "2.8rem",
  },
  h3: {
    fontFamily: "Gilroy-Bold",
    fontSize: "1.7rem",
  },
  h4: {
    fontFamily: "Gilroy-Bold",
    fontSize: "1.6rem",
  },
  h5: {
    fontFamily: "Gilroy-Bold",
    fontSize: "1.3rem",
  },
  h6: {
    fontFamily: "Gilroy-Medium",
    fontSize: "1.1rem",
  },
  body1: {
    fontFamily: "Gilroy-Regular",
    fontSize: "0.9rem",
  },
  body2: {
    fontFamily: "Gilroy-Medium",
    fontSize: "0.9rem",
  },
  button: {
    textTransform: "capitalize",
    fontFamily: "Gilroy-Medium",
    fontSize: "0.8rem",
  },
  subtitle1: { fontSize: "0.9rem", fontFamily: "Gilroy-Medium" },
  subtitle2: { fontSize: "0.9rem", fontFamily: "Gilroy-Regular" },
  caption: { fontFamily: "Gilroy-Medium", fontSize: "0.9rem" },
  sectionTitle: {
    fontFamily: "Gilroy-Medium",
    fontSize: "0.8rem",
    textTransform: "uppercase",
    color: "gray",
  },
};

export default typography;
