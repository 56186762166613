import { Box, Grid, MenuItem, Typography } from "@mui/material";
import { CountryCode, TimezoneName, getCountry } from "countries-and-timezones";
import { t } from "i18next";
import { ChangeEvent, useMemo } from "react";
import { IAddress } from "../../../../../../types/NendaTypes";
import { sortedCountries } from "../../../../../utils/countries";
import InputSelect from "../../../ui-components/input/InputSelect";
import TextInput from "../../../ui-components/input/TextInput";

interface AddressProps {
  address: IAddress;
  onAddressChange: (address: IAddress) => void;
  onTimezoneChange: (timezone: TimezoneName) => void;
  onCountryChange: (country: CountryCode) => void;
}

const Address: React.FC<AddressProps> = ({
  address,
  onAddressChange,
  onTimezoneChange,
  onCountryChange,
}) => {
  const currentAddress = address;
  const timezone = currentAddress.timezone;

  const handleSetAddress = (e: ChangeEvent<HTMLInputElement>) => {
    onAddressChange({
      ...currentAddress,
      [e.target.id]: e.target.value,
    });
  };
  const handleSetTimezone = (e: ChangeEvent<HTMLInputElement>) => {
    onTimezoneChange(e.target.value as TimezoneName);
  };

  const countryData = useMemo(() => {
    return getCountry(currentAddress.country);
  }, [address.country]);

  const timezones = useMemo(() => {
    return countryData?.timezones || [];
  }, [address.country]);

  const handleSetCountry = (e: ChangeEvent<HTMLSelectElement>) => {
    onCountryChange(e.target.value as CountryCode);
  };

  return (
    <Box
      component="form"
      sx={{
        width: "100%",
        pb: 2,
        px: 1,
      }}
      noValidate
      autoComplete="off"
    >
      <Grid columns={2} container spacing={"0.5rem"}>
        <Grid item xs={2}>
          <TextInput
            fullWidth
            variant={"standard"}
            id="streetAddress"
            label={t("form.address.street_address")}
            value={currentAddress.streetAddress}
            autoComplete="off"
            onChange={handleSetAddress}
          />
        </Grid>
        <Grid item xs={1}>
          <TextInput
            fullWidth
            variant={"standard"}
            id="postalCode"
            label={t("form.address.postal_code")}
            type="number"
            value={currentAddress.postalCode}
            autoComplete="off"
            onChange={handleSetAddress}
          />
        </Grid>
        <Grid item xs={1}>
          <TextInput
            fullWidth
            variant={"standard"}
            id="city"
            label={t("form.address.city")}
            value={currentAddress.city}
            autoComplete="off"
            onChange={handleSetAddress}
          />
        </Grid>
        <Grid item xs={1}>
          <TextInput
            fullWidth
            variant={"standard"}
            id="region"
            label={t("form.address.region")}
            value={currentAddress.region}
            autoComplete="off"
            onChange={handleSetAddress}
          />
        </Grid>
        <Grid item xs={1}>
          <InputSelect
            fullWidth
            variant="standard"
            id="country"
            title={t("form.address.country")}
            value={currentAddress.country}
            autoComplete="off"
            onChange={handleSetCountry}
          >
            {Object.values(sortedCountries()).map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </InputSelect>
        </Grid>
        <Grid item xs={1}>
          {timezones ? (
            <InputSelect
              fullWidth
              id="timezone"
              title={t("form.address.timezone")}
              value={timezone}
              variant="standard"
              onChange={handleSetTimezone}
            >
              {timezones.map((timezoneName) => (
                <MenuItem key={timezoneName} value={timezoneName}>
                  {timezoneName}
                </MenuItem>
              ))}
            </InputSelect>
          ) : (
            <Typography>No timezone information</Typography>
          )}
        </Grid>
      </Grid>
      <Box></Box>
    </Box>
  );
};

export default Address;
