export function splitFilterArray<T>(
  array: T[],
  condition: (item: T) => boolean,
  elseCondition: (item: T) => boolean
): [T[], T[]] {
  const result = array.reduce(
    (acc, current) => {
      if (condition(current)) {
        acc[0].push(current);
      } else if (elseCondition(current)) {
        acc[1].push(current);
      }
      return acc;
    },
    [[], []] as [T[], T[]]
  );

  return result;
}

export function range(size: number, startAt = 0) {
  return [...Array(size).keys()].map((i) => i + startAt);
}
