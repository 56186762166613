import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { ColumnProps } from "./types/table";

const TableHeader = ({ cols, onSort, sort }) => {
  const theme = useTheme();

  const handleSort = (col) => {
    if (col.sortable) {
      onSort(col.value);
    }
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: theme.palette.background.paper,
          th: {
            "&:first-child": {
              borderRadius: "0.3rem 0 0 0.3rem",
            },
            "&:last-child": {
              borderRadius: "0 0.3rem 0.3rem 0",
            },
          },
        }}
      >
        {cols?.map((col: ColumnProps, i: number) => {
          const isSortable = onSort && col.sortable;
          return (
            <TableCell
              onClick={() => handleSort(col)}
              key={col.title + i}
              id={col.title + i}
              align={(col.align as any) || "left"}
              sx={{
                width: col.width,
                padding: "0.6rem 1rem",
                textTransform: "capitalize",
                color: `${theme.palette.text.disabled} !important`,
                fill: theme.palette.text.disabled,
                "&:hover": {
                  cursor: isSortable ? "pointer" : "default",
                  color: isSortable
                    ? theme.palette.text.primary
                    : theme.palette.text.disabled,
                  "&:hover > svg": {
                    fill: isSortable
                      ? theme.palette.text.primary
                      : theme.palette.text.disabled,
                  },
                },
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  fontWeight: "medium",
                  fontSize: "0.8rem",
                  letterSpacing: "0.05rem",
                }}
              >
                {col.title}
              </Typography>
              {col?.sortable && onSort && (
                <KeyboardArrowDown
                  sx={{
                    width: "1rem",
                    height: "1rem",
                    mx: "0.5rem",
                    transform:
                      sort &&
                      sort.direction === "asc" &&
                      sort.column === col.value
                        ? "rotate(180deg)"
                        : "",
                  }}
                />
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow sx={{ height: "0.5rem" }}></TableRow>
    </TableHead>
  );
};

export default TableHeader;
