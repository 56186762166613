export const getValueFromPathname = (pathname, match) => {
  // Split the pathname into segments
  const segments = pathname.split("/");

  // Find the index of the segment you're interested in
  const indexOfYourValue = segments.indexOf(match); // Replace 'yourSegment' with the actual segment you're looking for

  // If the segment is found, return its value
  if (indexOfYourValue !== -1 && indexOfYourValue + 1 < segments.length) {
    return segments[indexOfYourValue + 1];
  }

  // Return null or handle the case where the segment is not found
  return null;
};

export const updateValueInPathname = (
  currentUrl: string,
  segmentToReplace: string,
  newValue: string
): string => {
  // Split the pathname into segments
  const segments = currentUrl.split("/");

  // Find the index of the segment you want to replace
  const indexOfSegment = segments.indexOf(segmentToReplace);

  // If the segment is found, replace its value with the new value
  if (indexOfSegment !== -1 && indexOfSegment + 1 < segments.length) {
    segments[indexOfSegment + 1] = newValue;
  } else {
    // If the segment is not found, add it to the end of the path
    segments.push(segmentToReplace, newValue);
  }

  // Join the segments back into a URL
  const updatedUrl = segments.join("/");

  return updatedUrl;
};
