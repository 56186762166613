import typography from "./typography";
import { paletteLight } from "./palette";
import fontImports from "./fontImports";
import componentOverrides from "./overrides/componentOverrides";
import { Theme } from "@mui/material";
import { Palette } from "@mui/material/styles";
import { Typography } from "@mui/material/styles/createTypography";
import shadows, { Shadows } from "@mui/material/styles/shadows";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    "2lg": true; // adds the `mobile` breakpoint
  }
}

const customShadows = [...shadows] as Shadows;
customShadows[24] = "18px 30px 82px -2px rgba(0,0,0,0.12)";

const themeOptions: Partial<Theme> = {
  palette: {
    ...(paletteLight as Palette),
  },
  typography: {
    fontFamily: "Gilroy, Gilroy-Medium, Arial",
    ...typography,
  } as Typography,
  components: {
    ...fontImports,
    ...componentOverrides,
  },
  shape: {
    borderRadius: 1,
  },
  shadows: [...customShadows],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      "2lg": 1366,
      xl: 1536,
    },
  } as Theme["breakpoints"],
};

export default themeOptions;
