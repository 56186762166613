import { CloseOutlined } from "@mui/icons-material";
import { Alert, Box, Button, Typography } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { IFrontLayerInformationURLContent } from "../../../../../../types/NendaTypes";
import TextInput from "../../../ui-components/input/TextInput";
import { isUrl } from "../../../../../utils/url";

const URLInformationItem = ({
  item,
  setItem,
}: {
  item: IFrontLayerInformationURLContent;
  setItem: (value: string) => void;
}) => {
  const [locator, setLocator] = useState<string>("");

  const handleLocatorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocator(e.target.value);
  };

  const onConfirm = async () => {
    if (isUrl(locator)) {
      setItem(locator);
    } else {
      alert(t("customerportal.pages.frontlayer.url_not_valid"));
    }
  };

  const handleClearLocator = () => {
    setItem("");
    setLocator("");
  };

  const isNendaUrl = item.locator?.includes("nenda");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{ display: "inline-flex", flexDirection: "column", gap: "1rem" }}
      >
        <Typography variant="body2">
          {t("customerportal.pages.frontlayer.url")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          {!item.locator && (
            <TextInput
              variant="standard"
              value={locator}
              onChange={handleLocatorChange}
              placeholder="https://example.com"
            />
          )}
          {!item.locator && (
            <Button variant="contained" onClick={onConfirm} disabled={!locator}>
              {t("common.add")}
            </Button>
          )}
        </Box>
        {item.locator && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                p: "1rem",
                border: "1px solid #eee",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Typography variant="body1">{item.locator}</Typography>
              <Box onClick={handleClearLocator}>
                <CloseOutlined />
              </Box>
            </Box>
            {!isNendaUrl && (
              <Alert
                variant="outlined"
                severity="info"
                sx={{ fontSize: "0.7rem", whiteSpace: "pre-line" }}
              >
                {t("customerportal.pages.frontlayer.iframe_notification")}
              </Alert>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default URLInformationItem;
