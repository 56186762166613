import React from "react";
import { DropzoneArea } from "mui-file-dropzone";

const KILOBYTE = 1024;
const MEGABYTE = KILOBYTE * KILOBYTE;
const MAX_FILE_SIZE = 50 * MEGABYTE;

interface FileUploadProps {
  files: File[];
  onFileChange: (files: File[]) => void;
  allowedFileTypes?: string[];
  fileLimit?: number | undefined;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  files,
  onFileChange,
  allowedFileTypes,
  fileLimit = 1,
}) => {
  const handleFileChange = (files: File[]) => {
    onFileChange(files);
  };

  return (
    <DropzoneArea
      dropzoneParagraphClass="DropzoneArea__paragraph"
      dropzoneClass="DropzoneArea__dropzone"
      acceptedFiles={allowedFileTypes}
      classes={{ active: "DropzoneArea__active", text: "DropzoneArea__text" }}
      filesLimit={fileLimit}
      maxFileSize={MAX_FILE_SIZE}
      showAlerts={["error"]}
      showFileNames={true}
      dropzoneText={"Drag and drop or click to select a file"}
      onChange={handleFileChange}
      fileObjects={files}
      alertSnackbarProps={{
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      }}
    />
  );
};
