import {
  CloseOutlined,
  ContentCopy,
  FormatColorResetOutlined,
} from "@mui/icons-material";
import { Box, Popover, Tooltip, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import ColorPicker from "react-best-gradient-color-picker";
interface ColorPickerProps {
  color?: string;
  onChange: (value: string) => void;
  canReset?: boolean;
}

const GradiantColorPicker: React.FunctionComponent<ColorPickerProps> = ({
  color,
  onChange,
  canReset = false,
}: ColorPickerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [notificationOpen, setNotificationOpen] = useState(false);
  const notificationRef = useRef(null);

  const handleCopyButtonClick = (event) => {
    setNotificationOpen(true);
    if (!color) return;
    navigator.clipboard.writeText(color);
    // Close the notification after 3 seconds
    setTimeout(() => {
      setNotificationOpen(false);
    }, 3000);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleClearButtonClick = () => {
    onChange("");
  };

  return (
    <Box>
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Box
          sx={(theme) => ({
            width: 50,
            height: 50,
            borderRadius: "0.5rem",
            border: `0.5px solid ${theme.palette.grey[400]}`,
            ml: 1,
            cursor: "pointer",
            position: "relative",
            overflow: "hidden",
          })}
          onClick={handleClick}
        >
          <Box
            sx={{
              position: "absolute",
              inset: 0,
              backgroundColor: "#fff",
              backgroundImage:
                "linear-gradient(45deg, #dcdcdc 25%, transparent 25%), linear-gradient(-45deg, #dcdcdc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #dcdcdc 75%), linear-gradient(-45deg, transparent 75%, #dcdcdc 75%)",
              backgroundSize: "10px 10px",
              backgroundposition: "0 0, 0 10px, 10px -10px, -10px 0px",
            }}
          ></Box>
          {color ? (
            <Box
              sx={{
                position: "absolute",
                inset: 0,
                background: color,
              }}
            ></Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                inset: 0,
                background: "transparent",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormatColorResetOutlined />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {color && (
            <>
              <Tooltip title="Copy">
                <>
                  <ContentCopy
                    sx={{ width: "1rem", cursor: "pointer" }}
                    onClick={handleCopyButtonClick}
                    ref={notificationRef}
                  />
                </>
              </Tooltip>
              {canReset && (
                <Tooltip title="Clear">
                  <CloseOutlined
                    sx={{ width: "1rem", cursor: "pointer" }}
                    onClick={handleClearButtonClick}
                  />
                </Tooltip>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ mt: "1rem" }}>
        {color && (
          <Typography
            variant={"body1"}
            sx={{
              fontSize: "0.6rem",
              display: "flex",
              gap: "1rem",
              textTransform: "uppercase",
              alignItems: "center",
            }}
          >
            {color && color}
            <Popover
              open={notificationOpen}
              onClose={handleNotificationClose}
              disableScrollLock={true}
              anchorEl={notificationRef.current}
              sx={(theme) => ({
                "& .MuiPopover-paper": {
                  color: theme.palette.common.white + " !important",
                },
              })}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              Copied to clipboard
            </Popover>
          </Typography>
        )}
      </Box>

      <Popover
        style={{ width: "auto" }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box style={{ padding: "10px" }}>
          <ColorPicker value={color} onChange={onChange} />
        </Box>
      </Popover>
    </Box>
  );
};

export default GradiantColorPicker;
