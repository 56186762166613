import React, { ReactNode } from "react";
import { IRoomType, RoomTypeCategory } from "../../../types/NendaTypes";
import { capitalizeString } from "../../utils/string";

interface IRoomTypeCategoryBadgeProps {
  roomType?: IRoomType;
  children?: ReactNode;
  border?: boolean;
}

export const RoomTypeCategoryBadge: React.FC<IRoomTypeCategoryBadgeProps> = ({
  roomType,
  children,
  border = false,
}) => {
  // const roomTypeCategories = useSelector(getRoomTypeCategories);
  // if (!roomType || !roomTypeCategories) {
  if (!roomType) {
    return <></>;
  }
  const { category } = roomType;
  const className = "badge mx-1 my-auto";
  children = children || capitalizeString(category).replace("_", " ");
  let badgeClassName = "";
  switch (category) {
    case RoomTypeCategory.ROOM:
      badgeClassName = "badge-success";
      break;
    case RoomTypeCategory.COMMON_AREA:
      badgeClassName = "badge-info";
      break;
    case RoomTypeCategory.PUBLIC:
      badgeClassName = "badge-secondary";
      break;
    default:
      return <></>;
  }
  return (
    <>
      <span
        className={`${className} ${badgeClassName}`}
        style={{ margin: "auto" }}
      >
        {children}
      </span>
    </>
  );
};
