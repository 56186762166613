import { Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ConfirmationDialog from "../../ConfirmationDialog";
import { store } from "../../store";
import { getCompanies } from "../../store/reducers/companyReducer";
import { deleteUser, selectUsers } from "../../store/reducers/userReducer";
import OptionsButton from "../../ui-components/buttons/OptionsButton";

const UserList = ({ onEditUser }: { onEditUser: (value: string) => void }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState<string>();
  const companies = useSelector(getCompanies);
  const handleConfirmCloseDialog = (isConfirmed: boolean) => {
    if (isConfirmed && userToDelete) {
      store.dispatch(deleteUser(userToDelete));
    }
    setShowDeleteDialog(false);
  };

  const handleDeleteUser = (userId: string) => {
    setUserToDelete(userId);
    setShowDeleteDialog(true);
  };

  const users = useSelector(selectUsers);
  const columns: GridColDef[] = [
    {
      field: "username",
      disableColumnMenu: true,
      headerName: "Username",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body1" sx={{ pl: "1rem" }}>
          {params.value}
        </Typography>
      ),
      renderHeader: (params) => (
        <Typography variant="body2" sx={{ pl: "1rem" }}>
          {"Email"}
        </Typography>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      disableColumnMenu: true,
      width: 150,
      renderCell: (params) => (
        <Typography variant="body1">{params.value}</Typography>
      ),
    },
    {
      field: "company",
      headerName: "Company",
      disableColumnMenu: true,
      width: 250,
      renderCell: (params) => {
        return (
          <Link to={`/dashboard/company/${params.value._id}`}>
            {params.value.name}
          </Link>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 120,
      valueFormatter: (params) => {
        return new Date(params as string).toLocaleDateString();
      },
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 120,
      valueFormatter: (params) => {
        return new Date(params as string).toLocaleDateString();
      },
    },
    {
      field: "actions",
      headerName: "",
      align: "right",
      disableColumnMenu: true,
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <OptionsButton
            options={params.value.map((action) => ({
              label: action.label,
              value: action.value,
              onClick: () => action.onClick(),
            }))}
          />
        );
      },
    },
  ];
  const rows: GridRowsProp = users.data?.map((user) => ({
    id: user._id,
    username: user.email,
    role: user.type,
    company: companies.find((c) => c._id === user.company) || "",
    updated_at: user.updatedAt,
    created_at: user.createdAt,
    actions: [
      {
        label: "Edit",
        value: "edit",
        onClick: () => onEditUser(user._id),
      },
      {
        label: "Delete",
        value: "delete",
        onClick: () => handleDeleteUser(user._id),
      },
    ],
  }));

  return (
    <>
      <DataGrid
        autoHeight
        columnBufferPx={100}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: false,
            },
          },
          sorting: {
            sortModel: [{ field: "updated_at", sort: "desc" }],
          },
        }}
        ignoreDiacritics={true}
        sx={{
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        disableColumnSelector
        disableRowSelectionOnClick
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { showQuickFilter: true } }}
        columns={columns}
        rows={rows}
      />
      <ConfirmationDialog
        title={t("customerportal.pages.users.confirmation.delete_user")}
        content={t(
          "customerportal.pages.users.confirmation.cannot_be_restored"
        )}
        isOpen={showDeleteDialog}
        onClose={handleConfirmCloseDialog}
      />
    </>
  );
};

export default UserList;
