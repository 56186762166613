import React, { useEffect } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

type ChannelMapProps = {
  channels: Record<number, string>;
  setParentChannelList: Function;
};

export const ChannelMapList = ({
  channels,
  setParentChannelList,
}: ChannelMapProps) => {
  const [channelList, setChannelList] = React.useState<
    Record<number, string | null>
  >({});

  useEffect(() => {
    if (!channels) channels = {};
    setChannelList(channels);
  }, [channels]);

  const add = () => {
    const [lastChannelInList] = channelList
      ? Object.keys(channelList).slice(-1)
      : "-1";
    channelList[
      !isNaN(parseInt(lastChannelInList))
        ? parseInt(lastChannelInList) + 1
        : "0"
    ] = "";
    setChannelList(channelList);
    setParentChannelList(channelList);
  };

  const remove = (e, index) => {
    delete channelList[index];
    setChannelList(channelList);
    setParentChannelList(channelList);
  };

  const handleAssetInputChange = (e, index) => {
    const { value } = e.target;
    const list = channelList;
    list[index] = value;
    setChannelList(list);
    setParentChannelList(list);
  };

  const handleIndexInputChange = (e, index) => {
    const { value } = e.target;
    if (channelList[value] === undefined) {
      const keepValue = channelList[index];
      const list = channelList;
      delete list[index];
      list[value] = keepValue;
      setChannelList(list);
      setParentChannelList(list);
    }
  };

  const setAsEmpty = (e, index) => {
    const { checked } = e.target;
    const list = channelList;
    list[index] = checked ? null : "";
    setChannelList(list);
    setParentChannelList(list);
  };

  const channelPositionStyle = {
    width: "50px",
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-1">Position</div>
        <div className="col-md-4">Asset ID</div>
      </div>
      {channelList &&
        Object.keys(channelList).map((index) => {
          return (
            <div className="row mb-1" key={"channel-" + index}>
              <div className="col-md-1">
                <input
                  type="text"
                  style={channelPositionStyle}
                  //onChange={(e) => handleIndexInputChange(e, index)}
                  onBlur={(e) => handleIndexInputChange(e, index)}
                  defaultValue={index}
                />
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  disabled={channelList[index] == null}
                  value={channelList[index] ? channelList[index] : ""}
                  onChange={(e) => handleAssetInputChange(e, index)}
                />
                <input
                  type="checkbox"
                  name="empty"
                  className="ml-2 mr-1"
                  checked={channelList[index] == null}
                  onChange={(e) => setAsEmpty(e, index)}
                />
                Empty
                <button
                  onClick={(e) => remove(e, index)}
                  className="btn btn-sm btn-secondary ml-2"
                >
                  <FaMinus />
                </button>
              </div>
            </div>
          );
        })}
      <button onClick={add} className="btn btn-secondary">
        <FaPlus /> Add
      </button>
    </div>
  );
};
