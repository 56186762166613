import { Epic, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { Role } from "../../types/NendaTypes";
import { authService } from "../http/auth.service";
import {
  AUTH_ACTIONS,
  AppState,
  AuthActions,
  LoginAction,
  SetLoginAction,
} from "../types/redux";
// import { appInsights } from "../utils/telemetry";
import { extractSessionTokenData } from "../utils/base64";
import { handleError } from "./error.redux";

export function Login(username, password): LoginAction {
  return { type: AUTH_ACTIONS.LOGIN, username, password };
}

export function SetLogin(session): SetLoginAction {
  return { type: AUTH_ACTIONS.SET_LOGIN, session };
}

export function Logout(): SetLoginAction {
  return SetLogin({
    sessionToken: null,
  });
}

//selectors
export const getUserRole = (state: AppState): Role | undefined => {
  const session = state.auth.session?.sessionToken
    ? extractSessionTokenData(state.auth.session?.sessionToken)
    : undefined;
  const role = session?.role;
  return role;
};

export const getUserSession = (state: AppState) => {
  const session = state.auth.session?.sessionToken
    ? extractSessionTokenData(state.auth.session?.sessionToken)
    : undefined;
  return session;
};

export const getSessionUsername = (state: AppState) => {
  const session = state.auth.session?.sessionToken
    ? extractSessionTokenData(state.auth.session?.sessionToken)
    : undefined;
  return session?.username;
};

export default function authReducer(
  state: AppState["auth"] = { session: undefined },
  action: AuthActions
): AppState["auth"] {
  switch (action.type) {
    case AUTH_ACTIONS.SET_LOGIN:
      localStorage.setItem("sessionToken", action.session.sessionToken);
      return {
        ...state,
        session: action.session,
      };
    default:
      return state;
  }
}

// Epics
const login$: Epic = (action$) => {
  return action$.pipe(
    ofType(AUTH_ACTIONS.LOGIN),
    switchMap((a) => {
      return authService.login(a.username, a.password).pipe(
        map((session) => {
          return SetLogin(session);
        }),
        catchError(handleError)
      );
    })
  );
};

export const authEpics = [login$];
