import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../components/ProtectedRoute/ProtectedRoute";
import { Login, getUserSession } from "../../redux/auth.redux";
import { store } from "../../redux/store";
import { PageWrapper } from "../PageWrapper";
import "./Login.scss";

const logoUrl =
  "https://www.nenda.com/wp-content/uploads/2019/05/nenda-loggo-white-1.svg";

interface ILoginPageProps {}

export const LoginPage: React.FC<ILoginPageProps> = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const session = useSelector(getUserSession);
  const userIsLoggedIn = isLoggedIn(session);
  const login = () => store.dispatch(Login(username, password));

  useEffect(() => {
    userIsLoggedIn && navigate("/");
  }, [userIsLoggedIn]);

  return (
    <PageWrapper className="bg-secondary ">
      <div className="d-flex justify-content-center">
        <div className="login-wrapper d-flex flex-column justify-content-center align-items-center">
          <img id="login-logo" src={logoUrl} />
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">Username</span>
            </div>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">Password</span>
            </div>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="form-control"
            />
          </div>
          <button className="btn btn-primary col-md-12" onClick={login}>
            Login
          </button>
        </div>
      </div>
    </PageWrapper>
  );
};
