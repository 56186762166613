import { Select, MenuItem, Button } from "@mui/material";
import React from "react";
import { FaLink, FaSave } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { findRoomTypeOnRules } from "@shared/productOfferingsUtils";
import {
  CacheLevel,
  IRoom,
  OrganizationUnitType,
  RoomStatus,
} from "../../../types/NendaTypes";
import { roomService } from "../../http/room.service";
import { SetError } from "../../redux/error.redux";
import { SetNotification } from "../../redux/notification.redux";
import {
  GetRoomsForHotel,
  UpdateRoom,
} from "../../redux/organizationUnit.redux";
import { GetProductOfferings } from "../../redux/productOfferings.redux";
import { store } from "../../redux/store";
import { AppState } from "../../types/redux";
import { ClearCacheButton } from "../Buttons/ClearCacheButton/ClearCacheButton";
import { DeleteOUWidget } from "../DeleteOuWidget/DeleteOuWidget";
import { PageHeader } from "../PageHeader/PageHeader";
import { RoomTypeCategoryBadge } from "../RoomTypeCategory/RoomTypeCategoryBadge";
import { StringArrayEdit } from "../StringArrayEdit/StringArrayEdit";
import { SectionWrapper } from "../UI/SectionWrapper/SectionWrapper";
import "./HotelChain.scss";

export const Room: React.FC = () => {
  const { id, hotelId, hotelChainId } = useParams();
  React.useEffect(() => {
    store.dispatch(GetRoomsForHotel(hotelId));
  }, []);
  const allCarousels = useSelector(
    (state: AppState) => state.carousels?.carousels || []
  );
  const allPages = useSelector((state: AppState) => state.subPages?.subPages);
  const hotelChain = useSelector((state: AppState) =>
    state.organizationUnits?.organizationUnits.find(
      ({ _id }) => _id === hotelChainId
    )
  );
  const productOfferings = useSelector(
    (state: AppState) => state.productOfferings?.productOfferings
  );

  const room = useSelector((state: AppState) => {
    const hotel = state.organizationUnits?.hotelRooms.find(
      (h) => h.hotelId === hotelId
    );
    if (hotel) {
      return hotel.rooms.find((r) => r._id === id);
    }
    return undefined;
  });

  const [state, setState] = React.useState<IRoom | undefined>(undefined);
  const [purchases, setPurchases] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (room) {
      setState(room);

      if (hotelChainId) {
        store.dispatch(GetProductOfferings(hotelChainId));
      }
      loadPurchases(room);
    }
  }, [room]);

  const loadPurchases = (room: IRoom) => {
    if (room?._id) {
      roomService
        .getPurchases(room._id)
        .toPromise()
        .then((_purchases) => setPurchases(_purchases))
        .catch((e) => console.error(e));
    }
  };

  const syncOfferings = (room: IRoom) => {
    if (room?._id) {
      roomService
        .sync(room._id)
        .toPromise()
        .then((result) =>
          store.dispatch(
            SetNotification({
              bgClass: "bg-success",
              message: "Sync triggered",
            })
          )
        )
        .catch((e) => {
          console.error(e);
          store.dispatch(SetError(`Sync failed: ${JSON.stringify(e)}`));
        });
    }
  };

  function update() {
    if (!room) return;
    if (state) {
      const {
        name,
        description,
        hotel,
        roomType,
        status,
        deviceId,
        autoplayChannelId,
        autoplayAsset,
      } = state;
      const payload = {
        name,
        description,
        hotel,
        roomType,
        status,
        deviceId,
        autoplayChannelId,
        autoplayAsset,
      };
      if (payload.roomType === room?.roomType) {
        delete payload.roomType;
      }
      if (payload.status === room?.status) {
        // @ts-ignore
        delete payload.status;
      }

      store.dispatch(UpdateRoom(room._id, payload));
    }
  }

  if (!hotelChain || !room || !state) return null;
  const isActiveRoom = room.status === RoomStatus.ACTIVE;
  const roomType = hotelChain.getRoomType(state.roomType);
  const relatedProductOfferings = productOfferings?.filter((po) =>
    findRoomTypeOnRules(po, state.roomType)
  );

  return (
    <>
      <PageHeader>
        <h2>{room.name}</h2>
        <DeleteOUWidget type={OrganizationUnitType.ROOM} ouId={room._id}>
          Delete Room
        </DeleteOUWidget>
        <ClearCacheButton cacheLevel={CacheLevel.Room} id={room._id} />
      </PageHeader>
      <SectionWrapper>
        <h4>Name</h4>
        <input
          onChange={(e) => setState({ ...state, name: e.target.value })}
          name="name"
          value={state.name}
          type="text"
          className="form-control"
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>Description</h4>
        <input
          onChange={(e) => setState({ ...state, description: e.target.value })}
          name="description"
          value={state.description}
          type="text"
          className="form-control"
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>Device ID</h4>
        <input
          onChange={(e) => setState({ ...state, deviceId: e.target.value })}
          name="deviceId"
          value={state.deviceId}
          type="text"
          className="form-control"
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>Status</h4>
        <p>
          Current:
          {isActiveRoom ? (
            <span
              className="badge badge-success ml-2"
              style={{ margin: "auto" }}
            >
              Active
            </span>
          ) : (
            <span className="badge badge-dark ml-2" style={{ margin: "auto" }}>
              Inactive
            </span>
          )}
        </p>
        <div className="d-flex">
          <Select
            onChange={(e) =>
              setState({ ...state, status: e.target.value as RoomStatus })
            }
            defaultValue={state.status || RoomStatus.INACTIVE}
            className="form-control col-md-6"
          >
            <MenuItem value={RoomStatus.INACTIVE}>inactive</MenuItem>
            <MenuItem value={RoomStatus.ACTIVE}>active</MenuItem>
          </Select>
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <h4>Auto-player Channel ID</h4>
        <div className="d-flex">
          <Select
            onChange={(e) =>
              setState({
                ...state,
                autoplayChannelId: Number(e.target.value),
              })
            }
            defaultValue={
              state.autoplayChannelId !== undefined &&
              state.autoplayChannelId !== null
                ? state.autoplayChannelId
                : -1
            }
            className="form-control col-md-6"
          >
            {Array.apply(0, Array(12)).map(function (x, i) {
              return (
                <MenuItem value={i - 1}>{i == 0 ? "None" : i - 1}</MenuItem>
              );
            })}
          </Select>
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <h4>Autoplay Asset ID</h4>
        <div className="custom-control custom-switch">
          <input
            name="autoplayAssetEnabled"
            id="autoplayAssetEnabled"
            type="checkbox"
            className="custom-control-input"
            checked={state.autoplayAsset?.enabled ?? false}
            onChange={({ target: { checked } }) =>
              setState({
                ...state,
                autoplayAsset: {
                  id: state.autoplayAsset?.id || "",
                  type: state.autoplayAsset?.type || "TV_CHANNEL",
                  enabled: checked,
                },
              })
            }
          />
          <label
            htmlFor="autoplayAssetEnabled"
            className="custom-control-label showNonPurchasedContent-label"
          >
            Enable
          </label>
        </div>
        <div className="d-flex">
          <input
            type="text"
            onChange={(e) =>
              setState({
                ...state,
                autoplayAsset: {
                  enabled: state.autoplayAsset?.enabled ?? false,
                  id: e.target.value,
                  type: "TV_CHANNEL",
                },
              })
            }
            disabled={!state.autoplayAsset?.enabled}
            value={state.autoplayAsset?.id ?? ""}
            className="form-control col-md-6"
          />
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <h4>Room type</h4>
        <div className="d-flex">
          <Select
            onChange={(e) => setState({ ...state, roomType: e.target.value })}
            defaultValue={state.roomType}
            className="form-control col-md-6"
          >
            <MenuItem key={"empty"} value={undefined}></MenuItem>
            {hotelChain.roomTypes.map((roomType) => (
              <MenuItem key={roomType._id} value={roomType._id}>
                {roomType.name}({roomType.numberOfRooms})
              </MenuItem>
            ))}
          </Select>
          {roomType && (
            <>
              <Link
                to={`/organizationunits/hotelchain/${hotelChainId}/roomType/${roomType._id}`}
              >
                <button className="btn btn-secondary">
                  <FaLink />
                </button>
              </Link>
              <RoomTypeCategoryBadge
                roomType={hotelChain?.getRoomType(roomType._id)}
              />
            </>
          )}
        </div>
        <p>
          Product offerings:{" "}
          {relatedProductOfferings.length
            ? relatedProductOfferings
                .map((po) => po.localizedMetadata?.[0].name)
                .join(", ")
            : "<no product offering>"}
        </p>
      </SectionWrapper>
      <SectionWrapper>
        <div className="d-flex mb-2">
          <h4>Active purchases</h4>
          <button
            className="btn btn-secondary ml-4"
            onClick={() => loadPurchases(room)}
          >
            Refresh
          </button>
          <button
            className="btn btn-secondary ml-4"
            onClick={() => syncOfferings(room)}
          >
            Sync
          </button>
        </div>
        <table className="w-100">
          <thead>
            <tr>
              <th>Name</th>
              <th>From</th>
              <th>Until</th>
            </tr>
          </thead>
          <tbody>
            {!purchases?.length && (
              <tr>
                <td colSpan={3}>{"<no purchases>"}</td>
              </tr>
            )}
            {purchases?.map((purchase) => (
              <tr key={purchase.purchaseId}>
                <td>
                  {purchase.metaData?.[0].name || purchase.productOfferingId}
                </td>
                <td>{new Date(purchase.from).toLocaleString()}</td>
                <td>{new Date(purchase.until).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </SectionWrapper>
      {roomType && (
        <>
          <SectionWrapper>
            <h5>Carousels on selected room type</h5>
            {roomType && roomType.carousels.length ? (
              <StringArrayEdit
                showPlus={false}
                showDelete={false}
                values={roomType.carousels.map((c) => {
                  const carousel = allCarousels.find(
                    (carousel) => carousel._id === c
                  );
                  return {
                    name: carousel ? carousel.name : c,
                    value: c,
                    link: `/components/carousels/carousel/${c}`,
                  };
                })}
                onChange={() => null}
              />
            ) : (
              <span>No carousels assigned</span>
            )}
          </SectionWrapper>
          <SectionWrapper>
            <h5>Pages on selected room type</h5>
            {roomType && roomType.pages.length ? (
              <StringArrayEdit
                showPlus={false}
                showDelete={false}
                values={roomType.pages.map((p) => {
                  const page = allPages.find((page) => page._id === p);
                  return {
                    name: page ? page.name : p,
                    value: p,
                    link: `/pages/${p}`,
                  };
                })}
                onChange={() => null}
              />
            ) : (
              <span>No pages assigned</span>
            )}
          </SectionWrapper>
        </>
      )}
      <Button
        disabled={state === room}
        onClick={update}
        className="mr-2 my-2"
        variant="contained"
        color="secondary"
        endIcon={<FaSave />}
      >
        Save
      </Button>
    </>
  );
};
