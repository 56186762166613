import { t } from "i18next";
import { ISignagePromotion } from "../../../../../../types/NendaTypes";
import { PromotionTableRowData } from "../types/promotion_table";

export const GetPromotionTableHeaderProps = () => {
  return PromotionTableHeaderProps.map((h) => {
    return {
      ...h,
      title: t(h.title),
    };
  });
};

const PromotionTableHeaderProps = [
  {
    title: "customerportal.pages.promotions.list.header.name",
    value: "name",
    width: "25%",
  },
  {
    title: "customerportal.pages.promotions.list.header.asset",
    value: "assetName",
    width: "20%",
  },
  {
    title: "customerportal.pages.promotions.list.header.start",
    value: "start",
    width: "20%",
  },
  {
    title: "customerportal.pages.promotions.list.header.end",
    value: "end",
    width: "20%",
  },
  {
    title: "customerportal.pages.promotions.list.header.number_of_screens",
    value: "numberOfScreens",
    width: "5%",
  },
  {
    title: "customerportal.pages.promotions.list.header.end_icon",
    value: "endIcon",
    align: "right",
    width: "10%",
  },
];

export const promotionTableRow: (
  promotion: ISignagePromotion,
  endIcon: React.ReactNode
) => PromotionTableRowData = (promotion: ISignagePromotion, endIcon) => {
  if (!promotion) return null;
  return {
    id: promotion._id,
    name: promotion.name,
    endIcon: endIcon,
  };
};
