import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import ColorPicker from "../../../ColorPicker";
import {
  IFrontLayerItemDesignConfiguration,
  IFrontlayerConfiguration,
} from "../../../../../../../types/NendaTypes";

const BackButtonConfig = ({
  state,
  handleChange,
  onBorderSelect,
}: {
  state: IFrontlayerConfiguration["backButton"];
  handleChange: (mutatedState: IFrontLayerItemDesignConfiguration) => void;
  onBorderSelect: (
    event: React.ChangeEvent<HTMLInputElement>,
    context: "home" | "subpages" | "backButton"
  ) => void;
}) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t("customerportal.settings.premise_settings.frontlayer.back_button")}
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          display: "grid",
          width: "100%",
          gridTemplateColumns: "1fr 1fr",
          [theme.breakpoints.down("md")]: {
            dipslay: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
          },
          gap: "2rem",
        })}
      >
        <Box>
          <Typography variant="body2">Color</Typography>
          <ColorPicker
            color={state.defaultItemColor || ""}
            onChange={(value) =>
              handleChange({
                defaultItemColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Selected color</Typography>
          <ColorPicker
            color={state.defaultSelectedItemColor || ""}
            onChange={(value) =>
              handleChange({
                defaultSelectedItemColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Border color</Typography>
          <ColorPicker
            color={state.defaultItemBorderColor || ""}
            onChange={(value) =>
              handleChange({
                defaultItemBorderColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Selected border color</Typography>
          <ColorPicker
            color={state.defaultSelectedItemBorderColor || ""}
            onChange={(value) =>
              handleChange({
                defaultSelectedItemBorderColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Font color</Typography>
          <ColorPicker
            color={state.fontColor}
            onChange={(value) =>
              handleChange({
                fontColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Selected font color</Typography>
          <ColorPicker
            color={state.selectedFontColor || ""}
            onChange={(value) =>
              handleChange({
                selectedFontColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">
            Change border color on selection
          </Typography>
          <RadioGroup
            row
            name="borderSelectionEnabled"
            value={state.isBorderSelectionEnabled ? "enabled" : "disabled"}
            onChange={(event) => onBorderSelect(event, "backButton")}
          >
            <FormControlLabel
              value={"enabled"}
              control={
                <Radio
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    "&.Mui-checked": {
                      color: theme.palette.primary.main,
                    },
                  })}
                />
              }
              label="Enabled"
            />
            <FormControlLabel
              value={"disabled"}
              control={
                <Radio
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    "&.Mui-checked": {
                      color: theme.palette.primary.main,
                    },
                  })}
                />
              }
              label="Disabled"
            />
          </RadioGroup>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default BackButtonConfig;
