import React, { useState } from "react";
import {
  Card,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import FormatColorTextOutlinedIcon from "@mui/icons-material/FormatColorTextOutlined";
import FormatColorFillOutlinedIcon from "@mui/icons-material/FormatColorFillOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ClearIcon from "@mui/icons-material/Clear";
import {
  FrontLayerBackgroundType,
  IFrontLayerItem,
} from "../../../../../../../types/NendaTypes";
import { ColorControlType, ColorPopup, ColorTypeAttribute } from "./ColorPopup";
import { Localization } from "./Localization";

type ControlType = "text" | ColorControlType;

interface ViewItemProps {
  homeScreenItem: IFrontLayerItem;
  defaultBackgroundColor: string;
  defaultSelectedBackgroundColor: string;
  defaultFontColor: string;
  defaultSelectedFontColor: string;
  font: string;
  defaultBorderColor: string;
  selectedBorderColor: string;
  onChange: (updatedItem: IFrontLayerItem) => void;
  onUpload: (file: File) => void;
  storeItem: IFrontLayerItem | undefined;
}

export const ViewItem: React.FC<ViewItemProps> = ({
  homeScreenItem,
  defaultBackgroundColor,
  defaultFontColor,
  defaultBorderColor,
  selectedBorderColor,
  defaultSelectedBackgroundColor,
  font,
  onChange,
  onUpload,
  storeItem,
}) => {
  const [controlType, setControlType] = useState<ControlType>("none");

  const getCurrentColor = () => {
    switch (controlType) {
      case "background":
        return homeScreenItem.backgroundColor;
      case "font":
        return homeScreenItem.fontColor;
      case "border":
        return homeScreenItem.borderColor;
      default:
        return "";
    }
  };

  const getCurrentSelectedColor = () => {
    switch (controlType) {
      case "background":
        return homeScreenItem.selectedBackgroundColor;
      case "font":
        return homeScreenItem.selectedFontColor;
      case "border":
        return homeScreenItem.selectedBorderColor;
      default:
        return "";
    }
  };
  const handleFontClick = () => {
    setControlType("font");
  };

  const handleBorderClick = () => {
    setControlType("border");
  };

  const handleBackgroundClick = () => {
    setControlType("background");
  };

  const handleTextClick = () => {
    setControlType("text");
  };

  const handleClearClick = () => {
    setControlType("none");
    onChange({
      ...homeScreenItem,
      backgroundColor: undefined,
      selectedBackgroundColor: undefined,
      fontColor: undefined,
      selectedFontColor: undefined,
      borderColor: undefined,
      selectedBorderColor: undefined,
      backgroundImageUrl: undefined,
      backgroundTypes: [FrontLayerBackgroundType.COLOR],
    });
  };

  const handleActionClose = () => {
    setControlType("none");
  };

  const getPropertyByAttribute = (
    type: ColorControlType,
    attribute: ColorTypeAttribute
  ): string => {
    switch (type) {
      case "background":
        return attribute === "default"
          ? "backgroundColor"
          : "selectedBackgroundColor";
      case "font":
        return attribute === "default" ? "fontColor" : "selectedFontColor";
      case "border":
        return attribute === "default" ? "borderColor" : "selectedBorderColor";
      default:
        return "";
    }
  };

  const handleItemColorChange = (
    type: ColorControlType,
    attribute: ColorTypeAttribute,
    color: string
  ) => {
    onChange({
      ...homeScreenItem,
      [getPropertyByAttribute(type, attribute)]: color,
    });
  };

  const handleFileUpload = (file: File) => {
    onUpload(file);
  };

  const handleBackgroundTypeChange = (
    type: FrontLayerBackgroundType,
    isEnabled: boolean
  ) => {
    const backgroundTypes = [...homeScreenItem.backgroundTypes];
    if (isEnabled) {
      backgroundTypes.push(type);
    } else {
      const index = backgroundTypes.indexOf(type);
      backgroundTypes.splice(index, 1);
    }

    onChange({
      ...homeScreenItem,
      backgroundTypes: [...new Set(backgroundTypes)],
    });
  };

  const actions = [
    {
      icon: <FormatColorTextOutlinedIcon />,
      name: "Font",
      action: handleFontClick,
    },
    {
      icon: <FormatColorFillOutlinedIcon />,
      name: "Background",
      action: handleBackgroundClick,
    },
    {
      icon: <BorderColorIcon />,
      name: "Border",
      action: handleBorderClick,
    },
    {
      icon: <ArticleIcon />,
      name: "Texts",
      action: handleTextClick,
    },
    {
      icon: <ClearIcon />,
      name: "Reset",
      action: handleClearClick,
    },
  ];

  const renderPopup = (type: ControlType) => {
    if (type !== "text") {
      return (
        <ColorPopup
          type={type}
          color={getCurrentColor() || ""}
          selectedColor={getCurrentSelectedColor() || ""}
          backgroundImageUrl={homeScreenItem.backgroundImageUrl}
          activeBackgroundTypes={homeScreenItem.backgroundTypes}
          onClose={handleActionClose}
          onColorChange={handleItemColorChange}
          onFileUpload={handleFileUpload}
          onBackgroundTypeChange={handleBackgroundTypeChange}
        />
      );
    } else if (type === "text") {
      return (
        <Localization
          locale="sv"
          type={type}
          name={homeScreenItem.name?.sv || ""}
          description={homeScreenItem.description?.sv || ""}
          font={homeScreenItem.font || ""}
          iconName={homeScreenItem.iconName || ""}
          onClose={handleActionClose}
          onChange={(locale, name, description, font, iconName) => {
            onChange({
              ...homeScreenItem,
              name: {
                ...homeScreenItem.name,
                [locale]: name,
              },
              description: {
                ...homeScreenItem.description,
                [locale]: description,
              },
              font,
              iconName,
            });
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {controlType !== "none" && renderPopup(controlType)}
      <Card
        sx={{
          "& .MuiSpeedDial-root": {
            visibility: "hidden",
            height: "2rem",
            top: "8%",
            left: "0%",
          },
          "&:hover .MuiSpeedDial-root": {
            visibility: "visible",
            height: "auto",
          },

          "&:hover": {
            borderColor: selectedBorderColor,
            background:
              homeScreenItem.selectedBackgroundColor ||
              defaultSelectedBackgroundColor,
          },
          width: "100%",
          height: "100%",
          display: "flex",
          background: homeScreenItem.backgroundColor || defaultBackgroundColor,
          border: `3px solid ${
            homeScreenItem.borderColor || defaultBorderColor
          }`,
          alignItems: "center",
          //transform: "translateZ(-1px)",
          position: "relative",
          justifyContent: "center",
          overflow: "visible",
        }}
      >
        <Typography
          sx={{
            fontFamily: font || undefined,
            fontSize: 24,
            fontWeight: 900,
            color: homeScreenItem.fontColor || defaultFontColor,
          }}
        >
          {homeScreenItem.name?.sv || homeScreenItem.id}
        </Typography>
        <SpeedDial
          ariaLabel="SpeedDial openIcon config"
          direction="down"
          FabProps={{ size: "small" }}
          sx={(theme) => ({ position: "absolute" })}
          icon={
            <SpeedDialIcon
              icon={<EditIcon fontSize="small" />}
              openIcon={<EditIcon fontSize="small" />}
            />
          }
        >
          {actions.map((action, i) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
            />
          ))}
        </SpeedDial>
      </Card>
    </>
  );
};
