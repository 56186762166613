import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// material-ui
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import NavItem from "../NavItem";

// assets
import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  NavigationLevel,
  NendaProduct,
} from "../../../../../../../types/NendaTypes";
import { CustomerPortalState } from "../../../../store";
import {
  getPermissions,
  selectUserRole,
} from "../../../../store/reducers/authReducer";
import { CustomizationState } from "../../../../store/reducers/customizationReducer";
import { MenuItem } from "../../../menuItems";
import { validateRules } from "../NavGroup";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

type NavCollapseProps = {
  menu: MenuItem;
  level: number;
  products: NendaProduct[];
  navigationLevel: NavigationLevel;
};

const NavCollapse = ({
  menu,
  level,
  products,
  navigationLevel,
}: NavCollapseProps) => {
  const theme = useTheme();
  const customization: CustomizationState = useSelector<
    CustomerPortalState,
    CustomizationState
  >((state) => state.customization);
  const navigate = useNavigate();
  const userRole = useSelector(selectUserRole);
  const permissions = useSelector(getPermissions);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null | undefined>(null);

  const params = useParams();
  const url = params["*"];

  useEffect(() => {
    const childIsSelected = menu.children?.some((child) => {
      return url?.includes(child.id);
    });
    if (url?.includes(menu.id) || childIsSelected) {
      if (selected && selected !== menu.id) {
        setSelected(undefined);
      }
      setSelected(menu.id);
      setOpen(true);
    } else if (selected === menu.id) {
      setOpen(false);
      setSelected(undefined);
    }
  }, [url, menu, selected, open]);

  const isValid = validateRules(
    menu,
    products || [],
    navigationLevel || [],
    userRole,
    permissions
  );
  if (!isValid) return null;

  // menu collapse & item
  const menus = menu.children?.map((item: MenuItem, i) => {
    switch (item.type) {
      case "collapse":
        return (
          <NavCollapse
            key={item.id + i}
            menu={item}
            level={level + 1}
            products={products}
            navigationLevel={navigationLevel}
          />
        );
      case "item":
        return (
          <NavItem
            key={item.id + i}
            item={item}
            level={level + 1}
            isSubMenuItem={true}
            products={products}
            navigationLevel={navigationLevel}
          />
        );
      default:
        return (
          <Typography
            key={item.id + i}
            variant="h6"
            color="error"
            align="center"
          >
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon;
  const menuIcon = menu.icon && (
    <Icon
      stroke={1.5}
      size="1.3rem"
      style={{ marginTop: "auto", marginBottom: "auto" }}
    />
  );

  const handleToggleDropdown = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        sx={{
          overflow: "hidden",
          display: "flex",
          justifyContent: customization.opened ? "space-between" : "center",
          height: "3rem",
          alignItems: "center",
          color: selected
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
          margin: "0.5rem 0 0.5rem 0",
          "&:hover": {
            backgroundColor: selected
              ? theme.palette.common.white
              : theme.palette.background.default,
            color: theme.palette.text.primary,
          },
          "&.Mui-selected": {
            background: theme.palette.common.white,
            "&:hover": {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.text.primary,
            },
            color: theme.palette.text.primary,
          },
        }}
        selected={selected === menu.id || open}
        onClick={() => navigate(`${menu.url}`)}
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <div
            style={{
              color: "inherit",
              display: "flex",
              justifyContent: "center",
              width: 24,
              margin: customization.opened ? "0 1rem 0 0" : "0 auto",
            }}
          >
            {menuIcon}
          </div>

          <ListItemText
            sx={{
              whiteSpace: "nowrap",
              display: customization.opened ? "flex" : "none",
            }}
            primary={
              <Typography
                variant={"body2"}
                color="inherit"
                sx={{ my: "auto", width: "100%" }}
              >
                {menu.title}
              </Typography>
            }
          />
        </Box>
        {open ? (
          <IconChevronUp
            stroke={1.5}
            size="1rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
            onClick={handleToggleDropdown}
            display={customization.opened ? "block" : "none"}
          />
        ) : (
          <IconChevronDown
            display={customization.opened ? "block" : "none"}
            stroke={1.5}
            size="1rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
            onClick={handleToggleDropdown}
          />
        )}
      </ListItemButton>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          margin: "-1rem 0rem 0rem 0rem !important",
          borderRadius: "0 !important",
        }}
      >
        <List
          component="div"
          disablePadding
          sx={{
            position: "relative",
            "&:after": {
              content: "''",
              position: "absolute",
              left: "32px",
              top: 0,
              height: "100%",
              width: "1px",
              opacity: 1,
              background: theme.palette.primary.main,
            },
          }}
        >
          {menus}
        </List>
      </Collapse>
    </>
  );
};

export default NavCollapse;
