import { styled } from "@mui/material/styles";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";

const EpicTextInput = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  background: theme.palette.transparent.purple,
  padding: "0.5rem 1rem",
  "& .MuiInputBase-input": {
    padding: "1rem",
    minWidth: "20rem",
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
}));

export default EpicTextInput;
