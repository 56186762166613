import { Role } from "../../../../../types/NendaTypes";

export function canEditCompanyAssets(userRole: Role): boolean {
  return [
    Role.Admin,
    Role.Superuser,
    Role.CompanyAdmin,
    Role.CompanyUser,
  ].includes(userRole);
}

export function canEditPremiseAssets(userRole: Role): boolean {
  return [
    Role.Admin,
    Role.Superuser,
    Role.CompanyAdmin,
    Role.PremiseAdmin,
    Role.PremiseUser,
  ].includes(userRole);
}

export const canDeleteAssets = ({ role, type }) => {
  if ([Role.Admin, Role.Superuser].includes(role)) return true;

  if (type === "company") {
    return role === Role.CompanyAdmin;
  } else if (type === "premise") {
    return role === Role.PremiseUser;
  } else {
    return false;
  }
};
