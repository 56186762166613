import { Box, FormControlLabel, Switch } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { IContentChannel } from "../../../../../../types/NendaTypes";

const ToggleList = ({
  channel,
  setChannel,
}: {
  channel: IContentChannel;
  setChannel: Dispatch<SetStateAction<IContentChannel>>;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <FormControlLabel
        label="Is public"
        control={
          <Switch
            size="medium"
            name="isPublic"
            onChange={(e) => {
              setChannel({
                ...channel,
                isPublic: e.target.checked,
              });
            }}
            checked={channel.isPublic}
          />
        }
      />
      <FormControlLabel
        label="Auto shuffle"
        control={
          <Switch
            size="medium"
            name="autoShuffle"
            onChange={(e) => {
              setChannel({
                ...channel,
                isAutoShuffleEnabled: e.target.checked,
              });
            }}
            checked={channel.isAutoShuffleEnabled}
          />
        }
      />
      <FormControlLabel
        label="Sound enabled"
        control={
          <Switch
            size="medium"
            name="soundEnabled"
            onChange={(e) => {
              setChannel({
                ...channel,
                hasSound: e.target.checked,
              });
            }}
            checked={channel.hasSound}
          />
        }
      />
      <FormControlLabel
        label="Allow branding overlay"
        control={
          <Switch
            size="medium"
            name="brandingAllowed"
            onChange={(e) => {
              setChannel({
                ...channel,
                branding: {
                  ...channel.branding,
                  allowed: e.target.checked,
                },
              });
            }}
            checked={channel.branding?.allowed}
          />
        }
      />
      <FormControlLabel
        label="Allow ads"
        control={
          <Switch
            size="medium"
            name="adsAllowed"
            onChange={(e) =>
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  external: {
                    ...channel.adPermissions.external,
                    bannerAllowed: e.target.checked,
                    fullTakeoverAllowed: e.target.checked,
                  },
                },
              })
            }
            checked={
              channel.adPermissions?.external?.bannerAllowed &&
              channel.adPermissions?.external?.fullTakeoverAllowed
            }
          />
        }
      />
      <FormControlLabel
        label="Full takeover"
        sx={{ ml: "2rem" }}
        control={
          <Switch
            size="medium"
            name="adsFullTakeoverAllowed"
            onChange={(e) => {
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  external: {
                    ...channel.adPermissions.external,
                    fullTakeoverAllowed: e.target.checked,
                  },
                },
              });
            }}
            checked={channel.adPermissions?.external?.fullTakeoverAllowed}
          />
        }
      />
      <FormControlLabel
        label="Banner"
        sx={{ ml: "2rem" }}
        control={
          <Switch
            size="medium"
            name="adsBannerAllowed"
            onChange={(e) => {
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  external: {
                    ...channel.adPermissions.external,
                    bannerAllowed: e.target.checked,
                  },
                },
              });
            }}
            checked={channel.adPermissions?.external?.bannerAllowed}
          />
        }
      />
      <FormControlLabel
        label="Allow signage"
        control={
          <Switch
            size="medium"
            name="signageAllowed"
            onChange={(e) =>
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  signage: {
                    ...channel.adPermissions.signage,
                    bannerAllowed: e.target.checked,
                    fullTakeoverAllowed: e.target.checked,
                  },
                },
              })
            }
            checked={
              channel.adPermissions?.signage?.bannerAllowed &&
              channel.adPermissions?.signage?.fullTakeoverAllowed
            }
          />
        }
      />
      <FormControlLabel
        label="Full takeover"
        sx={{ ml: "2rem" }}
        control={
          <Switch
            size="medium"
            name="signageFullTakeoverAllowed"
            onChange={(e) => {
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  signage: {
                    ...channel.adPermissions.signage,
                    fullTakeoverAllowed: e.target.checked,
                  },
                },
              });
            }}
            checked={channel.adPermissions?.signage?.fullTakeoverAllowed}
          />
        }
      />
      <FormControlLabel
        label="Banner"
        sx={{ ml: "2rem" }}
        control={
          <Switch
            size="medium"
            name="signageBannerAllowed"
            onChange={(e) => {
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  signage: {
                    ...channel.adPermissions.signage,
                    bannerAllowed: e.target.checked,
                  },
                },
              });
            }}
            checked={channel.adPermissions?.signage?.bannerAllowed}
          />
        }
      />
    </Box>
  );
};

export default ToggleList;
