// material-ui
import { Typography } from "@mui/material";
import React from "react";
import PermissionsGate from "../../components/PermissionGate";
import { MenuItem } from "../menuItems";

// project imports
import NavGroup from "./components/NavGroup";

const MenuList = ({ menuItems }) => {
  const navItems = menuItems.items.map((item: MenuItem) => {
    switch (item.type) {
      case "group":
        return item.restriction ? (
          <PermissionsGate key={item.id} restriction={item.restriction}>
            <NavGroup key={item.id} item={item} />
          </PermissionsGate>
        ) : (
          <NavGroup key={item.id} item={item} />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
