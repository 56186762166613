import { Select, MenuItem, Button } from "@mui/material";
import React from "react";
import { FaSave } from "react-icons/fa";
import { useSelector } from "react-redux";
import { findRoomTypeOnRules } from "@shared/productOfferingsUtils";
import {
  IRoomType,
  OrganizationUnitType,
  RoomTypeCategory,
} from "../../../types/NendaTypes";
import { getRoomTypeCategories } from "../../redux/config.redux";
import {
  DeleteRoomType,
  ResyncRoomType,
  UpdateRoomType,
} from "../../redux/organizationUnit.redux";
import { GetProductOfferings } from "../../redux/productOfferings.redux";
import { store } from "../../redux/store";
import { AppState } from "../../types/redux";
import { capitalizeString } from "../../utils/string";
import { DeleteOUWidget } from "../DeleteOuWidget/DeleteOuWidget";
import { PageHeader } from "../PageHeader/PageHeader";
import { RoomTypeCategoryBadge } from "../RoomTypeCategory/RoomTypeCategoryBadge";
import { StringArrayEdit } from "../StringArrayEdit/StringArrayEdit";
import { SectionWrapper } from "../UI/SectionWrapper/SectionWrapper";
import { useParams } from "react-router-dom";

export const RoomType: React.FC = () => {
  const { hotelChainId, roomTypeId } = useParams();
  const roomTypeCategories = useSelector(getRoomTypeCategories);
  const allCarousels = useSelector(
    (state: AppState) => state.carousels.carousels
  );
  const allPages = useSelector((state: AppState) => state.subPages.subPages);
  const roomTypeResync = useSelector(
    (state: AppState) => (state.organizationUnits.resync || {})[roomTypeId]
  );
  const hotelChain = useSelector((state: AppState) =>
    state.organizationUnits.organizationUnits.find(
      ({ _id }) => _id === hotelChainId
    )
  );
  const productOfferings = useSelector(
    (state: AppState) => state.productOfferings.productOfferings
  );

  if (!roomTypeId) return <></>;
  const roomType = hotelChain && hotelChain.getRoomType(roomTypeId);

  const [state, setState] = React.useState<IRoomType | undefined>(undefined);
  React.useEffect(() => {
    store.dispatch(GetProductOfferings(hotelChainId));
    setState(roomType);
  }, [roomType, hotelChainId]);

  const deleteRoomType = () => {
    store.dispatch(DeleteRoomType(hotelChainId, roomTypeId));
  };

  const update = () => {
    const payload = {
      ...roomType,
      ...state,
    };
    store.dispatch(UpdateRoomType(hotelChainId, roomTypeId, payload));
  };

  const resyncNumberOfRooms = () => {
    store.dispatch(ResyncRoomType(hotelChainId, roomTypeId));
  };

  if (!hotelChain || !roomType || !state) {
    return <></>;
  }
  const relatedProductOfferings = productOfferings.filter((po) =>
    findRoomTypeOnRules(po, roomTypeId)
  );

  return (
    <>
      <PageHeader>
        <h2>{roomType.name}</h2>
        <DeleteOUWidget
          type={OrganizationUnitType.ROOM}
          ouId={roomType._id}
          onConfirmDelete={() => deleteRoomType()}
        >
          Delete room type
        </DeleteOUWidget>
      </PageHeader>
      <SectionWrapper>
        <h4>Name</h4>
        <input
          onChange={(e) => setState({ ...state, name: e.target.value })}
          name="name"
          value={state.name}
          type="text"
          className="form-control"
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>Product offerings</h4>
        <p>
          {relatedProductOfferings.length
            ? relatedProductOfferings
                .map((po) => po.localizedMetadata?.[0].name)
                .join(", ")
            : "<no product offering>"}
        </p>
      </SectionWrapper>
      <SectionWrapper>
        <h4>Number of rooms</h4>
        <div>
          <span>{state.numberOfRooms}</span>
          <button
            onClick={() => resyncNumberOfRooms()}
            className="btn btn-secondary ml-4"
          >
            Re-sync
          </button>
          {roomTypeResync !== undefined && (
            <p className="mt-2">Red Bee count: {roomTypeResync.redBeeCount}</p>
          )}
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <h4>Category</h4>
        <p>
          Current:
          <RoomTypeCategoryBadge roomType={roomType} />
        </p>
        <Select
          onChange={({ target: { value } }) =>
            setState({ ...state, category: value as RoomTypeCategory })
          }
          value={state.category}
          className="form-control col-md-6"
        >
          {Object.keys(roomTypeCategories || {}).map((element, index) => (
            <MenuItem value={roomTypeCategories[element]} key={index}>
              {capitalizeString(element).replace("_", " ")}
            </MenuItem>
          ))}
        </Select>
      </SectionWrapper>
      <SectionWrapper>
        <h4>Carousels</h4>
        <StringArrayEdit
          draggable
          values={state.carousels.map((c) => {
            const carousel = allCarousels.find(
              (carousel) => carousel._id === c
            );
            return {
              name: carousel ? carousel.name : c,
              value: c,
              link: `/components/carousels/carousel/${c}`,
            };
          })}
          options={allCarousels.map((c) => ({ name: c.name, value: c._id }))}
          onChange={(values) => setState({ ...state, carousels: values })}
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>Pages</h4>
        <StringArrayEdit
          draggable
          values={state.pages.map((p) => {
            const page = allPages.find((page) => page._id === p);
            return {
              name: page ? page.name : p,
              value: p,
              link: `/pages/${p}`,
            };
          })}
          options={allPages.map((p) => ({ name: p.name, value: p._id }))}
          onChange={(values) => setState({ ...state, pages: values })}
        />
      </SectionWrapper>
      <Button
        disabled={state === roomType}
        onClick={update}
        className="mr-2 my-2"
        variant="contained"
        color="secondary"
        endIcon={<FaSave />}
      >
        Save
      </Button>
    </>
  );
};
