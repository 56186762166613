import { Edit } from "@mui/icons-material";
import { TabContext, TabList } from "@mui/lab";
import { Button, Divider, Tab } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IAddress,
  IOrganizationUnit,
  NendaProduct,
  Resource,
  Scope,
} from "../../../../../../types/NendaTypes";
import { store } from "../../../store";
import {
  getPermissions,
  selectUserRole,
} from "../../../store/reducers/authReducer";
import { UpdateOrganizationUnit } from "../../../store/reducers/organizationUnitReducer";
import DefaultDialog from "../../../ui-components/dialog/dialog";
import EditScreens from "../../Premise/Features/EditScreens";
import PremiseInfo from "../../Premise/Info/PremiseInfo";
import { checkPermission } from "../../helpers/permissions";
import PremiseFeatures from "./PremiseFeatures";

const EditPremiseDialog = ({ premise }: { premise: IOrganizationUnit }) => {
  const [showEditPremise, setShowEditPremise] = useState(false);
  const [tabValue, setTabValue] = useState("info");

  const initAddressData = {
    ...premise.address,
    city: premise.address?.city || "",
    streetAddress: premise.address?.streetAddress || "",
    postalCode: premise.address?.postalCode || "",
    region: premise.address?.region || "",
  };
  const initData: IOrganizationUnit = {
    ...premise,
    address: initAddressData,
  };
  const [editPremiseData, setEditPremiseData] =
    useState<IOrganizationUnit>(initData);

  if (premise._id !== editPremiseData._id) {
    setEditPremiseData(initData);
  }

  const role = useSelector(selectUserRole);
  const permissions = useSelector(getPermissions);

  const editPremiseDialogContent = useCallback(() => {
    if (!premise) return [];
    const items = [
      {
        label: "Info",
        value: "info",
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanEdit],
        },
        component: (
          <PremiseInfo
            setPremiseInfo={setEditPremiseData}
            premiseInfo={editPremiseData}
          />
        ),
      },
      {
        label: "Features",
        value: "features",
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanAdministrate],
        },
        component: (
          <PremiseFeatures
            premise={premise}
            setPremiseFeatures={setEditPremiseData}
            premiseFeatures={editPremiseData}
          />
        ),
      },
      {
        label: "Screens",
        value: "screens",
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanAdministrate],
        },
        component: <EditScreens premise={premise} />,
      },
    ];
    return items;
  }, [editPremiseData]);
  const tabItems = editPremiseDialogContent();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const saveChanges = ({ isConfirmed }) => {
    if (!isConfirmed) return setShowEditPremise(false);

    const updatedPremise: Partial<IOrganizationUnit> = {
      address: editPremiseData?.address,
      contactPerson: editPremiseData?.contactPerson,
      contract: editPremiseData?.contract && {
        ...editPremiseData?.contract,
        startDate: JSON.parse(
          JSON.stringify(editPremiseData?.contract?.startDate)
        ),
        endDate: JSON.parse(JSON.stringify(editPremiseData?.contract?.endDate)),
      },
      numberOfEntries: editPremiseData?.numberOfEntries,
      dwellTimeInMinutes: editPremiseData?.dwellTimeInMinutes,
      nendaProducts: editPremiseData?.nendaProducts,
      category: editPremiseData?.category,
      businessHours: editPremiseData?.businessHours,
      company: editPremiseData?.company,
      contentChannels: editPremiseData?.contentChannels,
      signageConfig: editPremiseData?.signageConfig,
      streamingContentManagerEnabled: editPremiseData.nendaProducts?.includes(
        NendaProduct.STREAMING_APP
      )
        ? editPremiseData?.streamingContentManagerEnabled
        : false, //Turns off this feature if the product is disabled
    };
    store.dispatch(UpdateOrganizationUnit(premise?._id, updatedPremise));

    setShowEditPremise(false);
  };

  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        startIcon={<Edit />}
        onClick={() => setShowEditPremise(true)}
        sx={{ ml: "auto" }}
      >
        {t("customerportal.pages.dashboard.edit_premise")}
      </Button>
      <DefaultDialog
        onClose={saveChanges}
        open={showEditPremise}
        title={t("customerportal.pages.dashboard.edit_premise") as string}
      >
        <>
          <Box>
            <TabContext value={tabValue}>
              <TabList onChange={handleTabChange} sx={{ mb: "1rem" }}>
                {role &&
                  tabItems
                    .filter((i) =>
                      checkPermission(role, i.restriction, permissions)
                    )
                    .map((item) => (
                      <Tab
                        key={item.value}
                        label={item.label}
                        value={item.value}
                      />
                    ))}
              </TabList>
              <Divider />
            </TabContext>
          </Box>

          <Box>
            {
              editPremiseDialogContent().find((item) => item.value === tabValue)
                ?.component
            }
          </Box>

          <Divider />
        </>
      </DefaultDialog>
    </Box>
  );
};

export default EditPremiseDialog;
