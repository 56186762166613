import { httpClient, Service } from "./client";

class ConfigService extends Service {
  getCountries() {
    return httpClient.get("/config/countries", this.authHeader());
  }
  getSettings() {
    return httpClient.get("/config", this.authHeader());
  }
  updateSettings(id: string, data: any) {
    return httpClient.put(`/config/${id}`, data, this.authHeader());
  }
  getFailedJobs() {
    return httpClient.get("/config/failedjobs", this.authHeader());
  }
}

export const configService = new ConfigService();
