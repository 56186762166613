import { push } from "react-router-redux";
import { map } from "rxjs/operators";
import { Carousel } from "@shared/carousel";
import { ICarousel } from "../../types/NendaTypes";
import { fromJson } from "../models/modelHelpers";
import { store } from "../redux/store";
import { httpClient, Service } from "./client";

class CarouselService extends Service {
  getCarousels() {
    return httpClient
      .get("/carousels", this.authHeader())
      .pipe(map((json) => json.map((j) => fromJson(Carousel, j))));
  }
  deleteCarousel(carouselId: string) {
    return httpClient.delete(`/carousels/${carouselId}`, this.authHeader());
  }
  updateCarousel(carouselId: string, payload: Partial<ICarousel>) {
    return httpClient.put(
      `/carousels/${carouselId}`,
      payload,
      this.authHeader()
    );
  }
  createCarousel(data: Partial<ICarousel>) {
    const payload = {
      title: [],
      type: "curated",
      assetIds: [],
      tagIds: [],
      displayLimit: 10,
      sort: "-created",
      ...data,
    };
    return httpClient.post("/carousels", payload, this.authHeader()).pipe(
      map((c) => {
        // redirect to carousel edit page
        store.dispatch(push(`/components/carousels/carousel/${c._id}`));
        return c;
      })
    );
  }
}

export const carouselService = new CarouselService();
