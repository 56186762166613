import { DeleteOutline, Warning } from "@mui/icons-material";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import { t } from "i18next";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  ISignagePromotion,
  LoadingStatus,
  NendaProduct,
} from "../../../../types/NendaTypes";
import CreateEditPromotionModal from "../components/Bookings/create/CreateEditPromotionModal";
import { CustomerPortalState, store } from "../store";
import {
  GetCompanyAssets,
  GetPremiseAssets,
  selectAssetsBasedOnNavigationScope,
} from "../store/reducers/assetReducer";
import {
  SetEditBookingId,
  ToggleBookingScreen,
  UnSetEditBookingId,
} from "../store/reducers/customizationReducer";
import { getPremisesByCompanyId } from "../store/reducers/organizationUnitReducer";
import {
  deleteCompanyPromotion,
  deletePremisePromotion,
  getCompanyPromotions,
  getPremisePromotions,
  selectCompanyPromotions,
  selectPremisePromotions,
  selectPromotionById,
} from "../store/reducers/promotionReducer";
import {
  batchGetScreens,
  getScreens,
  selectScreensForPremise,
  selectScreensForPremises,
  selectScreensByPromotionId,
} from "../store/reducers/screenReducer";
import {
  selectCurrentNavigationLevel,
  selectNavigationScope,
} from "../store/reducers/workspaceReducer";
import DefaultTable from "../ui-components/table";
import {
  GetPromotionTableHeaderProps,
  promotionTableRow,
} from "../ui-components/table/tableProps/promotion";
import { FOREVER_END_DATE } from "../components/Bookings/create/components/DateRangeSelect";

const Promotions: React.FC = () => {
  const scope = useSelector(selectNavigationScope);
  const scopeLevel = useSelector(selectCurrentNavigationLevel);

  const premises = useSelector((state: CustomerPortalState) =>
    getPremisesByCompanyId(state, scope.company || "")
  );

  const editPromotionId = useSelector(
    (state: CustomerPortalState) => state.customization.booking.editBookingId
  );
  const promotionScreens = useSelector((state: CustomerPortalState) =>
    selectScreensByPromotionId(state, editPromotionId)
  );

  const assets = useSelector(selectAssetsBasedOnNavigationScope);
  const companyPromotions = useSelector(selectCompanyPromotions);
  const premisePromotions = useSelector(selectPremisePromotions);
  const editPromotion = useSelector((state: CustomerPortalState) =>
    selectPromotionById(state, editPromotionId)
  );
  const companyPromotionLoadingStatus = useSelector(
    (state: CustomerPortalState) => {
      return state.promotion.companyPromotionsStatus;
    }
  );
  const premisePromotionLoadingStatus = useSelector(
    (state: CustomerPortalState) => {
      return state.promotion.premisePromotionsStatus;
    }
  );
  const companyPromotionsIsLoading =
    companyPromotionLoadingStatus === LoadingStatus.LOADING;
  const companyPromotionsIsIdle =
    companyPromotionLoadingStatus === LoadingStatus.IDLE;
  const premisePromotionsIsLoading =
    premisePromotionLoadingStatus === LoadingStatus.LOADING;
  const premisePromotionsIsIdle =
    premisePromotionLoadingStatus === LoadingStatus.IDLE;
  const companyAssetsIsLoading = useSelector(
    (state: CustomerPortalState) =>
      state.asset.paginatedCompanyAssets.status === LoadingStatus.LOADING
  );
  const premiseAssetsIsLoading = useSelector(
    (state: CustomerPortalState) =>
      state.asset.paginatedPremiseAssets.status === LoadingStatus.LOADING
  );

  const assetsIsLoading = companyAssetsIsLoading || premiseAssetsIsLoading;

  const screensIsLoading = useSelector(
    (state: CustomerPortalState) => state.screen.isLoading
  );

  const isLoading =
    companyPromotionsIsLoading ||
    premisePromotionsIsLoading ||
    assetsIsLoading ||
    screensIsLoading;

  const screensDispatch = (state: CustomerPortalState) => {
    if (scope.premise && scope.company) {
      return selectScreensForPremise(state, scope.premise);
    } else if (scope.company && !scope.premise) {
      return selectScreensForPremises(
        state,
        premises.map((p) => p._id)
      );
    } else {
      return [];
    }
  };
  const screens = useSelector((state: CustomerPortalState) =>
    screensDispatch(state)
  );

  useEffect(() => {
    if (!scope.company || screensIsLoading || !premises.length) return;
    store.dispatch(batchGetScreens(premises.map((p) => p._id)));
  }, [JSON.stringify(premises)]);

  useEffect(() => {
    if (!scope.premise || screensIsLoading) return;
    store.dispatch(getScreens(scope.premise));
  }, [scope.premise]);

  useEffect(() => {
    if (!scope.premise || premisePromotionsIsLoading) return;
    store.dispatch(getPremisePromotions(scope.premise));
  }, [scope.premise]);

  useEffect(() => {
    if (!scope.company || companyPromotionsIsLoading) return;
    store.dispatch(getCompanyPromotions(scope.company));
  }, [scope.company]);

  useEffect(() => {
    if (!scope.company || companyAssetsIsLoading) return;
    store.dispatch(
      GetCompanyAssets(scope.company, {
        filter: {
          product: NendaProduct.SIGNAGE,
        },
      })
    );
  }, [scope.company]);

  useEffect(() => {
    if (!scope.premise || premiseAssetsIsLoading) return;
    store.dispatch(
      GetPremiseAssets(scope.premise, {
        filter: {
          product: NendaProduct.SIGNAGE,
        },
      })
    );
  }, [scope.premise]);

  const handleDeletePromotion = (promotionId: string) => {
    if (scopeLevel === "company" && scope.company) {
      store.dispatch(
        deleteCompanyPromotion({
          promotionId,
          companyOrPremiseId: scope.company,
        })
      );
    }
    if (scopeLevel === "premise" && scope.premise) {
      store.dispatch(
        deletePremisePromotion({
          promotionId,
          companyOrPremiseId: scope.premise,
        })
      );
    }
  };

  const handleCreatePromotion = () => {
    store.dispatch(UnSetEditBookingId());
    store.dispatch(ToggleBookingScreen());
  };

  const createTableRow = (
    promotion: ISignagePromotion,
    isDeletable: boolean,
    isEditable: boolean
  ) => {
    const hasAsset = assets.some((a) =>
      promotion.playlist.find((pmpl) => pmpl.asset === a._id)
    );

    const assetName = assets.find((a) =>
      promotion.playlist.some((pmpl) => pmpl.asset === a._id)
    )?.name;

    const endDate = format(new Date(promotion.schedule.date.end), "yyyy-MM-dd");
    const foreverEndDate = format(new Date(FOREVER_END_DATE), "yyyy-MM-dd");
    const isEndForever = endDate === foreverEndDate;

    const numberOfScreens = screens.filter((s) =>
      s.signage.schedule.promotions.includes(promotion._id)
    )?.length;

    return {
      ...promotionTableRow(
        promotion,
        isDeletable && (
          <DeleteOutline
            key={promotion._id}
            id="endIcon"
            onClick={(event: React.MouseEvent) => {
              handleDeletePromotion(promotion._id);
              event.stopPropagation();
            }}
          />
        )
      ),
      assetName: hasAsset ? (
        <Typography variant="body1">{assetName || ""}</Typography>
      ) : (
        <Tooltip title={"Could not find the asset. It may have been deleted."}>
          <Warning
            sx={(theme) => ({
              fill: theme.palette.warning.light,
            })}
          />
        </Tooltip>
      ),
      start: format(new Date(promotion.schedule.date.start), "yyyy-MM-dd"),
      end: isEndForever ? t("common.no_end_date") : endDate,
      numberOfScreens,
      onClick: () => {
        if (!isEditable) return;
        store.dispatch(ToggleBookingScreen());
        store.dispatch(SetEditBookingId(promotion?._id));
      },
    };
  };

  const renderTable = () => {
    const tables = [
      {
        showCondition: scopeLevel === "premise",
        title: t("customerportal.pages.promotions.list.list_by_premise_header"),
        rows: premisePromotionRows,
        headers: GetPromotionTableHeaderProps(),
        isEditable: true,
      },
      {
        showCondition: true,
        title: t("customerportal.pages.promotions.list.list_by_company_header"),
        rows: companyPromotionRows,
        headers: GetPromotionTableHeaderProps(),
        isEditable: scopeLevel === "company",
      },
    ];

    return (
      <>
        {tables?.map((table) => {
          if (!table.showCondition) return null;
          return (
            <Box key={table.title} sx={{ my: "2rem" }}>
              <Typography variant="h6" sx={{ fontSize: "1.2rem", mb: "1rem" }}>
                {table.title}
              </Typography>

              <DefaultTable
                key={table.title}
                pagination={false}
                loading={isLoading}
                hasClickableRows={table.isEditable}
                tableData={{
                  rows: table.rows,
                  cols: table.headers,
                }}
              />
              {table.rows?.length < 1 &&
                !companyPromotionsIsIdle &&
                !premisePromotionsIsIdle &&
                !isLoading && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="body1">
                      {t("customerportal.pages.promotions.no_promotions_found")}
                    </Typography>
                  </Box>
                )}
            </Box>
          );
        })}
      </>
    );
  };

  const companyPromotionRows = useMemo(() => {
    return companyPromotions.map((promotion) =>
      createTableRow(
        promotion,
        scopeLevel === "company",
        scopeLevel === "company"
      )
    );
  }, [screens, companyPromotions, assets]);

  const premisePromotionRows = useMemo(() => {
    return premisePromotions.map((promotion) =>
      createTableRow(promotion, true, true)
    );
  }, [screens, premisePromotions, assets]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", gap: "2rem", alignItems: "center" }}>
        <Typography variant="h3">
          {t("customerportal.pages.promotions.title")}
        </Typography>
        <Button variant="contained" onClick={handleCreatePromotion}>
          {t("customerportal.pages.promotions.create_promotion")}
        </Button>
      </Box>
      <CreateEditPromotionModal
        isLoading={isLoading}
        assets={assets}
        screens={promotionScreens}
        promotion={editPromotion}
      />
      <Box sx={{ position: "relative" }}>{renderTable()}</Box>
    </Box>
  );
};

export default Promotions;
