import { Epic, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap, mergeMap } from "rxjs/operators";
import { handleError, SetError } from "./errorReducer";
import { CustomerPortalState } from "..";
import {
  IAdCampaign,
  IAdPlaylist,
  IRoom,
} from "../../../../../types/NendaTypes";
import {
  AdsActions,
  ADS_ACTIONS,
  CreateCampaignAction,
  DeleteCampaignAction,
  GetAdPlaylistAction,
  GetAdPlaylistFailureAction,
  GetAdPlaylistSuccessAction,
  GetAdScreensAction,
  GetCampaignsAction,
  GetCampaignsSuccessAction,
  UpdateAdPlaylistAction,
  UpdateAdPlaylistActionFailure,
  UpdateAdPlaylistActionSuccess,
  UpdateCampaignAction,
  UploadAdAssetAction,
} from "../../../../types/redux";
import { adsService } from "../../../../http/ads.service";
import { SetNotification } from "./notificationReducer";
import { GetScreensResponse } from "../../../../../server/api/internal/ad";
import { FileUploadResponse } from "../../../../../server/services/fileService";

export interface AdsState {
  adPlaylist: IAdPlaylist;
  campaigns: IAdCampaign[];
  isLoading: boolean;
  screens: GetScreensResponse;
  uploadedFile?: any;
}

export const initialState: AdsState = {
  adPlaylist: { assets: [] },
  campaigns: [],
  isLoading: false,
  screens: [],
};

export function GetCampaigns(): GetCampaignsAction {
  return { type: ADS_ACTIONS.GET_CAMPAIGNS };
}

export function GetCampaignsSuccess(
  campaigns: IAdCampaign[]
): GetCampaignsSuccessAction {
  return { type: ADS_ACTIONS.GET_CAMPAIGNS_SUCCESS, campaigns };
}

export function CreateCampaign(
  campaignData: IAdCampaign
): CreateCampaignAction {
  return { type: ADS_ACTIONS.CREATE_CAMPAIGN, data: campaignData };
}

export function CreateCampaignSuccess(campaign: IAdCampaign) {
  return { type: ADS_ACTIONS.CREATE_CAMPAIGN_SUCCESS, campaign };
}

export function UpdateCampaign(
  campaignId: string,
  campaignData: Partial<IAdCampaign>
): UpdateCampaignAction {
  return { type: ADS_ACTIONS.UPDATE_CAMPAIGN, campaignId, data: campaignData };
}

export function UpdateCampaignSucces(campaign: IAdCampaign) {
  return { type: ADS_ACTIONS.UPDATE_CAMPAIGN_SUCCESS, campaign };
}

export function DeleteCampaign(campaignId: string) {
  return { type: ADS_ACTIONS.DELETE_CAMPAIGN, campaignId };
}

export function DeleteCampaignSuccess(campaignId: string) {
  return { type: ADS_ACTIONS.DELETE_CAMPAIGN_SUCCESS, campaignId };
}

export function UploadAdAsset(file: File): UploadAdAssetAction {
  return { type: ADS_ACTIONS.UPLOAD_AD_ASSET, file };
}

export function UploadAdAssetSuccess(uploadResponse: FileUploadResponse) {
  return { type: ADS_ACTIONS.UPLOAD_AD_ASSET_SUCCESS, uploadResponse };
}

export function GetAdPlaylist(): GetAdPlaylistAction {
  return { type: ADS_ACTIONS.GET_AD_PLAYLIST };
}

function GetAdPlaylistSuccess(
  adPlaylist: IAdPlaylist
): GetAdPlaylistSuccessAction {
  return {
    type: ADS_ACTIONS.GET_AD_PLAYLIST_SUCCESS,
    adPlaylist,
  };
}

function GetAdPlaylistFailure(error: any): GetAdPlaylistFailureAction {
  return { type: ADS_ACTIONS.GET_AD_PLAYLIST_FAILURE, error };
}

export function UpdateAdPlaylist(data: IAdPlaylist): UpdateAdPlaylistAction {
  return { data, type: ADS_ACTIONS.UPDATE_AD_PLAYLIST };
}

function UpdateAdPlaylistSuccess(
  adPlaylist: IAdPlaylist
): UpdateAdPlaylistActionSuccess {
  return {
    type: ADS_ACTIONS.UPDATE_AD_PLAYLIST_SUCCESS,
    adPlaylist,
  };
}

function UpdateAdPlaylistFailure(error: any): UpdateAdPlaylistActionFailure {
  return {
    type: ADS_ACTIONS.UPDATE_AD_PLAYLIST_FAILURE,
    error: error.response ? error.response.message : error.message,
  };
}

export function GetScreens() {
  return { type: ADS_ACTIONS.GET_AD_SCREENS };
}

export function GetScreensSuccess(screens: Partial<IRoom>[]) {
  return { type: ADS_ACTIONS.GET_AD_SCREENS_SUCCESS, screens };
}

// selectors
export const getAdPlaylist = (state: CustomerPortalState): IAdPlaylist => {
  return state.ads.adPlaylist;
};

export const getScreens = (state: CustomerPortalState): GetScreensResponse => {
  return state.ads.screens;
};

export const selectIsLoading = (state: CustomerPortalState) =>
  state.ads.isLoading;

export const selectUploadedAdAssetUrl = (state: CustomerPortalState) =>
  state.ads.uploadedFile?.fileUrl;

// redurcer

export default function adsReducer(
  state: AdsState = initialState,
  action: AdsActions
): AdsState {
  switch (action.type) {
    case ADS_ACTIONS.GET_AD_PLAYLIST:
    case ADS_ACTIONS.GET_CAMPAIGNS:
    case ADS_ACTIONS.CREATE_CAMPAIGN:
    case ADS_ACTIONS.UPDATE_CAMPAIGN:
    case ADS_ACTIONS.DELETE_CAMPAIGN:
    case ADS_ACTIONS.UPLOAD_AD_ASSET:
      return {
        ...state,
        isLoading: true,
      };
    case ADS_ACTIONS.GET_AD_PLAYLIST_SUCCESS:
      return {
        ...state,
        adPlaylist: action.adPlaylist,
      };
    case ADS_ACTIONS.UPDATE_AD_PLAYLIST_SUCCESS:
      return {
        ...state,
        adPlaylist: action.adPlaylist,
        isLoading: false,
      };
    case ADS_ACTIONS.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.campaigns,
        isLoading: false,
      };
    case ADS_ACTIONS.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, action.campaign],
        isLoading: false,
      };
    case ADS_ACTIONS.UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.map((campaign) =>
          campaign._id === action.campaign._id ? action.campaign : campaign
        ),
        isLoading: false,
      };
    case ADS_ACTIONS.DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.filter(
          (campaign) => campaign._id !== action.campaignId
        ),
        isLoading: false,
      };
    case ADS_ACTIONS.UPLOAD_AD_ASSET_SUCCESS:
      return {
        ...state,
        uploadedFile: action.uploadResponse,
        isLoading: false,
      };
    case ADS_ACTIONS.GET_AD_SCREENS:
      return {
        ...state,
        isLoading: true,
      };
    case ADS_ACTIONS.GET_AD_SCREENS_SUCCESS:
      return {
        ...state,
        screens: action.screens,
        isLoading: false,
      };
    case ADS_ACTIONS.GET_CAMPAIGNS_FAILURE:
    case ADS_ACTIONS.CREATE_CAMPAIGN_FAILURE:
    case ADS_ACTIONS.UPDATE_CAMPAIGN_FAILURE:
    case ADS_ACTIONS.DELETE_CAMPAIGN_FAILURE:
    case ADS_ACTIONS.UPLOAD_AD_ASSET_FAILURE:
    case ADS_ACTIONS.GET_AD_SCREENS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

// Epics

const getAdsPlaylist$: Epic = (action$) => {
  return action$.pipe(
    ofType(ADS_ACTIONS.GET_AD_PLAYLIST),
    switchMap((a: GetAdPlaylistAction) => {
      return adsService.getAdPlaylist().pipe(
        map((adPlaylist) => {
          return GetAdPlaylistSuccess(adPlaylist);
        }),
        catchError(handleError)
      );
    })
  );
};

const updateAdsPlaylist$: Epic = (action$) => {
  return action$.pipe(
    ofType(ADS_ACTIONS.UPDATE_AD_PLAYLIST),
    switchMap((a: UpdateAdPlaylistAction) => {
      return adsService.updateAdPlaylist(a.data).pipe(
        mergeMap((response: IAdPlaylist) => {
          return [
            UpdateAdPlaylistSuccess(response),
            SetNotification("Playlist saved!"),
          ];
        }),
        catchError((err) => of(UpdateAdPlaylistFailure(err)))
      );
    })
  );
};

const updateAdsPlaylistFailed$: Epic = (action$) => {
  return action$.pipe(
    ofType(ADS_ACTIONS.UPDATE_AD_PLAYLIST_FAILURE),
    map((a) => SetError(a.payload))
  );
};

const getCampaigns$: Epic = (action$) => {
  return action$.pipe(
    ofType(ADS_ACTIONS.GET_CAMPAIGNS),
    switchMap((a: GetCampaignsAction) => {
      return adsService.getCampaigns().pipe(
        map((campaigns) => {
          return GetCampaignsSuccess(campaigns);
        }),
        catchError(handleError)
      );
    })
  );
};

const createCampaign$: Epic = (action$) => {
  return action$.pipe(
    ofType(ADS_ACTIONS.CREATE_CAMPAIGN),
    switchMap((a: CreateCampaignAction) => {
      return adsService.createCampaign(a.data).pipe(
        mergeMap((campaign) => {
          return [
            CreateCampaignSuccess(campaign),
            SetNotification("Campaign created!"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const updateCampaign$: Epic = (action$) => {
  return action$.pipe(
    ofType(ADS_ACTIONS.UPDATE_CAMPAIGN),
    switchMap((a: UpdateCampaignAction) => {
      return adsService.updateCampaign(a.campaignId, a.data).pipe(
        mergeMap((campaign) => {
          return [
            UpdateCampaignSucces(campaign),
            SetNotification("Campaign updated!"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const deleteCampaign$: Epic = (action$) => {
  return action$.pipe(
    ofType(ADS_ACTIONS.DELETE_CAMPAIGN),
    switchMap((a: DeleteCampaignAction) => {
      return adsService.deleteCampaign(a.campaignId).pipe(
        mergeMap((campaign) => {
          return [
            DeleteCampaignSuccess(campaign._id),
            SetNotification("Campaign deleted!"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const uploadAdAsset$: Epic = (action$) => {
  return action$.pipe(
    ofType(ADS_ACTIONS.UPLOAD_AD_ASSET),
    switchMap((a: UploadAdAssetAction) => {
      return adsService.uploadAdAsset(a.file).pipe(
        mergeMap((response) => {
          return [
            UploadAdAssetSuccess(response),
            SetNotification("Ad asset uploaded"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const getAdScreens$: Epic = (action$) => {
  return action$.pipe(
    ofType(ADS_ACTIONS.GET_AD_SCREENS),
    switchMap((a: GetAdScreensAction) => {
      return adsService.getScreens().pipe(
        map((screens) => {
          return GetScreensSuccess(screens);
        }),
        catchError(handleError)
      );
    })
  );
};

export const adsEpics = [
  getAdsPlaylist$,
  updateAdsPlaylist$,
  updateAdsPlaylistFailed$,
  getCampaigns$,
  createCampaign$,
  updateCampaign$,
  deleteCampaign$,
  uploadAdAsset$,
  getAdScreens$,
];
