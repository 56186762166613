import { Button } from "@mui/material";
import React from "react";
import { FaSave } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { IRoomType } from "../../../../types/NendaTypes";
import { EditProductOffering } from "../../../redux/productOfferings.redux";
import { store } from "../../../redux/store";

interface IProductOfferingsSaveButtonProps {
  disabled: boolean;
  editedRoomTypes: Array<Partial<IRoomType>>;
}

export const ProductOfferingsSaveButton: React.FC<
  IProductOfferingsSaveButtonProps
> = ({ disabled, editedRoomTypes }) => {
  const params = useParams<{ hotelChainId: string; id: string }>();

  const save = () => {
    const res = {
      or: [...editedRoomTypes],
    };
    store.dispatch(EditProductOffering(params.hotelChainId, params.id, res));
  };

  return (
    <Button
      disabled={disabled}
      onClick={save}
      variant="contained"
      color="secondary"
      className="mr-2 my-2"
      endIcon={<FaSave />}
    >
      Save
    </Button>
  );
};
