import { ITag } from "../../types/NendaTypes";
import { httpClient, Service } from "./client";

class TagService extends Service {
  getAssetTags() {
    return httpClient.get("/tags/assetTags", this.authHeader());
  }
  getTags() {
    return httpClient.get("/tags", this.authHeader());
  }
  getAdminTags() {
    return httpClient.get("/tags/adminTags", this.authHeader());
  }
  getTagById(id: string) {
    return httpClient.get(`/tags/${id}`, this.authHeader());
  }
  createTag(data: Omit<ITag, "_id" | "created" | "updated">) {
    return httpClient.post("/tags", data, this.authHeader());
  }
  deleteTag(id: string) {
    return httpClient.delete(`/tags/${id}`, this.authHeader());
  }
}

export const tagService = new TagService();
