import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Role, UserSession } from "../../../types/NendaTypes";
import { getUserSession } from "../../redux/auth.redux";

const allowedRoles = [Role.Admin, Role.Superuser];

export function isLoggedIn(session: UserSession | undefined) {
  if (!session) return false;
  return (
    session &&
    session.expires &&
    new Date(session.expires).getTime() > Date.now()
  );
}

function hasPermission(session: UserSession | undefined) {
  return session && allowedRoles.includes(session.role);
}

interface ProtectedRouteProps {
  childRouteElement?: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { childRouteElement } = props;
  const navigate = useNavigate();
  const session = useSelector(getUserSession);
  const userIsLoggedIn = isLoggedIn(session);
  const userHasPermission = hasPermission(session);

  useEffect(() => {
    !userIsLoggedIn && navigate("login");
  }, [userIsLoggedIn]);

  if (!userHasPermission) {
    return <div>No Permission</div>;
  }

  return <>{childRouteElement}</>;
};
