import {
  Card,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  capitalize,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { sv } from "date-fns/locale";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { getKeysFromNumericEnum } from "@shared/enumUtils";
import { DayOfWeek } from "../../../../../../types/HelperTypes";
import { IBusinessHours } from "../../../../../../types/NendaTypes";

interface BusinessHoursProps {
  businessHours?: Partial<Record<DayOfWeek, IBusinessHours>>;
  onChange: (hours: Partial<Record<DayOfWeek, IBusinessHours>>) => void;
}

const BusinessHours: React.FunctionComponent<BusinessHoursProps> = ({
  businessHours = {},
  onChange,
}) => {
  const [currentBusinessHours, setCurrentBusinessHours] =
    useState<Partial<Record<DayOfWeek, IBusinessHours>>>(businessHours);

  useEffect(() => {
    if (currentBusinessHours) onChange(currentBusinessHours);
  }, [currentBusinessHours]);

  const handleToggleOpen = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isOpen = event.target.checked;
    const dayOfWeek = event.target.name;

    const newState = { ...currentBusinessHours };
    newState[DayOfWeek[dayOfWeek]] = {
      ...newState[DayOfWeek[dayOfWeek]],
      isOpen: isOpen,
    };

    setCurrentBusinessHours(newState);
  };

  const handleOpeningTimeChange =
    (dayOfWeek: DayOfWeek) => (date: Date | null) => {
      if (!date || date.toString() === t("form.validation.invalid_date")) {
        return;
      }

      const time = date.toTimeString().substring(0, 5);
      const newState = { ...currentBusinessHours };
      const day = newState[dayOfWeek];
      const newHours: [string, string] = [time, day?.hours?.[1] || ""];

      newState[dayOfWeek] = {
        ...newState[dayOfWeek],
        hours: newHours,
      } as any;

      setCurrentBusinessHours(newState);
    };

  const handleClosingTimeChange =
    (dayOfWeek: DayOfWeek) => (date: Date | null) => {
      if (!date || date.toString() === t("form.validation.invalid_date")) {
        return;
      }

      const time = date.toTimeString().substring(0, 5);
      const newState = { ...currentBusinessHours };
      const day = newState[dayOfWeek];
      const newHours: [string, string] = [day?.hours?.[0] || "", time];

      newState[dayOfWeek] = {
        ...newState[dayOfWeek],
        hours: newHours,
      } as any;

      setCurrentBusinessHours(newState);
    };

  const openingHoursToDate = (openingHours: string) => {
    const hour = Number(openingHours.substring(0, 2));
    const minute = Number(openingHours.substring(3, 5));
    return new Date(0, 0, 0, hour, minute);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
      <Card elevation={0}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="business hours table">
            <TableHead>
              <TableRow>
                <TableCell>{capitalize(t("common.day"))}</TableCell>
                <TableCell align="left">{t("common.open")}</TableCell>
                <TableCell align="left">{t("common.opening")}</TableCell>
                <TableCell align="left">{t("common.closing")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getKeysFromNumericEnum(DayOfWeek).map((key) => {
                const dailyHours: IBusinessHours =
                  currentBusinessHours[DayOfWeek[key]];
                return (
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {capitalize(key.toLowerCase())}
                    </TableCell>
                    <TableCell align="left">
                      <Checkbox
                        name={key}
                        onChange={handleToggleOpen}
                        checked={dailyHours && dailyHours.isOpen ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TimePicker
                        disabled={!dailyHours?.isOpen}
                        minutesStep={15}
                        sx={(theme) => ({
                          "& .MuiInputBase-input": {
                            width: "100%",
                          },
                          "& .MuiInputAdornment-root": {
                            mr: "1rem",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent !important",
                          },
                          "& .MuiOutlinedInput-root:hover": {
                            borderColor:
                              theme.palette.primary.light + " !important",
                          },
                        })}
                        value={
                          (dailyHours &&
                            dailyHours.hours &&
                            dailyHours.hours[0] &&
                            openingHoursToDate(dailyHours.hours[0])) ||
                          null
                        }
                        onChange={handleOpeningTimeChange(DayOfWeek[key])}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TimePicker
                        disabled={!dailyHours?.isOpen}
                        minutesStep={15}
                        sx={(theme) => ({
                          "& .MuiInputBase-input": {
                            width: "100%",
                          },
                          "& .MuiInputAdornment-root": {
                            mr: "1rem",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent !important",
                          },
                          "& .MuiOutlinedInput-root:hover": {
                            borderColor:
                              theme.palette.primary.light + " !important",
                          },
                        })}
                        value={
                          (dailyHours &&
                            dailyHours.hours &&
                            dailyHours.hours[1] &&
                            openingHoursToDate(dailyHours.hours[1])) ||
                          null
                        }
                        onChange={handleClosingTimeChange(DayOfWeek[key])}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </LocalizationProvider>
  );
};

export default BusinessHours;
