import { Select, MenuItem, Button } from "@mui/material";
import React from "react";
import { FaSave, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import carouselTypes from "@shared/carouselTypes";
import { ICarouselWithAsset } from "../../../types/NendaTypes";
import { GetAssetsForCarousel } from "../../redux/asset.redux";
import { DeleteCarousel, UpdateCarousel } from "../../redux/carousels.redux";
import { store } from "../../redux/store";
import { GetTags } from "../../redux/tags.redux";
import { AppState } from "../../types/redux";
import { PageHeader } from "../PageHeader/PageHeader";
import { TitleCreator } from "../TitleCreator/TitleCreator";
import { SectionWrapper } from "../UI/SectionWrapper/SectionWrapper";
import { CarouselPreview } from "./CarouselPreview";
import { ChannelSelector } from "./widgets/ChannelSelector";
import { CuratedWidget } from "./widgets/CuratedWidget";
import { TagQueryWidget } from "./widgets/TagQueryWidget";

export const Carousel: React.FC = () => {
  const carousels = useSelector(
    (state: AppState) => state.carousels?.carousels
  );
  const params = useParams();
  const carousel = carousels?.find(
    (c) => c._id === params.id
  ) as ICarouselWithAsset;

  function saveCarousel(newValues) {
    store.dispatch(UpdateCarousel(carousel._id, newValues));
  }
  if (carousel) {
    return <CarouselForm carousel={carousel} onSave={saveCarousel} />;
  }
  return null;
};
interface ICarouselFormProps {
  carousel: ICarouselWithAsset;
  onSave(newValues: any): void;
}

export const CarouselForm: React.FC<ICarouselFormProps> = ({
  carousel,
  onSave,
}) => {
  const [stateCarousel, setStateCarousel] = React.useState<ICarouselWithAsset>(
    {} as ICarouselWithAsset
  );
  const assets = useSelector(
    (state: AppState) =>
      state.assets?.assetsPerCarousel.find(
        (a) => a.carouselId === carousel._id
      ) || {
        assets: [],
      }
  );
  React.useEffect(() => {
    setStateCarousel(carousel);
  }, [carousel]);
  React.useEffect(() => {
    if (stateCarousel.query) {
      store.dispatch(
        GetAssetsForCarousel(
          stateCarousel.query,
          stateCarousel.sort ?? "-created",
          stateCarousel._id
        )
      );
    }
  }, [
    stateCarousel.assetIds,
    stateCarousel.tagIds,
    stateCarousel.query,
    stateCarousel.sort,
    stateCarousel.logicConnector,
  ]);
  React.useEffect(() => {
    store.dispatch(GetTags());
  }, []);

  function deleteCarousel() {
    store.dispatch(DeleteCarousel(stateCarousel._id));
  }

  function updateState(newValues: {}) {
    setStateCarousel({
      ...stateCarousel,
      ...newValues,
    });
  }
  if (!stateCarousel.name) return null;
  return (
    <>
      <PageHeader>
        <h2>{stateCarousel.name}</h2>
        <Button
          variant="contained"
          color="error"
          startIcon={<FaTrash />}
          onClick={deleteCarousel}
        >
          Delete
        </Button>
      </PageHeader>
      <SectionWrapper>
        <h4>
          Name{" "}
          <span className="description">
            This will act as the fallback title as well as an human readable
            identifier.
          </span>
        </h4>
        <input
          onChange={(e) => updateState({ name: e.target.value })}
          value={stateCarousel.name}
          className="form-control"
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>
          Titles{" "}
          <span className="description">
            The localized title of the carousel, to be shown in the apps.
          </span>
        </h4>
        <TitleCreator
          values={stateCarousel.title || []}
          onValueChange={(v) => updateState({ title: v })}
        />
        {/* <TitleCreator values={stateCarousel.title} onValueChange={(v) => updateState({ title: v })} onRemove={updateState} /> */}
      </SectionWrapper>
      <SectionWrapper>
        <h4>
          Display limit{" "}
          <span className="description">
            The number of assets shown before a "Se all" card is added (defaults
            to 10 if empty).
          </span>
        </h4>
        <input
          type="number"
          onChange={(e) => updateState({ displayLimit: e.target.value })}
          value={stateCarousel.displayLimit || ""}
          className="form-control"
        />
      </SectionWrapper>

      <SectionWrapper>
        <h4>
          Display non-purchased content
          <span className="description">
            Display assets that is not available to that room (user account).
          </span>
        </h4>
        <div className="custom-control custom-switch">
          <input
            name="showNonPurchasedContent"
            id="showNonPurchasedContent"
            type="checkbox"
            className="custom-control-input"
            checked={stateCarousel.showNonPurchasedContent}
            onChange={({ target: { checked } }) =>
              updateState({ showNonPurchasedContent: checked })
            }
          />
          <label
            htmlFor="showNonPurchasedContent"
            className="custom-control-label showNonPurchasedContent-label"
          >
            Display
          </label>
        </div>
      </SectionWrapper>

      <div className="m-2 py-3 d-none">
        <h4>Query</h4>
        <input className="form-control" value={stateCarousel.query} disabled />
      </div>
      <SectionWrapper>
        <h4>
          Carousel type{" "}
          <span className="description">
            Whether the carousel should be curated manually or automatically by
            tag/genre.
          </span>
        </h4>
        <div className="input-group">
          <Select
            className="form-control"
            value={stateCarousel.type}
            onChange={(e) => updateState({ type: e.target.value })}
          >
            <MenuItem value={carouselTypes.CURATED}>Curated carousel</MenuItem>
            <MenuItem value={carouselTypes.QUERY}>Query carousel</MenuItem>
            <MenuItem value={carouselTypes.RECOMMEDED}>Recommended</MenuItem>
            <MenuItem value={carouselTypes.CONTINUE_WATCHING}>
              Continue watching
            </MenuItem>
            <MenuItem value={carouselTypes.EPG}>EPG</MenuItem>
          </Select>
        </div>
      </SectionWrapper>
      {stateCarousel.type === carouselTypes.CURATED && (
        <CuratedWidget
          carousel={stateCarousel}
          onChange={updateState}
          assets={assets ? assets.assets : []}
        />
      )}
      {stateCarousel.type === carouselTypes.QUERY && (
        <TagQueryWidget carousel={stateCarousel} onChange={updateState} />
      )}
      {stateCarousel.type === carouselTypes.RECOMMEDED && (
        <p>
          The carousel will be auto populated with videos by Red Bee Medias
          recommendation engine{" "}
        </p>
      )}
      {stateCarousel.type === carouselTypes.CONTINUE_WATCHING && (
        <p>
          The carousel will be auto populated with videos that the user has
          started to watch{" "}
        </p>
      )}
      {stateCarousel.type === carouselTypes.EPG && (
        <ChannelSelector
          currentValue={stateCarousel.channelId || ""}
          onChange={(channelId) => updateState({ channelId })}
        />
      )}
      <Button
        disabled={stateCarousel === carousel}
        onClick={() => onSave(stateCarousel)}
        variant="contained"
        color="secondary"
        endIcon={<FaSave />}
      >
        Save
      </Button>
      {assets &&
        (stateCarousel.type === carouselTypes.CURATED ||
          stateCarousel.type === carouselTypes.QUERY) && (
          <CarouselPreview
            assets={assets.assets.sort(
              (a, b) =>
                stateCarousel.assetIds.indexOf(a.assetId) -
                stateCarousel.assetIds.indexOf(b.assetId)
            )}
          />
        )}
      {/* {(stateCarousel.type === carouselTypes.EPG && stateCarousel.channelId) && <EPGPreview channelId={stateCarousel.channelId} />} */}
    </>
  );
};
