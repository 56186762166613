import { IAdCampaign, IAdPlaylist } from "../../types/NendaTypes";
import { httpClient, Service } from "./client";

class AdsService extends Service {
  getAdPlaylist() {
    return httpClient.get("/ad/playlist", this.authHeader());
  }

  updateAdPlaylist(playlist: IAdPlaylist) {
    return httpClient.patch(`/ad/playlist`, playlist, this.authHeader());
  }

  getCampaigns() {
    return httpClient.get("/ad/campaign", this.authHeader());
  }

  getCampaignById(id: string) {
    return httpClient.get(`/ad/campaign/${id}`, this.authHeader());
  }

  createCampaign(campaign: IAdCampaign) {
    return httpClient.post(`/ad/campaign`, campaign, this.authHeader());
  }

  updateCampaign(id: string, campaign: Partial<IAdCampaign>) {
    return httpClient.patch(`/ad/campaign/${id}`, campaign, this.authHeader());
  }

  deleteCampaign(id: string) {
    return httpClient.delete(`/ad/campaign/${id}`, this.authHeader());
  }

  uploadAdAsset(file: File) {
    const formData = new FormData();
    formData.append("scope", "ads");
    formData.append("file", file);
    return httpClient.put(
      `/ad/upload`,
      formData,
      {
        ...this.authHeader(),
      },
      false
    );
  }

  getScreens() {
    return httpClient.get("/ad/screens", this.authHeader());
  }
}

export const adsService = new AdsService();
