import { httpClient, Service } from "./client";

class UpsalesService extends Service {
  getCompany(id: number) {
    return httpClient.get(`/upsales/company/${id}`, this.authHeader());
  }

  getCompanies() {
    return httpClient.get(`/upsales/company`, this.authHeader());
  }

  getSubscription(id: number) {
    return httpClient.get(`/upsales/subscription/${id}`, this.authHeader());
  }

  getSubscriptions() {
    return httpClient.get(`/upsales/subscription`, this.authHeader());
  }

  getOrder(id: number) {
    return httpClient.get(`/upsales/order/${id}`, this.authHeader());
  }

  getOrders(readyForDeliveryOnly: boolean = true) {
    return httpClient.get(
      `/upsales/order${
        readyForDeliveryOnly ? "?readyForDeliveryOnly=true" : ""
      }`,
      this.authHeader()
    );
  }
}

export const upsalesService = new UpsalesService();
