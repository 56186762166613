import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppState } from "../../../types/redux";
import {
  StringArrayEdit,
  StringArrayEditTypes,
} from "../../StringArrayEdit/StringArrayEdit";
import { SectionWrapper } from "../../UI/SectionWrapper/SectionWrapper";

interface IProductOfferingSelectsProps {
  roomTypesValues: Array<any>;
  updateRoomTypes(values: Array<any>): void;
}

export const ProductOfferingSelects: React.FC<IProductOfferingSelectsProps> = ({
  roomTypesValues,
  updateRoomTypes,
}) => {
  const { hotelChainId } = useParams<{ hotelChainId: string }>();
  const hotelChain = useSelector((state: AppState) =>
    state.organizationUnits.organizationUnits.find(
      ({ _id }) => _id === hotelChainId
    )
  );
  const options =
    hotelChain?.roomTypes
      .filter(({ _id }) => !roomTypesValues.includes(_id))
      .map(({ name, _id }) => ({ name, value: _id })) || [];
  return (
    <>
      <SectionWrapper>
        <h4>Room types</h4>
        <StringArrayEdit
          showPlus={!!options.length}
          values={roomTypesValues
            .map((rt) => {
              const roomType = hotelChain?.roomTypes.find(
                ({ _id }) => _id === rt
              );
              if (roomType)
                return {
                  name: roomType.name,
                  value: roomType._id,
                };
            })
            .filter((rt) => rt)}
          options={options}
          onChange={(values) => updateRoomTypes(values)}
          type={StringArrayEditTypes.ROOM_TYPES}
        />
      </SectionWrapper>
    </>
  );
};
