import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageWrapper } from "../PageWrapper";
import { AnalyticsOverview } from "./AnalyticsOverview";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";

export const AnalyticsPage: React.FC = () => {
  return (
    <PageWrapper>
      <Routes>
        <Route
          path={"/*"}
          element={<ProtectedRoute childRouteElement={<AnalyticsOverview />} />}
        />
      </Routes>
    </PageWrapper>
  );
};
