import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, mergeMap, switchMap } from "rxjs/operators";
import { CustomerPortalState, store } from "..";
import { DayOfWeek } from "../../../../../types/HelperTypes";
import {
  IDailyContentPlaylist,
  IWeeklyScheduleItem,
  LoadingStatus,
} from "../../../../../types/NendaTypes";
import { organizationUnitService } from "../../../../http/organizationUnit.service";
import { SetNotification } from "../../../../redux/notification.redux";
import { handleError } from "./errorReducer";
import { batchPutWeeklyScheduleForScreens } from "./screenReducer";

export interface DailyContentPlaylistState {
  playlists: IDailyContentPlaylist[];
  status: LoadingStatus;
  editPlaylist: string | null;
  deletePlaylist: string | null;
  createEditPlaylistModalIsOpen: boolean;
  error: {
    message: string;
    type: "warning" | "error" | "info" | "success" | null;
  };
}

export type ScreenSelection = {
  _id: string;
  name: string;
  schedule: {
    [key in DayOfWeek]?: string;
  };
  selected: number[];
};
export interface AssignPlaylistsPayload {
  createPlaylist: IDailyContentPlaylist;
  selectedScreens: ScreenSelection[];
}

export const initialContentPlaylistState: DailyContentPlaylistState = {
  playlists: [],
  status: LoadingStatus.IDLE,
  editPlaylist: null,
  deletePlaylist: null,
  createEditPlaylistModalIsOpen: false,
  error: {
    message: "",
    type: null,
  },
};

const dailyContentPlaylistSlice = createSlice({
  name: "dailyContentPlaylist",
  initialState: initialContentPlaylistState,
  reducers: {
    getPlaylists(state, _action: PayloadAction<string>) {
      state.status = LoadingStatus.LOADING;
    },
    setPlaylists(state, action) {
      state.playlists = action.payload;
      state.status = LoadingStatus.SUCCEEDED;
    },
    createPlaylist(state, _action: PayloadAction<IDailyContentPlaylist>) {
      state.status = LoadingStatus.LOADING;
    },
    createPlaylistSuccess(state, action) {
      state.playlists.push(action.payload);
      state.status = LoadingStatus.SUCCEEDED;
    },
    createAndAssignPlaylist(
      state,
      _action: PayloadAction<AssignPlaylistsPayload>
    ) {
      state.status = LoadingStatus.LOADING;
    },
    createAndAssignPlaylistSuccess(state, action) {
      state.playlists.push(action.payload);
      state.status = LoadingStatus.SUCCEEDED;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setEditPlaylist(state, action) {
      state.editPlaylist = action.payload;
    },
    setDeletePlaylist(state, action) {
      state.deletePlaylist = action.payload;
    },
    setCreateEditPlaylistModalIsOpen(state, action) {
      state.createEditPlaylistModalIsOpen = action.payload;
    },
    updatePlaylist(state, _action: PayloadAction<IDailyContentPlaylist>) {
      state.status = LoadingStatus.LOADING;
    },
    updatePlaylistSuccess(state, action: PayloadAction<IDailyContentPlaylist>) {
      const updatedPlaylists = state.playlists.map((playlist) => {
        if (playlist._id === action.payload._id) {
          return action.payload;
        }
        return playlist;
      });
      state.playlists = updatedPlaylists;
      state.status = LoadingStatus.SUCCEEDED;
    },
    deletePlaylist(
      _state,
      _action: PayloadAction<{ premiseId: string; playlistId: string }>
    ) {},
    deletePlaylistSuccess(state, action) {
      const filteredPlaylists = state.playlists.filter(
        (playlist) => playlist._id !== action.payload
      );
      state.playlists = filteredPlaylists;
      state.status = LoadingStatus.SUCCEEDED;
    },
    setError(state, action) {
      state.error = {
        message: action.payload.message,
        type: action.payload.type,
      };
    },
  },
});

//Selectors
export const selectPlaylists = createSelector(
  (state: CustomerPortalState) => state.dailyContentPlaylist.playlists,
  (playlists) => {
    return playlists;
  }
);

export const selectPlaylistsByPremiseId = createSelector(
  [
    (state: CustomerPortalState) => state.dailyContentPlaylist.playlists,
    (_: any, premiseId: string) => premiseId,
  ],
  (playlists, premiseId) => {
    return playlists.filter((playlist) => playlist.premise === premiseId);
  }
);

export const selectEditPlaylist = createSelector(
  (state: CustomerPortalState) => state.dailyContentPlaylist.editPlaylist,
  (editPlaylist) => {
    return editPlaylist;
  }
);

export const selectDeletePlaylist = createSelector(
  (state: CustomerPortalState) => state.dailyContentPlaylist.deletePlaylist,
  (deletePlaylist) => {
    return deletePlaylist;
  }
);

export const selectStatus = createSelector(
  (state: CustomerPortalState) => state.dailyContentPlaylist.status,
  (status) => {
    return status;
  }
);

export const selectCreateEditPlaylistModalIsOpen = createSelector(
  (state: CustomerPortalState) =>
    state.dailyContentPlaylist.createEditPlaylistModalIsOpen,
  (createEditPlaylistModalIsOpen) => {
    return createEditPlaylistModalIsOpen;
  }
);

export const selectError = createSelector(
  (state: CustomerPortalState) => state.dailyContentPlaylist.error,
  (error) => {
    return error;
  }
);

//Epics
const getPlaylists$: Epic = (action$) => {
  return action$.pipe(
    filter(dailyContentPlaylistSlice.actions.getPlaylists.match),
    switchMap((a) => {
      return organizationUnitService.getDailyContentPlaylists(a.payload).pipe(
        mergeMap((playlists: IDailyContentPlaylist[]) => {
          return [setPlaylists(playlists), setStatus(LoadingStatus.SUCCEEDED)];
        }),
        catchError((error) => {
          return of([setStatus(LoadingStatus.FAILED), handleError(error)]);
        })
      );
    })
  );
};

const createPlaylist$: Epic = (action$) => {
  return action$.pipe(
    filter(dailyContentPlaylistSlice.actions.createPlaylist.match),
    switchMap((a) => {
      return organizationUnitService.createDailyContentPlaylist(a.payload).pipe(
        mergeMap((playlist) => {
          const actionsToDispatch = [
            dailyContentPlaylistSlice.actions.setCreateEditPlaylistModalIsOpen(
              false
            ),
            dailyContentPlaylistSlice.actions.setEditPlaylist(null),
            dailyContentPlaylistSlice.actions.createPlaylistSuccess(playlist),
            SetNotification({
              message: "Playlist created successfully",
              bgClass: "bg-success",
            }),
          ];

          // Ensure each action is dispatched individually
          return of(...actionsToDispatch);
        }),
        catchError((error) => of(handleError(error)))
      );
    })
  );
};
const createAndAssignPlaylist$: Epic = (action$) => {
  return action$.pipe(
    filter(dailyContentPlaylistSlice.actions.createAndAssignPlaylist.match),
    switchMap((a) => {
      return organizationUnitService
        .createDailyContentPlaylist(a.payload.createPlaylist)
        .pipe(
          mergeMap((playlist) => {
            const assignPlaylistItems: IWeeklyScheduleItem[] =
              a.payload.selectedScreens.map((screen) => {
                const schedule: { [key in DayOfWeek]?: string } = {
                  ...screen.schedule,
                };
                screen.selected.forEach((day) => {
                  schedule[day] = playlist._id;
                });

                //Clear out empty days
                Object.keys(schedule).forEach((key) => {
                  if (!schedule[key]) {
                    delete schedule[key];
                  }
                });

                return {
                  screenId: screen._id,
                  schedule,
                };
              });

            const actionsToDispatch = [
              dailyContentPlaylistSlice.actions.createPlaylistSuccess(playlist),
              store.dispatch(
                batchPutWeeklyScheduleForScreens(assignPlaylistItems)
              ),
              SetNotification({
                message: "Playlist created successfully",
                bgClass: "bg-success",
              }),
            ];

            // Ensure each action is dispatched individually
            return of(...actionsToDispatch);
          }),
          catchError((error) => of(handleError(error)))
        );
    })
  );
};

const updatePlaylist$: Epic = (action$) => {
  return action$.pipe(
    filter(dailyContentPlaylistSlice.actions.updatePlaylist.match),
    switchMap((a) => {
      return organizationUnitService.updateDailyContentPlaylist(a.payload).pipe(
        mergeMap((playlist) => {
          const actionsToDispatch = [
            dailyContentPlaylistSlice.actions.updatePlaylistSuccess(playlist),
            SetNotification({
              message: "Playlist updated successfully",
              bgClass: "bg-success",
            }),
            dailyContentPlaylistSlice.actions.setCreateEditPlaylistModalIsOpen(
              false
            ),
            dailyContentPlaylistSlice.actions.setEditPlaylist(null),
          ];

          // Ensure each action is dispatched individually
          return of(...actionsToDispatch);
        }),
        catchError((error) => of(handleError(error)))
      );
    })
  );
};

const deletePlaylist$: Epic = (action$) =>
  action$.pipe(
    filter(dailyContentPlaylistSlice.actions.deletePlaylist.match),
    switchMap((a) =>
      organizationUnitService
        .deleteDailyContentPlaylist(a.payload.premiseId, a.payload.playlistId)
        .pipe(
          mergeMap((playlist) => {
            return [
              dailyContentPlaylistSlice.actions.deletePlaylistSuccess(
                playlist._id
              ),
              dailyContentPlaylistSlice.actions.setDeletePlaylist(null),
              SetNotification({
                message: "Playlist deleted successfully",
                bgClass: "bg-success",
              }),
            ];
          }),
          catchError((error) => of(handleError(error)))
        )
    )
  );

export const dailyContentPlaylistEpics = [
  getPlaylists$,
  createPlaylist$,
  deletePlaylist$,
  updatePlaylist$,
  createAndAssignPlaylist$,
];

export const {
  getPlaylists,
  setPlaylists,
  setStatus,
  setEditPlaylist,
  setDeletePlaylist,
  deletePlaylist,
  setCreateEditPlaylistModalIsOpen,
  updatePlaylist,
  createPlaylist,
  setError,
  createAndAssignPlaylist,
} = dailyContentPlaylistSlice.actions;

export default dailyContentPlaylistSlice.reducer;
