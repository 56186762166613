import { Box, Checkbox, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import constants from "@shared/constants";
import { t } from "i18next";

const TermsAndConditions = ({
  acceptedTerms,
  setAcceptedTerms,
}: {
  acceptedTerms: boolean;
  setAcceptedTerms: (value: boolean) => void;
}) => {
  return (
    <Box sx={{ py: "1rem" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
        <Checkbox
          size="medium"
          checked={acceptedTerms}
          indeterminate={false}
          onChange={() => setAcceptedTerms(!acceptedTerms)}
        />
        <Typography variant="body1">
          {t(
            "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.accept_terms"
          )}
          <Link to={constants.TERMS_AND_CONDITIONS} target="_blank">
            {t(
              "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.terms_and_conditions"
            )}
          </Link>
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          {t(
            "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.terms_details"
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
