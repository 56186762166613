// project imports
import { isMobile } from "react-device-detect";
import { ScheduleSlotType } from "../../../../../types/NendaTypes";
import {
  CustomizationActions,
  CUSTOMOMIZATION_ACTIONS,
  MenuOpenAction,
  SetMenuAction,
  SetSettingsAction,
  ToggleBookingScreenAction,
  ToggleMenuAction,
} from "../../../../types/redux";

// action - state management
export enum CalendarRange {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}
export type CalendarMode =
  | CalendarRange.DAY
  | CalendarRange.WEEK
  | CalendarRange.MONTH;

export interface CustomizationState {
  settingsIsOpen: boolean;
  isOpen: Array<string>;
  opened: boolean;
  booking: {
    bookingScreenIsOpen: boolean;
    editBookingId: string | undefined;
  };
}

export const initialState: CustomizationState = {
  settingsIsOpen: false,
  isOpen: [], // for active default menu
  opened: true,
  booking: {
    bookingScreenIsOpen: false,
    editBookingId: "",
  },
};

export function SetEditBookingId(id: string) {
  return {
    type: CUSTOMOMIZATION_ACTIONS.SET_EDIT_BOOKING_ID,
    bookingId: id,
  };
}

export function SetCreateEditBookingType(bookingType: ScheduleSlotType) {
  return {
    type: CUSTOMOMIZATION_ACTIONS.SET_CREATE_EDIT_BOOKINGTYPE,
    bookingType,
  };
}

export function UnSetEditBookingId() {
  return {
    type: CUSTOMOMIZATION_ACTIONS.SET_EDIT_BOOKING_ID,
  };
}
export function OpenSettings(): SetSettingsAction {
  return { type: CUSTOMOMIZATION_ACTIONS.SETTINGS_TOGGLE, opened: true };
}

export function CloseSettings(): SetSettingsAction {
  return { type: CUSTOMOMIZATION_ACTIONS.SETTINGS_TOGGLE, opened: false };
}

export function ToggleBookingScreen(): ToggleBookingScreenAction {
  return { type: CUSTOMOMIZATION_ACTIONS.TOGGLE_BOOKING_SCREEN };
}

export function OpenMenu(): SetMenuAction {
  return { type: CUSTOMOMIZATION_ACTIONS.SET_MENU, opened: true };
}

export function CloseMenu(): SetMenuAction {
  return { type: CUSTOMOMIZATION_ACTIONS.SET_MENU, opened: false };
}
export function ToggleMenu(): ToggleMenuAction {
  return {
    type: CUSTOMOMIZATION_ACTIONS.MENU_TOGGLE,
  };
}

export function RegisterOpenItem(id: string): MenuOpenAction {
  return { type: CUSTOMOMIZATION_ACTIONS.MENU_OPEN, id };
}

//Selector

const customizationReducer = (
  state = initialState,
  action: CustomizationActions
): CustomizationState => {
  let id;
  switch (action.type) {
    case CUSTOMOMIZATION_ACTIONS.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
        opened: isMobile ? false : state.opened,
      };
    case CUSTOMOMIZATION_ACTIONS.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case CUSTOMOMIZATION_ACTIONS.MENU_TOGGLE:
      return {
        ...state,
        opened: !state.opened,
      };
    case CUSTOMOMIZATION_ACTIONS.SETTINGS_TOGGLE:
      return {
        ...state,
        settingsIsOpen: action.opened,
      };
    case CUSTOMOMIZATION_ACTIONS.TOGGLE_BOOKING_SCREEN:
      return {
        ...state,
        booking: {
          ...state.booking,
          bookingScreenIsOpen: !state.booking.bookingScreenIsOpen,
          editBookingId: state.booking.bookingScreenIsOpen
            ? ""
            : state.booking.editBookingId,
        },
      };
    case CUSTOMOMIZATION_ACTIONS.SET_EDIT_BOOKING_ID:
      return {
        ...state,
        booking: {
          ...state.booking,
          editBookingId: action.bookingId || undefined,
        },
      };
    default:
      return state;
  }
};

export default customizationReducer;
