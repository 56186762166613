import { push } from "react-router-redux";
import { store } from "../redux/store";
import { httpClient, Service } from "./client";

class SubPagesService extends Service {
  getSubPages() {
    return httpClient.get("/pages", this.authHeader());
  }
  updateSubPage(id, payload) {
    return httpClient.put(`/pages/${id}`, payload, this.authHeader());
  }
  createSubPage(name) {
    return httpClient.post("/pages", { name }, this.authHeader());
  }
  deleteSubPage(id) {
    return httpClient
      .delete(`/pages/${id}`, this.authHeader())
      .pipe((response) => {
        store.dispatch(push("/pages"));
        return response;
      });
  }
}

export const subPagesService = new SubPagesService();
