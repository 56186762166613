import React from "react";
import {
  IRoom,
  IRoomType,
  NendaProduct,
  Resource,
  RoomStatus,
  Scope,
} from "../../../../../types/NendaTypes";
import RoomTypeSelect from "./RoomType/RoomTypeSelect";
import { CustomerPortalState, store } from "../../store";
import { selectRoomTypesByPremiseGroupId } from "../../store/reducers/organizationUnitReducer";
import { useSelector } from "react-redux";
import { selectStreamChannels } from "../../store/reducers/streamChannelReducer";
import { updateScreen } from "../../store/reducers/screenReducer";
import { Box, Button, Divider, Stack } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import PermissionsGate from "../PermissionGate";
import NendaProducts from "../Premise/NendaProducts";
import StreamChannelSelect from "../Premise/Screen/SignalDelivery/StreamChannelSelect";
import { t } from "i18next";
import TextInput from "../../ui-components/input/TextInput";
import StatusSelect from "./StatusSelect";
import AutoPlayAsset from "./AutoplayAsset";

interface UnitProps {
  unit: IRoom;
  productsOnPremise: NendaProduct[];
}

const Unit: React.FC<UnitProps> = ({ unit, productsOnPremise }) => {
  const [state, setState] = React.useState<IRoom>(unit);

  const roomTypes = useSelector((state: CustomerPortalState): IRoomType[] =>
    selectRoomTypesByPremiseGroupId(state, unit.hotelChain)
  );

  const availableStreamChannels = useSelector(selectStreamChannels);

  const handleRoomTypeChange = (roomTypeId: string) => {
    const updatedUnit = {
      ...unit,
      roomType: roomTypeId,
    };

    setState(updatedUnit);
  };

  const handleStatusChange = (status: RoomStatus) => {
    setState({ ...state, status });
  };

  const handleProductChange = (productName: NendaProduct) => {
    let updatedProducts = [...state.nendaProducts];
    if (updatedProducts.indexOf(productName) === -1) {
      updatedProducts.push(productName);
    } else {
      updatedProducts = updatedProducts.filter((x) => x != productName);
    }
    setState({ ...state, nendaProducts: updatedProducts });
  };

  const handleStreamChannelChange = (selectedChannelIds: string[]) => {
    const updatedState: IRoom = JSON.parse(JSON.stringify(state));

    updatedState.streamChannels = selectedChannelIds;
    setState(updatedState);
  };

  const saveChanges = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    store.dispatch(updateScreen(state));
  };

  const curriedTextInputChange =
    (key: string): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [key]: e.target.value });
    };

  const handleDeviceIdChange = curriedTextInputChange("deviceId");
  const handleNameChange = curriedTextInputChange("name");
  const handleDescriptionChange = curriedTextInputChange("description");

  const handleAutoplayAssetEnabledChange = (enabled: boolean) => {
    setState({
      ...state,
      autoplayAsset: {
        ...(state.autoplayAsset || { id: "" }),
        enabled,
      },
    });
  };

  const handleAutoplayAssetIdChange = (assetId: string) => {
    setState({
      ...state,
      autoplayAsset: {
        ...(state.autoplayAsset || { enabled: false }),
        id: assetId,
      },
    });
  };

  const selectedRoomType = roomTypes.find((rt) => rt._id === state.roomType);
  const roomHasStreamingApp = state.nendaProducts?.includes(
    NendaProduct.STREAMING_APP
  );

  return (
    <Box>
      <Stack>
        <TextInput
          id="name"
          value={state.name}
          label={t("customerportal.pages.unit.name")}
          onChange={handleNameChange}
          variant="outlined"
          sx={{ textTransform: "capitalize", mb: "1.5rem" }}
        />
        <TextInput
          id="description"
          value={state.description}
          label={t("customerportal.pages.unit.description")}
          onChange={handleDescriptionChange}
          variant="outlined"
          sx={{ textTransform: "capitalize", mb: "1.5rem" }}
        />
        <TextInput
          id="device-id"
          value={state.deviceId}
          label={t("customerportal.pages.unit.device_id")}
          onChange={handleDeviceIdChange}
          variant="outlined"
          sx={{ textTransform: "capitalize", mb: "1.5rem" }}
        />
        <NendaProducts
          selectedProducts={state.nendaProducts}
          availableProducts={productsOnPremise}
          onProductSelect={handleProductChange}
        />
        {roomHasStreamingApp && (
          <RoomTypeSelect
            roomTypes={roomTypes}
            selectedRoomTypeId={state.roomType}
            onChange={handleRoomTypeChange}
          />
        )}
        <StatusSelect status={state.status} onChange={handleStatusChange} />
        <Divider />
        <AutoPlayAsset
          enabled={state.autoplayAsset ? state.autoplayAsset?.enabled : false}
          assetId={state.autoplayAsset ? state.autoplayAsset?.id : ""}
          onEnabledChange={handleAutoplayAssetEnabledChange}
          onAssetIdChange={handleAutoplayAssetIdChange}
        />
        <Divider />
        {state.nendaProducts?.includes(NendaProduct.SIGNAL_DELIVERY) && (
          <PermissionsGate
            restriction={{
              resource: Resource.StreamChannel,
              scopes: [Scope.CanAdministrate],
            }}
          >
            <StreamChannelSelect
              availableChannels={availableStreamChannels}
              selectedChannelIds={state.streamChannels}
              onChange={handleStreamChannelChange}
            />
          </PermissionsGate>
        )}
        <Button
          type="button"
          onClick={saveChanges}
          variant="contained"
          sx={{ mt: 2 }}
          endIcon={<SaveAltIcon />}
        >
          {t("common.save")}
        </Button>
      </Stack>
    </Box>
  );
};

export default Unit;
