import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React from "react";
import { FONTS } from "./constants";
import InputSelect from "../../../ui-components/input/InputSelect";

type FontSelectProps = {
  selectedFont: string;
  onSelect: (font: string) => void;
};

const FontSelect: React.FC<FontSelectProps> = ({ selectedFont, onSelect }) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onSelect(event.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <Typography variant="body2">Font</Typography>
      <InputSelect
        sx={{
          fontFamily: selectedFont,
        }}
        labelId="font-select-label"
        id="font-select"
        name="font"
        variant="outlined"
        value={selectedFont || ""}
        onChange={handleChange}
      >
        {FONTS.map((font, index) => (
          <MenuItem
            sx={{ fontFamily: font.family }}
            key={index}
            value={font.family}
          >
            {font.family}
          </MenuItem>
        ))}
      </InputSelect>
    </FormControl>
  );
};

export default FontSelect;
