export const groupByObjectKey = (arr: any[], key: string) => {
  return arr.reduce((acc, curr) => {
    const value = curr[key];
    acc[value] = [...(acc[value] || []), curr];
    return acc;
  }, {});
};

// An implementation of Fisher–Yates shuffle
// https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
export const shuffle = (array: any[]) => {
  let currentIndex = array.length;
  let randomIndex: number;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const ensureArray = <T>(value: T | T[]): T[] => {
  if (value === undefined) return [];
  return Array.isArray(value) ? value : [value];
};

export const countOccurrences = <T extends string | number>(
  arr: T[]
): Record<T, number> => {
  const count: Record<T, number> = {} as Record<T, number>;

  arr.forEach((item: T) => {
    count[item] = (count[item] || 0) + 1;
  });

  return count;
};

export const compareStringArrays = (
  arr1: string[],
  arr2: string[]
): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};

export const isSubset = <T>(subset: T[], fullSet: T[]): boolean => {
  return subset.every((item) => fullSet.includes(item));
};
