import { Button, FormControl, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { store } from "../store";
import {
  ResetPassword,
  getResetPasswordStatus,
} from "../store/reducers/authReducer";
import TextInput from "../ui-components/input/TextInput";
import { LoadingStatus, Status } from "../../../../types/NendaTypes";

const ChangePasswordForm = () => {
  const theme = useTheme();
  const [resetStatus, setResetStatus] = useState<Status>(LoadingStatus.IDLE);
  const passwordResetSucceded = resetStatus === "succeeded";

  const reset = (password: string) => {
    store.dispatch(ResetPassword(password));
  };
  const status = useSelector(getResetPasswordStatus);

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(t("form.validation.password_required") as string)
      .min(8, t("form.validation.password_min_length") as string)
      .matches(
        /\d/,
        t("form.validation.password_must_contain_number") as string
      ),
    passwordConfirmation: yup
      .string()
      .required(t("form.validation.password_reenter_required") as string)
      .oneOf(
        [yup.ref("password"), ""],
        t("form.validation.passwords_must_match") as string
      ),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ password }) => {
      setResetStatus(LoadingStatus.LOADING);
      reset(password);
    },
  });

  useEffect(() => {
    if (status === "succeeded") {
      setResetStatus(status);
      formik.resetForm();
    } else {
      setResetStatus(LoadingStatus.IDLE);
    }
  }, [status]);
  return (
    <FormControl
      sx={{
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "1fr 1fr 1fr",
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr",
        },
        alignItems: "baseline",
      }}
    >
      <TextInput
        label={t("customerportal.pages.reset_password.password1")}
        type="password"
        variant={"standard"}
        placeholder={t("customerportal.pages.reset_password.password1")}
        id="password"
        name="password"
        fullWidth
        width="100%"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <TextInput
        fullWidth
        label={t("customerportal.pages.reset_password.password2")}
        type="password"
        placeholder={
          t("customerportal.pages.reset_password.repeat_password") || ""
        }
        width="100%"
        variant={"standard"}
        id="passwordConfirmation"
        name="passwordConfirmation"
        value={formik.values.passwordConfirmation}
        onChange={formik.handleChange}
        error={
          formik.touched.passwordConfirmation &&
          Boolean(formik.errors.passwordConfirmation)
        }
        helperText={
          formik.touched.passwordConfirmation &&
          formik.errors.passwordConfirmation
        }
        sx={{ [theme.breakpoints.down("lg")]: { gridColumn: "1" } }}
      />

      <Button
        variant="outlined"
        onClick={() => formik.submitForm()}
        size="small"
        sx={{
          marginTop: "1rem",
          justifySelf: "flex-start",
          gridColumn: "3",
          [theme.breakpoints.down("lg")]: { gridColumn: "1" },
        }}
      >
        {passwordResetSucceded
          ? t("customerportal.pages.account_settings.password_updated")
          : t("customerportal.pages.account_settings.save_password")}
      </Button>
    </FormControl>
  );
};

export default ChangePasswordForm;
