import React from "react";
import ReactPlayer from "react-player";
import {
  Alert,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { IStreamChannel } from "../../../../../types/NendaTypes";
import StreamChannelForm from "./StreamChannelForm";
import { Buffer } from "buffer";

interface StreamChannelListItemProps {
  channel: IStreamChannel;
}
// regex that matches the nenda api url:
// https://api.nenda.com/api // without subdomain
// https://api*.nenda.com/api //with any subdomain e.g. api-stage.nenda.com/api
const NENDA_API_URL_REGEX = "^https://api.*.nenda.com/api/.*$";

interface PlayerError {
  type?: string;
  error?: string;
  details?: string;
  response?: {
    code?: number;
    text?: string;
  };
}

interface Credentials {
  username: string;
  password: string;
}

const MediaError: Record<number, string> = {
  1: "MEDIA_ERR_ABORTED",
  2: "MEDIA_ERR_NETWORK",
  3: "MEDIA_ERR_DECODE",
  4: "MEDIA_ERR_SRC_NOT_SUPPORTED",
};

const StreamChannelListItem: React.FC<StreamChannelListItemProps> = ({
  channel,
}) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [customCredentials, setCustomCredentials] = React.useState<Credentials>(
    { username: "", password: "" }
  );

  const [playerKey, setPlayerKey] = React.useState(0);
  const [error, setError] = React.useState<PlayerError | undefined>();

  const [useCustomCredentials, setUseCustomCredentials] = React.useState(false);

  const triggeerReload = () => {
    setPlayerKey(playerKey + 1);
    setError(undefined);
  };

  const toggleUseCustomCredentials = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setUseCustomCredentials(checked);
    triggeerReload();
  };
  const printError = (error: PlayerError) => {
    return (
      <Table
        size="small"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
            py: 0,
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell>Error</TableCell>
            <TableCell>{error?.error}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>{error?.type}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Details</TableCell>
            <TableCell>{error?.details}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Response code</TableCell>
            <TableCell>{error?.response?.code}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Response text</TableCell>
            <TableCell>{error?.response?.text}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  return (
    <Card sx={{ my: 2 }}>
      <StreamChannelForm channel={channel} onClose={() => {}} />
      <Box sx={{ p: 2 }}>
        <ReactPlayer
          style={{ backgroundColor: "#000" }}
          key={playerKey}
          config={{
            file: {
              //forceHLS: true,
              hlsOptions: {
                withCredentials: true,
                autoStartLoad: true,
                xhrSetup: function (xhr: any, url: string) {
                  if (url.match(NENDA_API_URL_REGEX)) {
                    xhr.setRequestHeader(
                      "Authorization",
                      "Basic " +
                        Buffer.from(
                          `${customCredentials.username}:${customCredentials.password}`
                        ).toString("base64")
                    );
                  }
                },
              },
            },
          }}
          playing={isPlaying}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          controls
          url={useCustomCredentials ? channel.streamLinks.HLS : ""}
          onError={(error, data, hlsInstance) => {
            if (data) {
              // HLS error
              setError({
                error: error.toString(),
                ...(data || {}),
              });
              // If we fail to get the key (e.g. due to wrong credentials)
              // It will retry forever, so we destroy the player to avoid
              // "DDOSing" ourselves.
              // This might be needed for other errors as well
              if (data.fatal || data.details === "keyLoadError") {
                hlsInstance?.destroy();
              }
            } else if (error.target?.error) {
              // Player event error
              setError({
                error: error.target?.error?.message,
                type: error.target?.error[Symbol.toStringTag],
                details: MediaError[error.target?.error?.code],
              });
            }
            setIsPlaying(false);
          }}
        />

        {error && <Alert severity="error">{printError(error)}</Alert>}

        <Typography sx={{ my: 1 }} variant="h6">
          Use custom credentials
        </Typography>
        <Stack direction={"row"} gap={1}>
          <TextField
            label="Username"
            disabled={useCustomCredentials}
            value={customCredentials.username}
            onChange={(e) => {
              setCustomCredentials({
                ...customCredentials,
                username: e.target.value,
              });
            }}
          />
          <TextField
            label="Password"
            disabled={useCustomCredentials}
            value={customCredentials.password}
            onChange={(e) => {
              setCustomCredentials({
                ...customCredentials,
                password: e.target.value,
              });
            }}
          />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={toggleUseCustomCredentials} />}
              label="Use custom credentials"
            />
          </FormGroup>
        </Stack>
      </Box>
    </Card>
  );
};

export default StreamChannelListItem;
