import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoginIcon from "@mui/icons-material/Login";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomerPortalState, store } from "../CustomerPortal/store";
import {
  Login,
  selectLoggingInStatus,
} from "../CustomerPortal/store/reducers/authReducer";
import TextInput from "../CustomerPortal/ui-components/input/TextInput";

const LoginForm = () => {
  const theme = useTheme();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    store.dispatch(Login(email, password));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const loginActionStatus = useSelector(selectLoggingInStatus);
  const errMessage = useSelector(
    (state: CustomerPortalState) => state.auth.errors.login?.message
  );
  return (
    <Box p={2}>
      <Box sx={{ my: "1rem" }}>
        <Typography
          variant="h6"
          align="center"
          color={theme.palette.text.secondary}
        >
          {t("customerportal.pages.login.description")}
        </Typography>
      </Box>
      <FormControl
        sx={{ display: "flex", gap: 2 }}
        component="form"
        onSubmit={handleSubmit}
      >
        <TextInput
          placeholder="Your email"
          variant={"outlined"}
          label={t("common.email")}
          fullWidth
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextInput
          placeholder="Your password"
          fullWidth
          variant={"outlined"}
          id="password"
          name="password"
          label={t("common.password")}
          type={showPassword ? "text" : "password"}
          sx={{ position: "relative" }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ position: "absolute", right: "1rem" }}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          type="submit"
          loading={loginActionStatus === "loading"}
          variant="contained"
          fullWidth
          endIcon={<LoginIcon />}
        >
          {t("common.login")}
        </LoadingButton>
      </FormControl>
      {errMessage && (
        <Box
          sx={{
            border: "1px solid",
            borderColor: theme.palette.error.main,
            borderRadius: "0.5rem",
            background: theme.palette.error.contrastText,
            my: "0.5rem",
          }}
        >
          <Typography
            align="center"
            variant="body1"
            sx={{
              color: theme.palette.error.main,
              fontSize: "0.8rem",
              my: "0.5rem",
            }}
          >
            {errMessage}
          </Typography>
        </Box>
      )}
      <Button
        variant="text"
        onClick={() => navigate("/forgotpassword")}
        fullWidth
        sx={{
          color: theme.palette.common.white,
          borderColor: theme.palette.common.white,
          fontSize: "1rem",
        }}
      >
        <Typography
          align="center"
          variant="body1"
          sx={{
            color: theme.palette.text.secondary,
            fontSize: "0.8rem",
            my: "0.5rem",
          }}
        >
          {t("customerportal.pages.login.forgot_password")}
        </Typography>
      </Button>
    </Box>
  );
};

export default LoginForm;
