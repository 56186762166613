import { Box } from "@mui/material";

const StripedOverlay = () => {
  return (
    <Box
      sx={{
        background:
          "repeating-linear-gradient(-55deg,rgba(0,0,0,0.1), rgba(0,0,0,0.1) 10px, rgba(0,0,0,0.2) 10px,rgba(0,0,0,0.2) 20px)",
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    ></Box>
  );
};

export default StripedOverlay;
