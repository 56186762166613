import { Star } from "@mui/icons-material";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { t } from "i18next";
import { ISignagePromotion } from "../../../../../../../types/NendaTypes";
import PromotionsHeatMap from "./PromotionsHeatMap";

const PromotionOverviewListItem = ({
  promotion,
  blockedRangeDimensions,
}: {
  promotion: ISignagePromotion;
  blockedRangeDimensions?: { left: number; width: number }[];
}) => {
  return (
    <Grid container columns={20} alignItems={"center"} key={promotion._id}>
      <Grid item xs={5}>
        <Box sx={{ height: "2rem", pl: "1rem" }}>
          <Typography variant="body1" fontSize={"0.7rem"}>
            {promotion.name}
            {promotion.isHighPriority && (
              <Tooltip title={t("customerportal.pages.dashboard.high_prio")}>
                <Star sx={{ height: "0.8rem" }} />
              </Tooltip>
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={14}>
        <Box
          sx={{
            height: "2rem",
            position: "relative",
            px: "0.5rem",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              px: "0.5rem",
            }}
          >
            {[
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ].map((hr) => (
              <Box
                key={hr}
                sx={{
                  display: "flex",
                  flex: 1,
                  borderLeft: "1px solid rgba(0,0,0,0.05)",
                }}
              />
            ))}
          </Box>
          <PromotionsHeatMap
            promotions={[promotion]}
            flat
            tooltip
            blockedRangeDimensions={blockedRangeDimensions}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default PromotionOverviewListItem;
