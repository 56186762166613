import React, { useState, useEffect } from "react";
import { StringArrayEdit } from "../../components/StringArrayEdit/StringArrayEdit";
import { useSettings } from "../../hooks/useSettings";
import ISO6391 from "iso-639-1";
import { configService } from "../../http/config.service";

const allLanguages = ISO6391.getLanguages(ISO6391.getAllCodes()).map((l) => ({
  name: l.name,
  value: l.code,
}));

export function UpdateSettingsForm() {
  const settings = useSettings();
  const [modifiedState, setModifiedState] = useState({} as any);
  useEffect(() => {
    if (settings) {
      setModifiedState(settings);
    }
  }, [settings]);

  function onLanguagesChange(val) {
    setModifiedState({
      ...modifiedState,
      languages: val.map((item) => allLanguages.find((l) => item === l.value)),
    });
  }

  function onSubmit() {
    const { languages } = modifiedState;
    configService
      .updateSettings(modifiedState._id, {
        languages: languages.map((l) => l.value),
      })
      .toPromise()
      .then(() => console.log("updated settings"));
  }

  if (!modifiedState) return null;
  return (
    <div className="my-2">
      <div className="my-2">
        <h3>Languages</h3>
        <StringArrayEdit
          options={allLanguages}
          values={modifiedState.languages}
          onChange={onLanguagesChange}
        />
      </div>
      <button
        disabled={modifiedState == settings}
        onClick={onSubmit}
        className="btn btn-secondary"
      >
        Save
      </button>
    </div>
  );
}
