import { useEffect } from "react";
import ScreenList from "../Screen/ScreenList";
import { CustomerPortalState, store } from "../../../store";
import {
  getScreens,
  selectScreensForPremise,
} from "../../../store/reducers/screenReducer";
import { useSelector } from "react-redux";

const EditScreens = ({ premise }) => {
  const screens = useSelector((state: CustomerPortalState) => {
    return selectScreensForPremise(state, premise?._id as string);
  });

  useEffect(() => {
    if (premise?._id) {
      store.dispatch(getScreens(premise?._id));
    }
  }, [JSON.stringify(premise?._id)]);
  //TODO: Add state to formState to have a single source of truth and only ONE save button
  return <ScreenList screens={screens} />;
};

export default EditScreens;
