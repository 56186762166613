import {
  AppState,
  NotificationsActions,
  NOTIFICATION_ACTIONS,
  SetNotificationAction,
} from "../types/redux";

export interface INotification {
  message?: string;
  bgClass: "bg-danger" | string | undefined;
  severity?: "error" | "warning" | "info" | "success" | undefined;
}

export function SetNotification(data?: INotification): SetNotificationAction {
  return {
    type: NOTIFICATION_ACTIONS.SET_NOTIFICATION,
    notification: !data
      ? undefined
      : {
          message: data.message,
          bgClass: data.bgClass || "bg-danger",
        },
  };
}

export default function notificationReducer(
  state: AppState["notification"] = {},
  action: NotificationsActions
): AppState["notification"] {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.SET_NOTIFICATION:
      return {
        ...state,
        currentNotification: action.notification,
      };
    default:
      return state;
  }
}
