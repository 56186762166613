import {
  ResourcePermissions,
  Restriction,
  Role,
} from "../../../../../types/NendaTypes";

export function checkPermission(
  role: Role | undefined,
  restriction: Restriction,
  permissions?: ResourcePermissions
): boolean {
  if (!permissions || !role) {
    return false;
  }
  return (
    role &&
    restriction.scopes.every((scope) =>
      permissions[restriction.resource]?.[role]?.includes(scope) ? true : false
    )
  );
}
