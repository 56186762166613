import { Route, Routes } from "react-router-dom";
import { CompanyType } from "../../../types/NendaTypes";
import Ads from "./Pages/Ads";
import AssetsPage from "./Pages/Assets";
import Channels from "./Pages/Channels";
import Companies from "./Pages/Companies";
import ContentChannel from "./Pages/ContentChannel/ContentChannel";
import ContentChannels from "./Pages/ContentChannel/ContentChannels";
import DailyPlaylists from "./Pages/DailyPlaylists";
import Dashboard from "./Pages/Dashboard";
import Premises from "./Pages/Premises";
import Profile from "./Pages/Profile";
import Promotions from "./Pages/Promotions";
import StreamChannel from "./Pages/StreamChannel/StreamChannel";
import StreamChannels from "./Pages/StreamChannel/StreamChannels";
import Users from "./Pages/Users";
import WeeklyPlanner from "./Pages/WeeklyPlanner";
import Contact from "./components/Dashboard/Contact";
import FrontlayerPage from "./Pages/Frontlayer";
import UnitPage from "./Pages/UnitPage";
import UnitListPage from "./Pages/UnitListPage";

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="dashboard">
        <Route path=":type/:id" element={<Dashboard />} />
      </Route>
      <Route path="/premise/:premiseId/units" element={<UnitListPage />} />
      <Route path="/premise/:premiseId/unit/:unitId" element={<UnitPage />} />
      <Route path="/premises/company/:id" element={<Premises />} />
      <Route path="/frontlayer/premise/:id" element={<FrontlayerPage />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="admin">
        <Route path="companies" element={<Companies />}>
          {Object.values(CompanyType).map((type) => (
            <Route key={type} path={type} element={<Companies />} />
          ))}
        </Route>
        <Route path="premiselist" element={<Premises />} />
        <Route path="content" element={<ContentChannels />} />
        <Route path="content/:channelId" element={<ContentChannel />} />
        <Route path="ads" element={<Ads />} />
        <Route path="signaldelivery/channel" element={<StreamChannels />} />
      </Route>
      <Route path="channel-manager">
        <Route path="channels/:type/:id" element={<Channels />} />
        <Route path="week-planner/:type/:id" element={<WeeklyPlanner />} />
        <Route path="daily-playlists/:type/:id" element={<DailyPlaylists />} />
      </Route>
      <Route path="/users" element={<Users />} />
      <Route path="promotions">
        <Route path="overview">
          <Route path=":type/:id" element={<Promotions />} />
        </Route>
        <Route path="media-library">
          <Route path=":type/:id" element={<AssetsPage />} />
        </Route>
      </Route>
      <Route path="/signaldelivery/channel/:id" element={<StreamChannel />} />
      <Route path="contact" element={<Contact />}>
        <Route path=":type/:id" element={<Contact />} />
      </Route>
    </Routes>
  );
};

export default Main;
