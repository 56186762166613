import {
  BrandingWatermarkOutlined,
  DateRangeOutlined,
  LiveTvOutlined,
  PermMediaOutlined,
  PlaylistPlayOutlined,
  PresentToAllOutlined,
  QueuePlayNextOutlined,
  VideoSettings,
  TvOutlined,
} from "@mui/icons-material";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import { useSelector } from "react-redux";
import {
  NavigationLevel,
  NendaProduct,
  Resource,
  Restriction,
  Role,
  Scope,
} from "../../../../types/NendaTypes";
import i18next from "../i18n";
import { selectUserRole } from "../store/reducers/authReducer";
import { selectNavigationScope } from "../store/reducers/workspaceReducer";
import { capitalize } from "@mui/material";

export type MenuItem = {
  id: string;
  title: string;
  caption?: string;
  type: string;
  children?: MenuItem[];
  url?: string;
  icon?: any;
  chip?: any;
  breadcrumbs?: boolean;
  target?: boolean;
  external?: boolean;
  disabled?: boolean;
  restriction?: Restriction;
  products?: NendaProduct[];
  level?: NavigationLevel[];
};

export const dynamicMenu = () => {
  const scope = useSelector(selectNavigationScope);
  const role = useSelector(selectUserRole);
  const isGlobalAdmin = [Role.Admin, Role.Superuser].includes(role);
  const companyId = scope.company;
  const premiseId = scope.premise;

  const adminMenu: MenuItem = {
    id: "admin",
    title: "Menu",
    type: "group",
    children: [
      {
        id: "premiselist",
        title: i18next.t("customerportal.sidemenu.premises"),
        type: "item",
        url: `admin/premiselist`,
        icon: AddBusinessOutlinedIcon,
        restriction: {
          resource: Resource.Company,
          scopes: [Scope.CanAdministrate],
        },
        breadcrumbs: false,
      },
      {
        id: "companies",
        title: i18next.t("customerportal.sidemenu.companies"),
        type: "item",
        url: "admin/companies",
        icon: BusinessOutlinedIcon,
        breadcrumbs: false,
        restriction: {
          resource: Resource.Company,
          scopes: [Scope.CanAdministrate],
        },
      },
      {
        id: "content",
        title: i18next.t("customerportal.sidemenu.effect_content"),
        type: "item",
        url: "admin/content",
        icon: OndemandVideoOutlinedIcon,
        breadcrumbs: false,
        restriction: {
          resource: Resource.Ads,
          scopes: [Scope.CanAdministrate],
        },
      },
      {
        id: "ads",
        title: i18next.t("customerportal.sidemenu.effect_advertisement"),
        type: "item",
        url: "admin/ads",
        icon: CurrencyExchangeOutlinedIcon,
        breadcrumbs: false,
        restriction: {
          resource: Resource.Ads,
          scopes: [Scope.CanAdministrate],
        },
      },
      {
        id: "signal-delivery",
        title: i18next.t("customerportal.sidemenu.signal_delivery"),
        type: "item",
        url: "admin/signaldelivery/channel",
        icon: OndemandVideoOutlinedIcon,
        breadcrumbs: false,
        restriction: {
          resource: Resource.StreamChannel,
          scopes: [Scope.CanAdministrate],
        },
      },
      {
        id: "users",
        title: i18next.t("customerportal.sidemenu.users"),
        type: "item",
        url: `/users`,
        icon: GroupOutlinedIcon,
        breadcrumbs: false,
        restriction: {
          resource: Resource.User,
          scopes: [Scope.CanAdministrate],
        },
      },
    ],
  };

  const mainMenu: MenuItem = {
    id: "main",
    title: "Menu",
    type: "group",
    children: [
      {
        id: "dashboard",
        title: i18next.t("customerportal.sidemenu.dashboard"),
        type: "item",
        url: premiseId
          ? `/dashboard/premise/${premiseId}`
          : companyId
          ? `/dashboard/company/${companyId}`
          : "/",
        icon: DashboardOutlinedIcon,
        products: [NendaProduct.SIGNAGE],
        level: [NavigationLevel.PREMISE, NavigationLevel.COMPANY],
        breadcrumbs: false,
      },
      {
        id: "units",
        title: capitalize(i18next.t("glossary.unit", { count: 2 })),
        type: "item",
        url: `/premise/${premiseId}/units`,
        icon: TvOutlined,
        level: [NavigationLevel.PREMISE],
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanAdministrate],
        },
      },
      {
        id: "frontlayer",
        title: i18next.t("Frontlayer Settings"),
        type: "item",
        url: `/frontlayer/premise/${premiseId}`,
        products: [NendaProduct.FRONTLAYER],
        level: [NavigationLevel.PREMISE],
        icon: BrandingWatermarkOutlined,
        breadcrumbs: false,
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanEdit],
        },
      },
      {
        id: "channel-manager",
        title: i18next.t("customerportal.sidemenu.channel_manager"),
        type: "collapse",
        url: `/channel-manager/channels/premise/${premiseId}`,
        icon: VideoSettings,
        products: [NendaProduct.SIGNAGE],
        level: [NavigationLevel.PREMISE],
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanView],
        },
        children: [
          {
            id: "channels",
            title: i18next.t("customerportal.sidemenu.channels"),
            type: "item",
            icon: LiveTvOutlined,
            url: `/channel-manager/channels/premise/${premiseId}`,
            restriction: {
              resource: Resource.Premise,
              scopes: [Scope.CanView],
            },
            breadcrumbs: false,
          },
          {
            id: "week-planner",
            title: i18next.t("customerportal.sidemenu.week_planner"),
            type: "item",
            url: `/channel-manager/week-planner/premise/${premiseId}`,
            icon: DateRangeOutlined,
            restriction: {
              resource: Resource.Premise,
              scopes: [Scope.CanView],
            },
            breadcrumbs: false,
          },
          {
            id: "daily-playlists",
            title: i18next.t("customerportal.sidemenu.daily_playlists"),
            type: "item",
            url: `/channel-manager/daily-playlists/premise/${premiseId}`,
            icon: PlaylistPlayOutlined,
            restriction: {
              resource: Resource.Premise,
              scopes: [Scope.CanView],
            },
            breadcrumbs: false,
          },
        ],
      },
      {
        id: "premises",
        title: i18next.t("customerportal.sidemenu.premises"),
        type: "item",
        level: [NavigationLevel.COMPANY],
        url: premiseId
          ? `/premises/premise/${premiseId}`
          : `/premises/company/${companyId}`,
        icon: AddBusinessOutlinedIcon,
        restriction: {
          resource: Resource.Company,
          scopes: [Scope.CanEdit],
        },
        breadcrumbs: false,
      },
      {
        id: "promotions",
        title: i18next.t("customerportal.sidemenu.promotions"),
        type: "collapse",
        url: `/promotions/overview/${
          premiseId ? "premise/" + premiseId : "company/" + companyId
        }`,
        icon: QueuePlayNextOutlined,
        restriction: {
          resource: Resource.SignagePromotion,
          scopes: [Scope.CanView],
        },
        products: [NendaProduct.SIGNAGE],
        level: [NavigationLevel.PREMISE, NavigationLevel.COMPANY],
        children: [
          {
            id: "promotions/overview",
            title: i18next.t("customerportal.sidemenu.overview"),
            type: "item",
            icon: PresentToAllOutlined,
            url: `/promotions/overview/${
              premiseId ? "premise/" + premiseId : "company/" + companyId
            }`,
            restriction: {
              resource: Resource.SignagePromotion,
              scopes: [Scope.CanView],
            },
            breadcrumbs: false,
          },
          {
            id: "media-library",
            title: i18next.t("customerportal.sidemenu.assets"),
            type: "item",
            icon: PermMediaOutlined,
            url: `/promotions/media-library/${
              premiseId ? "premise/" + premiseId : "company/" + companyId
            }`,
            restriction: { resource: Resource.Asset, scopes: [Scope.CanView] },
            breadcrumbs: false,
          },
        ],
      },
    ].filter(Boolean),
  };

  return isGlobalAdmin && !companyId && !premiseId ? [adminMenu] : [mainMenu];
};
