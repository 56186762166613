import { Select, MenuItem } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { NendaProduct } from "../../../types/NendaTypes";
import { CreateRooms } from "../../redux/organizationUnit.redux";
import { store } from "../../redux/store";
import { AppState } from "../../types/redux";
import { Modal } from "../Modal/Modal";

function range(size: number, startAt = 0) {
  return [...Array(size).keys()].map((i) => i + startAt);
}

interface ICreateRoomButtonProps {
  hotelId: string;
  hotelChainId: string;
  roomType: string;
}

export const CreateRoomButton: React.FC<ICreateRoomButtonProps> = ({
  hotelId,
  hotelChainId,
  roomType,
}) => {
  const hotelChain = useSelector((state: AppState) =>
    state.organizationUnits.organizationUnits.find(
      ({ _id }) => _id === hotelChainId
    )
  );
  const premise = useSelector((state: AppState) =>
    state.organizationUnits.organizationUnits.find(({ _id }) => _id === hotelId)
  );

  const [showForm, setShowForm] = React.useState(false);
  const [state, setState] = React.useState({
    from: 0,
    to: 0,
    hotelId,
    hotelChainId,
    roomType,
    status: "active",
    nendaProducts: premise?.nendaProducts || [],
  });

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    let updatedProducts = [...state.nendaProducts];
    if (checked) {
      updatedProducts.push(name as NendaProduct);
    } else {
      updatedProducts = updatedProducts.filter((x) => x != name);
    }
    setState({ ...state, nendaProducts: [...new Set(updatedProducts)] });
  };

  function submitRoom() {
    const payload = {
      roomNumbers: range(state.to - state.from + 1, state.from),
      hotelChainId: state.hotelChainId,
      hotelId: state.hotelId,
      roomType: state.roomType,
      status: state.status,
      nendaProducts: state.nendaProducts,
    };
    store.dispatch(CreateRooms(payload));
  }

  return (
    <>
      <button
        onClick={() => setShowForm(true)}
        type="button"
        className="btn btn-secondary"
      >
        Add rooms
      </button>
      <Modal
        visible={showForm}
        onClose={() => setShowForm(false)}
        title={"Add rooms"}
        onSubmit={submitRoom}
      >
        <>
          <p>
            Select a range of room number to create. If you want to create a
            single room, set both from and to to the same value
          </p>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">From</span>
            </div>
            <input
              onChange={(e) =>
                setState({ ...state, from: parseInt(e.target.value) })
              }
              name="name"
              type="number"
              min={0}
              className="form-control"
            />
          </div>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">To</span>
            </div>
            <input
              onChange={(e) =>
                setState({ ...state, to: parseInt(e.target.value) })
              }
              name="name"
              type="number"
              min={state.from}
              className="form-control"
            />
          </div>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">Room type</span>
            </div>
            <Select
              onChange={(e) => setState({ ...state, roomType: e.target.value })}
              value={state.roomType}
              className="form-control"
            >
              {!roomType && <option value=""></option>}
              {hotelChain?.roomTypes.map((roomType) => (
                <MenuItem key={roomType._id} value={roomType._id}>
                  {roomType.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">Status</span>
            </div>
            <Select
              onChange={(e) => setState({ ...state, status: e.target.value })}
              value={state.status}
              className="form-control"
            >
              <MenuItem value={"inactive"}>Inactive</MenuItem>
              <MenuItem value={"active"}>Active</MenuItem>
            </Select>
          </div>
          <div>
            {premise?.nendaProducts.includes(NendaProduct.STREAMING_APP) && (
              <div className="custom-control custom-switch">
                <input
                  name={NendaProduct.STREAMING_APP}
                  id={NendaProduct.STREAMING_APP}
                  type="checkbox"
                  className="custom-control-input"
                  checked={state.nendaProducts.includes(
                    NendaProduct.STREAMING_APP
                  )}
                  onChange={handleProductChange}
                />
                <label
                  htmlFor={NendaProduct.STREAMING_APP}
                  className={`custom-control-label ${NendaProduct.STREAMING_APP}-label`}
                >
                  Streaming
                </label>
              </div>
            )}
            {premise?.nendaProducts.includes(NendaProduct.FRONTLAYER) && (
              <div className="custom-control custom-switch">
                <input
                  name={NendaProduct.FRONTLAYER}
                  id={NendaProduct.FRONTLAYER}
                  type="checkbox"
                  className="custom-control-input"
                  checked={state.nendaProducts.includes(
                    NendaProduct.FRONTLAYER
                  )}
                  onChange={handleProductChange}
                />
                <label
                  htmlFor={NendaProduct.FRONTLAYER}
                  className={`custom-control-label ${NendaProduct.FRONTLAYER}-label`}
                >
                  Frontlayer
                </label>
              </div>
            )}
            {premise?.nendaProducts.includes(NendaProduct.SIGNAGE) && (
              <div className="custom-control custom-switch">
                <input
                  name={NendaProduct.SIGNAGE}
                  id={NendaProduct.SIGNAGE}
                  type="checkbox"
                  className="custom-control-input"
                  checked={state.nendaProducts.includes(NendaProduct.SIGNAGE)}
                  onChange={handleProductChange}
                />
                <label
                  htmlFor={NendaProduct.SIGNAGE}
                  className={`custom-control-label ${NendaProduct.SIGNAGE}-label`}
                >
                  Signage
                </label>
              </div>
            )}
            {premise?.nendaProducts.includes(NendaProduct.SIGNAL_DELIVERY) && (
              <div className="custom-control custom-switch">
                <input
                  name={NendaProduct.SIGNAL_DELIVERY}
                  id={NendaProduct.SIGNAL_DELIVERY}
                  type="checkbox"
                  className="custom-control-input"
                  checked={state.nendaProducts.includes(
                    NendaProduct.SIGNAL_DELIVERY
                  )}
                  onChange={handleProductChange}
                />
                <label
                  htmlFor={NendaProduct.SIGNAL_DELIVERY}
                  className={`custom-control-label ${NendaProduct.SIGNAL_DELIVERY}-label`}
                >
                  Signal delivery
                </label>
              </div>
            )}
          </div>
        </>
      </Modal>
    </>
  );
};
