import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { SectionWrapper } from "../../components/UI/SectionWrapper/SectionWrapper";
import { reportService } from "../../http/report.service";
import { AppState } from "../../types/redux";
import { capitalizeString } from "../../utils/string";
import { IReport } from "../../../types/NendaTypes";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const dateToString = (date) => date.toLocaleDateString("sv");

export const ReportPage: React.FC = () => {
  const params = useParams();
  const { hotelChainId, id: hotelId } = params;

  const hotels: Array<string> = useSelector((state: AppState) => {
    if (hotelId) {
      return [hotelId];
    } else if (hotelChainId) {
      return state.organizationUnits.organizationUnits
        .filter((hotel) => hotel.parent === hotelChainId)
        .map(({ _id }) => _id);
    } else {
      return [];
    }
  });

  const today = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(today.getFullYear(), today.getMonth() - 1, 1, 0)
  );
  const [toDate, setToDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1, 0)
  );
  const [report, setReport] = useState<{
    rooms: IReport["rooms"];
    subscriptions: IReport["subscriptions"];
    packages: IReport["packages"];
    content: IReport["content"];
    transactions: IReport["transactions"];
  }>({
    rooms: [],
    subscriptions: [],
    packages: [],
    content: [],
    transactions: [],
  });
  const [loaded, setLoaded] = useState(false);
  const loadReport = () => {
    if (!hotelChainId) {
      setLoaded(false);
      reportService
        .postReportAll(fromDate.toISOString(), toDate.toISOString())
        .toPromise()
        .then((response) => {
          setReport(response);
          setLoaded(true);
        });
      return;
    }
    if (!hotels.length) {
      return;
    }
    setLoaded(false);
    reportService
      .postReport(hotels, fromDate.toISOString(), toDate.toISOString())
      .toPromise()
      .then((response) => {
        setReport(response);
        setLoaded(true);
      });
  };

  useEffect(() => {
    loadReport();
  }, []);

  const exportToCSV = () => {
    for (const key of Object.keys(report)) {
      if (!report[key] || !report[key].length) {
        continue;
      }
      const items = report[key];
      const replacer = (_, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv =
        [
          header.join(","), // header row first
          ...items.map((row) =>
            header
              .map((fieldName) => JSON.stringify(row[fieldName], replacer))
              .join(",")
          ),
        ].join("\r\n") || "";
      // Download file
      const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csv);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      const filename = `${key}_${fromDate.toISOString().split("T")[0]}.${
        toDate.toISOString().split("T")[0]
      }.csv`;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <>
      <PageHeader>
        <div className="d-flex">
          <h4>Report</h4>
          <button
            disabled={!loaded}
            className="btn btn-secondary mt-auto mx-auto mb-0"
            onClick={() => exportToCSV()}
          >
            Export to CSV
          </button>
        </div>
      </PageHeader>
      <SectionWrapper>
        <h4>Time period</h4>
        <div className="d-flex align-content-center">
          <div className="col-4">
            <label htmlFor="from-input">From</label>
            <input
              type="date"
              id="from-input"
              className="form-control"
              value={dateToString(fromDate)}
              onChange={({ target: { value } }) => setFromDate(new Date(value))}
            />
          </div>
          <div className="col-4">
            <label htmlFor="to-input">To</label>
            <input
              type="date"
              id="to-input"
              className="form-control"
              value={dateToString(toDate)}
              onChange={({ target: { value } }) => setToDate(new Date(value))}
            />
          </div>
          <div className="col-4 d-flex">
            <button
              className="btn btn-secondary mt-auto mx-auto mb-0"
              onClick={() => loadReport()}
            >
              Load report
            </button>
          </div>
        </div>
      </SectionWrapper>
      {loaded ? (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="accordion-summary-gray"
            >
              Rooms
            </AccordionSummary>
            <AccordionDetails>
              {(report.rooms || []).length > 0 ? (
                <SectionWrapper>
                  <h4>Rooms</h4>
                  <table className="table">
                    <caption>
                      * An active room is a room that has been activated for
                      more than 50% of the selected time period
                    </caption>
                    <caption>
                      ** Time between dates is calculated in seconds
                    </caption>
                    <thead>
                      <tr>
                        <th>Chain</th>
                        <th>Premise</th>
                        <th>Category</th>
                        <th>Active room count</th>
                        <th>Inactive room count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(report.rooms || []).map(
                        (
                          {
                            activeRoomCount,
                            chain,
                            premise,
                            roomCategory,
                            roomCount,
                          },
                          index
                        ) => (
                          <tr key={index}>
                            <td>{chain}</td>
                            <td>{premise}</td>
                            <td>
                              {capitalizeString(roomCategory).replace("_", " ")}
                            </td>
                            <td>{activeRoomCount}</td>
                            <td>{roomCount - activeRoomCount}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </SectionWrapper>
              ) : (
                <h5>No data</h5>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="accordion-summary-gray"
            >
              Subscriptions
            </AccordionSummary>
            <AccordionDetails>
              {(report.subscriptions || []).length > 0 ? (
                <SectionWrapper>
                  <h4>Subscriptions</h4>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Chain</th>
                        <th>Premise</th>
                        <th>Package</th>
                        <th>Active room count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(report.subscriptions || []).map(
                        (
                          { activeRoomCount, chain, premise, packageName },
                          index
                        ) => (
                          <tr key={index}>
                            <td>{chain}</td>
                            <td>{premise}</td>
                            <td>{packageName}</td>
                            <td>{activeRoomCount}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </SectionWrapper>
              ) : (
                <h5>No data</h5>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="accordion-summary-gray"
            >
              Packages
            </AccordionSummary>
            <AccordionDetails>
              {(report.packages || []).length > 0 ? (
                <SectionWrapper>
                  <h4>Packages</h4>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Chain</th>
                        <th>Package</th>
                        <th>Assets</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(report.packages || []).map(
                        ({ assetNames, chain, packageName }, index) => (
                          <tr key={index}>
                            <td>{chain}</td>
                            <td>{packageName}</td>
                            <td>{assetNames}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </SectionWrapper>
              ) : (
                <h5>No data</h5>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="accordion-summary-gray"
            >
              Content
            </AccordionSummary>
            <AccordionDetails>
              {(report.content || []).length > 0 ? (
                <SectionWrapper>
                  <h4>Content</h4>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Provider</th>
                        <th>Content</th>
                        <th>Chain</th>
                        <th>Rooms</th>
                        <th>CA</th>
                        <th>Public</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(report.content || []).map(
                        (
                          {
                            provider,
                            content,
                            chain,
                            rooms,
                            common_area,
                            publicArea,
                          },
                          index
                        ) => (
                          <tr key={index}>
                            <td>{provider}</td>
                            <td>{content}</td>
                            <td>{chain}</td>
                            <td>{rooms}</td>
                            <td>{common_area}</td>
                            <td>{publicArea}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </SectionWrapper>
              ) : (
                <h5>No data</h5>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="accordion-summary-gray"
            >
              Transactions
            </AccordionSummary>
            <AccordionDetails>
              {(report.transactions || []).length > 0 ? (
                <SectionWrapper>
                  <h4>Transactions</h4>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Chain</th>
                        <th>Premise</th>
                        <th>Room</th>
                        <th>Timestamp</th>
                        <th>Type</th>
                        <th>Product offering</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(report.transactions || []).map(
                        (
                          { chain, date, asset, premise, price, room, type },
                          index
                        ) => (
                          <tr key={index}>
                            <td>{chain}</td>
                            <td>{premise}</td>
                            <td>{room}</td>
                            <td>{new Date(date).toLocaleString()}</td>
                            <td>{type}</td>
                            <td>{asset}</td>
                            <td>{price}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </SectionWrapper>
              ) : (
                <h5>No data</h5>
              )}
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};
