import React from "react";
import { Route, Routes } from "react-router-dom";
import { TemplatesComponent } from "../../components/Templates/TemplatesComponent";
import { store } from "../../redux/store";
import { GetTemplates } from "../../redux/templates.redux";
import { PageWrapper } from "../PageWrapper";
import { TemplatesOverview } from "./TemplatesOverview";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";

export const TemplatesPage: React.FC = () => {
  React.useEffect(() => {
    store.dispatch(GetTemplates());
  }, []);
  return (
    <PageWrapper>
      <Routes>
        <Route
          path={"/*"}
          element={<ProtectedRoute childRouteElement={<TemplatesOverview />} />}
        />
        <Route path={"/:id"} element={<TemplatesComponent />} />
      </Routes>
    </PageWrapper>
  );
};
