import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ProtectedRoute } from "../ProtectedRoute/ProtectedRoute";

import { useSelector } from "react-redux";
import { GetCarousels } from "../../redux/carousels.redux";
import { GetConfigs, GetCountries } from "../../redux/config.redux";
import { GetOrganizationUnits } from "../../redux/organizationUnit.redux";
import { store } from "../../redux/store";
import { GetSubPages } from "../../redux/subPages.redux";
import { routes } from "../../routes";
import { AppState } from "../../types/redux";
import { SideMenu } from "../SideMenu/SideMenu";
import { getSessionUsername, getUserSession } from "../../redux/auth.redux";

const Home: React.FC = () => {
  const location = useLocation();
  const sessionUser = useSelector(getSessionUsername);

  React.useEffect(() => {
    store.dispatch(GetCarousels(""));
    store.dispatch(GetOrganizationUnits());
    store.dispatch(GetCountries());
    store.dispatch(GetSubPages());
    store.dispatch(GetConfigs());
  }, []);
  const carousels = useSelector(
    (state: AppState) => state.carousels?.carousels
  );
  const carouselsSubMenu = carousels
    ?.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
    .map((c) => {
      return {
        text: c.name || "Unnamed carousel",
        path: `/components/carousels/carousel/${c._id}`,
      };
    });
  const organizationUnits = useSelector(
    (state: AppState) => state?.organizationUnits?.organizationUnits
  );
  const hotelsSubMenu = organizationUnits
    ?.filter((ou) => ou.type === "hotelChain")
    .map((hotelChainOu) => {
      const subMenu = organizationUnits
        .filter(
          (hotelLevelOu) =>
            hotelLevelOu.type === "hotel" &&
            hotelLevelOu.parent === hotelChainOu._id
        )
        .map((hotel) => {
          return {
            text: hotel.name,
            path: `/organizationunits/hotelchain/${hotelChainOu._id}/hotel/${hotel._id}`,
            invalid: hotel.isInvalid(),
            hasNoChildren: hotel.numberOfRooms === 0,
          };
        });
      return {
        text: hotelChainOu.name,
        path: `/organizationunits/hotelchain/${hotelChainOu._id}`,
        invalid: hotelChainOu.isInvalid(),
        hasInvalidChild: subMenu.some((h) => !!h.invalid),
        subMenu: subMenu,
      };
    });
  const subPages = useSelector((state: AppState) => state.subPages?.subPages);
  const subPagesSubMenu = subPages?.map((p) => {
    return {
      text: (
        <>
          {p.name}{" "}
          {p.draft ? (
            <span className="draftlabel text-dark bg-warning rounded py-1 px-2 ml-2">
              {p.draft && "   Draft"}
            </span>
          ) : null}
        </>
      ),
      path: `/pages/${p._id}`,
    };
  });
  const menuItems = [
    {
      text: "Premises",
      path: "/organizationunits",
      subMenu: hotelsSubMenu,
    },
    {
      text: "Carousels",
      path: "/components/carousels",
      subMenu: carouselsSubMenu,
    },
    {
      text: "Pages",
      path: "/pages",
      subMenu: subPagesSubMenu,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <SideMenu
          menuItems={menuItems}
          currentLocation={location}
          sessionUser={sessionUser}
        />

        <div className="col-md-9">
          <Routes>
            {routes.map((r) => {
              return (
                <Route
                  path={r.path}
                  key={r.name}
                  element={<ProtectedRoute childRouteElement={r.element} />}
                />
              );
            })}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Home;
