import { DeleteOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid";
import { t } from "i18next";
import { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Company,
  CompanyType,
  Resource,
  Scope,
} from "../../../../types/NendaTypes";
import { OrganizationUnit } from "../../../models/organizationUnit";
import { getCompanyIdByPremise } from "../../../utils/company";
import ConfirmationDialog from "../ConfirmationDialog";
import CreateEditCompanyForm from "../components/Dashboard/company/CreateEditCompanyForm";
import PermissionsGate from "../components/PermissionGate";
import { CustomerPortalState, store } from "../store";
import { DeleteCompany, getCompanies } from "../store/reducers/companyReducer";
import { useNavigate } from "react-router-dom";

export const CompanyTypeLabel = new Map<CompanyType, string>([
  [CompanyType.CUSTOMER, "Customers"],
  [CompanyType.RESELLER, "Resellers"],
  [CompanyType.SYSTEM_INTEGRATOR, "System Integrators"],
  [CompanyType.ADVERTISER, "Advertiser"],
]);

const Companies = () => {
  const theme = useTheme();
  const naviate = useNavigate();
  const [companyType, setCompanyType] = useState(CompanyType.CUSTOMER);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [companyIdToDelete, setcCompanyIdToDelete] = useState<
    string | undefined
  >();

  const createStatus = useSelector(
    (state: CustomerPortalState) => state.company.status.createCompany
  );

  const allPremises = useSelector(
    (state: CustomerPortalState) => state.organizationUnits.premises.data
  );
  const allUsers = useSelector(
    (state: CustomerPortalState) => state.user.users
  );
  const companyList = useSelector(getCompanies);

  useEffect(() => {
    if (createStatus === "succeeded") setIsFormVisible(false);
  }, [createStatus]);

  const toggleForm = () => setIsFormVisible(!isFormVisible);

  const handleDeleteCompany = (companyId: string) => {
    setcCompanyIdToDelete(companyId);
    setShowDeleteDialog(true);
  };

  const confirmDeleteCompany = (companyId: string) => {
    store.dispatch(DeleteCompany(companyId));
  };

  const rows: GridRowsProp = companyList.map(
    (company: Company & { created: string }) => {
      return {
        id: company._id,
        name: company.name,
        type: company.type,
        created_at: company.createdAt || company.created,
        premises: allPremises.filter((premise) => {
          const companyId = getCompanyIdByPremise(premise);
          return companyId === company._id;
        }).length,
        users: allUsers.data.filter((user) => user.company === company._id)
          .length,
        actions: (
          <Button
            variant="text"
            onClick={() => handleDeleteCompany(company._id)}
          >
            <DeleteOutline />
          </Button>
        ),
      };
    }
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <Link
            sx={{ color: theme.palette.primary.dark, cursor: "pointer" }}
            onClick={() => naviate(`/dashboard/company/${params.row.id}`)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "type",
      headerName: "Customer Type",
      flex: 1,
      sortable: true,
    },
    {
      field: "premises",
      headerName: "No of Premises",
      flex: 1,
      sortable: true,
    },
    {
      field: "users",
      headerName: "No of Users",
      flex: 1,
      sortable: true,
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      sortable: true,
      valueFormatter: (params) => {
        return new Date(params as string).toLocaleDateString();
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return params.value as ReactElement;
      },
    },
  ];

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PermissionsGate
      restriction={{
        resource: Resource.Company,
        scopes: [Scope.CanCreate],
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: isMobile ? "flex-start" : "center",
            py: "1rem",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              textTransform: "capitalize",
              color: theme.palette.primary.light,
              paddingRight: "1rem",
            }}
          >
            {companyList.length || 0}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              textTransform: "capitalize",
              color: theme.palette.text.primary,
            }}
          >
            {CompanyTypeLabel.get(companyType)}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ marginLeft: isMobile ? 0 : "2rem" }}
            onClick={toggleForm}
          >
            <Typography variant="button">
              {t("customerportal.pages.companies.create_company")}
            </Typography>
          </Button>
        </Box>

        <CreateEditCompanyForm open={isFormVisible} onClose={toggleForm} />
        <DataGrid
          columnBufferPx={100}
          autoHeight
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: false,
              },
            },
            sorting: {
              sortModel: [{ field: "created_at", sort: "desc" }],
            },
          }}
          ignoreDiacritics={true}
          disableColumnSelector
          disableDensitySelector
          columnVisibilityModel={{
            id: false,
            users: isMobile ? false : true,
            type: isMobile ? false : true,
            premises: isMobile ? false : true,
            created_at: isMobile ? false : true,
          }}
          columns={columns}
          rows={rows}
          slots={{ toolbar: GridToolbar }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
        />
        <ConfirmationDialog
          title={t(
            "customerportal.pages.companies.confirmation.delete_company"
          )}
          content={t(
            "customerportal.pages.companies.confirmation.cannot_be_restored"
          )}
          isOpen={showDeleteDialog}
          onClose={(isConfirmed: boolean) => {
            if (isConfirmed && companyIdToDelete) {
              confirmDeleteCompany(companyIdToDelete);
            }
            setShowDeleteDialog(false);
            setcCompanyIdToDelete(undefined);
          }}
        ></ConfirmationDialog>
      </Box>
    </PermissionsGate>
  );
};

export default Companies;
