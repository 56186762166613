import { Box, Tooltip } from "@mui/material";
import { t } from "i18next";
import { Hour } from "../../../../../../../types/HelperTypes";
import { ISignagePromotion, Time } from "../../../../../../../types/NendaTypes";
import { getPrettyTimeRange } from "../../../../../../utils/timeline";
import StripedOverlay from "./StripedOverlay";

const PromotionsHeatMap = ({
  promotions,
  flat = false,
  tooltip = false,
  blockedRangeDimensions,
}: {
  promotions: ISignagePromotion[];
  flat?: boolean;
  tooltip?: boolean;
  blockedRangeDimensions?: { left: number; width: number }[];
}) => {
  return (
    <Box
      sx={(theme) => ({
        height: "1rem",
        background:
          promotions.length > 0 ? "transparent" : theme.palette.grey[100],
        borderRadius: "0.5rem",
        overflow: "hidden",
        position: "relative",
      })}
    >
      {promotions?.map((pm) => {
        const dimensions = getDimensions(
          pm.schedule.time.start,
          pm.schedule.time.end
        );
        return (
          <Tooltip
            title={`${getPrettyTimeRange(pm.schedule.time)}`}
            disableHoverListener={!tooltip}
            key={`${pm.name} + ${pm._id}`}
          >
            <Box
              sx={(theme) => ({
                background: flat
                  ? theme.palette.transparent.mediumOrange
                  : theme.palette.transparent.orange,
                position: "absolute",
                top: 0,
                borderRadius: "0.5rem",
                bottom: 0,
                left: `${dimensions.left}%`,
                width: `${dimensions.width}%`,
              })}
            />
          </Tooltip>
        );
      })}
      {blockedRangeDimensions?.map((range) => (
        <Tooltip
          title={t("customerportal.notifications.blocked_promotions")}
          key={`${range.left} + ${range.width} + ${promotions.length}`}
        >
          <Box
            sx={(theme) => ({
              background: "rgba(0,0,0,0.1)",
              position: "absolute",
              top: 0,
              borderRadius: "0.5rem",
              bottom: 0,
              overflow: "hidden",
              left: `${range.left}%`,
              width: `${range.width}%`,
            })}
          >
            <StripedOverlay />
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
};

const calculateLeftPosition = (time: Time): number => {
  const scaleLength = 24; // hours in a day

  if (!time) return 0;
  return ((time.hour + time.minute / 60) / scaleLength) * 100;
};

const calculateWidth = (startTime: Time, endTime: Time): number => {
  const startPercentage = calculateLeftPosition(startTime);
  const endPercentage = calculateLeftPosition(
    endTime.hour === 0 && endTime.minute === 0
      ? { hour: 24 as Hour, minute: 0 }
      : endTime
  );

  return endPercentage - startPercentage;
};

export const getDimensions = (start: Time, end: Time) => {
  return {
    left: calculateLeftPosition(start),
    width: calculateWidth(start, end),
  };
};

export default PromotionsHeatMap;
