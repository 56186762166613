import { isAfter, isBefore, isEqual } from "date-fns";
import { ITimeInterval } from "../../../../../../types/NendaTypes";
import { hourMinuteObjectToDate } from "../../../../../utils/timeUtil";
import { timeOverlap } from "@shared/dateTimeUtils";

const endOfDay = 2400;
const startOfDay = 0;

const isWithinDay = (time: number) => {
  return time >= startOfDay && time <= endOfDay;
};

export const hasOverlaps = (
  newTimeObject: ITimeInterval,
  timeObjects: ITimeInterval[]
) => {
  if (!timeObjects || !newTimeObject) return false;
  const newStartDate = Number(
    "" + newTimeObject.start.hour + newTimeObject.start.minute
  );
  let newEndDate = Number(
    "" + newTimeObject.end.hour + newTimeObject.end.minute
  );

  if (newTimeObject.end.hour === 0 && newTimeObject.end.minute === 0) {
    newEndDate = endOfDay;
  }

  const isValidStart = isWithinDay(newStartDate);
  const isValidEnd = isWithinDay(newEndDate);

  if (!isValidStart || !isValidEnd) {
    return true;
  }

  for (let i = 0; i < timeObjects.length; i++) {
    const currentInterval = timeObjects[i];

    let currentEndDate = Number(
      "" + currentInterval.end.hour + currentInterval.end.minute
    );

    if (currentInterval.end.hour === 0 && currentInterval.end.minute === 0) {
      currentEndDate = endOfDay;
    }

    const isOverlapping = timeOverlap(newTimeObject, currentInterval);

    if (isOverlapping) {
      return true;
    }
  }

  return false; // No overlaps found
};

export const isValidTimeInterval = (timeObject) => {
  let { start, end } = timeObject;
  if (end.hour === 0 && end.minute === 0) {
    end = { hour: 24, minute: 0 };
  }
  const startDate = hourMinuteObjectToDate(start);
  const endDate = hourMinuteObjectToDate(end);

  return (
    (isBefore(startDate, endDate) && isAfter(endDate, startDate)) ||
    isEqual(startDate, endDate)
  );
};

export const isValidTimeIntervalAndOverlaps = (
  newTimeObject: ITimeInterval,
  timeObjects: ITimeInterval[]
) => {
  const isValid = isValidTimeInterval(newTimeObject);
  const hasNoOverlaps = !hasOverlaps(newTimeObject, timeObjects);

  return isValid && hasNoOverlaps;
};

export const isAllTimeIntervalsValidWithoutOverlaps = (
  timeObjects: ITimeInterval[]
) => {
  let isValid = true;
  if (!timeObjects) return false;
  for (let i = 0; i < timeObjects.length; i++) {
    timeObjects.forEach(() => {
      const otherObjects = timeObjects.filter((to) => to !== timeObjects[i]);
      const currentInterval = timeObjects[i];
      const isValidTimeInterval = isValidTimeIntervalAndOverlaps(
        currentInterval,
        otherObjects
      );

      if (!isValidTimeInterval) {
        isValid = false;
      }
    });
    if (!isValid) {
      break;
    }
  }
  return isValid;
};
