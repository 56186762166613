import { Theme } from "@mui/material/styles";

const tableStyles = (theme: Theme) => ({
  backgroundColor: "transparent !important",
  "& .MuiTableCell-root": {
    padding: "0.5rem 1rem",
    fontSize: "0.9rem",
  },
  "& .MuiTableCell-head": {
    border: "none",
  },
  "& .MuiTableCell-body": {
    border: "none",
    // backgroundColor: theme.palette.background.paper,
  },
});

export default tableStyles;
