import React from "react";
import { useSortable, UseSortableArguments } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@mui/material";

interface SortableItemProps<T> {
  id: string;
  data: T;
  index: number;
  renderItem: (data: T, index: number, listeners: any) => React.ReactNode;
}

function SortableItem<T>({
  id,
  data,
  renderItem,
  index,
}: SortableItemProps<T>) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id } as UseSortableArguments);

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };
  return (
    <Box ref={setNodeRef} style={style} {...attributes}>
      {renderItem(data, index, listeners)}
    </Box>
  );
}

export default SortableItem;
