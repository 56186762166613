import { Chip, useTheme } from "@mui/material";
import { t } from "i18next";
import { RoomTypeCategory } from "../../../../../../types/NendaTypes";

interface RoomTypeCategoryChipProps {
  roomTypeCategory?: RoomTypeCategory;
}

const RoomTypeCategoryChip: React.FC<RoomTypeCategoryChipProps> = ({
  roomTypeCategory,
}) => {
  const theme = useTheme();
  if (!roomTypeCategory) return null;
  return (
    <Chip
      label={t(
        `customerportal.pages.unit.room_type_category.${roomTypeCategory}`
      )}
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: "white !important", // This should be fixed
        textTransform: "capitalize",
        fontSize: "0.8rem",
        mr: "0.5rem",
      }}
    />
  );
};

export default RoomTypeCategoryChip;
