import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import React from "react";

interface ConfirmationDialogProps {
  title: string;
  content: string;
  onClose: (isConfirmed: boolean) => void;
  isOpen: boolean;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  content,
  onClose,
  isOpen,
}) => {
  const handleClose = ({ isConfirmed }) => {
    onClose(isConfirmed);
  };
  const theme = useTheme();

  return (
    <Dialog open={isOpen} onClose={() => handleClose({ isConfirmed: false })}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          color="error"
          onClick={() => handleClose({ isConfirmed: false })}
          autoFocus
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleClose({ isConfirmed: true })}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
