import React from "react";
import { Route, Routes } from "react-router-dom";
import { Carousel } from "../../components/Carousel/Carousel";
import { PageWrapper } from "../PageWrapper";
import { CarouselsOverview } from "./CarouselsOverview";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";

export const CarouselPage: React.FC = () => {
  return (
    <PageWrapper>
      <Routes>
        <Route
          path={"/*"}
          element={<ProtectedRoute childRouteElement={<CarouselsOverview />} />}
        ></Route>
        <Route path={"/carousel/:id"} element={<Carousel />} />
      </Routes>
    </PageWrapper>
  );
};
