import { Box, Fade, Skeleton, Typography, useTheme } from "@mui/material";

const LabelValueRow = ({
  label,
  value,
  loading,
}: {
  label: string;
  value?: any;
  loading?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      key={label}
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        flexWrap: "wrap",
      })}
    >
      <Box
        sx={{
          padding: 0,
          borderBottom: "none",
          color: `${theme.palette.grey[500]} !important`,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ fontFamily: "Gilroy-Bold !important" }}
        >
          {label}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: 0,
          borderBottom: "none",
          height: "2.2rem",
        }}
      >
        {!loading ? (
          <Typography
            variant="subtitle1"
            sx={{
              color: value ? "inherit" : theme.palette.grey[500],
            }}
          >
            {value || "Not specified"}
          </Typography>
        ) : (
          <Fade in={true}>
            <Skeleton
              width={"10rem"}
              height={"1rem"}
              variant="rectangular"
              animation="wave"
              sx={{
                margin: "0 0 0 auto",
                borderRadius: "0.5rem",
                background: theme.palette.transparent.blue,
              }}
            />
          </Fade>
        )}
      </Box>
    </Box>
  );
};

export default LabelValueRow;
