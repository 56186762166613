import React from "react";
import { PageWrapper } from "../PageWrapper";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";
import { subPagesRoutes } from "./subPages.routes";
import { SubPagesOverview } from "./SubPagesOverview";
import { store } from "../../redux/store";
import { GetSubPages } from "../../redux/subPages.redux";
import { SubPageComponent } from "../../components/SubPage/SubPage";

export const SubPage: React.FC = () => {
  React.useEffect(() => {
    store.dispatch(GetSubPages());
  }, []);
  return (
    <PageWrapper>
      <Routes>
        <Route
          path={"/*"}
          element={<ProtectedRoute childRouteElement={<SubPagesOverview />} />}
        />
        <Route path={"/:id"} element={<SubPageComponent />} />
      </Routes>
    </PageWrapper>
  );
};
