import React from "react";
import { SectionWrapper } from "../UI/SectionWrapper/SectionWrapper";
import "./CarouselPreview.scss";

interface ICarouselPreviewProps {
  assets: Array<any>;
}

export const CarouselPreview: React.FC<ICarouselPreviewProps> = ({
  assets,
}) => {
  return (
    <SectionWrapper>
      <h4>
        Carousel Preview{" "}
        <span className="description">
          Please note that this isn't an exact implementation visualization nor
          all the assets might not be available at all the hotels.
        </span>
      </h4>
      <div className="d-flex asset-slide-wrapper">
        {assets.map((asset) => (
          <div
            className="asset-slide"
            style={{ backgroundImage: `url(${asset.getImage()})` }}
            key={asset.assetId}
          >
            <span className="text-white">{asset.getTitle()}</span>
          </div>
        ))}
      </div>
    </SectionWrapper>
  );
};
