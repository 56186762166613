import React from "react";
import { FaChartLine, FaCog, FaConciergeBell, FaRegFile } from "react-icons/fa";
import { Link } from "react-router-dom";
import { UserSession } from "../../../types/NendaTypes";
import { Logout } from "../../redux/auth.redux";
import { store } from "../../redux/store";
import "./SideMenu.scss";

interface ISubMenuListItemProps {
  menuItem: any;
  currentLocation: any;
  depth: number;
}

const SubMenuListItem: React.FC<ISubMenuListItemProps> = ({
  menuItem,
  currentLocation,
  depth,
}) => {
  const [showSubMenu, setShowSubMenu] = React.useState(
    currentLocation.pathname.includes(menuItem.path)
  );
  const hasMenu = menuItem.subMenu && menuItem.subMenu.length > 0;

  return (
    <div className="p-0 my-1 ml-3 menu-wrapper">
      <div className="menu-link-wrapper">
        <Link
          className={
            `text-white menu-link` +
            (menuItem.path === currentLocation.pathname ? " active" : "")
          }
          to={menuItem.path}
          onClick={() => setShowSubMenu(!showSubMenu)}
        >
          {hasMenu && (
            <span className="row-toggle">{showSubMenu ? "-" : "+"}</span>
          )}
          <div className={`py-1 px-${depth} menu-item`}>{menuItem.text}</div>
        </Link>
        {(menuItem.invalid && (
          <span className="badge badge-danger" style={{ margin: "auto" }}>
            Invalid
          </span>
        )) ||
          (menuItem.hasInvalidChild && (
            <span className="badge badge-warning" style={{ margin: "auto" }}>
              Invalid child
            </span>
          )) ||
          (menuItem.hasNoChildren && (
            <span className="badge badge-warning" style={{ margin: "auto" }}>
              No rooms
            </span>
          ))}
        {depth === 1 && (
          <Link to={menuItem.path}>
            <button className="btn btn-secondary">Create new</button>
          </Link>
        )}
      </div>
      {showSubMenu && hasMenu ? (
        <div className="p-0">
          <SubMenuList
            depth={depth + 1}
            menuItems={menuItem.subMenu}
            currentLocation={currentLocation}
          />
        </div>
      ) : null}
    </div>
  );
};

interface ISubMenuListProps {
  menuItems: Array<any>;
  currentLocation: any;
  depth: number;
}

const SubMenuList: React.FC<ISubMenuListProps> = ({
  menuItems,
  currentLocation,
  depth,
}) => {
  return (
    <>
      {menuItems.map((menuItem, index) => {
        return (
          <SubMenuListItem
            key={index}
            menuItem={menuItem}
            depth={depth}
            currentLocation={currentLocation}
          />
        );
      })}
    </>
  );
};

const logoUrl =
  "https://www.nenda.com/wp-content/uploads/2019/05/nenda-loggo-white-1.svg";

interface ISideMenuProps {
  menuItems: Array<any>;
  currentLocation: any;
  sessionUser?: string;
}

export const SideMenu: React.FC<ISideMenuProps> = ({
  menuItems,
  currentLocation,
  sessionUser,
}) => {
  return (
    <div className="col-md-3 px-3 py-2 text-white side-menu">
      <a href="/">
        <img id="logo" src={logoUrl} />
      </a>
      <SubMenuList
        depth={1}
        menuItems={menuItems}
        currentLocation={currentLocation}
      />

      <div className="ml-3 mt-3 pt-3 p-1 d-flex justify-content-between flex-grow-1">
        <div className="menu-wrapper flex-grow-1">
          <div className="menu-link-wrapper">
            <Link
              className={
                `text-white menu-link align-items-center` +
                (currentLocation.pathname === "Settings" ? " active" : "")
              }
              to={"/settings"}
            >
              <FaCog />
              <div className={`menu-item pl-2 py-1 px-1`}>Settings</div>
            </Link>
          </div>
        </div>
      </div>

      <div className="ml-3 p-1 d-flex justify-content-between flex-grow-1">
        <div className="menu-wrapper flex-grow-1">
          <div className="menu-link-wrapper">
            <Link
              className={
                `text-white menu-link align-items-center` +
                (currentLocation.pathname === "Templates" ? " active" : "")
              }
              to={"/templates"}
            >
              <FaRegFile />
              <div className={`menu-item pl-2 py-1 px-1`}>Templates</div>
            </Link>
          </div>
        </div>
      </div>

      <div className="ml-3 p-1 d-flex justify-content-between flex-grow-1">
        <div className="menu-wrapper flex-grow-1">
          <div className="menu-link-wrapper">
            <Link
              className={
                `text-white menu-link align-items-center` +
                (currentLocation.pathname === "Analytics" ? " active" : "")
              }
              to={"/analytics"}
            >
              <FaChartLine />
              <div className={`menu-item pl-2 py-1 px-1`}>Analytics</div>
            </Link>
          </div>
        </div>
      </div>

      <div className="ml-3 p-1 d-flex justify-content-between flex-grow-1">
        <div className="menu-wrapper flex-grow-1">
          <div className="menu-link-wrapper">
            <Link
              className={
                `text-white menu-link align-items-center` +
                (currentLocation.pathname === "Settings" ? " active" : "")
              }
              to={"/room-management"}
            >
              <FaConciergeBell />
              <div className={`menu-item pl-2 py-1 px-1`}>Room management</div>
            </Link>
          </div>
        </div>
      </div>

      <div className="ml-3 mb-2 py-3 p-1 d-flex flex-column justify-content-between">
        <span className={`pl-3`}>{"Logged in as " + sessionUser}</span>
        <button
          className="btn btn-danger text-white"
          onClick={() => store.dispatch(Logout())}
        >
          Logout
        </button>
      </div>
    </div>
  );
};
