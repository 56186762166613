import { format, getTimezoneOffset } from "date-fns-tz";
import { ITimeInterval } from "../types/NendaTypes";
import { WallClockDateTimeUtc } from "../types/HelperTypes";

export function withoutTime(date: Date): Date {
  return new Date(new Date(date).setUTCHours(0, 0, 0, 0));
}

export function timeOverlap(
  first: ITimeInterval,
  second: ITimeInterval
): boolean {
  // All times are converted to minutes for easier comparison
  const firstStartMinutes = first.start.hour * 60 + first.start.minute;
  const secondStartMinutes = second.start.hour * 60 + second.start.minute;
  const firstEndMinutes = first.end.hour * 60 + first.end.minute;
  const secondEndMinutes = second.end.hour * 60 + second.end.minute;

  // calculate duration of each time range
  const firstDuration = firstEndMinutes - firstStartMinutes;
  const secondDuration = secondEndMinutes - secondStartMinutes;

  if (firstDuration === 0 || secondDuration === 0) {
    // If either time ranges cover the entire day, they will overlap
    return true;
  }

  // Check if the time ranges overlap based on the minute values
  return (
    firstStartMinutes < secondEndMinutes && secondStartMinutes < firstEndMinutes
  );
}

export function isInRange(
  date: Date,
  startDate: Date,
  endDate: Date,
  includeTime: boolean = false
): boolean {
  if (includeTime) {
    return date >= startDate && date <= endDate;
  }
  return (
    withoutTime(date) >= withoutTime(startDate) &&
    withoutTime(date) <= withoutTime(endDate)
  );
}

export function generateIntervals(
  start: Date,
  end: Date,
  intervalMinutes: number
): [Date, Date][] {
  const intervals: [Date, Date][] = [];
  const current = new Date(start);
  let intervalStart = new Date(
    Math.floor(current.getTime() / (intervalMinutes * 60 * 1000)) *
      intervalMinutes *
      60 *
      1000
  );

  while (intervalStart < end) {
    const intervalEnd = new Date(
      intervalStart.getTime() + intervalMinutes * 60 * 1000
    );
    intervals.push([new Date(intervalStart), new Date(intervalEnd)]);
    intervalStart = intervalEnd;
  }

  return intervals;
}

export function isValidDate(date: any): boolean {
  if (!date) return false;
  return date instanceof Date && !isNaN(date.getTime());
}

export function convertTimeToMinutes(time: string): number {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

export function toWallClockDateTime(
  date: Date,
  timezone: string
): WallClockDateTimeUtc {
  const offset = getTimezoneOffset(timezone, date);
  const utcDate = new Date(date.getTime() + offset);
  return utcDate;
}
