import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageWrapper } from "../PageWrapper";
import { OrganizationUnitsOverview } from "./OrganizationUnitsOverview";
import { organizationUnitRoutes } from "./organization_unit_routes";
import { ProtectedRoute } from "../../components/ProtectedRoute/ProtectedRoute";

export const OrganizationUnitsPage: React.FC = () => {
  return (
    <PageWrapper>
      <Routes>
        <Route
          path={"/*"}
          key={""}
          element={
            <ProtectedRoute childRouteElement={<OrganizationUnitsOverview />} />
          }
        ></Route>

        {organizationUnitRoutes.map(({ path, name, element }) => {
          return (
            <Route key={path + name} path={path} element={element}></Route>
          );
        })}
      </Routes>
    </PageWrapper>
  );
};
