import React, { ReactNode } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router";
import "./PageWrapper.scss";

interface IPageWrapperProps {
  children?: ReactNode;
  className?: string;
}

export const PageWrapper: React.FC<IPageWrapperProps> = ({
  children,
  className,
}) => {
  const history = useNavigate();
  return (
    <div
      className={"container-fluid row h-100 full-height m-0 " + className}
      id="page-wrapper"
    >
      <div className="col-md-12 py-2">
        <button onClick={() => history(-1)} className="btn btn-secondary mr-2">
          <FaArrowLeft />
        </button>
        {children}
      </div>
    </div>
  );
};
