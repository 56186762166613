import {
  FileUploadScope,
  IContentChannel,
  PatchRequest,
} from "../../types/NendaTypes";
import { httpClient, Service } from "./client";

class ContentChannelService extends Service {
  getChannels() {
    return httpClient.get("/contentchannel", this.authHeader());
  }

  getChannel(id: string) {
    return httpClient.get(`/contentchannel/${id}`, this.authHeader());
  }

  createChannel(channel: IContentChannel) {
    return httpClient.post("/contentchannel", channel, this.authHeader());
  }

  updateChannel(request: PatchRequest<IContentChannel>) {
    return httpClient.patch(
      `/contentchannel/${request.data._id}`,
      request,
      this.authHeader()
    );
  }

  deleteChannel(id: string) {
    return httpClient.delete(`/contentchannel/${id}`, this.authHeader());
  }

  uploadFile(id: string, file: File, scope: FileUploadScope) {
    const formData = new FormData();
    formData.append("scope", scope);
    formData.append("file", file);
    return httpClient.put(
      `/contentchannel/${id}/file`,
      formData,
      {
        ...this.authHeader(),
      },
      false
    );
  }

  /*updateAdPlaylist(playlist: IAdPlaylist) {
    return httpClient.patch(`/ad/playlist`, playlist, this.authHeader());
  }*/
}

export const contentChannelService = new ContentChannelService();
