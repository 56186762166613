import * as React from "react";
import { Box } from "@mui/material";
import { AssetType } from "../../../../../../types/NendaTypes";
import { useRef } from "react";
//import { getVideoDurationInSeconds } from "get-video-duration";

interface AssetFileProps {
  src: string;
  type: AssetType;
  onLoadedVideoMetaData: (video: any) => void;
}

const AssetFile: React.FC<AssetFileProps> = ({
  type,
  src,
  onLoadedVideoMetaData,
}) => {
  const videoEl = useRef(null);
  const handleLoadedMetadata = () => {
    const video: any = videoEl.current;
    onLoadedVideoMetaData(video);
  };

  return (
    <React.Fragment>
      {(type === AssetType.IMAGE || type == AssetType.SLIDE) && (
        <img
          src={src}
          style={{ width: "100%", padding: 0, maxWidth: "none" }}
        />
      )}
      {type === AssetType.VIDEO && (
        <video
          style={{ width: "100%" }}
          src={src}
          ref={videoEl}
          controls
          onLoadedMetadata={handleLoadedMetadata}
        />
      )}
    </React.Fragment>
  );
};

export default AssetFile;
