import { ICarousel } from "../types/NendaTypes";
import { ITagType } from "./types/RedBee.types";

export class Carousel {
  private title: Array<any>;
  private tagIds: Array<any>;
  private assetIds: Array<any>;
  private displayLimit: number;
  constructor() {
    this.title = [];
    this.tagIds = [];
    this.assetIds = [];
  }
  getTitle() {
    if (this.title.length === 0) return "";
    return this.title[0].text;
  }
}

export class CarouselUtils {
  static getQuery(carousel: ICarousel, availableTags: ITagType[]) {
    let query;
    switch (carousel.type) {
      case "curated":
        if (carousel.assetIds.length > 0) {
          return this.assetListToQuery(carousel.assetIds);
        }
      case "query":
        if (carousel.tagIds.length > 0) {
          query = this.tagListToQuery(
            carousel.tagIds,
            availableTags,
            carousel.logicConnector || "AND"
          );
          if (carousel.assetTypeFilter && carousel.assetTypeFilter.length > 0) {
            query = `${
              query ? "(" + query + ") AND " : ""
            }type:(${carousel.assetTypeFilter.join(" OR ")})`;
          }
          return query;
        } else {
          if (carousel.assetTypeFilter && carousel.assetTypeFilter.length > 0) {
            query = "type:(" + carousel.assetTypeFilter.join(" OR ") + ")";
            return query;
          }
        }
      default:
        return "*:*"; // get all assets
    }
  }

  static assetListToQuery(assetList) {
    return assetList.map((assetId) => `assetId:${assetId}`).join(" OR ");
  }

  static tagListToQuery(
    tagList: string[],
    availableTags: ITagType[],
    logicConnector = "AND"
  ) {
    const tagQuery = tagList
      .map((tagId) => {
        const availableTag = availableTags.find((t) => t.tagId === tagId);
        return availableTag
          ? `tags.${availableTag.scheme}:${availableTag.tagId}`
          : "";
      })
      .filter(Boolean)
      .join(` ${logicConnector} `);
    return tagQuery;
  }
}

export default { Carousel, CarouselUtils };
