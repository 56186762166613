import {
  Box,
  FormControlLabel,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { CreatePromotionStateProps } from "./SelectRulesForm";

const DaySelect = ({ state, setState }: CreatePromotionStateProps) => {
  const theme = useTheme();
  const allDaysIsSelected = state.schedule.days.length === 7;
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValues: string[]
  ) => {
    if (newValues.includes("all") || newValues.length === 7) {
      return setState((prevState) => ({
        ...prevState,
        schedule: {
          ...state.schedule,
          days: days.map((day) => day.label),
        },
      }));
    }

    setState((prevState) => ({
      ...prevState,
      schedule: {
        ...state.schedule,
        days: newValues,
      },
    }));
  };

  const onAllToggle = (event: any) => {
    if (state.schedule.days.length === 7) {
      handleChange(event, []);
    } else {
      handleChange(event, ["all"]);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        pr: "1rem",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{ minWidth: "100px", color: theme.palette.grey[700] }}
        >
          {t("Days")}
        </Typography>
        <FormControlLabel
          control={<Switch checked={state.schedule.days.length === 7} />}
          label={t("form.validation.promotions.schedule.no_day_of_week_rule")}
          onChange={onAllToggle}
          labelPlacement="start"
          sx={{
            "&.MuiFormControlLabel-root": {
              margin: "0",
              color: theme.palette.grey[700],
            },
          }}
        />
      </Box>
      {!allDaysIsSelected && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "0.2rem",
            pb: "1rem",
          }}
        >
          <ToggleButtonGroup
            value={state.schedule.days}
            onChange={handleChange}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.4rem",
            }}
          >
            {days.map((day) => {
              return (
                <ToggleButton
                  key={day.label}
                  value={day.label}
                  size="small"
                  sx={{
                    border: `none !important`,
                    outline: `1px solid ${theme.palette.primary.light} !important`,
                    borderRadius: "0.2rem !important",
                    backgroundColor: "transparent",
                    color: theme.palette.primary.light,
                    padding: "0.2rem 0.4rem",
                    [theme.breakpoints.down("md")]: {
                      padding: "0.3rem 0.6rem",
                    },
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.text.secondary + " !important",
                      border: `none !important`,
                    },
                  }}
                  selected={state.schedule.days.includes(day.label)}
                >
                  {day.label}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Box>
      )}
    </Box>
  );
};

export const days = [
  {
    value: "monday",
    dayIndex: 1,
    label: "Monday",
  },
  {
    value: "tuesday",
    dayIndex: 2,
    label: "Tuesday",
  },
  {
    value: "wednesday",
    dayIndex: 3,
    label: "Wednesday",
  },
  {
    value: "thursday",
    dayIndex: 4,
    label: "Thursday",
  },
  {
    value: "friday",
    dayIndex: 5,
    label: "Friday",
  },
  {
    value: "saturday",
    dayIndex: 6,
    label: "Saturday",
  },
  {
    value: "sunday",
    dayIndex: 0,
    label: "Sunday",
  },
];

export default DaySelect;
