import React from "react";
import { OrganizationUnit } from "../../components/OrganizationUnit/OrganizationUnit";
import { ProductOffering } from "../../components/OrganizationUnit/ProductOffering/ProductOffering";
import { Room } from "../../components/OrganizationUnit/Room";
import { RoomType } from "../../components/OrganizationUnit/RoomType";
import { ReportPage } from "../ReportPage/ReportPage";

type Route = {
  name: string;
  path: string;
  element: React.ReactNode;
};
type Routes = Route[];

export const organizationUnitRoutes: Routes = [
  {
    name: "hotelChain",
    path: "/hotelchain/:id",
    element: <OrganizationUnit />,
  },
  {
    name: "productOffering",
    path: "/hotelchain/:hotelChainId/productoffering/:id",
    element: <ProductOffering />,
  },
  {
    name: "hotel",
    path: "/hotelchain/:hotelChainId/hotel/:id",
    element: <OrganizationUnit />,
  },
  {
    name: "allHotelsReport",
    path: "/report-all",
    element: <ReportPage />,
  },
  {
    name: "hotelChainReport",
    path: "/hotelchain/:hotelChainId/report",
    element: <ReportPage />,
  },
  {
    name: "hotelReport",
    path: "/hotelchain/:hotelChainId/hotel/:id/report",
    element: <ReportPage />,
  },
  {
    name: "hotel",
    path: "/hotelchain/:hotelChainId/hotel/:hotelId/room/:id",
    element: <Room />,
  },
  {
    name: "roomType",
    path: "/hotelchain/:hotelChainId/roomType/:roomTypeId",
    element: <RoomType />,
  },
];
