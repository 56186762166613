import { Box, Button, Fade, Typography, useTheme } from "@mui/material";
import { addHours } from "date-fns";
import { t } from "i18next";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import {
  IContentChannel,
  IContentOverride,
  NendaProduct,
} from "../../../../types/NendaTypes";
import channelPosterPlaceholder from "../assets/images/poster-placeholder.png";

import ChannelOverride, {
  ChannelOverrideState,
} from "../components/Content/ChannelOverride";
import DisabledFeatures from "../components/Content/components/DisabledFeatures";
import SetDefaultChannel from "../components/Content/components/SetDefaultChannel";
import { CustomerPortalState, store } from "../store";
import {
  selectCategorizedContentChannelsByNavigatedPremise,
  selectContentChannels,
} from "../store/reducers/contentChannelReducer";
import {
  batchSetContentOverride,
  batchSetDefaultChannelForScreens,
  getScreens,
  selectScreensByProductAndPremise,
} from "../store/reducers/screenReducer";
import { selectNavigationScope } from "../store/reducers/workspaceReducer";
import DefaultDialog from "../ui-components/dialog/dialog";

const Channels = () => {
  const theme = useTheme();
  const scope = useSelector(selectNavigationScope);
  const [selectedScreens, setSelectedScreens] = useState<string[]>([]);
  const [selectedChannelId, setSelectedChannelId] = useState<string>("");
  const [showOverrideModal, setShowOverrideModal] = useState<boolean>(false);
  const [showDefaultChannelModal, setShowDefaultChannelModal] = useState(false);

  const screens = useSelector((state: CustomerPortalState) =>
    selectScreensByProductAndPremise(
      state,
      NendaProduct.SIGNAGE,
      scope.premise || ""
    )
  );

  const channelsByCategory = useSelector(
    selectCategorizedContentChannelsByNavigatedPremise
  );

  const contentChannels = useSelector(selectContentChannels);
  const selectedChannel = contentChannels.find(
    (c) => c._id === selectedChannelId
  );

  const handleCloseOverrideDialog = () => {
    setShowOverrideModal(false);
    setSelectedChannelId("");
  };

  const saveChannelOverride = (data: ChannelOverrideState) => {
    const params: IContentOverride = {
      channel: data.channelId,
      from: new Date().toISOString() as any,
      to: addHours(new Date(), data.duration).toISOString() as any,
    };

    store.dispatch(
      batchSetContentOverride(
        data.screens.map((co) => ({
          contentOverride: { ...params },
          screenId: co,
        }))
      )
    );

    handleCloseOverrideDialog();
  };

  const handleShowDefaultChannelModal = (channelId: string) => {
    setShowDefaultChannelModal(true);
    setSelectedChannelId(channelId);
  };

  const handleShowOverrideDialog = (channelId: string) => {
    setShowOverrideModal(true);
    setSelectedChannelId(channelId);
  };

  const clearDefaultChannelModalState = () => {
    setSelectedScreens([]);
    setSelectedChannelId("");
  };

  const onCloseDefaultChannelModal = ({ isConfirmed }) => {
    if (isConfirmed) {
      store.dispatch(
        batchSetDefaultChannelForScreens({
          channelId: selectedChannelId,
          screens: selectedScreens,
        })
      );
    }
    setShowDefaultChannelModal(false);
    clearDefaultChannelModalState();
  };

  const onSelectScreens = (screens: string[]) => {
    setSelectedScreens(screens);
  };

  useEffect(() => {
    if (scope.premise) {
      store.dispatch(getScreens(scope.premise || ""));
    }
  }, [scope.premise]);

  return (
    <Fade in={true}>
      <Box>
        <ChannelOverride
          channelId={selectedChannelId || ""}
          open={showOverrideModal}
          onSave={saveChannelOverride}
          onClose={handleCloseOverrideDialog}
        />
        <DefaultDialog
          onClose={onCloseDefaultChannelModal}
          disabled={selectedScreens.length < 1}
          open={showDefaultChannelModal}
          title={
            t(
              "customerportal.pages.content_channels.set_default_channel"
            ) as string
          }
        >
          <SetDefaultChannel
            screens={screens}
            onSelect={onSelectScreens}
            selectedScreens={selectedScreens}
            channel={selectedChannel}
          />
        </DefaultDialog>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            mb: "1rem",
          }}
        >
          <Typography
            variant="h3"
            sx={(theme) => ({
              textTransform: "capitalize",
              color: theme.palette.text.primary,
            })}
          >
            {t("customerportal.pages.content_channels.title")}
          </Typography>
          <Typography
            sx={{ paddingBottom: "1rem", maxWidth: "40rem" }}
            variant="body1"
          >
            {t("customerportal.pages.content_channels.helper_text")}
          </Typography>
        </Box>
        {Object.keys(channelsByCategory).map((category) => (
          <Box sx={{ paddingBottom: "1rem" }} key={category}>
            <Typography sx={{ paddingBottom: ".5rem" }} variant="h6">
              {category}
            </Typography>
            <Carousel
              infinite={false}
              partialVisible={true}
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 3000, min: 2001 },
                  items: 7,
                  slidesToSlide: 7,
                  partialVisibilityGutter:
                    channelsByCategory[category].length <= 7 ? 0 : 20,
                },
                prettyLargeDesktop: {
                  breakpoint: { max: 2000, min: 1560 },
                  items: 6,
                  slidesToSlide: 6,
                  partialVisibilityGutter:
                    channelsByCategory[category].length <= 6 ? 0 : 20,
                },
                desktop: {
                  breakpoint: { max: 1560, min: 1024 },
                  items: 5,
                  slidesToSlide: 5,
                  partialVisibilityGutter:
                    channelsByCategory[category].length <= 5 ? 0 : 20,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 700 },
                  items: 3,
                },
                mobile: {
                  breakpoint: { max: 700, min: 0 },
                  items: 2,
                },
              }}
            >
              {channelsByCategory[category].map((channel: IContentChannel) => {
                return (
                  <Box
                    sx={{
                      mr: "12px",
                      height: "250px",
                      maxWidth: "180px",
                      borderRadius: "0.3rem",
                      overflow: "hidden",
                      position: "relative",
                      "&:hover .overlay": {
                        opacity: 1,
                      },
                    }}
                  >
                    <DisabledFeatures
                      channel={channel}
                      sx={{
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        zIndex: 1,
                        top: 0,
                        right: 0,
                        backgroundColor: "white",
                        p: "0.2rem",
                      }}
                    />

                    <Box
                      sx={(theme) => ({
                        height: "100%",
                        width: "100%",
                        backgroundImage: `url(${
                          channel.posterUrl ||
                          channel.logo?.locator ||
                          channelPosterPlaceholder
                        })`,
                        backgroundColor: theme.palette.primary.dark,
                        backgroundSize: "100%",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      })}
                    />
                    <Box
                      className="overlay"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: 0,
                        backgroundColor: "rgba(0,0,0,0.5)",
                        transition: "opacity 0.3s",
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5rem",
                        justifyContent: "center",
                        px: "0.5rem",
                      }}
                    >
                      <Button
                        onClick={() => handleShowOverrideDialog(channel._id)}
                        variant="contained"
                      >
                        {t(
                          "customerportal.pages.content_channels.temporarily_play_channel"
                        )}
                      </Button>
                      <Button
                        onClick={() =>
                          handleShowDefaultChannelModal(channel._id)
                        }
                        variant="contained"
                      >
                        {t(
                          "customerportal.pages.content_channels.set_default_channel"
                        )}
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "rgba(0,0,0,0.5)",
                        textAlign: "center",
                        py: "0.5rem",
                        px: "0.2rem",
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        color: "white",
                      }}
                    >
                      <Typography variant="button">{channel.name}</Typography>
                    </Box>
                  </Box>
                );
              })}
            </Carousel>
          </Box>
        ))}
      </Box>
    </Fade>
  );
};

export default Channels;
