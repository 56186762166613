import { Epic, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { tagService } from "../http/tags.service";
import {
  AppState,
  GetTagsAction,
  SetTagsAction,
  TagsActions,
  TAG_ACTIONS,
} from "../types/redux";
import { handleError } from "./error.redux";

export function SetTags(data: any): SetTagsAction {
  return { type: TAG_ACTIONS.SET_TAGS, tags: data };
}

export function GetTags(): GetTagsAction {
  return { type: TAG_ACTIONS.GET_TAGS };
}

export default function tagReducer(
  state: AppState["tags"] = { tags: [] },
  action: TagsActions
): AppState["tags"] {
  switch (action.type) {
    case TAG_ACTIONS.SET_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    default:
      return state;
  }
}

const getTags$: Epic = (action$) => {
  return action$.pipe(
    ofType(TAG_ACTIONS.GET_TAGS),
    switchMap(() => {
      return tagService.getAssetTags().pipe(
        map((tags) => {
          return SetTags(tags);
        }),
        catchError(handleError)
      );
    })
  );
};

export const tagsEpics = [getTags$];
