import { Theme } from "@mui/material";

const linkStyles = (theme: Theme) => {
  return {
    styleOverrides: {
      root: {
        color: "inherit",
        textDecoration: "none !important",
        borderColor: "white !important",
        ":hover": {
          color: "white !important",
          textDecoration: "none !important",
        },
      },
    },
  };
};

export default linkStyles;
