import {
  Fade,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { TableProps } from "./types/table";
import React from "react";

const TableMain: React.FC<TableProps> = ({
  rows,
  columns,
  hasClickableRows = true,
}) => {
  const theme = useTheme();

  if (!rows) return null;

  const properties = Object.keys({ ...rows[0] });

  const propertiesToShow = columns?.map((c) => {
    const val = properties.find((p) => p === c.value);
    return { value: val || "" };
  });

  return (
    <TableBody
      sx={{
        padding: "0px 0px",
        overflow: "hidden",
        borderRadius: "0.3rem !important",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {rows?.map((row, i) => {
        return (
          <Fade in={true} timeout={500} key={row.id}>
            <TableRow
              onClick={row.onClick || (() => {})}
              sx={{
                borderRadius: "0.3rem !important",
                overflow: "hidden",
                opacity: 0.6,
                cursor: hasClickableRows ? "pointer" : "default",
                transition: "all 0.1s ease-out",
                borderBottom: `2px solid ${theme.palette.background.paper}`,
                "& #endIcon": {
                  opacity: 1,
                },
                "&:hover": hasClickableRows
                  ? {
                      opacity: 1,
                      borderBottom: `2px solid ${theme.palette.primary.light}`,
                      background: theme.palette.transparent.blue,
                      "& #endIcon": {
                        opacity: 1,
                      },
                    }
                  : {},
                "&:last-child": {
                  borderBottom: `2px solid transparent`,
                  "&:hover": hasClickableRows
                    ? {
                        opacity: 1,
                        backgroundColor: theme.palette.background.paper,
                        borderBottom: `2px solid ${theme.palette.primary.light}`,
                      }
                    : {},
                  "& .MuiTableCell-root": {
                    "&:first-of-type": { borderRadius: "0 0 0 0.3rem" },
                    "&:last-of-type": { borderRadius: "0 0 0.3rem 0" },
                  },
                },
                "&:first-child": {
                  "& .MuiTableCell-root": {
                    "&:first-of-type": { borderRadius: "0.3rem 0 0 0" },
                    "&:last-of-type": { borderRadius: "0 0.3rem 0 0" },
                  },
                },
              }}
            >
              {propertiesToShow?.map(({ value }, i) => (
                <TableCell
                  key={i}
                  align={
                    columns?.find((col) => col.value === value)?.align as any
                  }
                  sx={{
                    display: "table-cell",
                    position: "relative",
                  }}
                >
                  {typeof row[value] === "string" ||
                  typeof row[value] === "number" ? (
                    <Typography variant={value === "name" ? "body2" : "body1"}>
                      {row[value]}
                    </Typography>
                  ) : (
                    <>{row[value]}</>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </Fade>
        );
      })}
    </TableBody>
  );
};

export default TableMain;
