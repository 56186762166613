import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { FileUploadType } from "../../../../../types/NendaTypes";
import ConfirmationDialog from "../../ConfirmationDialog";
import { store } from "../../store";
import {
  UploadFile,
  selectIsLoading,
} from "../../store/reducers/contentChannelReducer";
import { FileUpload } from "../FileUpload";
import { VALID_FILE_TYPES } from "./ChannelLogo";

interface ChannelPosterProps {
  channelId: string;
  posterUrl: string;
  onPosterChange: (posterUrl?: string) => void;
}

const ChannelPoster: React.FC<ChannelPosterProps> = ({
  channelId,
  posterUrl,
  onPosterChange,
}) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const isLoading = useSelector(selectIsLoading);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const uploadLogo = () => {
    if (!file) return;
    store.dispatch(UploadFile(channelId, file, FileUploadType.POSTER));
  };

  const toggleUploadForm = () => {
    setShowUploadForm(!showUploadForm);
  };
  const toggleDeleteDialog = () => {
    setIsDeleteDialogOpen(!isDeleteDialogOpen);
  };

  const handleDeleteDialogClose = (isConfirmed: boolean) => {
    setIsDeleteDialogOpen(false);
    if (isConfirmed) {
      onPosterChange(undefined);
    }
  };
  return (
    <Grid container spacing={2} columns={{ xs: 1, md: 2 }}>
      <Grid item xs={1}>
        <Typography variant="h6">
          {t("customerportal.pages.content_channels.poster_image")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {posterUrl && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box>
              <img src={posterUrl} width={"100%"} />
            </Box>
            <Button
              size="small"
              color="error"
              sx={{ height: "30px" }}
              variant="outlined"
              onClick={toggleDeleteDialog}
            >
              {t("common.delete")}
            </Button>
            <ConfirmationDialog
              title="Are you sure you want to delete the channel poster?"
              content="Note that the change is not applied until you save your changes."
              isOpen={isDeleteDialogOpen}
              onClose={handleDeleteDialogClose}
            />
          </Box>
        )}
        {!posterUrl && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Button variant="outlined" onClick={toggleUploadForm}>
              {
                //If showUploadForm is true, show "Hide Upload Form", else show "Show Upload Form"
                showUploadForm
                  ? t("common.cancel")
                  : t(
                      "customerportal.pages.content_channels.upload_channel_poster"
                    )
              }
            </Button>
            {showUploadForm && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <FileUpload
                  files={file ? [file] : []}
                  onFileChange={(files: File[]) => setFile(files[0])}
                  allowedFileTypes={VALID_FILE_TYPES}
                />
                <LoadingButton
                  disabled={!file}
                  color="primary"
                  sx={{ mt: 1 }}
                  variant="outlined"
                  loading={isLoading}
                  onClick={uploadLogo}
                >
                  {t("common.upload")}
                </LoadingButton>
              </Box>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ChannelPoster;
