import { Box, Typography } from "@mui/material";
import {
  Company,
  IOrganizationUnit,
  IRoom,
} from "../../../../../../../types/NendaTypes";
import { getInheritedSignageSlotValue } from "../../screenCard/EditScreen";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { CustomerPortalState } from "../../../../store";
import { selectContentChannelById } from "../../../../store/reducers/contentChannelReducer";

const ScreenListItemInfo = ({
  screen,
  company,
  premise,
}: {
  screen: IRoom;
  company?: Company;
  premise?: IOrganizationUnit;
}) => {
  const defaultChannel = useSelector((state: CustomerPortalState) =>
    selectContentChannelById(state, screen?.signage.config?.defaultChannel)
  );
  return (
    <Box sx={{ mt: "-0.5rem" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          gap: "0.5rem",
        }}
      >
        <Typography
          variant="caption"
          sx={(theme) => ({
            fontSize: "0.6rem",
            textTransform: "uppercase",
            color: theme.palette.grey[600],
          })}
        >
          {t("customerportal.pages.settings.screens.signage_slots_per_hour") +
            ": "}
        </Typography>
        <Typography
          variant="body1"
          sx={(theme) => ({
            fontSize: "0.6rem",
            textTransform: "uppercase",
            color: theme.palette.grey[800],
          })}
        >
          {screen?.signage.config?.numberOfHourlySignageSlots !== undefined
            ? screen?.signage.config?.numberOfHourlySignageSlots
            : getInheritedSignageSlotValue({
                premise,
                company,
              })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          gap: "0.5rem",
        }}
      >
        <Typography
          variant="caption"
          sx={(theme) => ({
            fontSize: "0.6rem",
            textTransform: "uppercase",
            color: theme.palette.grey[600],
          })}
        >
          {t("customerportal.pages.settings.screens.default_channel") + ": "}
        </Typography>
        <Typography
          variant="body1"
          sx={(theme) => ({
            fontSize: "0.6rem",
            textTransform: "uppercase",
            color: theme.palette.grey[800],
          })}
        >
          {defaultChannel?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default ScreenListItemInfo;
