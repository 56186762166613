import { Divider, Paper, Typography } from "@mui/material";
import { FC } from "react";
import {
  IOrganizationUnit,
  NendaProduct,
  SignageSlotAmount,
} from "../../../../../../types/NendaTypes";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { CustomerPortalState } from "../../../store";
import constants from "@shared/constants";
import { selectNavigatedCompanyId } from "../../../store/reducers/workspaceReducer";
import NendaProducts from "../../Premise/NendaProducts";
import SignageSlots from "../../Premise/Features/SignageSlots";
import CompanySelect from "../../Premise/CompanySelect";
import ContentChannels from "../../Premise/Features/ContentChannels";
import { getCompanyIdByPremise } from "../../../../../utils/company";
import ConditionalFeaturesSelect, {
  ConditionalFeature,
} from "../../Premise/ConditionalFeaturesSelect";

interface FeaturesProps {
  premise: IOrganizationUnit;
  premiseFeatures: Partial<IOrganizationUnit>;
  setPremiseFeatures: React.Dispatch<React.SetStateAction<IOrganizationUnit>>;
}
const Features: FC<FeaturesProps> = ({
  premise,
  premiseFeatures,
  setPremiseFeatures,
}) => {
  const nendaProducts = premiseFeatures.nendaProducts || [];
  const companyId = useSelector(selectNavigatedCompanyId);
  const company = useSelector((state: CustomerPortalState) =>
    state.company.companies.find((c) => c._id === companyId)
  );

  const handleProductChange = (productName: NendaProduct) => {
    const productIndex = nendaProducts.indexOf(productName);
    if (productIndex === -1) {
      setPremiseFeatures((prevFormState) => ({
        ...prevFormState,
        nendaProducts: [...nendaProducts, productName],
      }));
    } else {
      setPremiseFeatures((prevFormState) => ({
        ...prevFormState,
        nendaProducts: nendaProducts.filter((_, i) => i !== productIndex),
      }));
    }
  };

  const handleStreamingContentMangerEnabledChange = (value: boolean) => {
    setPremiseFeatures((prevFormState) => ({
      ...prevFormState,
      streamingContentManagerEnabled: value,
    }));
  };

  const handleSignageSlotChange = (value: SignageSlotAmount) => {
    setPremiseFeatures((prevFormState) => ({
      ...prevFormState,
      signageConfig: {
        ...prevFormState.signageConfig,
        numberOfHourlySignageSlots: value,
      },
    }));
  };

  const handleCompanyChange = (companyId: string) => {
    setPremiseFeatures((prevFormState) => ({
      ...prevFormState,
      company: companyId,
    }));
  };

  const handleContentChannelsChange = (contentChannels: string[]) => {
    setPremiseFeatures((prevFormState) => ({
      ...prevFormState,
      contentChannels,
    }));
  };
  const getInheritedSignageSlotValue = () => {
    if (premise?.signageConfig?.numberOfHourlySignageSlots) {
      return premise?.signageConfig?.numberOfHourlySignageSlots;
    }
    if (company?.signageConfig?.numberOfHourlySignageSlots) {
      return company?.signageConfig?.numberOfHourlySignageSlots;
    }
    const { SIGNAGE_SLOTS_PER_HOUR } = constants.Signage();
    return SIGNAGE_SLOTS_PER_HOUR;
  };

  const companyIdByPremise = getCompanyIdByPremise(
    premiseFeatures as IOrganizationUnit
  );

  const conditionalFeatures: ConditionalFeature[] = [
    {
      label: t(
        "customerportal.settings.premise_settings.streaming_content_manager"
      ),
      onChange: handleStreamingContentMangerEnabledChange,
      checked: premiseFeatures.streamingContentManagerEnabled || false,
      visible:
        premiseFeatures.nendaProducts?.includes(NendaProduct.STREAMING_APP) ||
        false,
    },
  ];

  return (
    <Paper sx={{ p: 1 }}>
      <Typography variant="body2" sx={{ my: "0.5rem" }}>
        {t("common.products")}
      </Typography>
      <NendaProducts
        selectedProducts={nendaProducts}
        availableProducts={Object.values(NendaProduct)}
        onProductSelect={handleProductChange}
      />
      {conditionalFeatures.length > 0 && (
        <ConditionalFeaturesSelect features={conditionalFeatures} />
      )}
      <Divider sx={{ my: "1rem" }} />
      <Typography variant="body2" sx={{ my: "0.5rem" }}>
        {t("common.company")}
      </Typography>
      <CompanySelect
        company={companyIdByPremise || ""}
        setCompany={handleCompanyChange}
      />
      <Divider sx={{ my: "1rem" }} />
      <Typography variant="body2">{t("common.signage_settings")}</Typography>
      <SignageSlots
        selectedValue={
          premiseFeatures.signageConfig?.numberOfHourlySignageSlots || 0
        }
        handleSignageSlotChange={handleSignageSlotChange}
        parentValue={getInheritedSignageSlotValue()}
      />

      <Typography variant="body2" sx={{ my: "0.5rem" }}>
        {t("common.content_channels")}
      </Typography>
      <ContentChannels
        premise={premise}
        contentChannels={premiseFeatures.contentChannels || []}
        setContentChannels={handleContentChannelsChange}
      />
    </Paper>
  );
};

export default Features;
