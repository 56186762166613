import { PlayArrow, ZoomIn } from "@mui/icons-material";
import { CircularProgress, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";
import { AssetType, IAsset } from "../../../../../types/NendaTypes";
import useVideoFileInformation from "../../../../hooks/useVideoFileInformation";
import { formatDurationInSeconds } from "../../../../utils/timeUtil";
import { fileSizeConverted } from "../../../../utils/fileUtil";

const AssetMedia = ({
  asset,
  setMediaProperties,
}: {
  asset: IAsset;
  setMediaProperties: (values: any) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const theme = useTheme();
  const media = asset?.thumbnailLocator || asset?.locator || "";

  const fileInfo = useVideoFileInformation(asset?.locator || "");
  const { isLoading, duration, fileSize, fileType, resolution } = fileInfo;

  useEffect(() => {
    if (!isLoading) {
      setMediaProperties({
        isLoading,
        duration: formatDurationInSeconds(duration || 0),
        fileSize: fileSizeConverted(asset.fileSize || fileSize || 0),
        fileType,
        resolution: `${resolution?.width}x${resolution?.height}`,
      });
    }
  }, [duration, asset, resolution.width, resolution.height, fileSize]);
  return (
    <Box>
      {/* Hack-changing the background of the lightbox image to support transparent images (adds a checkerboard standard transparency background) */}
      <style>{`
          .yarl__slide_image {
            background-color: #fff;
            background-image: linear-gradient(45deg, #dcdcdc 25%, transparent 25%), linear-gradient(-45deg, #dcdcdc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #dcdcdc 75%), linear-gradient(-45deg, transparent 75%, #dcdcdc 75%);
            background-size: 20px 20px;
            background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
          }
        `}</style>
      <Box
        sx={{
          position: "relative",
          borderRadius: "0.3rem",
          overflow: "hidden",
          height: "17rem",
          width: "30rem",
          margin: "1rem 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && (
          <CircularProgress sx={{ position: "absolute", zIndex: 10 }} />
        )}
        <Box
          onClick={() => setOpenImage(true)}
          component={asset?.type === AssetType.VIDEO ? "video" : "img"}
          src={asset?.locator}
          sx={{
            height: "100%",
            width: "auto",
          }}
          onLoadStart={async (event) => {
            setLoading(true);
          }}
          onLoadedData={() => {
            setLoading(false);
          }}
        />
        <Box
          onClick={() => setOpenImage(true)}
          sx={{
            position: "absolute",
            inset: 0,
            opacity: loading ? 0.5 : 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            transition: "opacity 0.2s ease",
            background: loading
              ? theme.palette.transparent.purple
              : "transparent",
            "&:hover": {
              opacity: 1,
              background: "rgba(0,0,0,0.5)",
            },
          }}
        >
          {asset?.type !== AssetType.VIDEO ? (
            <ZoomIn
              sx={{
                fontSize: "3rem",
                color: theme.palette.common.white,
              }}
            />
          ) : (
            <PlayArrow
              sx={{
                fontSize: "3rem",
                color: theme.palette.common.white,
              }}
            />
          )}
        </Box>
      </Box>
      <Box onClick={() => setOpenImage(false)}>
        <Lightbox
          plugins={asset?.type === "video" ? [Video] : []}
          video={{ autoPlay: true, playsInline: true }}
          open={openImage}
          close={() => setOpenImage(false)}
          render={{
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
          slides={
            asset?.type === "video"
              ? [
                  {
                    type: "video",
                    width: 1280,
                    height: 720,
                    poster: media,
                    sources: [
                      {
                        src: asset?.locator,
                        type: "video/mp4",
                      },
                    ],
                  },
                ]
              : [
                  {
                    src: asset?.locator,
                    width: 1280,
                    height: 720,
                    type: "image",
                  },
                ]
          }
        />
      </Box>
    </Box>
  );
};

export default AssetMedia;
