import { Edit } from "@mui/icons-material";
import { Skeleton, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Field, useFormikContext } from "formik";
import { t } from "i18next";
import { useState } from "react";
import { IAsset } from "../../../../../types/NendaTypes";
import useClickOutside from "../../../../hooks/useClickOutside";
import { ImageAdditionalProps } from "./AssetDetailsDrawer";
import { fileSizeConverted } from "../../../../utils/fileUtil";

export interface EditAssetFormValues {
  value: string;
  property: string;
}

const getFileType = (url) => {
  const supportedTypes = ["jpg", "jpeg", "png", "mp4", "avi"];

  const extension = url.split(".").pop();

  if (supportedTypes.includes(extension)) {
    return extension.toLowerCase();
  } else {
    return "unknown";
  }
};

const AssetDetailsInfo = ({
  asset,
  fileData,
  canEdit,
}: {
  asset: IAsset;
  fileData: ImageAdditionalProps;
  canEdit: boolean;
}) => {
  const { values }: { values: { fileName: string } } = useFormikContext();

  const fileType = getFileType(asset.locator);

  const created = asset?.createdAt && new Date(asset.createdAt);
  const updated = asset?.updatedAt && new Date(asset.updatedAt);

  const resolution = fileData.resolution;
  const duration = fileData.duration;
  const fileSize = asset.fileSize
    ? fileSizeConverted(asset.fileSize)
    : fileData.fileSize;

  return (
    <Box>
      <GridRow
        label={t("common.file.name")}
        value={values.fileName || asset.name}
        editable={canEdit}
        id={"fileName"}
      />
      <GridRow
        label={t("common.file.file_type")}
        value={asset.type + "/" + fileType}
      />
      {resolution && (
        <GridRow
          label={t("common.file.resolution")}
          value={resolution}
          isLoading={fileData.isLoading}
        />
      )}
      {fileSize !== "0" && (
        <GridRow
          label={t("common.file.file_size")}
          value={fileSize}
          isLoading={fileData.isLoading}
        />
      )}
      {asset.type === "video" && duration && (
        <GridRow
          label={t("common.file.duration")}
          value={duration}
          isLoading={fileData.isLoading}
        />
      )}
      {created && (
        <GridRow
          label={t("common.file.created_at")}
          value={formattedDate(created)}
        />
      )}
      {updated && (
        <GridRow
          label={t("common.file.updated_at")}
          value={formattedDate(updated)}
        />
      )}
    </Box>
  );
};

const GridRow = ({
  id,
  editable = false,
  label,
  value,
  sx,
  isLoading,
}: {
  id?: string;
  editable?: boolean;
  label: string;
  value: string;
  sx?: any;
  isLoading?: boolean;
}) => {
  const { handleChange } = useFormikContext();
  const theme = useTheme();
  const [edit, setEdit] = useState(false);
  const handleClickOutside = () => {
    setEdit(false);
  };

  const inputRef = useClickOutside(handleClickOutside);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        paddingY: "0.3rem",
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: 700, color: ({ palette }) => palette.grey[600] }}
      >
        {label}
      </Typography>
      {!edit ? (
        <>
          {!isLoading ? (
            <Typography
              align="right"
              variant="subtitle2"
              {...sx}
              sx={{
                "#edit-asset": {
                  display: "none",
                },
                "&:hover": {
                  "#edit-asset": {
                    display: "inline-block",
                  },
                },
              }}
            >
              {editable && (
                <Edit
                  onClick={() => setEdit(!edit)}
                  sx={{ height: "1rem", cursor: "pointer" }}
                  id="edit-asset"
                />
              )}
              {value}
            </Typography>
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{
                background: theme.palette.transparent.purple,
                borderRadius: "0.4rem",
              }}
              animation="wave"
            />
          )}
        </>
      ) : (
        <Field
          id={id}
          name={id}
          placeholder={value}
          onChange={handleChange}
          innerRef={inputRef}
        />
      )}
    </Box>
  );
};

const formattedDate = (date: Date) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    date.toLocaleDateString("en-EN", dateOptions) +
    "  " +
    date.toLocaleTimeString()
  );
};

export default AssetDetailsInfo;
