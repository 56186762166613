import { useTheme } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import TopNavSelect from "./TopNavigation/TopNavSelect";

export default function TopNavSelectDropdown({
  open,
  anchorEl,
  handleClick,
}: {
  open: boolean;
  anchorEl;
  handleClick: (value: any) => void;
}) {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClose = ({ path }) => {
    if (path) navigate(path);
    handleClick(null);
  };

  return (
    <Menu
      id="basic-menu"
      sx={{
        "& .MuiPopover-paper": {
          minWidth: "15rem",
          width: "100%",
          borderRadius: "0.6rem",
          background: theme.palette.common.white,
          boxShadow: "20px 20px 50px rgba(0, 0, 0, 0.1)",
        },
        "& .MuiMenuItem-root": {
          background: "transparent",
          "&:hover": {
            background: theme.palette.transparent.blue,
          },
        },
        "& .Mui-focusVisible": {
          backgroundColor: "transparent !important",
        },
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <TopNavSelect />
    </Menu>
  );
}
