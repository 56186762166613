import { AddCircle, Check, CloseOutlined } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { FrontLayerInformationItem } from "../../../../../../types/NendaTypes";
import TextInput from "../../../ui-components/input/TextInput";
import InformationListItem from "./InformationListItem";
import SortableList from "../../../ui-components/dnd/SortableList";

const InformationItems = ({
  onAddItem,
  onItemNameChange,
  onRemoveItem,
  informationItems,
  setSelectedInformationItemIndex,
  setInformationItems,
  selectedInformationItemIndex,
}: {
  onAddItem: (newName: string) => void;
  onItemNameChange: (newName: string, index: number) => void;
  onRemoveItem: (index: number) => void;
  informationItems: FrontLayerInformationItem[];
  setInformationItems: (items: FrontLayerInformationItem[]) => void;
  setSelectedInformationItemIndex: (index: number) => void;
  selectedInformationItemIndex: number;
}) => {
  const theme = useTheme();
  const isMaxItems = informationItems.length >= 7;

  const handleOnDrop = (dropResult: FrontLayerInformationItem[]) => {
    const prevSelectedItem = informationItems[selectedInformationItemIndex];
    const newSelectedItemIndex = dropResult.findIndex(
      (item) => item.name === prevSelectedItem.name
    );
    setSelectedInformationItemIndex(newSelectedItemIndex);
    setInformationItems(dropResult);
  };

  const [showAddItem, setShowAddItem] = useState<boolean>(false);
  const [newItemName, setNewItemName] = useState<string>("");

  const onNewItemComplete = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleAddItem();
      return;
    }
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewItemName(e.target.value);
  };

  const handleAddItem = () => {
    if (newItemName === "") {
      toggleShowAddItem();
      return;
    }
    onAddItem(newItemName);
    toggleShowAddItem();
  };

  const toggleShowAddItem = () => {
    setShowAddItem(!showAddItem);
    setNewItemName("");
  };

  const informationItemsWithId = informationItems.map((item, index) => ({
    ...item,
    id: item.name + item.sourceType + index,
  }));

  const renderItem = (
    item: FrontLayerInformationItem,
    index: number,
    listeners: any
  ) => {
    const handleNameChange = (newName: string) => {
      onItemNameChange(newName, index);
    };
    const handleSelectItem = () => {
      setSelectedInformationItemIndex(index);
    };
    const handleDeleteItem = () => {
      onRemoveItem(index);
    };
    return (
      <InformationListItem
        key={item.name + item.sourceType}
        informationItem={item}
        onNameChange={handleNameChange}
        setSelectedItemIndex={handleSelectItem}
        onDelete={handleDeleteItem}
        index={index ? index : 0}
        selectedInformationItemIndex={selectedInformationItemIndex}
        dragHandleListeners={listeners}
      />
    );
  };

  return (
    <>
      {informationItems.length > 0 && (
        <SortableList
          items={informationItemsWithId}
          onChange={handleOnDrop}
          renderItem={renderItem}
        />
      )}
      {!isMaxItems && (
        <Box
          onClick={showAddItem ? () => {} : toggleShowAddItem}
          sx={{
            cursor: "pointer",
            width: "100%",

            height: "6rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: "1rem",
            background: showAddItem
              ? theme.palette.common.white
              : theme.palette.transparent.blue,
            opacity: 0.8,
            "&:hover": {
              opacity: 1,
            },
          }}
        >
          {showAddItem ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <TextInput
                variant="standard"
                value={newItemName}
                onChange={handleNameChange}
                onKeyUp={onNewItemComplete}
                autoFocus
                fullWidth
                placeholder="New item name"
                inputProps={{
                  sx: {
                    width: "100%",
                  },
                  maxLength: 30,
                }}
              />
              <Check
                onClick={handleAddItem}
                sx={{ fill: theme.palette.primary.light }}
              />
              <CloseOutlined
                onClick={toggleShowAddItem}
                sx={{ fill: theme.palette.error.light }}
              />
            </Box>
          ) : (
            <AddCircle sx={{ color: theme.palette.primary.main }} />
          )}
        </Box>
      )}
    </>
  );
};

export default InformationItems;
