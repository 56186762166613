import { PlayArrow, ZoomIn } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import { AssetType, IAsset } from "../../../../../types/NendaTypes";

type Props = {
  asset?: IAsset;
  loading?: boolean;
  size?: "small" | "medium" | "large";
  width?: string;
};

const ClickableAssetPreview = ({ asset, loading, width = "100%" }: Props) => {
  if (!asset) return null;
  const theme = useTheme();
  const media = asset?.thumbnailLocator || asset?.locator || "";
  const [openImage, setOpenImage] = useState(false);
  const toggleLightBox = () => setOpenImage(!openImage);
  return (
    <Box
      onClick={toggleLightBox}
      sx={{
        width,
        aspectRatio: "16/9",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {asset?.locator && asset.type !== "video" ? (
        <img
          src={asset.locator}
          alt={asset.name}
          style={{
            width: "100%",
            borderRadius: "0.2rem",
            objectFit: "contain",
          }}
        />
      ) : (
        <video
          src={asset?.locator}
          style={{
            width: "100%",
            borderRadius: "0.2rem",
            objectFit: "contain",
            aspectRatio: "16/9",
          }}
        />
      )}
      {asset && (
        <Lightbox
          plugins={asset?.type === "video" ? [Video] : []}
          video={{ autoPlay: true, playsInline: true }}
          open={openImage}
          close={toggleLightBox}
          render={{
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
          slides={
            asset?.type === "video"
              ? [
                  {
                    type: "video",
                    width: 1280,
                    height: 720,
                    poster: media,
                    sources: [
                      {
                        src: asset?.locator,
                        type: "video/mp4",
                      },
                    ],
                  },
                ]
              : [
                  {
                    src: asset?.locator,
                    width: 1280,
                    height: 720,
                    type: "image",
                  },
                ]
          }
        />
      )}
      <Box
        onClick={toggleLightBox}
        sx={{
          position: "absolute",
          inset: 0,
          opacity: loading ? 0.5 : 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "opacity 0.2s ease",
          background: loading
            ? theme.palette.transparent.purple
            : "transparent",
          "&:hover": {
            opacity: 1,
            background: "rgba(0,0,0,0.5)",
          },
        }}
      >
        {asset?.type !== AssetType.VIDEO ? (
          <ZoomIn
            sx={{
              fontSize: "3rem",
              color: theme.palette.common.white,
            }}
          />
        ) : (
          <PlayArrow
            sx={{
              fontSize: "3rem",
              color: theme.palette.common.white,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ClickableAssetPreview;
