import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import {
  FrontLayerSectionType,
  IFrontlayerConfiguration,
} from "../../../../../../../types/NendaTypes";
import ColorPicker from "../../../ColorPicker";

const HeaderAndFooterConfig = ({
  state,
  handleChange,
}: {
  state: {
    header: IFrontlayerConfiguration[FrontLayerSectionType.HEADER];
    footer: IFrontlayerConfiguration[FrontLayerSectionType.FOOTER];
  };
  handleChange;
}) => {
  if (!state) {
    return null;
  }
  const handleSectionChange =
    (section: FrontLayerSectionType) => (key: string, value: string) => {
      handleChange({
        [section]: {
          ...state[section],
          [key]: value,
        },
      });
    };
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t(
          "customerportal.settings.premise_settings.frontlayer.header_and_footer"
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "2rem" }}
        >
          <Box>
            <Typography variant="body2">Header background color</Typography>
            <ColorPicker
              canReset={true}
              color={state.header.backgroundColor}
              onChange={(value: string) =>
                handleSectionChange(FrontLayerSectionType.HEADER)(
                  "backgroundColor",
                  value
                )
              }
            />
          </Box>
          <Box>
            <Typography variant="body2">Footer background color</Typography>
            <ColorPicker
              canReset={true}
              color={state.footer.backgroundColor}
              onChange={(value: string) =>
                handleSectionChange(FrontLayerSectionType.FOOTER)(
                  "backgroundColor",
                  value
                )
              }
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default HeaderAndFooterConfig;
