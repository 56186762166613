import { Typography } from "@mui/material";
import React from "react";
import PermissionsGate from "../components/PermissionGate";
import {
  GetCampaigns,
  getScreens,
  GetScreens,
} from "../store/reducers/adsReducer";
import { useSelector } from "react-redux";
import { CustomerPortalState, store } from "../store";
import { Resource, Scope } from "../../../../types/NendaTypes";
import CampaignList from "../components/Adverts/CampaignList";
import { t } from "i18next";

const Ads = () => {
  React.useEffect(() => {
    store.dispatch(GetCampaigns());
    store.dispatch(GetScreens());
  }, []);

  const campaigns = useSelector((state: CustomerPortalState) => {
    return state.ads.campaigns;
  });

  const screens = useSelector(getScreens);

  return (
    <PermissionsGate
      restriction={{
        resource: Resource.Ads,
        scopes: [Scope.CanAdministrate],
      }}
    >
      <React.Fragment>
        <Typography
          mb={2}
          variant="h3"
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          {t("customerportal.pages.ads.title")}
        </Typography>
        <CampaignList campaigns={campaigns || []} screens={screens} />
      </React.Fragment>
    </PermissionsGate>
  );
};

export default Ads;
