import { LoadingButton } from "@mui/lab";
import {
  Box,
  MenuItem,
  Button,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { sv } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetScreensResponse } from "../../../../../server/api/internal/ad";
import {
  AssetSource,
  AssetType,
  IAdCampaign,
  ILeanAsset,
  NendaProduct,
} from "../../../../../types/NendaTypes";
import { CustomerPortalState, store } from "../../store";
import {
  CreateCampaign,
  selectIsLoading,
  selectUploadedAdAssetUrl,
  UpdateCampaign,
  UploadAdAsset,
} from "../../store/reducers/adsReducer";
import { selectAdvertisers } from "../../store/reducers/companyReducer";
import { selectOrganizationUnitsByProduct } from "../../store/reducers/organizationUnitReducer";
import { FileUpload } from "../FileUpload";
import { ScreenSelect } from "./ScreenSelect";
import TextInput from "../../ui-components/input/TextInput";

interface CampaignFormProps {
  campaign?: IAdCampaign;
  screens: GetScreensResponse;
  onClose: () => void;
}

type Mode = "create" | "edit";
type CampaignFormValues = Partial<
  Omit<IAdCampaign, "asset"> & {
    asset?: Partial<ILeanAsset>;
  }
>;

const VALID_IMG_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/svg",
  "image/webp",
];

const VALID_VIDEO_FILE_TYPES = ["video/mp4"];

const CampaignForm: React.FC<CampaignFormProps> = ({
  campaign,
  screens,
  onClose,
}) => {
  const mode: Mode = campaign ? "edit" : "create";
  const theme = useTheme();
  const [state, setState] = React.useState<CampaignFormValues>({ ...campaign });
  const [isAddingAsset, setIsAddingAsset] = React.useState(false);
  const [isScreensOpen, setIsScreensOpen] = React.useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const isLoading = useSelector(selectIsLoading);
  const uploadedFileUrl = useSelector(selectUploadedAdAssetUrl);

  const advertisers = useSelector(selectAdvertisers);

  const signagePremises = useSelector((state: CustomerPortalState) =>
    selectOrganizationUnitsByProduct(state, NendaProduct.SIGNAGE)
  );
  const signageScreensByCategory = screens.reduce((acc, screen) => {
    const category =
      signagePremises.find((x) => x._id === screen.hotel)?.category ||
      "Uncategorized";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(screen);
    return acc;
  }, {} as Record<string, typeof screens>);

  useEffect(() => {
    if (uploadedFileUrl) {
      setAssetValue("locator", uploadedFileUrl);
    }
  }, [uploadedFileUrl]);

  const setValue = (
    key: keyof IAdCampaign,
    value: string | Date | null | undefined
  ) => {
    setState({ ...state, [key]: value });
  };

  const setAssetValue = (
    key: keyof ILeanAsset,
    value: string | AssetSource | AssetType
  ) => {
    setState({ ...state, asset: { ...state.asset, [key]: value } });
  };

  const createCampaign = () => {
    if (isValid()) {
      store.dispatch(
        CreateCampaign({
          ...state,
          asset: { ...state.asset, name: state.name + " asset" },
        } as IAdCampaign)
      );
      onClose();
    }
  };

  const updateCampaign = () => {
    if (campaign && isValid()) {
      store.dispatch(
        UpdateCampaign(campaign._id, state as Partial<IAdCampaign>)
      );
      onClose();
    }
  };

  const uploadAdAsset = () => {
    if (!file) return;
    store.dispatch(UploadAdAsset(file));
  };

  const handleCancel = () => {
    onClose();
  };

  const handleScreenSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    if (event.target.checked) {
      setState({ ...state, screens: [...(state.screens || []), name] });
    }
    if (!event.target.checked) {
      setState({
        ...state,
        screens: state.screens?.filter((x) => x !== name),
      });
    }
  };

  const handleCategorySelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const screens = signageScreensByCategory[name].map((x) => x._id);
    if (event.target.checked) {
      setState({
        ...state,
        screens: [...new Set([...(state.screens || []), ...screens])],
      });
    }
    if (!event.target.checked) {
      setState({
        ...state,
        screens: state.screens?.filter((x) => !screens.includes(x)),
      });
    }
  };

  const selectedScreens = () => {
    return state.screens
      ? state.screens.reduce((a, v) => ({ ...a, [v as any]: true }), {})
      : {};
  };

  const isValid = () => {
    return (
      state.name &&
      state.company &&
      state.startDate &&
      state.endDate &&
      new Date(state.startDate) < new Date(state.endDate) &&
      state.asset?.source &&
      state.asset?.type &&
      state.asset?.locator
    );
  };

  return (
    <Stack
      gap={2}
      sx={{
        maxWidth: "500px",
        p: 2,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <TextInput
        variant="standard"
        id="name"
        label="Campaign name"
        value={state.name || ""}
        autoComplete="off"
        required
        onChange={(e) => {
          setValue("name", e.target.value);
        }}
      />
      <TextInput
        variant="standard"
        id="advertiser"
        label="Advertiser"
        select
        value={state.company || ""}
        onChange={(e) => setValue("company", e.target.value)}
        required
      >
        {advertisers.map((advertiser) => (
          <MenuItem key={advertiser._id} value={advertiser._id}>
            {advertiser.name}
          </MenuItem>
        ))}
      </TextInput>

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DatePicker
            label="Start date"
            value={new Date(state.startDate || new Date())}
            onChange={(e) => setValue("startDate", e)}
            slots={{
              textField: TextInput,
            }}
            slotProps={{ textField: { required: true, variant: "standard" } }}
          />
          <DatePicker
            label="End date"
            slots={{
              textField: TextInput,
            }}
            value={new Date(state.endDate || new Date())}
            onChange={(e) => setValue("endDate", e)}
            slotProps={{ textField: { required: true, variant: "standard" } }}
          />
        </Box>
      </LocalizationProvider>
      {
        <Button
          sx={{ justifyContent: "flex-start" }}
          onClick={() => setIsAddingAsset(!isAddingAsset)}
        >
          {isAddingAsset ? "Hide asset" : "Show asset"}
        </Button>
      }
      {isAddingAsset && (
        <Stack gap={1.5}>
          <TextInput
            variant="standard"
            id="assetType"
            label="Asset type"
            value={state.asset?.type || ""}
            onChange={(e) => setAssetValue("type", e.target.value)}
            select
            required
          >
            <MenuItem value="video">Video</MenuItem>
            <MenuItem value="image">Image</MenuItem>
          </TextInput>
          <TextInput
            variant="standard"
            id="assetSource"
            label="Asset source"
            value={state.asset?.source || ""}
            onChange={(e) => setAssetValue("source", e.target.value)}
            select
          >
            {Object.values(AssetSource).map((source) => (
              <MenuItem key={source} value={source}>
                {source}
              </MenuItem>
            ))}
          </TextInput>

          <TextInput
            variant="standard"
            id="assetUrl"
            label="Asset url"
            value={state.asset?.locator || ""}
            onChange={(e) => setAssetValue("locator", e.target.value)}
            required
          />
          {state.asset?.source === AssetSource.NENDA && (
            <Box>
              <FileUpload
                files={file ? [file] : []}
                onFileChange={(files: File[]) => setFile(files[0])}
                allowedFileTypes={
                  state.asset?.type === AssetType.VIDEO
                    ? VALID_VIDEO_FILE_TYPES
                    : VALID_IMG_FILE_TYPES
                }
              />
              <LoadingButton
                disabled={!file}
                sx={{ mt: 1 }}
                variant="outlined"
                loading={isLoading}
                onClick={uploadAdAsset}
              >
                Upload
              </LoadingButton>
            </Box>
          )}
        </Stack>
      )}
      {
        <Button
          sx={{ justifyContent: "flex-start" }}
          onClick={() => setIsScreensOpen(!isScreensOpen)}
        >
          {isScreensOpen ? "Hide screens" : "show screens"}
        </Button>
      }
      {isScreensOpen && (
        <ScreenSelect
          screens={signageScreensByCategory}
          selected={selectedScreens()}
          onGroupSelectChange={handleCategorySelect}
          onScreenSelectChange={handleScreenSelect}
        />
      )}
      <Button variant="outlined" color="error" onClick={handleCancel}>
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        onClick={mode === "create" ? createCampaign : updateCampaign}
        disabled={!isValid()}
      >
        {mode === "create" ? "Create" : "Save"}
      </LoadingButton>
    </Stack>
  );
};

export default CampaignForm;
