import { useEffect, useState } from "react";
import { configService } from "../http/config.service";

export function useSettings() {
  const [settings, setSettings] = useState(null);
  useEffect(() => {
    configService
      .getSettings()
      .toPromise()
      .then((settings) => setSettings(settings));
  }, []);
  return settings;
}
