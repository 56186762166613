import { Box, CardMedia, Grid, Link, Paper, Typography } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { t } from "i18next";

const Contact = () => {
  return (
    <Paper sx={{ mb: 3, p: "1rem" }}>
      <Box sx={{ display: "flex" }}>
        <CardMedia
          component="img"
          sx={{ width: 151 }}
          image="https://www.nenda.com/wp-content/uploads/2020/11/Carl_Johan_Portrait_close-e1568815990720.jpg"
          alt="Carl-Johan Barnekow"
        />
        <Box sx={{ ml: 3 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {t("customerportal.pages.contact.title")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("customerportal.pages.contact.subtitle")}
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              alignItems="baseline"
              columnSpacing={{ xs: 1 }}
              sx={{ mb: 1 }}
            >
              <Grid item>
                <EmailOutlinedIcon sx={{ height: "0.9rem" }} />
              </Grid>
              <Grid item>
                <Link href="mailto:support@nenda.com">
                  <Typography variant="body1">
                    {t("customerportal.pages.contact.email_address")}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="baseline"
              columnSpacing={{ xs: 1 }}
              sx={{ mb: 1 }}
            >
              <Grid item>
                <PhoneIphoneOutlinedIcon sx={{ height: "0.9rem" }} />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {t("customerportal.pages.contact.phonenumber")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="baseline"
              columnSpacing={{ xs: 1 }}
              sx={{ mb: 1 }}
            >
              <Grid item>
                <LocationOnOutlinedIcon sx={{ height: "0.9rem" }} />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {t("customerportal.pages.contact.address")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default Contact;
