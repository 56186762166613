import { useParams } from "react-router-dom";
import { IRoom, NendaProduct } from "../../../../types/NendaTypes";
import React, { ReactNode, useEffect } from "react";
import { CustomerPortalState, store } from "../store";
import { getScreen, selectScreenById } from "../store/reducers/screenReducer";
import { useSelector } from "react-redux";
import CircularLoadingIndicator from "../ui-components/loading/CircularLoadingIndicator";
import { Alert, Box } from "@mui/material";
import Unit from "../components/Unit/Unit";
import { t } from "i18next";
import { selectPremiseById } from "../store/reducers/organizationUnitReducer";
import { GetStreamChannels } from "../store/reducers/streamChannelReducer";

const Loading = () => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularLoadingIndicator />
    </Box>
  );
};

const NotFound = () => {
  return (
    <Alert severity="error" sx={{ width: "100%" }}>
      {t("customerportal.pages.unit.not_found")}
    </Alert>
  );
};

const UnitPage: React.FC = (): ReactNode => {
  const { unitId: id, premiseId } = useParams<{
    unitId: string;
    premiseId: string;
  }>();

  useEffect(() => {
    if (!id) return;
    store.dispatch(getScreen(id));
  }, []);

  const isLoading = useSelector(
    (state: CustomerPortalState) => state.screen.isLoading
  );

  const unit = useSelector((state: CustomerPortalState): IRoom | undefined =>
    id ? selectScreenById(state, id) : undefined
  );

  const premise = useSelector((state: CustomerPortalState) =>
    premiseId ? selectPremiseById(state, premiseId) : undefined
  );

  useEffect(() => {
    if (premise?.nendaProducts?.includes(NendaProduct.SIGNAL_DELIVERY)) {
      store.dispatch(GetStreamChannels());
    }
  }, [premise?._id, premise?.nendaProducts]);

  if (isLoading) return <Loading />;
  if (!unit) return <NotFound />;
  if (premiseId !== unit.hotel) return <NotFound />;

  const { nendaProducts } = premise || { nendaProducts: [] };

  return <Unit unit={unit} productsOnPremise={nendaProducts} key={unit._id} />;
};

export default UnitPage;
