import { HomeOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  CompanyType,
  NavigationLevel,
  Resource,
  Scope,
} from "../../../../../types/NendaTypes";
import { getCompanyIdByPremise } from "../../../../utils/company";
import { checkPermission } from "../../components/helpers/permissions";
import { store } from "../../store";
import {
  getPermissions,
  selectUserRole,
} from "../../store/reducers/authReducer";
import { getCompanies } from "../../store/reducers/companyReducer";
import { getPremises } from "../../store/reducers/organizationUnitReducer";
import {
  selectCurrentNavigationLevel,
  setScope,
} from "../../store/reducers/workspaceReducer";
import TextInput from "../../ui-components/input/TextInput";
import NavCompanySelect from "./components/NavCompanySelect";
import NavPremiseSelect from "./components/NavPremiseSelect";
import { getValueFromPathname } from "./helpers/urlParams";

export type NavSelectItem = {
  id: string;
  name: string;
};

const TopNavSelect = () => {
  let companyId = getValueFromPathname(window.location.pathname, "company");
  const premiseId = getValueFromPathname(window.location.pathname, "premise");

  const allPremises = useSelector(getPremises);
  const allCompanies = useSelector(getCompanies);

  const companies: NavSelectItem[] = allCompanies
    ?.filter((c) => c.type === CompanyType.CUSTOMER)
    .map((c) => ({ id: c._id, name: c.name }));

  if (premiseId) {
    const premise = allPremises?.find((p) => p._id === premiseId);
    companyId = getCompanyIdByPremise(premise);
  }

  const scopedPremises = allPremises
    ?.filter((p) => getCompanyIdByPremise(p) === companyId)
    .map((p) => ({ id: p._id, name: p.name }));

  const newScope = { company: companyId, premise: premiseId };
  const permissions = useSelector(getPermissions);
  const role = useSelector(selectUserRole);

  const canEditCompany = checkPermission(
    role,
    { resource: Resource.Company, scopes: [Scope.CanEdit] },
    permissions
  );
  const canClearOrSelectPremise = scopedPremises?.length > 1 || canEditCompany;

  useEffect(() => {
    store.dispatch(setScope(newScope));
  }, [companyId, premiseId]);

  return (
    <Box>
      <NavigationItems
        premises={scopedPremises}
        companies={companies}
        selectedCompany={companyId}
        selectedPremise={premiseId}
        disableSelectPremise={!canClearOrSelectPremise}
      />
    </Box>
  );
};

type NavigationItemsProps = {
  premises: NavSelectItem[];
  companies: NavSelectItem[];
  selectedCompany: string;
  selectedPremise: string;
  disableSelectPremise: boolean;
};

const NavigationItems = ({
  premises,
  companies,
  selectedCompany,
  selectedPremise,
  disableSelectPremise,
}: NavigationItemsProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }}
    >
      <Box sx={{ [theme.breakpoints.down("md")]: { display: "none" } }}>
        <Link to="/">
          <HomeOutlined />
        </Link>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          borderRadius: "0.2rem",
          width: "100%",
        })}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            pr: "0.5rem",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              width: "100%",
              borderRadius: "0.2rem",
              py: "0.5rem",
            },
          }}
        >
          <NavCompanySelect
            disabled={companies.length === 1}
            companies={companies}
            selected={selectedCompany}
          />
          <NavPremiseSelect
            selectDisabled={disableSelectPremise}
            premises={premises}
            selected={selectedPremise}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const ScopeSelect = ({
  icon,
  disabled,
  options,
  onSelect,
  selectedId,
}) => {
  const handleChange = (newValue) => {
    onSelect(newValue || "");
  };
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const providedOptions = useMemo(() => {
    return options.map((option) => ({
      label: option.name,
      value: option.id,
    }));
  }, [options]);

  const selectedOption = providedOptions.find(
    (option) => option.value === selectedId
  );
  return (
    <Box sx={{ width: "100%" }}>
      <Autocomplete
        id="scope-select"
        disabled={disabled}
        options={providedOptions}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        value={selectedOption || null}
        onChange={(event, newValue: any) => {
          handleChange(newValue?.value || "");
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
        sx={{
          margin: 0,
          "& .MuiTextField-root": {
            minWidth: "12rem",
            margin: 0,
          },
        }}
        renderInput={(params) => {
          // Function to clip the input text if it exceeds 10 characters
          const value = params.inputProps.value as string;
          const clipLength = isMobile ? 25 : 15;
          const valueLength = value?.length;
          const shouldClip = valueLength > clipLength;
          const clippedText = shouldClip
            ? `${value.substring(0, clipLength)}...`
            : value;

          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              {icon}
              <TextInput
                {...params}
                inputProps={{
                  ...params.inputProps,
                  value: clippedText,
                }}
                variant="standard"
                sx={{
                  mx: 0,
                }}
              />
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default TopNavSelect;
