import React from "react";
import {
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  SvgIconProps,
  Tooltip,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IContentChannel } from "../../../../../types/NendaTypes";
import { stringToColor } from "../../../../utils/string";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../../ConfirmationDialog";
import { PublicOff, PublicOutlined } from "@mui/icons-material";

interface ChannelListProps {
  channels: IContentChannel[];
  onDelete: (channelId: string) => void;
}

const ChannelList: React.FC<ChannelListProps> = ({ channels, onDelete }) => {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [selectedChannel, setSelectedChannel] = React.useState<string>("");

  const theme = useTheme();

  const grayIconProps: SvgIconProps = {
    sx: { cursor: "default", ml: 1, color: "#aaa" },
    fontSize: "small",
  };
  const greenIconProps: SvgIconProps = {
    sx: { cursor: "default", ml: 1, color: theme.palette.success.light },
    fontSize: "small",
  };

  const { t } = useTranslation();
  const handleDelete = (isConfirmed) => {
    if (isConfirmed) {
      onDelete(selectedChannel);
    }
    setShowDeleteDialog(false);
  };

  const handleShowDeleteDialog = (channelId: string) => {
    setSelectedChannel(channelId);
    setShowDeleteDialog(true);
  };

  const channelNameById = (id: string) => {
    const channel = channels.find((channel) => channel._id === id);
    return channel ? channel.name : "";
  };
  return (
    <Box>
      <ConfirmationDialog
        title={t(
          "customerportal.pages.content_channels.delete_channel_confirmation_title",
          {
            channelName: channelNameById(selectedChannel),
          }
        )}
        onClose={handleDelete}
        content={t(
          "customerportal.pages.content_channels.delete_channel_confirmation_description"
        )}
        isOpen={showDeleteDialog}
      />
      <Box sx={{ width: "100%", maxWidth: 600, bgcolor: "background.paper" }}>
        <List>
          {channels.map((item, index) => (
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={item.name}
                  secondaryTypographyProps={{
                    color: theme.palette.primary.light,
                  }}
                  secondary={t("glossary.assetWithCount", {
                    count: item.playlist.length,
                  })}
                />
                <ListItemSecondaryAction>
                  <Chip
                    label={item.category}
                    sx={{
                      backgroundColor: stringToColor(item.category) + "55",
                    }}
                  />
                  {item.hasSound ? (
                    <Tooltip title="Channel has sound">
                      <VolumeUpIcon {...grayIconProps} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Channel has no sound">
                      <VolumeOffIcon {...grayIconProps} />
                    </Tooltip>
                  )}
                  {item.isPublic ? (
                    <Tooltip title="Channel is public">
                      <PublicOutlined {...greenIconProps} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Channel is not public">
                      <PublicOff {...grayIconProps} />
                    </Tooltip>
                  )}
                  <Tooltip title="Edit channel">
                    <IconButton
                      aria-label="edit"
                      href={`/admin/content/${item._id}/`}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete channel">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleShowDeleteDialog(item._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ChannelList;
