import { Epic, ofType } from "redux-observable";
import { catchError, flatMap, map, mergeMap, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { organizationUnitService } from "../http/organizationUnit.service";
import {
  AddCarouselToOrganizationUnitAction,
  AppState,
  CreateAreaAction,
  CreateOrganizationUnitAction,
  CreateRoomsAction,
  CreateRoomTypeAction,
  DeleteOrganizationUnitAction,
  DeleteRoomAction,
  DeleteRoomTypeAction,
  GetOrganizationUnitsAction,
  GetRoomsForHotelAction,
  OrganizationUnitActions,
  ORGANIZATION_UNITS_ACTIONS,
  RemoveCarouselFromOrganizationUnitAction,
  ResyncRoomTypeAction,
  SetOrganizationUnitsAction,
  SetResyncRoomTypeAction,
  SetRoomsForHotelAction,
  UpdateOrganizationUnitAction,
  UpdateOrganizationUnitsAction,
  UpdateRoomAction,
  UpdateRoomTypeAction,
  UpdateRoomTypeAllRoomsAction,
} from "../types/redux";
import { handleError } from "./error.redux";
import { IRoom } from "../../types/NendaTypes";
import { SetNotification } from "./notification.redux";

export function SetOrganizationUnits(data: any): SetOrganizationUnitsAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.SET_ORGANIZATION_UNITS,
    organizationUnits: data,
  };
}

export function GetOrganizationUnits(): GetOrganizationUnitsAction {
  return { type: ORGANIZATION_UNITS_ACTIONS.GET_ORGANIZATION_UNITS };
}

export function CreateOrganizationUnit(
  data: any,
  notifyEmailAddress?: string
): CreateOrganizationUnitAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.CREATE_ORGANIZATION_UNIT,
    payload: data,
    notifyEmailAddress,
  };
}

export function AddCarouselToOrganizationUnit(
  organizationUnitId: any,
  carouselId: any
): AddCarouselToOrganizationUnitAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.ADD_CAROUSEL_TO_ORGANIZATION_UNIT,
    organizationUnitId,
    carouselId,
  };
}

export function RemoveCarouselFromOrganizationUnit(
  organizationUnitId: any,
  carouselId: any
): RemoveCarouselFromOrganizationUnitAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.REMOVE_CAROUSEL_FROM_ORGANIZATION_UNIT,
    organizationUnitId,
    carouselId,
  };
}

export function UpdateOrganizationUnit(
  organizationUnitId: any,
  data: any
): UpdateOrganizationUnitAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNIT,
    organizationUnitId,
    payload: data,
  };
}

export function UpdateOrganizationUnits(
  organizationUnitIds: any,
  data: any
): UpdateOrganizationUnitsAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNITS,
    organizationUnitIds,
    payload: data,
  };
}

export function CreateRooms(payload: any): CreateRoomsAction {
  return { type: ORGANIZATION_UNITS_ACTIONS.CREATE_ROOMS, payload: payload };
}

export function CreateArea(payload: any): CreateAreaAction {
  return { type: ORGANIZATION_UNITS_ACTIONS.CREATE_AREA, payload };
}

export function CreateRoomType(
  organizationUnitId: any,
  payload: any
): CreateRoomTypeAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.CREATE_ROOM_TYPE,
    organizationUnitId,
    payload,
  };
}

export function UpdateRoomType(
  organizationUnitId: any,
  roomTypeId: any,
  payload: any
): UpdateRoomTypeAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.UPDATE_ROOM_TYPE,
    organizationUnitId,
    roomTypeId,
    payload,
  };
}

export function DeleteRoomType(
  organizationUnitId: any,
  roomTypeId: any
): DeleteRoomTypeAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.DELETE_ROOM_TYPE,
    organizationUnitId,
    roomTypeId,
  };
}

export function ResyncRoomType(
  organizationUnitId: any,
  roomTypeId: any
): ResyncRoomTypeAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.RESYNC_ROOM_TYPE,
    organizationUnitId,
    roomTypeId,
  };
}

export function SetResyncRoomType(
  roomTypeId: any,
  data: any
): SetResyncRoomTypeAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.SET_RESYNC_ROOM_TYPE_DATA,
    roomTypeId,
    data,
  };
}

export function UpdateRoomTypeAllRooms(
  organizationUnitId: string,
  roomTypeId: string
): UpdateRoomTypeAllRoomsAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.UPDATE_ROOM_TYPE_ALL_ROOMS,
    organizationUnitId,
    roomTypeId,
  };
}

export function DeleteOrganizationUnit(id: any): DeleteOrganizationUnitAction {
  return { type: ORGANIZATION_UNITS_ACTIONS.DELETE_ORGANIZATION_UNIT, id };
}

export function GetRoomsForHotel(hotelId: any): GetRoomsForHotelAction {
  return { type: ORGANIZATION_UNITS_ACTIONS.GET_ROOMS_FOR_HOTEL, hotelId };
}

export function SetRoomsForHotel(
  hotelId: any,
  rooms: any
): SetRoomsForHotelAction {
  return {
    type: ORGANIZATION_UNITS_ACTIONS.SET_ROOMS_FOR_HOTEL,
    hotelId,
    rooms,
  };
}

export function UpdateRoom(
  roomId: string,
  room: Partial<IRoom>
): UpdateRoomAction {
  return { type: ORGANIZATION_UNITS_ACTIONS.UPDATE_ROOM, roomId, room };
}

export function DeleteRoom(roomId: string): DeleteRoomAction {
  return { type: ORGANIZATION_UNITS_ACTIONS.DELETE_ROOM, roomId };
}

export const organizationUnitsInitialState = {
  organizationUnits: [],
  hotelRooms: [],
  loading: false,
};

export default function ouReducer(
  state: AppState["organizationUnits"] = {
    hotelRooms: [],
    loading: false,
    organizationUnits: [],
  },
  action: OrganizationUnitActions
): AppState["organizationUnits"] {
  switch (action.type) {
    case ORGANIZATION_UNITS_ACTIONS.SET_ORGANIZATION_UNITS:
      return {
        ...state,
        organizationUnits: action.organizationUnits,
        loading: false,
      };
    case ORGANIZATION_UNITS_ACTIONS.GET_ORGANIZATION_UNITS:
    case ORGANIZATION_UNITS_ACTIONS.CREATE_ORGANIZATION_UNIT:
      return {
        ...state,
        loading: true,
      };
    case ORGANIZATION_UNITS_ACTIONS.SET_ROOMS_FOR_HOTEL:
      return {
        ...state,
        hotelRooms: [
          ...state.hotelRooms.filter((r) => r.hotelId != action.hotelId),
          { hotelId: action.hotelId, rooms: action.rooms },
        ],
      };
    case ORGANIZATION_UNITS_ACTIONS.SET_RESYNC_ROOM_TYPE_DATA:
      return {
        ...state,
        resync: {
          ...(state.resync || {}),
          [action.roomTypeId]: action.data,
        },
      };
    default:
      return state;
  }
}

export const getAllOrganizationUnitsSelector = (state: AppState) => {
  //* .flat() was causing a compilation error on my end
  const hotelRooms = ([] as IRoom[]).concat(
    ...state.organizationUnits.hotelRooms.map((r) => r.rooms)
  );
  // const hotelRooms = state.organizationUnits.hotelRooms.map((r) => r.rooms).flat();
  return [...hotelRooms, ...state.organizationUnits.organizationUnits];
};

// Epics
const getOrganizationUnits$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.GET_ORGANIZATION_UNITS),
    switchMap(() => {
      return organizationUnitService
        .getOrganizationUnitsAsClassInstances()
        .pipe(
          map((organizationUnits) => {
            return SetOrganizationUnits(organizationUnits);
          }),
          catchError(handleError)
        );
    })
  );
};

const createOrganizationUnit$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.CREATE_ORGANIZATION_UNIT),
    switchMap((a) => {
      return organizationUnitService
        .createOrganizationUnit(a.payload, a.notifyEmailAddress)
        .pipe(
          map(() => {
            return GetOrganizationUnits();
          }),
          catchError(handleError)
        );
    })
  );
};

const addCarouselToOrganizationUunit$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.ADD_CAROUSEL_TO_ORGANIZATION_UNIT),
    switchMap((a) => {
      return organizationUnitService
        .addCarouselToOrganizationUnit(a.organizationUnitId, a.carouselId)
        .pipe(
          map(() => {
            return GetOrganizationUnits();
          }),
          catchError(handleError)
        );
    })
  );
};

const removeCarouselfromOrganizationUunit$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.REMOVE_CAROUSEL_FROM_ORGANIZATION_UNIT),
    switchMap((a) => {
      return organizationUnitService
        .removeCarouselfromOrganizationUnit(a.organizationUnitId, a.carouselId)
        .pipe(
          map(() => {
            return GetOrganizationUnits();
          }),
          catchError(handleError)
        );
    })
  );
};

const updateOrganizationUunit$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNIT),
    flatMap((a) => {
      return organizationUnitService
        .updateOrganizationUnit(a.organizationUnitId, a.payload)
        .pipe(
          mergeMap((ou) => {
            if (ou.type === "room") {
              return [GetOrganizationUnits(), GetRoomsForHotel(ou.hotel)];
            }
            return [GetOrganizationUnits()];
          }),
          catchError(handleError)
        );
    })
  );
};

const updateOrganizationUunits$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNITS),
    flatMap((a) => {
      return organizationUnitService
        .updateOrganizationUnits(a.organizationUnitIds, a.payload)
        .pipe(
          mergeMap((ou) => {
            return [GetOrganizationUnits()];
          }),
          catchError(handleError)
        );
    })
  );
};

const createRooms$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.CREATE_ROOMS),
    switchMap((a) => {
      return organizationUnitService.createRooms(a.payload).pipe(
        map(() => {
          return GetOrganizationUnits();
        }),
        catchError(handleError)
      );
    })
  );
};

const createArea$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.CREATE_AREA),
    switchMap((a) => {
      return organizationUnitService.createRoom(a.payload).pipe(
        map(() => {
          return GetOrganizationUnits();
        }),
        catchError(handleError)
      );
    })
  );
};

const deleteOrganizationUnit$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.DELETE_ORGANIZATION_UNIT),
    switchMap((a) => {
      return organizationUnitService.deleteOrganizationUnit(a.id).pipe(
        mergeMap((ou) => {
          if (ou.type === "room") {
            return [GetOrganizationUnits(), GetRoomsForHotel(ou.hotel)];
          }
          return [GetOrganizationUnits()];
        }),
        catchError(handleError)
      );
    })
  );
};

const getRoomsForHotel$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.GET_ROOMS_FOR_HOTEL),
    switchMap((a) => {
      return organizationUnitService.getRoomsForHotel(a.hotelId).pipe(
        map((rooms) => {
          return SetRoomsForHotel(a.hotelId, rooms.data);
        }),
        catchError(handleError)
      );
    })
  );
};

const updateRoom$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.UPDATE_ROOM),
    switchMap((a) => {
      return organizationUnitService.updateRoom(a.roomId, a.room).pipe(
        mergeMap(() =>
          of(GetRoomsForHotel(a.room.hotel), GetOrganizationUnits())
        ),
        catchError(handleError)
      );
    })
  );
};

const deleteRoom$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.DELETE_ROOM),
    switchMap((a) => {
      return organizationUnitService.deleteRoom(a.roomId).pipe(
        map((room) => {
          return GetRoomsForHotel(room.hotel);
        }),
        catchError(handleError)
      );
    })
  );
};

const createRoomType$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.CREATE_ROOM_TYPE),
    switchMap(({ organizationUnitId, payload }) => {
      return organizationUnitService
        .createRoomType(organizationUnitId, payload)
        .pipe(
          map(() => {
            return GetOrganizationUnits();
          }),
          catchError(handleError)
        );
    })
  );
};

const updateRoomType$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.UPDATE_ROOM_TYPE),
    switchMap(({ organizationUnitId, roomTypeId, payload }) => {
      return organizationUnitService
        .updateRoomType(organizationUnitId, roomTypeId, payload)
        .pipe(
          map(() => {
            return GetOrganizationUnits();
          }),
          catchError(handleError)
        );
    })
  );
};

const deleteRoomType$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.DELETE_ROOM_TYPE),
    switchMap(({ organizationUnitId, roomTypeId }) => {
      return organizationUnitService
        .deleteRoomType(organizationUnitId, roomTypeId)
        .pipe(
          map(() => {
            return GetOrganizationUnits();
          }),
          catchError(handleError)
        );
    })
  );
};

const resyncRoomType$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.RESYNC_ROOM_TYPE),
    switchMap(({ organizationUnitId, roomTypeId }) => {
      return organizationUnitService
        .resyncRoomType(organizationUnitId, roomTypeId)
        .pipe(
          mergeMap((res) => {
            return [GetOrganizationUnits(), SetResyncRoomType(roomTypeId, res)];
          }),
          catchError(handleError)
        );
    })
  );
};

const updateRoomTypeAllRooms$: Epic = (action$) => {
  return action$.pipe(
    ofType(ORGANIZATION_UNITS_ACTIONS.UPDATE_ROOM_TYPE_ALL_ROOMS),
    switchMap(({ organizationUnitId, roomTypeId }) => {
      return organizationUnitService
        .updateRoomTypeAllRooms(organizationUnitId, roomTypeId)
        .pipe(
          mergeMap((rooms) => {
            return [
              SetRoomsForHotel(organizationUnitId, rooms),
              SetNotification({
                message: `Updated room type for ${rooms.length} rooms`,
                bgClass: "bg-success",
              }),
            ];
          }),
          catchError(handleError)
        );
    })
  );
};

export const organizationUnitEpics = [
  getOrganizationUnits$,
  createOrganizationUnit$,
  updateOrganizationUunit$,
  updateOrganizationUunits$,
  addCarouselToOrganizationUunit$,
  removeCarouselfromOrganizationUunit$,
  createRooms$,
  deleteOrganizationUnit$,
  getRoomsForHotel$,
  updateRoom$,
  deleteRoom$,
  createArea$,
  createRoomType$,
  updateRoomType$,
  deleteRoomType$,
  resyncRoomType$,
  updateRoomTypeAllRooms$,
];
