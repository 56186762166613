import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IProductOfferings } from "../../../../types/NendaTypes";
import { GetProductOfferingById } from "../../../redux/productOfferings.redux";
import { store } from "../../../redux/store";
import { AppState } from "../../../types/redux";
import { PageHeader } from "../../PageHeader/PageHeader";
import { SectionWrapper } from "../../UI/SectionWrapper/SectionWrapper";
import { ProductOfferingSelectsHandler } from "./ProductOfferingSelectsHandler";
import { getFinalPrice } from "@shared/productOfferingsUtils";

interface IProductOfferingProps {}

export const ProductOffering: React.FC<IProductOfferingProps> = () => {
  const params: { hotelChainId: string; id: string } = useParams();

  const [productOffering, setProductOffering] = useState<IProductOfferings>(
    {} as IProductOfferings
  );

  useEffect(() => {
    store.dispatch(GetProductOfferingById(params.hotelChainId, params.id));
  }, []);

  const offering = useSelector(
    (state: AppState) => state.productOfferings.productOffering
  );

  useEffect(() => {
    if (offering) {
      setProductOffering(offering);
    }
  }, [offering]);

  const metaData = productOffering.localizedMetadata
    ? productOffering.localizedMetadata[0]
    : null;

  const priceToFloat = () => getFinalPrice(productOffering);

  const bgClass =
    (offering.productIds || []).length === 0
      ? "bg-danger"
      : offering.numberOfAssets === 0
      ? "bg-warning"
      : "";
  return (
    <>
      {metaData && (
        <div className="product-offering">
          <PageHeader>
            <h2>
              <span className={`${bgClass}`}>{metaData.name}</span>
            </h2>
            {bgClass && (
              <p>
                This problem needs to be fixed on the Red Bee platform
                <br />
                {bgClass === "bg-danger" && (
                  <>
                    <span className="bg-danger text-white">Red:</span> Offering
                    without products
                    <br />
                  </>
                )}
                {bgClass === "bg-warning" && (
                  <>
                    <span className="bg-warning text-white">Orange:</span>{" "}
                    Offering without assets
                  </>
                )}
              </p>
            )}
          </PageHeader>
          <SectionWrapper>
            {productOffering.localizedMetadata && metaData.description && (
              <p>{metaData.description}</p>
            )}
            {productOffering.offeringPrice && (
              <p>
                Price: &#36;{priceToFloat()}{" "}
                {productOffering.offeringPrice.price.currency}
              </p>
            )}
          </SectionWrapper>
          {productOffering.externalId && (
            <SectionWrapper>
              <h4>ID</h4>
              <span>{productOffering.externalId}</span>
            </SectionWrapper>
          )}
          <SectionWrapper>
            <h4>Label</h4>
            <span>{productOffering.type}</span>
          </SectionWrapper>
          <SectionWrapper>
            <h4>Number of assets reached</h4>
            <span>{productOffering.numberOfAssets || 0}</span>
          </SectionWrapper>
          <ProductOfferingSelectsHandler offering={productOffering} />
        </div>
      )}
    </>
  );
};
