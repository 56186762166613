import { Select, MenuItem, Button } from "@mui/material";
import React from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { ITitle } from "../../../types/NendaTypes";
import { getAvailableLanguages } from "../../redux/config.redux";

interface ITitleCreatorProps {
  values: Array<ITitle>;
  onValueChange(values: Array<any>): void;
}

export const TitleCreator: React.FC<ITitleCreatorProps> = ({
  values,
  onValueChange,
}) => {
  const select = React.useRef<any>(null);
  const availableLanguages = useSelector(getAvailableLanguages);
  const [state, setState] = React.useState({ values });
  React.useEffect(() => {
    setState({ values });
  }, [values]);
  function onChange(e) {
    const newState = {
      ...state,
      values: state.values.map((title) => {
        if (title.language === e.target.name) {
          title.text = e.target.value;
        }
        return title;
      }),
    };
    setState(newState);
    onValueChange(newState.values);
  }
  function addTitle() {
    if (select.current) {
      const newState = {
        ...state,
      };
      newState.values.push({ language: select.current.value, text: "" });
      setState(newState);
      onValueChange(newState.values);
    }
  }
  return (
    <div>
      <div className="input-group">
        <Select ref={select} className="form-control">
          {availableLanguages
            .filter((lang) => !values.find((v) => v.language === lang.value))
            .map((lang) => (
              <MenuItem key={lang.value} value={lang.value}>
                {lang.name}
              </MenuItem>
            ))}
        </Select>
        <button onClick={addTitle} className="btn btn-secondary">
          <FaPlus />
        </button>
      </div>
      {state.values.map((title) => {
        const language = availableLanguages.find(
          (l) => l.value === title.language
        );
        return (
          <div key={title.language} className="input-group py-2">
            <div className="input-group-prepend">
              <span className="input-group-text">
                {language ? language.name : title.language}
              </span>
            </div>
            <input
              name={title.language}
              onChange={onChange}
              value={title.text}
              type="text"
              className="form-control"
            />
            <Button
              variant="contained"
              color="error"
              onClick={() =>
                onValueChange(
                  state.values.filter((v) => v.language != title.language)
                )
              }
            >
              <FaTrash />
            </Button>
          </div>
        );
      })}
    </div>
  );
};
