import { Epic, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { productOfferingService } from "../http/productOfferings.service";
import {
  AppState,
  EditProductOfferingAction,
  GetProductOfferingByIdAction,
  GetProductOfferingsAction,
  ProductOfferingsActions,
  PRODUCT_OFFERING_ACTIONS,
  SetProductOfferingAction,
  SetProductOfferingsAction,
} from "../types/redux";
import { handleError } from "./error.redux";

export function EditProductOffering(
  hotelChainId: any,
  productOfferingId: any,
  labels: any
): EditProductOfferingAction {
  return {
    type: PRODUCT_OFFERING_ACTIONS.EDIT_PRODUCT_OFFERING,
    hotelChainId,
    productOfferingId,
    labels,
  };
}

export function SetProductOffering(
  productOffering: any
): SetProductOfferingAction {
  return {
    type: PRODUCT_OFFERING_ACTIONS.SET_PRODUCT_OFFERING,
    productOffering: productOffering,
  };
}

export function GetProductOfferings(id: any): GetProductOfferingsAction {
  return { type: PRODUCT_OFFERING_ACTIONS.GET_PRODUCT_OFFERINGS, id };
}

export function GetProductOfferingById(
  hotelChainId: any,
  productOfferingId: any
): GetProductOfferingByIdAction {
  return {
    type: PRODUCT_OFFERING_ACTIONS.GET_PRODUCT_OFFERING_BY_ID,
    hotelChainId,
    productOfferingId,
  };
}

export function SetProductOfferings(data: any): SetProductOfferingsAction {
  return {
    type: PRODUCT_OFFERING_ACTIONS.SET_PRODUCT_OFFERINGS,
    productOfferings: data,
  };
}

export default function poReducer(
  state: AppState["productOfferings"] = {
    loading: false,
    productOffering: null,
    productOfferings: [],
  },
  action: ProductOfferingsActions
): AppState["productOfferings"] {
  switch (action.type) {
    case PRODUCT_OFFERING_ACTIONS.EDIT_PRODUCT_OFFERING:
      //TODO: return edited product offerings, probably will only get the affected offering in the api call
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_OFFERING_ACTIONS.SET_PRODUCT_OFFERINGS:
      return {
        ...state,
        productOfferings: action.productOfferings,
        loading: false,
      };
    case PRODUCT_OFFERING_ACTIONS.GET_PRODUCT_OFFERINGS:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_OFFERING_ACTIONS.GET_PRODUCT_OFFERING_BY_ID:
      return {
        ...state,
        productOffering: {},
        loading: true,
      };
    case PRODUCT_OFFERING_ACTIONS.SET_PRODUCT_OFFERING:
      return {
        ...state,
        productOffering: action.productOffering,
        loading: false,
      };
    default:
      return state;
  }
}

// Epics
const getProductOfferings$: Epic = (action$) => {
  return action$.pipe(
    ofType(PRODUCT_OFFERING_ACTIONS.GET_PRODUCT_OFFERINGS),
    switchMap((a) => {
      return productOfferingService.getProductOfferings(a.id).pipe(
        map((productOfferings) => {
          productOfferings.sort((a, b) => {
            const idA = a.productOfferingId.toUpperCase(); // ignore upper and lowercase
            const idB = b.productOfferingId.toUpperCase(); // ignore upper and lowercase
            if (idA < idB) {
              return -1;
            }
            if (idA > idB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          return SetProductOfferings(productOfferings);
        }),
        catchError(handleError)
      );
    })
  );
};

const getProductOfferingById$: Epic = (action$) => {
  return action$.pipe(
    ofType(PRODUCT_OFFERING_ACTIONS.GET_PRODUCT_OFFERING_BY_ID),
    switchMap((a) => {
      return productOfferingService
        .getProductOfferingById(a.hotelChainId, a.productOfferingId)
        .pipe(
          map((productOffering) => {
            return SetProductOffering(productOffering);
          }),
          catchError(handleError)
        );
    })
  );
};

const editProductOffering$: Epic = (action$) => {
  return action$.pipe(
    ofType(PRODUCT_OFFERING_ACTIONS.EDIT_PRODUCT_OFFERING),
    switchMap((a) => {
      return productOfferingService
        .editProductOffering(a.hotelChainId, a.productOfferingId, a.labels)
        .pipe(
          map((productOffering) => {
            return SetProductOffering(productOffering);
          }),
          catchError(handleError)
        );
    })
  );
};

export const productOfferingEpics = [
  getProductOfferings$,
  editProductOffering$,
  getProductOfferingById$,
];
