import { PersonAddOutlined } from "@mui/icons-material";
import { Button, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Resource, Role, Scope } from "../../../../types/NendaTypes";
import PermissionsGate from "../components/PermissionGate";
import {
  CreateEditUserForm,
  isPremiseSelectVisible,
} from "../components/User/CreateEditUserForm";
import UserList from "../components/User/UserList";
import { store } from "../store";
import { selectUserCompany } from "../store/reducers/authReducer";
import {
  createUser,
  selectUsers,
  updateUser,
} from "../store/reducers/userReducer";
import DefaultDialog from "../ui-components/dialog/dialog";

export type CreateUserProps = {
  _id: string;
  username: string;
  password?: string;
  role: Role;
  company: string;
  shouldSendEmail: boolean;
  selectedPremises: string[];
};

const initialUserDetails = (defaultCompany?: string): CreateUserProps => {
  return {
    _id: "",
    username: "",
    password: "",
    role: Role.Superuser,
    company: defaultCompany || "",
    shouldSendEmail: true,
    selectedPremises: [],
  };
};

const Users = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [editUserId, setEditUserId] = useState<string | null>(null);
  const users = useSelector(selectUsers);

  const defaultCompany = useSelector(selectUserCompany);

  const handleShowUserForm = () => {
    setShowUserForm(true);
  };

  const handleClearUserForm = () => {
    setUserDetails(initialUserDetails(defaultCompany));
    setEditUserId(null);
  };

  const handleCloseUserForm = () => {
    setShowUserForm(false);
    handleClearUserForm();
  };

  const handleSetEditUserId = (userId: string) => {
    setEditUserId(userId);
    handleShowUserForm();
  };

  const [userDetails, setUserDetails] = useState<CreateUserProps>(
    initialUserDetails(defaultCompany)
  );

  const userToEdit = users.data.find((u) => u._id === editUserId);

  if (userToEdit && userDetails._id !== editUserId) {
    setUserDetails({
      _id: userToEdit._id,
      username: userToEdit.email,
      password: "",
      role: userToEdit.type,
      company: userToEdit.company || "",
      shouldSendEmail: false,
      selectedPremises: userToEdit.premises || [],
    });
  }

  const handleCreateUserClick = ({ isConfirmed }) => {
    const {
      username,
      password,
      role,
      company,
      shouldSendEmail,
      selectedPremises,
    } = userDetails;

    const updateUserDetails = {
      _id: userDetails._id,
      email: username,
      type: role,
      company,
      shouldSendEmail,
      password,
      premises: isPremiseSelectVisible(role) ? selectedPremises : [],
    };
    if (!updateUserDetails.password) {
      delete updateUserDetails.password;
    }

    if (isConfirmed) {
      if (userDetails._id) {
        store.dispatch(updateUser({ ...updateUserDetails }));
      } else {
        store.dispatch(
          createUser({
            email: username,
            password: password || "",
            type: role,
            company,
            shouldSendEmail,
            premises: isPremiseSelectVisible(role) ? selectedPremises : [],
          })
        );
      }
    }

    handleCloseUserForm();
  };

  const onCompanyChange = (e: SelectChangeEvent) => {
    setUserDetails({
      ...userDetails,
      company: e.target.value,
      selectedPremises: [],
    });
  };
  const onRoleChange = (role: Role) => {
    setUserDetails({
      ...userDetails,
      role,
      selectedPremises: [],
    });
  };
  const onUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserDetails({
      ...userDetails,
      username: e.target.value,
    });
  };
  const onPasswordChange = (password: string) => {
    setUserDetails({
      ...userDetails,
      password,
    });
  };
  const onSendEmailChange = () => {
    setUserDetails({
      ...userDetails,
      shouldSendEmail: !userDetails.shouldSendEmail,
    });
  };
  const onPremisesChange = (premises: string[]) => {
    setUserDetails({
      ...userDetails,
      selectedPremises: premises,
    });
  };
  return (
    <PermissionsGate
      restriction={{
        resource: Resource.User,
        scopes: [Scope.CanCreate],
      }}
    >
      <>
        <Stack direction={"row"} alignItems={"center"} mb={"2rem"} gap="1rem">
          <Typography
            variant="h3"
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            {t("customerportal.pages.users.title")}
          </Typography>
          <Button variant="contained" size="small" onClick={handleShowUserForm}>
            {t("customerportal.pages.users.create_user")}{" "}
            <PersonAddOutlined sx={{ height: "1rem", ml: "0.5rem" }} />
          </Button>
        </Stack>
        <Stack gap={2}>
          <UserList onEditUser={handleSetEditUserId} />
        </Stack>
        <DefaultDialog
          open={showUserForm}
          onClose={handleCreateUserClick}
          title={editUserId ? "Edit User" : "Create User"}
        >
          <CreateEditUserForm
            onUserNameChange={onUserNameChange}
            onPasswordChange={onPasswordChange}
            onSendEmailChange={onSendEmailChange}
            onCompanyChange={onCompanyChange}
            onRoleChange={onRoleChange}
            onPremisesChange={onPremisesChange}
            userName={userDetails.username}
            password={userDetails.password || ""}
            role={userDetails.role}
            company={userDetails.company}
            shouldSendEmail={userDetails.shouldSendEmail}
            selectedPremiseIds={userDetails.selectedPremises}
            isEdit={!!editUserId}
          />
        </DefaultDialog>
      </>
    </PermissionsGate>
  );
};

export default Users;
