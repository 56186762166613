import { Box, List, ListItem, Typography } from "@mui/material";
import { IDailyContentPlaylist } from "../../../../../../types/NendaTypes";
import PlaylistTimeline from "../../DailyPlaylist/PlaylistTimeline";
import { playlistToContentTimelineIntervals } from "../../../../../utils/timeline";

type ExistingPlaylistListProps = {
  playlists: IDailyContentPlaylist[];
  onPlaylistSelect: (playlistId: string) => void;
  selectedPlaylistId: string | null;
};

const ExistingPlaylistList = ({
  playlists,
  onPlaylistSelect,
  selectedPlaylistId,
}: ExistingPlaylistListProps) => {
  return (
    <List
      sx={(theme) => ({
        background: theme.palette.transparent.blue,
        p: "0.5rem",
        borderRadius: "0.5rem",
      })}
    >
      {playlists?.map((pl) => {
        const isSelected = pl._id === selectedPlaylistId;
        return (
          <ListItem
            key={pl._id}
            onClick={() => onPlaylistSelect(pl._id)}
            sx={(theme) => ({
              background: isSelected
                ? theme.palette.transparent.blue
                : "transparent",
              "&:hover": {
                background: theme.palette.transparent.blue,
              },
              borderRadius: "0.5rem",
              outlineOffset: "-0.2rem",
              outline: isSelected
                ? `3px solid ${theme.palette.primary.light}`
                : "none",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            })}
          >
            <Box sx={{ minWidth: "12rem" }}>
              <Typography>{pl.name}</Typography>
            </Box>
            <Box sx={{ width: "100%", display: "flex" }}>
              <PlaylistTimeline
                playlist={playlistToContentTimelineIntervals(pl)}
              />
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ExistingPlaylistList;
