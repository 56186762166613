import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { PackageChannel } from "../../ContentManagerModal";
import VodMusicIcon from "../../../../../../../assets/images/svg/music_vod.svg";
import VodMoviesIcon from "../../../../../../../assets/images/svg/movies_vod.svg";
import { useMemo } from "react";
import { t } from "i18next";

export default function PackageChannelImage({
  channel,
  size = "large",
}: {
  channel: PackageChannel;
  size?: "large" | "small";
}) {
  const theme = useTheme();
  const height = size === "large" ? "50px" : "40px";
  const width = size === "large" ? "70px" : "60px";
  const { name, imageSrc, description } = channel;

  const channelImage = useMemo(() => {
    return imageSrc === "scanbox_vod" ? (
      <VodMoviesIcon
        style={{
          fill: theme.palette.primary.main,
          color: "blue",
          height: "100%",
          width: "100%",
        }}
      />
    ) : imageSrc === "stingray_vod" ? (
      <VodMusicIcon
        style={{
          fill: theme.palette.primary.main,
          color: "blue",
          height: "100%",
          width: "100%",
        }}
      />
    ) : (
      <img
        src={imageSrc}
        alt="channel"
        height={"100%"}
        width={"100%"}
        style={{ objectFit: "contain", objectPosition: "center" }}
      />
    );
  }, [channel]);

  return (
    <>
      {channel && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height,
            width,
            padding: "0.2rem",
            borderRadius: "2px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              backgroundColor: theme.palette.common.white,
              boxShadow: theme.shadows[24],
              borderWidth: "0.5px",
              borderColor: theme.palette.grey[100],
              borderStyle: "solid",
              p: "0.3rem",
            }}
          >
            {channel && (
              <Tooltip
                title={
                  <Box>
                    <Typography variant="body1">{t(name)}</Typography>
                    {description && (
                      <>
                        <br />
                        <Typography variant="body1">
                          {t(description)}
                        </Typography>
                      </>
                    )}
                  </Box>
                }
                arrow
                enterDelay={500}
              >
                <Box sx={{ width: "100%", height: "100%" }}>{channelImage}</Box>
              </Tooltip>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
