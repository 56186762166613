import { Box, Chip, Grid, Paper, Typography, useTheme } from "@mui/material";
import {
  ContentPackage,
  IRoom,
  UserSession,
} from "../../../../../../../../../../types/NendaTypes";
import { t } from "i18next";
import PackageChannelImage from "../packageSelector/PackageChannelImage";
import ContentManagerForm from "./ContentManagerForm";
import TermsAndConditions from "./TermsAndConditions";
import { PackageChannel } from "../../ContentManagerModal";

type AdditionalInformationProps = {
  screens: IRoom[];
  packages: ContentPackage[];
  selectedChannels: PackageChannel[];
  userEmail: string;
  acceptedTerms: boolean;
  setAcceptedTerms: (value: boolean) => void;
};

export const limitDisplayItems = (items: any, limit: number) => {
  const itemsDisplayLimit = limit;
  const displayableItems = items.slice(0, itemsDisplayLimit);
  const restOfItems = items.slice(itemsDisplayLimit);
  const additionalItemsText =
    restOfItems.length > 0
      ? t("common.and_x_more", {
          count: restOfItems.length,
        })
      : "";
  return { displayableItems, restOfItems, additionalItemsText };
};

const AdditionalInformation = ({
  screens,
  packages,
  selectedChannels,
  userEmail,
  acceptedTerms,
  setAcceptedTerms,
}: AdditionalInformationProps) => {
  const theme = useTheme();
  const roomsDisplayLimit = 10;
  const { displayableItems, additionalItemsText: additionalRoomsText } =
    limitDisplayItems(screens, roomsDisplayLimit);

  const totalPrice =
    packages.reduce((acc, p) => acc + p.price, 0) * screens.length;

  return (
    <Grid
      container
      columns={{ sm: 2, xs: 1 }}
      spacing={"1rem"}
      sx={{ overflowY: "scroll" }}
    >
      <Grid xs={1} item sx={{ width: "100%", padding: "0.5rem" }}>
        <Box sx={{ pb: "0.5rem" }}>
          <Typography variant="sectionTitle">
            {t(
              "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.contact_info"
            )}
          </Typography>
          <ContentManagerForm userEmail={userEmail} />
          <Box sx={{ pt: "1rem" }}>
            <Typography variant="sectionTitle">
              {" "}
              {t(
                "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.terms_and_conditions"
              )}
            </Typography>
          </Box>
          <TermsAndConditions
            acceptedTerms={acceptedTerms}
            setAcceptedTerms={setAcceptedTerms}
          />
        </Box>
      </Grid>
      <Grid xs={1} item sx={{ width: "100%", padding: "0.5rem" }}>
        <Box
          sx={{
            p: "0.5rem",
            background: "white",
            mb: "0.5rem",
            boxShadow: theme.shadows[24],
          }}
        >
          <Box sx={{ mb: "0.5rem" }}>
            <Typography variant="sectionTitle">
              {t(
                "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.rooms"
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "0.25rem",
              alignItems: "center",
              flexWrap: "wrap",
              maxWidth: "600px",
            }}
          >
            {displayableItems.map((s) => (
              <Chip key={s.name} size="small" label={s.name} />
            ))}
            {screens.length > roomsDisplayLimit && (
              <Typography variant="body1" fontSize="0.8rem" px="0.5rem">
                {additionalRoomsText}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            pb: "0.5rem",
            background: "white",
          }}
        >
          <Box
            sx={{
              p: "0.5rem",
              background: "white",
              borderRadius: "2px",
              boxShadow: theme.shadows[24],
            }}
          >
            <Box sx={{ mb: "0.5rem" }}>
              <Typography variant="sectionTitle">
                {t(
                  "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.included_content"
                )}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "0.2rem",
                flexWrap: "wrap",
                maxWidth: "600px",
              }}
            >
              {selectedChannels.map((ch, i) => {
                return (
                  <PackageChannelImage key={ch.id} channel={ch} size="small" />
                );
              })}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            p: "0.5rem",
            background: "white",
            borderRadius: "3px",
            boxShadow: theme.shadows[24],
          }}
        >
          <Box>
            <Typography variant="sectionTitle">
              {t(
                "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.pricing"
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              p: "0.5rem",
              borderRadius: "2px",
            }}
          >
            {packages.map((p) => (
              <PriceBoxRow
                key={p.id}
                title={p.name}
                value={p.price === 0 ? "" : p.price.toString()}
                suffix={
                  p.price === 0
                    ? "Free"
                    : (t(
                        "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.sek_short"
                      ) as string)
                }
              />
            ))}
            {screens.length > 0 && (
              <PriceBoxRow title="Rooms" value={screens.length} prefix={"x"} />
            )}
            <PriceBoxRow
              title="Total"
              value={totalPrice}
              suffix={
                t(
                  "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.sek_short_per_month"
                ) as string
              }
              size="large"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const PriceBoxRow = ({
  title,
  value,
  suffix,
  prefix,
  size = "small",
}: {
  title: string;
  value: string | number;
  suffix?: string;
  prefix?: string;
  size?: "large" | "small";
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        variant="body1"
        fontSize={size === "large" ? "1.2rem" : "0.8rem"}
      >
        {title}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
        {prefix && (
          <Typography
            variant="body1"
            fontSize={size === "large" ? "1.2rem" : "0.8rem"}
          >
            {prefix}
          </Typography>
        )}
        <Typography
          variant="body1"
          fontSize={size === "large" ? "1.2rem" : "0.8rem"}
        >
          {value}
        </Typography>
        {suffix && (
          <Typography
            variant="body1"
            fontSize={size === "large" ? "1.2rem" : "0.8rem"}
          >
            {suffix}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AdditionalInformation;
