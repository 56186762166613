import * as React from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  TextField,
  Typography,
  capitalize,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { ISignagePromotion } from "../../../../../../types/NendaTypes";
import DropdDownPaper from "../../../ui-components/DropdownPaper";
import { t } from "i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface BookingsProps {
  activePromotions: string[];
  availablePromotions: ISignagePromotion[];
  onChange: (selectedPromotions: string[]) => void;
}

const PromotionSelect: React.FC<BookingsProps> = ({
  activePromotions,
  availablePromotions,
  onChange,
}) => {
  const selectedPromotions: ISignagePromotion[] = availablePromotions.filter(
    (b) => activePromotions.includes(b._id)
  );

  const handlePromotionSelect = (
    _event: React.SyntheticEvent,
    value: ISignagePromotion[]
  ) => {
    onChange(value.map((b) => b._id));
  };

  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <Typography
        sx={{
          paddingLeft: "0.3rem",
          marginBottom: "0.4rem",
          fontSize: "0.7rem",
          letterSpacing: "0.07rem",
        }}
      >
        {capitalize(t("glossary.promotion", { count: 2 }))}
      </Typography>
      <Autocomplete
        multiple
        id="promotion-select"
        options={availablePromotions}
        value={selectedPromotions}
        onChange={handlePromotionSelect}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        PaperComponent={DropdDownPaper}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                background: "white !important",
              },
              "& .MuiOutlinedInput-root": {
                padding: "0.5rem !important",
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default PromotionSelect;
