import { Box, Typography } from "@mui/material";
import {
  AssetType,
  IFrontLayerInformationImage,
  IFrontLayerInformationImageContent,
} from "../../../../../../types/NendaTypes";
import { CloseOutlined, DragHandle } from "@mui/icons-material";
import ClickableImagePreview from "../../../ui-components/media/ClickableImagePreview";
import SortableList from "../../../ui-components/dnd/SortableList";
import { useCallback, useMemo } from "react";

const SortableImageList = ({
  images,
  setImages,
}: {
  images: IFrontLayerInformationImageContent["images"];
  setImages: (images: IFrontLayerInformationImageContent["images"]) => void;
}) => {
  if (!images) return null;
  const newImages = [...images];

  const onDrop = (dropResult: IFrontLayerInformationImage[]) => {
    dropResult.forEach((image, index) => {
      image.sortOrder = index + 1;
    });
    setImages(dropResult);
  };

  const sortedImages = useMemo(() => {
    return newImages?.sort((a, b) => a.sortOrder - b.sortOrder);
  }, [images]);

  const imageItems = useMemo(() => {
    return sortedImages.map((image) => ({
      ...image,
      id: image.locator,
    }));
  }, [sortedImages]);

  const renderItem = useCallback(
    (image: IFrontLayerInformationImage, index: number, listeners: any) => {
      const handleRemoveImage = () => {
        const updatedImages = newImages.filter(
          (img) => img.sortOrder !== image.sortOrder
        );
        setImages(updatedImages);
      };
      return (
        <ImageItem
          image={image}
          onRemove={handleRemoveImage}
          dragHandleListeners={listeners}
        />
      );
    },
    [imageItems]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      {imageItems && (
        <SortableList
          items={imageItems}
          renderItem={renderItem}
          onChange={onDrop}
        />
      )}
    </Box>
  );
};

const ImageItem = ({
  image,
  onRemove,
  dragHandleListeners,
}: {
  image: IFrontLayerInformationImage;
  onRemove: (value: number) => void;
  dragHandleListeners: any;
}) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        gap: "1rem",
        border: "1px solid #eee",
        px: "2rem",
        py: "0.5rem",
        my: "0.5rem",
        borderRadius: "0.2rem",
      }}
    >
      <Box
        sx={{
          cursor: "grab",
          "&:active": {
            cursor: "grabbing",
          },
        }}
        {...dragHandleListeners}
      >
        <DragHandle />
      </Box>
      <Box sx={{ px: "1rem" }}>
        <Typography variant="body2">{image.sortOrder}</Typography>
      </Box>
      <Box sx={{ width: "15rem" }}>
        <ClickableImagePreview url={image.locator} type={AssetType.IMAGE} />
      </Box>
      <Box sx={{ cursor: "pointer" }} onClick={() => onRemove(image.sortOrder)}>
        <CloseOutlined />
      </Box>
    </Box>
  );
};

export default SortableImageList;
