import { LoadingButton } from "@mui/lab";
import { Button, Stack, TextField, useTheme } from "@mui/material";
import React from "react";
import { updateObjectAtPath } from "@shared/objectUtils";
import { IStreamChannel } from "../../../../../types/NendaTypes";
import { store } from "../../store";
import {
  CreateStreamChannel,
  UpdateStreamChannel,
} from "../../store/reducers/streamChannelReducer";

interface StreamChannelFormProps {
  channel?: IStreamChannel;
  onClose: () => void;
}

type Mode = "create" | "edit";

const StreamChannelForm: React.FC<StreamChannelFormProps> = ({
  channel,
  onClose,
}) => {
  const [state, setState] = React.useState<Partial<IStreamChannel>>(
    channel
      ? {
          ...channel,
        }
      : {}
  );

  const mode: Mode = channel ? "edit" : "create";
  const theme = useTheme();
  const setStateValue = (key: string, value: string | undefined) => {
    setState(
      updateObjectAtPath<Partial<IStreamChannel>>(state || {}, key, value)
    );
  };

  const createChannel = () => {
    if (isValid()) {
      store.dispatch(CreateStreamChannel(state as IStreamChannel));
      onClose();
    }
  };

  const updateChannel = () => {
    if (channel && isValid()) {
      store.dispatch(
        UpdateStreamChannel(channel._id, {
          ...state,
        })
      );
      onClose();
    }
  };

  const handleCancel = () => {
    setState({ ...channel });
    onClose();
  };

  const isValid = () => {
    return state && state.name && state.externalId && state.streamLinks?.HLS;
  };

  const isCancelDisabled = () => {
    return (
      mode == "edit" &&
      channel?.name === state.name &&
      channel?.externalId === state.externalId &&
      channel?.streamLinks?.HLS === state.streamLinks?.HLS
    );
  };

  return (
    <Stack
      gap={2}
      sx={{
        maxWidth: "500px",
        p: 2,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <TextField
        id="name"
        label="Channel name"
        value={state.name || ""}
        autoComplete="off"
        required
        onChange={(e) => {
          setStateValue("name", e.target.value);
        }}
      />
      <TextField
        id="externalId"
        label="External Id"
        value={state.externalId || ""}
        autoComplete="off"
        required
        onChange={(e) => {
          setStateValue("externalId", e.target.value);
        }}
      />
      <TextField
        id="streamLinksHLS"
        label="HLS stream link"
        value={state.streamLinks?.HLS || ""}
        autoComplete="off"
        required
        onChange={(e) => {
          setStateValue("streamLinks.HLS", e.target.value);
        }}
      />
      <Button
        variant="outlined"
        color="error"
        onClick={handleCancel}
        disabled={isCancelDisabled()}
      >
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        onClick={mode === "create" ? createChannel : updateChannel}
        disabled={!isValid()}
      >
        {mode === "create" ? "Create" : "Save"}
      </LoadingButton>
    </Stack>
  );
};

export default StreamChannelForm;
