import { IStreamChannel } from "../../types/NendaTypes";
import { httpClient, Service } from "./client";

class StreamChannelService extends Service {
  getChannels() {
    return httpClient.get("/streamchannel", this.authHeader());
  }

  getChannel(id: string) {
    return httpClient.get(`/streamchannel/${id}`, this.authHeader());
  }

  createChannel(channel: IStreamChannel) {
    return httpClient.post("/streamchannel", channel, this.authHeader());
  }

  updateChannel(id: string, channel: Partial<IStreamChannel>) {
    return httpClient.patch(`/streamchannel/${id}`, channel, this.authHeader());
  }

  deleteChannel(id: string) {
    return httpClient.delete(`/streamchannel/${id}`, this.authHeader());
  }
}

export const streamChannelService = new StreamChannelService();
