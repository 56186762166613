import { CheckBox } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React from "react";
import { FaSave } from "react-icons/fa";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { DeleteSubPage, UpdateSubPage } from "../../redux/subPages.redux";
import { AppState } from "../../types/redux";
import { Confirm } from "../Confirm/Confirm";
import { PageHeader } from "../PageHeader/PageHeader";
import { StringArrayEdit } from "../StringArrayEdit/StringArrayEdit";
import { TitleCreator } from "../TitleCreator/TitleCreator";
import { SectionWrapper } from "../UI/SectionWrapper/SectionWrapper";
import "./SubPage.scss";
import { useParams } from "react-router-dom";

export const SubPageComponent: React.FC = () => {
  const params = useParams();
  const subPage = useSelector((state: AppState) =>
    state.subPages.subPages.find((p) => p._id === params.id)
  );
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [state, setState] = React.useState(subPage);
  React.useEffect(() => {
    setState(subPage);
  }, [subPage]);
  const allCarousels = useSelector(
    (state: AppState) => state.carousels.carousels
  );
  function update() {
    if (!state || !subPage) return;
    const { title, carousels, draft, icon } = state;
    store.dispatch(
      UpdateSubPage(subPage._id, { title, carousels, draft, icon })
    );
  }
  function deleteSubPage() {
    if (!subPage) return;
    store.dispatch(DeleteSubPage(subPage._id));
  }
  if (!subPage || !state) return null;
  return (
    <>
      <PageHeader>
        <h2>{subPage.name}</h2>
        <Button
          color="error"
          variant="contained"
          onClick={() => setShowDeleteConfirm(true)}
        >
          Delete page
        </Button>
      </PageHeader>
      {showDeleteConfirm && (
        <Confirm
          onCancel={() => setShowDeleteConfirm(false)}
          onConfirm={deleteSubPage}
        />
      )}
      <SectionWrapper>
        <h4>
          Titles{" "}
          <span className="description">
            The localized title of the carousel, to be shown in the apps.
          </span>
        </h4>
        <TitleCreator
          values={state.title}
          onValueChange={(v) => setState({ ...state, title: v })}
        />
      </SectionWrapper>
      <SectionWrapper>
        <h2>Icon</h2>
        <i style={{ fontSize: "4rem" }} className="material-icons">
          {state.icon}
        </i>
        <p>A material design icon name</p>
        <input
          className="form-control"
          name="icon"
          value={state.icon || ""}
          onChange={(e) => setState({ ...state, icon: e.target.value })}
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>Carousels</h4>
        <StringArrayEdit
          draggable={true}
          values={state.carousels.map((c) => {
            const carousel = allCarousels.find(
              (carousel) => carousel._id === c
            );
            return {
              name: carousel ? carousel.name : c,
              value: c,
              link: `/components/carousels/carousel/${c}`,
            };
          })}
          options={allCarousels.map((c) => ({ name: c.name, value: c._id }))}
          onChange={(values) => setState({ ...state, carousels: values })}
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>Status</h4>
        <ToggleButtonGroup
          value={state.draft}
          exclusive
          onChange={(e, v) => setState({ ...state, draft: v })}
          aria-label="Status"
        >
          <ToggleButton value={true} aria-label="Draft" color="warning">
            Draft
          </ToggleButton>
          <ToggleButton value={false} aria-label="Published" color="success">
            Published
          </ToggleButton>
        </ToggleButtonGroup>
      </SectionWrapper>
      <Button
        disabled={state == subPage}
        onClick={update}
        className="mr-2 my-2"
        variant="contained"
        color="secondary"
        startIcon={<FaSave />}
      >
        Save
      </Button>
    </>
  );
};
