import { useState, useEffect } from "react";

interface FileInfo {
  fileSize: number | null;
  duration: number | null;
  fileType: string | null;
  mediaType: "video" | "image" | null;
  resolution: {
    width: number | null;
    height: number | null;
  };
}
interface VideoDurationResult {
  duration: number | null;
  error: string | null;
}

export function useVideoDuration(videoUrl: string): VideoDurationResult {
  const [result, setResult] = useState<VideoDurationResult>({
    duration: null,
    error: null,
  });

  useEffect(() => {
    const fetchVideoDuration = async () => {
      try {
        const video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src); // Release the object URL to free up resources
          setResult({ duration: video.duration, error: null });
        };
        video.onerror = function () {
          setResult({
            duration: null,
            error: "Unable to fetch video duration",
          });
        };
        video.src = videoUrl;
      } catch (error) {
        setResult({ duration: null, error: "Error fetching video duration" });
      }
    };

    if (videoUrl) {
      fetchVideoDuration();
    }
  }, [videoUrl]);

  return result;
}

const useMediaFileInformation = (mediaUrl?: string) => {
  const [fileInfo, setFileInfo] = useState<FileInfo>({
    fileSize: null,
    duration: null,
    fileType: null,
    mediaType: null,
    resolution: {
      width: null,
      height: null,
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchMediaInformation = async (url) => {
    setIsLoading(true);

    if (!url) {
      setIsLoading(false);
      return;
    }

    const mediaType =
      url.toLowerCase().endsWith(".mp4") ||
      url.toLowerCase().endsWith(".m4v") ||
      url.toLowerCase().endsWith(".mov") ||
      url.toLowerCase().endsWith(".wmv") ||
      url.toLowerCase().endsWith(".avi")
        ? "video"
        : url.toLowerCase().endsWith(".jpg") ||
          url.toLowerCase().endsWith(".jpeg") ||
          url.toLowerCase().endsWith(".gif") ||
          url.toLowerCase().endsWith(".svg") ||
          url.toLowerCase().endsWith(".png")
        ? "image"
        : null;

    if (!mediaType) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const mediaElement =
      mediaType === "video" ? document.createElement("video") : new Image();

    try {
      if (mediaType === "video") {
        mediaElement.src = url;
        let duration: number | null = null;
        mediaElement.addEventListener("loadedmetadata", async () => {
          const videoDuration = (mediaElement as HTMLVideoElement).duration; // Duration in seconds
          duration = videoDuration;

          const fileType = url.split(".").pop()?.toLowerCase() || null;
          const videoWidth = (mediaElement as HTMLVideoElement).videoWidth; // Width in pixels
          const videoHeight = (mediaElement as HTMLVideoElement).videoHeight; // Height in pixels

          const response = await fetch(url, {
            method: "HEAD",
          });
          const fileSize = response.headers.get("Content-Length");
          const fetchedFileSize = fileSize ? Number(fileSize) : null;
          const info: FileInfo = {
            duration: duration || null,
            fileType,
            mediaType,
            resolution: {
              width: videoWidth,
              height: videoHeight,
            },
            fileSize: fetchedFileSize,
          };
          setFileInfo(info);
          setIsLoading(false);
          mediaElement.remove();
        });
      } else if (mediaType === "image") {
        mediaElement.src = url;
        mediaElement.onload = async () => {
          try {
            const width = (mediaElement as HTMLImageElement).naturalWidth; // Width in pixels
            const height = (mediaElement as HTMLImageElement).naturalHeight; // Height in pixels
            const fileType = url.split(".").pop()?.toLowerCase() || null;
            const response = await fetch(url, {
              method: "HEAD",
            });
            const fileSize = await response.headers.get("Content-Length");
            const fetchedFileSize = fileSize ? Number(fileSize) : null;
            const info: FileInfo = {
              fileType,
              mediaType,
              duration: null,
              fileSize: fetchedFileSize,
              resolution: {
                width,
                height,
              },
            };
            setFileInfo(info);

            setIsLoading(false);

            mediaElement.remove();
          } catch (error) {
            console.error("Error:", error);
            setIsLoading(false);
          }
        };
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (mediaUrl) {
      fetchMediaInformation(mediaUrl);
    } else {
      return;
    }
  }, [mediaUrl]);

  return { ...fileInfo, isLoading };
};

export default useMediaFileInformation;
