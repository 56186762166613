import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const DropdDownPaper = (props) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{ border: `1px solid ${theme.palette.primary.main}` }}
      elevation={8}
      {...props}
    />
  );
};

export default DropdDownPaper;
