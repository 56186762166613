import { MoreVert } from "@mui/icons-material";
import { Box, Menu, MenuItem, useTheme } from "@mui/material";
import { useState } from "react";

export interface Option {
  title: string;
  onClick: () => void;
}

interface ScreenListOptionsProps {
  options: Option[];
}

const ScreenListOptions: React.FC<ScreenListOptionsProps> = ({ options }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (options.length === 0) return null;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          "&:hover": { color: theme.palette.primary.main },
          cursor: "pointer",
          color: theme.palette.grey[500],
        }}
        onClick={handleClick}
      >
        <MoreVert color={"inherit"} />
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option) => {
          const handleClick = () => {
            option.onClick();
            handleClose();
          };
          return (
            <MenuItem key={option.title} onClick={handleClick}>
              {option.title}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ScreenListOptions;
