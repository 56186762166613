import { of } from "rxjs";
import {
  ErrorActions,
  ERROR_ACTIONS,
  SetErrorAction,
  SetLoadingAction,
} from "../../../../types/redux";

export interface ErrorState {
  currentError?: string;
  loading: boolean;
}

export const initialState: ErrorState = {
  currentError: undefined,
  loading: false,
};

export function SetError(data: string): SetErrorAction {
  return { type: ERROR_ACTIONS.SET_ERROR, currentError: data };
}

export function SetLoading(loading: boolean): SetLoadingAction {
  return { type: ERROR_ACTIONS.SET_LOADING, loading };
}

export const handleError = (err: any) => {
  return of(SetError(err.response ? err.response.message : err.message));
};

export default function errorReducer(
  state: ErrorState = initialState,
  action: ErrorActions
): ErrorState {
  switch (action.type) {
    case ERROR_ACTIONS.SET_ERROR:
      return {
        ...state,
        currentError: action.currentError,
        loading: false,
      };
    case ERROR_ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
}
