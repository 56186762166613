import { Box, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { FrontLayerInformationItem } from "../../../../../../types/NendaTypes";
import { CustomerPortalState, store } from "../../../store";
import { GetInfoItemRender } from "../../../store/reducers/frontlayerConfigReducer";
import DOMPurify from "dompurify";

export type PreviewInformationItemProps = {
  title: string;
  value: string;
};
const PreviewInformationItem = ({
  informationItem,
}: {
  informationItem: FrontLayerInformationItem;
}) => {
  const theme = useTheme();
  const itemWithMarkup = useSelector(
    (state: CustomerPortalState) => state.frontlayerConfig.infoItemPreview
  );

  useEffect(() => {
    if (
      !informationItem ||
      informationItem?.content === itemWithMarkup?.content
    )
      return;
    store.dispatch(GetInfoItemRender({ ...informationItem }));
  }, [informationItem]);

  const infoItemMarkup = {
    __html: DOMPurify.sanitize(itemWithMarkup?.markup || "", {
      USE_PROFILES: { html: true },
      ADD_TAGS: ["iframe"], // We need to allow iframes to be rendered even if not 100% safe
    }),
  };

  return (
    <Box sx={{ display: "flex" }}>
      {itemWithMarkup && (
        <Box
          sx={{
            borderRadius: "0.2rem",
            mx: "auto",
            border: `3px solid ${theme.palette.primary.light}`,
            aspectRatio: "13/9",
            width: "100%",
            overflowY: "scroll",
          }}
          dangerouslySetInnerHTML={infoItemMarkup}
        />
      )}
    </Box>
  );
};

export default PreviewInformationItem;
