import * as React from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { IStreamChannel } from "../../../../../../../types/NendaTypes";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface StreamChannelSelectProps {
  availableChannels: IStreamChannel[];
  selectedChannelIds: string[];
  onChange: (selectedChannels: string[]) => void;
}

const DropdDownPaper = (props) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{ border: `1px solid ${theme.palette.primary.main}` }}
      elevation={8}
      {...props}
    />
  );
};

const StreamChannelSelect: React.FunctionComponent<
  StreamChannelSelectProps
> = ({ availableChannels, onChange, selectedChannelIds = [] }) => {
  const selectedChannels: IStreamChannel[] = React.useMemo(
    () =>
      selectedChannelIds
        .map((id) => availableChannels.find((c) => c._id === id))
        .filter((c) => c !== undefined) as IStreamChannel[],
    [selectedChannelIds, availableChannels]
  );

  const handleChannelSelect = (_event, value: IStreamChannel[]) => {
    onChange(value.map((c) => c._id));
  };

  return (
    <div>
      <Typography sx={{ fontWeight: 900, mb: 1 }}>Stream channels</Typography>
      <Autocomplete
        multiple
        id="channel-select"
        options={availableChannels}
        value={selectedChannels}
        onChange={handleChannelSelect}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        PaperComponent={DropdDownPaper}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        style={{ maxWidth: 600 }}
        renderInput={(params) => <TextField {...params} label="Channels" />}
      />
    </div>
  );
};

export default StreamChannelSelect;
