import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StreamChannelListItem from "../../components/StreamChannels/StreamChannelListItem";
import { store } from "../../store";
import {
  GetStreamChannels,
  selectStreamChannel,
} from "../../store/reducers/streamChannelReducer";

const StreamChannel = () => {
  const { id }: any = useParams();
  const channel = useSelector((state: any) => {
    return selectStreamChannel(state, id);
  });

  useEffect(() => {
    store.dispatch(GetStreamChannels());
  }, []);

  return (
    <>
      <Typography mb={2} variant="h3">
        Stream channel
      </Typography>
      {channel && <StreamChannelListItem channel={channel} />}
    </>
  );
};

export default StreamChannel;
