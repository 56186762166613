import { CacheLevel } from "../../types/NendaTypes";
import { httpClient, Service } from "./client";

class UtilService extends Service {
  clearCache(cacheLevel: CacheLevel, id: string) {
    let url;
    if (cacheLevel == CacheLevel.All) url = "/util/clear-cache";
    else if (
      (cacheLevel == CacheLevel.PremiseGroup ||
        cacheLevel == CacheLevel.Premise) &&
      id
    )
      url = `/organizationunits/${id}/clear-cache`;
    else if (cacheLevel == CacheLevel.Room && id)
      url = `/organizationunits/rooms/${id}/clear-cache`;
    else throw new Error("Missing parameters to purge cache");
    return httpClient.get(url, this.authHeader());
  }
}

export const utilService = new UtilService();
