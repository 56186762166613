export function dataURLtoFile(
  dataUrl: string,
  fileName: string
): File | undefined {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
}

export const fileSizeConverted = (bytes: number): string => {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  if (!bytes) {
    return "";
  } else if (bytes < kilobyte) {
    return bytes + " bytes";
  } else if (bytes < megabyte) {
    const kilobytes = Math.round(bytes / kilobyte);
    return kilobytes + "KB";
  } else {
    const megabytes = Math.round(bytes / megabyte);
    return megabytes + "MB";
  }
};
