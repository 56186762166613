import { MenuItem, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { ReactElement, useEffect, useState } from "react";
import { Company, CompanyType } from "../../../../../../types/NendaTypes";
import { CompanyTypeLabel } from "../../../Pages/Companies";
import { store } from "../../../store";
import {
  CreateCompany,
  UpdateCompany,
} from "../../../store/reducers/companyReducer";
import DefaultDialog from "../../../ui-components/dialog/dialog";
import InputSelect from "../../../ui-components/input/InputSelect";
import TextInput from "../../../ui-components/input/TextInput";

export type CompanyInfo = {
  name: string;
  orgNumber: string;
  type: CompanyType;
};
interface EditCompanyFormProps {
  company?: Company;
  onClose: () => void;
  open: boolean;
}

const style = {
  textField: {
    maxWidth: "50ch",
  },
};

const CreateEditCompanyForm = ({
  company,
  onClose,
  open,
}: EditCompanyFormProps) => {
  const [editCompanyInfo, setEditCompanyInfo] = useState<CompanyInfo>({
    name: "",
    orgNumber: "",
    type: CompanyType.CUSTOMER,
  });
  const { name, orgNumber, type } = editCompanyInfo;

  useEffect(() => {
    if (company) {
      setEditCompanyInfo({
        name: company?.name,
        orgNumber: company?.organizationNumber || "",
        type: company?.type,
      });
    }
  }, [company]);

  const createCompany = () => {
    if (!company) {
      store.dispatch(
        CreateCompany({
          name,
          organizationNumber: orgNumber,
          type,
        })
      );
    }
  };

  const updateCompany = () => {
    if (company) {
      store.dispatch(
        UpdateCompany(company._id, {
          name,
          organizationNumber: orgNumber,
          type,
        })
      );
    }
  };

  const companyTypeItems: ReactElement[] = [];
  CompanyTypeLabel.forEach((label, value) => {
    companyTypeItems.push(
      <MenuItem value={value} key={value}>
        {label}
      </MenuItem>
    );
  });

  const handleEditCreateCompany = ({ isConfirmed }) => {
    if (!isConfirmed) return onClose();
    company ? updateCompany() : createCompany();
    onClose();
  };

  return (
    <DefaultDialog
      onClose={handleEditCreateCompany}
      open={open}
      title={
        company
          ? (t("customerportal.pages.companies.edit_company") as string)
          : (t("customerportal.pages.companies.create_company") as string)
      }
    >
      <Stack sx={{ width: "100%", height: "100%" }}>
        <InputSelect
          id="company-type"
          value={type}
          title={t("customerportal.pages.companies.company_type")}
          defaultValue={type || CompanyType.CUSTOMER}
          onChange={(e) =>
            setEditCompanyInfo({ ...editCompanyInfo, type: e.target.value })
          }
          variant={"outlined"}
          sx={{ textTransform: "capitalize", mb: "1.5rem" }}
        >
          {companyTypeItems}
        </InputSelect>
        <TextInput
          id="company-name"
          label={t("customerportal.pages.companies.company_name")}
          variant="standard"
          placeholder={name}
          value={name}
          onChange={(e) =>
            setEditCompanyInfo({ ...editCompanyInfo, name: e.target.value })
          }
        />
        <TextInput
          id="org-id"
          label={t("common.org_nr")}
          variant="standard"
          placeholder={orgNumber}
          value={orgNumber}
          onChange={(e) =>
            setEditCompanyInfo({
              ...editCompanyInfo,
              orgNumber: e.target.value,
            })
          }
          sx={{ minWidth: "20rem" }}
        />
      </Stack>
    </DefaultDialog>
  );
};

export default CreateEditCompanyForm;
