import React from "react";
import {
  Box,
  Button,
  Link,
  List,
  ListItemButton,
  ListItemText,
  useTheme,
} from "@mui/material";
import { IStreamChannel } from "../../../../../types/NendaTypes";
import StreamChannelForm from "./StreamChannelForm";
import { Link as RouterLink } from "react-router-dom";

interface StreamChannelsListProps {
  channels: IStreamChannel[];
}

const StreamChannelList: React.FC<StreamChannelsListProps> = ({ channels }) => {
  const [showForm, setShowForm] = React.useState(false);

  const handleClose = () => setShowForm(false);

  const theme = useTheme();

  return (
    <Box>
      {showForm ? (
        <StreamChannelForm onClose={handleClose} />
      ) : (
        <Button variant="outlined" onClick={() => setShowForm(true)}>
          Create channel
        </Button>
      )}
      <List>
        {channels.map((channel) => (
          <Link
            key={channel._id}
            component={RouterLink}
            to={`/signaldelivery/channel/${channel._id}`}
            style={{ textDecoration: "none" }}
          >
            <ListItemButton
              sx={{
                bgcolor: "background.paper",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "start",
                my: 1,
              }}
            >
              <ListItemText
                sx={{
                  width: "15%",
                  color: theme.palette.primary.dark,
                }}
                primary="Name"
                secondary={channel.name}
                secondaryTypographyProps={{
                  color: theme.palette.primary.light,
                }}
              />
              <ListItemText
                sx={{ minWidth: "15%" }}
                primary="External Id"
                secondary={channel.externalId}
                secondaryTypographyProps={{
                  color: theme.palette.primary.light,
                }}
              />
              <ListItemText
                sx={{ minWidth: "70%" }}
                primary="HLS stream link"
                secondary={channel.streamLinks.HLS}
                secondaryTypographyProps={{
                  color: theme.palette.primary.light,
                }}
              />
            </ListItemButton>
          </Link>
        ))}
      </List>
    </Box>
  );
};

export default StreamChannelList;
