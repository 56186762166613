import {
  CollectionsOutlined,
  Edit,
  SubscriptionsOutlined,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  LoadingStatus,
  NendaProduct,
  Resource,
  ScheduleSlotType,
  Scope,
} from "../../../../../../types/NendaTypes";
import { convertPremises } from "../../../Pages/Premises";
import { CustomerPortalState, store } from "../../../store";
import {
  GetCompanyAssets,
  selectAssetsBasedOnNavigationScope,
} from "../../../store/reducers/assetReducer";
import {
  SetCreateEditBookingType,
  ToggleBookingScreen,
} from "../../../store/reducers/customizationReducer";
import { selectOrganizationUnitsByCompanyId } from "../../../store/reducers/organizationUnitReducer";
import {
  batchGetScreens,
  selectScreensForPremises,
} from "../../../store/reducers/screenReducer";
import { selectNavigatedCompanyId } from "../../../store/reducers/workspaceReducer";
import { paletteLight } from "../../../theme/palette";
import DefaultTable from "../../../ui-components/table";
import { premiseTableProps } from "../../../ui-components/table/tableProps/premises";
import { PremiseTableRowData } from "../../../ui-components/table/types/premise_table";
import ActionGrid, { ActionGridItemProps } from "../../ActionGrid";
import CreateEditPromotionModal from "../../Bookings/create/CreateEditPromotionModal";
import PermissionsGate from "../../PermissionGate";
import CreateEditCompanyForm from "./CreateEditCompanyForm";

const CompanyDashboard = () => {
  const companyId = useSelector(selectNavigatedCompanyId) || "";
  const premisesLoading = useSelector(
    (state: CustomerPortalState) => state.organizationUnits.isLoading
  );

  const companies =
    useSelector((state: CustomerPortalState) => {
      return state.company.companies;
    }) || [];

  const company = companies.find((company) => company._id === companyId);
  const screensLoading = useSelector(
    (state: CustomerPortalState) => state.screen.isLoading
  );
  const assetsLoading = useSelector(
    (state: CustomerPortalState) =>
      state.asset.paginatedCompanyAssets.status === LoadingStatus.LOADING ||
      state.asset.paginatedPremiseAssets.status === LoadingStatus.LOADING
  );
  const assets = useSelector(selectAssetsBasedOnNavigationScope);
  const [premiseList, setPremiseList] = useState<PremiseTableRowData[]>([]);
  const companyPremises = useSelector((state: CustomerPortalState) =>
    selectOrganizationUnitsByCompanyId(state, companyId as string)
  );

  const anyPremiseHasSignage = companyPremises.some((premise) =>
    premise.nendaProducts.includes(NendaProduct.SIGNAGE)
  );

  const companyPremiseIds = companyPremises.map((p) => p._id);

  const screens = useSelector((state: CustomerPortalState) =>
    selectScreensForPremises(state, companyPremiseIds)
  );

  const isLoading = screensLoading || premisesLoading || assetsLoading;

  useEffect(() => {
    setPremiseList(convertPremises(companyPremises));
  }, [JSON.stringify(companyPremises)]);

  useEffect(() => {
    if (!companyId) return;
    store.dispatch(batchGetScreens(companyPremises.map((p) => p._id)));
  }, [companyId]);

  useEffect(() => {
    if (!companyId || assetsLoading) return;
    store.dispatch(
      GetCompanyAssets(companyId, {
        filter: {
          product: NendaProduct.SIGNAGE,
        },
      })
    );
  }, [companyId]);

  const navigate = useNavigate();

  const companyActions: ActionGridItemProps[] = [
    {
      title: t(
        "customerportal.pages.dashboard.cta.quick_nav.create_signage_promotion.title"
      ),
      description: t(
        "customerportal.pages.dashboard.cta.quick_nav.create_signage_promotion.description"
      ),
      color: paletteLight.secondary?.light,
      buttonText: t(
        "customerportal.pages.dashboard.cta.quick_nav.create_signage_promotion.button"
      ) as string,
      onClick: () => {
        store.dispatch(SetCreateEditBookingType(ScheduleSlotType.SIGNAGE));
        store.dispatch(ToggleBookingScreen());
      },
      icon: SubscriptionsOutlined,
      restriction: {
        resource: Resource.SignagePromotion,
        scopes: [Scope.CanEdit],
      },
      isVisible: anyPremiseHasSignage,
    },
    {
      title:
        "customerportal.pages.dashboard.cta.quick_nav.to_media_library.title",
      description:
        "customerportal.pages.dashboard.cta.quick_nav.to_media_library.description",
      color: paletteLight.secondary?.main,
      onClick: () => {
        navigate(`/promotions/media-library/company/${companyId}`);
      },
      buttonText:
        "customerportal.pages.dashboard.cta.quick_nav.to_media_library.button",
      icon: CollectionsOutlined,
      restriction: {
        resource: Resource.Asset,
        scopes: [Scope.CanEdit],
      },
      isVisible: anyPremiseHasSignage,
    },
  ];
  const [showEditCompany, setShowEditCompany] = useState(false);

  const handleToggleEditCompany = () => {
    setShowEditCompany(!showEditCompany);
  };
  return (
    <Box>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <PermissionsGate
            restriction={{
              scopes: [Scope.CanAdministrate],
              resource: Resource.Company,
            }}
          >
            <Button
              onClick={handleToggleEditCompany}
              sx={{ alignSelf: "flex-end" }}
              variant="outlined"
              startIcon={<Edit sx={{ height: "1rem" }} />}
            >
              {t("customerportal.pages.dashboard.edit_company")}
            </Button>
          </PermissionsGate>
          <CreateEditCompanyForm
            onClose={handleToggleEditCompany}
            open={showEditCompany}
            company={company}
          />
        </Box>
        <ActionGrid type={Resource.Company} items={companyActions} />
        <CreateEditPromotionModal
          isLoading={isLoading}
          assets={assets}
          screens={screens}
        />
      </Box>
      <Box sx={{ display: "flex", gap: "2rem" }}>
        <Typography variant="h3">
          {t("customerportal.pages.dashboard.title")}
        </Typography>
      </Box>
      <Box sx={{ py: "1rem" }}>
        {/* <InfoCard
          company={company}
          premises={companyPremises}
          isLoading={companiesLoading === "loading" || premisesLoading}
        /> */}
        <Box py={"1rem"}>
          <Typography variant="h6" py="1rem">
            {t("customerportal.pages.premises.title")}
          </Typography>
          <DefaultTable
            sort={{ column: "name", direction: "asc" }}
            loading={false}
            pagination={false}
            tableData={{
              rows: premiseList,
              cols: premiseTableProps.columns,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyDashboard;
