import {
  ArrowForward,
  CellTowerOutlined,
  ConnectedTvOutlined,
  QueuePlayNextOutlined,
} from "@mui/icons-material";
import { Box, Link, Tooltip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  Company,
  IOrganizationUnit,
  NendaProduct,
} from "../../../../../../types/NendaTypes";
import { capitalizeString } from "../../../../../utils/string";
import FrontlayerIcon from "../../../assets/images/svg/front_layer_icon.svg";
import { store } from "../../../store";
import { PremiseTableRowData } from "../types/premise_table";
import { ColumnProps } from "../types/table";
import { isMobile } from "react-device-detect";

export const premiseTableProps: { columns: ColumnProps[] } = {
  columns: [{ title: "name", value: "name", sortable: true }],
};

if (!isMobile) {
  premiseTableProps.columns.push(
    ...[
      { title: "address", value: "address" },
      { title: "entries", value: "numberOfEntries" },
      { title: "category", value: "category" },
      { title: "products", value: "products" },
      { title: "company", value: "company", hasOwnLink: true },
      { title: "", value: "endIcon", align: "right" },
    ]
  );
}

export const ProductIcon = ({ product }) => {
  const style = {
    sx: { height: "1.2rem", paddingRight: "0.2rem", margin: 0 },
  };

  switch (product) {
    case NendaProduct.FRONTLAYER:
      return (
        <Tooltip title={"Frontlayer"}>
          <Box sx={{ padding: 0, margin: 0, display: "flex" }}>
            <FrontlayerIcon id="frontlayer-icon" style={style.sx} />
          </Box>
        </Tooltip>
      );
    case NendaProduct.SIGNAGE:
      return (
        <Tooltip title={"Signage"}>
          <QueuePlayNextOutlined {...style} />
        </Tooltip>
      );
    case NendaProduct.SIGNAL_DELIVERY:
      return (
        <Tooltip title={"Signal Delivery"}>
          <CellTowerOutlined
            style={{ ...style.sx, height: "1.1rem", marginLeft: "-0.11rem" }}
          />
        </Tooltip>
      );
    case NendaProduct.STREAMING_APP:
      return (
        <Tooltip title={"Streaming"}>
          <ConnectedTvOutlined {...style} />
        </Tooltip>
      );
    default:
      return <></>;
  }
};

export const premiseTableData: (
  premise: IOrganizationUnit
) => PremiseTableRowData = (premise: IOrganizationUnit) => {
  if (!premise) return null;

  let company = premise.company as Company;

  if (typeof company === "string") {
    company = store.getState().company.companies.find((c) => c._id === company);
  }

  return {
    id: premise._id,
    name: (
      <Link component={RouterLink} to={`/dashboard/premise/${premise._id}`}>
        <Typography
          variant="body2"
          sx={(theme) => ({ [theme.breakpoints.down("md")]: { p: "1rem" } })}
        >
          {premise.name}
        </Typography>
      </Link>
    ),
    address: capitalizeString(
      premise.address.streetAddress + " " + premise.address.city
    ),
    numberOfEntries: premise.numberOfEntries || "-",
    category: capitalizeString(premise.category || ""),
    products: (
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {premise.nendaProducts.map((product: NendaProduct, i) => (
          <ProductIcon product={product} key={product + i} />
        ))}
      </Box>
    ),
    company: (
      <Link component={RouterLink} to={`/dashboard/company/${company?._id}`}>
        {company?.name || "-"}
      </Link>
    ),
    endIcon: (
      <Link component={RouterLink} to={`/dashboard/premise/${premise._id}`}>
        <ArrowForward
          id="endIcon"
          sx={{
            height: "1.2rem",
            width: "1.2rem",
            position: "relative",
          }}
        />
      </Link>
    ),
  };
};
