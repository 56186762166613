import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useQuery } from "../../../../utils/routerUtils";
import { CustomerPortalState, store } from "../../store";
import { ToggleCreateAssetDialog } from "../../store/reducers/assetReducer";
import DefaultDialog from "../../ui-components/dialog/dialog";
import { CreateAssetForm } from "../Premise/Signage/Asset/CreateAssetForm";

const UploadAssetModal = ({
  premise,
  company,
}: {
  premise?: string;
  company: string;
}) => {
  const query = useQuery();
  const open = useSelector(
    (state: CustomerPortalState) => state.asset.showCreateAssetDialog
  );

  const handleCloseDialog = () => {
    store.dispatch(ToggleCreateAssetDialog());
  };

  if (!company) return null;

  return (
    <DefaultDialog
      open={open}
      onClose={handleCloseDialog}
      defaultSaveButton={false}
      width="30%"
      title={
        premise
          ? (t("customerportal.pages.assets.create_premise_asset") as string)
          : (t("customerportal.pages.assets.create_company_asset") as string)
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <CreateAssetForm
          onClose={handleCloseDialog}
          companyId={company}
          premiseId={premise || ""}
        />
      </Box>
    </DefaultDialog>
  );
};

export default UploadAssetModal;
