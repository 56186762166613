import { Box, Step, StepButton, Stepper } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NendaProduct } from "../../../../../types/NendaTypes";
import { CustomerPortalState, store } from "../../store";
import {
  getScreens,
  selectScreensByProduct,
} from "../../store/reducers/screenReducer";
import { selectNavigatedPremiseId } from "../../store/reducers/workspaceReducer";
import DefaultDialog from "../../ui-components/dialog/dialog";
import ChannelOverrideStepContent from "./ChannelOverrideStepContent";

const steps = [
  "Select channel",
  "Select duration",
  "Select screens",
  "Confirm",
];

export type ChannelOverrideState = {
  premise: string;
  channelId: string;
  screens: string[];
  duration: number;
};

const ChannelOverride = ({
  open,
  channelId,
  onSave,
  onClose,
}: {
  channelId: string;
  open: boolean;
  onSave: (value: ChannelOverrideState) => void;
  onClose: () => void;
}) => {
  const initStep = channelId ? 1 : 0;
  const premise = useSelector(selectNavigatedPremiseId);
  const [activeStep, setActiveStep] = useState(initStep);
  const screens = useSelector((state: CustomerPortalState) =>
    selectScreensByProduct(state, NendaProduct.SIGNAGE)
  );
  const initState = {
    premise: premise || "",
    channelId,
    screens: [],
    duration: 1,
  };
  const [channelOverrideState, setChannelOverrideState] =
    useState<ChannelOverrideState>(initState);

  const totalSteps = () => {
    return steps.length;
  };

  // Modal has a init step to select channel, if we select this channel from the channel list, we can skip this step
  if (channelId && activeStep === 0) {
    setActiveStep(1);
  }

  if (channelId !== channelOverrideState.channelId) {
    setChannelOverrideState({ ...channelOverrideState, channelId });
  }

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (!screens.length) {
      store.dispatch(getScreens(premise || ""));
    }
  }, []);

  const clearState = () => {
    setChannelOverrideState(initState);
    setActiveStep(initStep);
  };

  const handleSave = () => {
    onSave(channelOverrideState);
    clearState();
  };

  const handleClose = () => {
    onClose();
    clearState();
  };

  const overrideIsValid = () => {
    return (
      !!channelOverrideState.channelId &&
      !!channelOverrideState.duration &&
      !!channelOverrideState.screens.length
    );
  };

  const stepIsValid = () => {
    switch (activeStep) {
      case 0:
        return !!channelOverrideState.channelId;
      case 1:
        return channelOverrideState.duration > 0;
      case 2:
        return !!channelOverrideState.screens.length;
      default:
        return true;
    }
  };
  const isLastStep = activeStep === totalSteps() - 1;
  return (
    <DefaultDialog
      open={open}
      onClose={handleClose}
      defaultSaveButton={false}
      customActions={[
        {
          label: t("common.previous"),
          onClick: handleBack,
          variant: "text",
          disabled: false,
          visible: activeStep > 0,
        },
        {
          label: t("common.next"),
          onClick: handleNext,
          variant: "contained",
          disabled: !stepIsValid(),
          visible: !isLastStep,
        },
        {
          label: t("common.save"),
          onClick: handleSave,
          variant: "contained",
          disabled: !overrideIsValid(),
          visible: isLastStep,
        },
      ]}
      title={
        t("customerportal.pages.content_channels.change_channel") as string
      }
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "30rem",
          height: "100%",
          position: "relative",
        }}
      >
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={activeStep > index}>
              <StepButton
                sx={(theme) => ({
                  "& .MuiStepLabel-alternativeLabel .Mui-disabled": {
                    color: theme.palette.text.disabled,
                  },
                })}
                onClick={handleStep(index)}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <Box
          sx={{
            py: "1rem",
          }}
        >
          <ChannelOverrideStepContent
            setStep={setActiveStep}
            activeStep={activeStep}
            channelOverrideState={channelOverrideState}
            setChannelOverrideState={setChannelOverrideState}
          />
        </Box>
      </Box>
    </DefaultDialog>
  );
};

export default ChannelOverride;
