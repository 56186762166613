import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "../Modal/Modal";
import { CreateRoomType } from "../../redux/organizationUnit.redux";
import { store } from "../../redux/store";
import { AppState } from "../../types/redux";
import { RoomTypeCategory } from "../../../types/NendaTypes";
import { Button, MenuItem, Select } from "@mui/material";

interface ICreateRoomTypeButtonProps {
  hotelChainId: string;
}

export const CreateRoomTypeButton: React.FC<ICreateRoomTypeButtonProps> = ({
  hotelChainId,
}) => {
  const [showForm, setShowForm] = React.useState(false);
  const [state, setState] = React.useState<{
    name: string;
    based: string | null;
  }>({ name: "", based: null });

  const hotelChain = useSelector((state: AppState) =>
    state.organizationUnits.organizationUnits.find(
      ({ _id }) => _id === hotelChainId
    )
  );

  function submitRoomType() {
    const based = hotelChain?.roomTypes.find(({ _id }) => _id === state.based);
    const payload = {
      hotelChain: hotelChainId,
      name: state.name,
      carousels: based ? [...based.carousels] : [],
      pages: based ? [...based.pages] : [],
      category: based?.category || RoomTypeCategory.ROOM,
    };
    store.dispatch(CreateRoomType(hotelChainId, payload));
  }

  return (
    <>
      <Button
        onClick={() => setShowForm(true)}
        variant="contained"
        color="secondary"
      >
        Add room type
      </Button>
      <Modal
        visible={showForm}
        onClose={() => setShowForm(false)}
        title={"Add room type"}
        onSubmit={submitRoomType}
      >
        <>
          <p>
            Please, provide the name for this room type. Also, you can select an
            existent as base(the carousels and pages will be copied over to the
            new room type)
          </p>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">Name</span>
            </div>
            <input
              onChange={(e) => setState({ ...state, name: e.target.value })}
              name="name"
              value={state.name}
              type="text"
              className="form-control"
            />
          </div>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">Use as base:</span>
            </div>
            <Select
              onChange={(e) =>
                setState({ ...state, based: e.target.value as string })
              }
              className="form-control"
            >
              <MenuItem value=""></MenuItem>
              {hotelChain?.roomTypes.map((roomType, index) => (
                <MenuItem key={`${roomType._id}_${index}`} value={roomType._id}>
                  {roomType.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </>
      </Modal>
    </>
  );
};
