import { Box, Paper, useTheme } from "@mui/material";
import { t } from "i18next";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FrontLayerInformationImageItem,
  FrontLayerInformationItem,
  FrontLayerInformationSource,
  FrontLayerInformationURLItem,
  IFrontLayerInformationImageContent,
  IFrontlayerConfigValues,
  IFrontlayerConfiguration,
  IOrganizationUnit,
  Resource,
  Scope,
} from "../../../../../types/NendaTypes";
import { store } from "../../store";
import {
  ClearCopyConfig,
  ClearFrontlayerPreviewLink,
  ClearImageUploadUrl,
  CreateFrontlayerConfig,
  GetCopyFromConfig,
  ImageUploadTypes,
  StoreFrontlayerConfigPreview,
  UpdateFrontlayerConfig,
  selectFrontlayerConfigImageUploadUrl,
  selectFrontlayerPreviewLink,
} from "../../store/reducers/frontlayerConfigReducer";
import { MenuOption } from "../../ui-components/DropdownMenu";
import ButtonOverlayList, {
  ActionButtonProps,
} from "../../ui-components/overlayComponents/ButtonOverlayList";
import PermissionsGate from "../PermissionGate";
import { FrontlayerConfig } from "../Premise/FrontlayerConfig/FrontlayerConfig";
import { defualtFrontLayerOptions } from "../Premise/FrontlayerConfig/constants";
import EditPreviewInformationItem from "./components/EditPreviewInformationItem";
import ImportConfigModal from "./components/ImportConfigModal";
import InformationItems from "./components/InformationItems";

const FrontlayerSettings = ({
  frontlayerConfig,
  copyFromConfig,
  premise,
}: {
  frontlayerConfig: IFrontlayerConfiguration;
  copyFromConfig?: IFrontlayerConfigValues;
  premise: IOrganizationUnit;
}) => {
  const theme = useTheme();
  const imageUploadUrl = useSelector(selectFrontlayerConfigImageUploadUrl);
  const isDefaultConfig = !premise?.frontlayerConfig;
  const premiseId = premise._id || "";
  const [showTransferConfigDialog, setShowTransferConfigDialog] =
    useState<boolean>(false);

  const [frontlayerId, setFrontlayerId] = useState<string>(
    frontlayerConfig._id
  );

  const initialState = {
    ...frontlayerConfig,
  };

  const [frontlayerState, setFrontlayerState] =
    useState<IFrontlayerConfigValues>(initialState);

  const [informationItems, setInformationItems] = useState<
    FrontLayerInformationItem[]
  >([...JSON.parse(JSON.stringify(initialState?.information?.items))]);

  useEffect(() => {
    if (copyFromConfig) {
      setFrontlayerState(copyFromConfig);
      setInformationItems([...copyFromConfig.information.items]);
      store.dispatch(ClearCopyConfig());
    }
  }, [copyFromConfig]);

  useEffect(() => {
    if (imageUploadUrl.url && imageUploadUrl.type) {
      switch (imageUploadUrl.type) {
        case ImageUploadTypes.logo:
          setFrontlayerState({
            ...frontlayerState,
            logoUrl: imageUploadUrl.url,
          });
          break;
        case ImageUploadTypes.homeScreenBackground:
          setFrontlayerState({
            ...frontlayerState,
            backgroundImageUrl: imageUploadUrl.url,
          });
          break;
        case ImageUploadTypes.radioPageBackground:
          setFrontlayerState({
            ...frontlayerState,
            radio: {
              ...frontlayerState.radio,
              backgroundImageUrl: imageUploadUrl.url,
            },
          });
          break;
        case ImageUploadTypes.informationPageBackground:
          setFrontlayerState({
            ...frontlayerState,
            information: {
              ...frontlayerState.information,
              backgroundImageUrl: imageUploadUrl.url,
            },
          });
          break;
        case ImageUploadTypes.applicationPageBackground:
          setFrontlayerState({
            ...frontlayerState,
            applications: {
              ...frontlayerState.applications,
              backgroundImageUrl: imageUploadUrl.url,
            },
          });
          break;
        case ImageUploadTypes.itemBackground:
          setFrontlayerState({
            ...frontlayerState,
            homeScreenItems: frontlayerState.homeScreenItems.map((item) =>
              item.id === imageUploadUrl.itemId
                ? { ...item, backgroundImageUrl: imageUploadUrl.url }
                : item
            ),
          });
      }
      store.dispatch(ClearImageUploadUrl());
    }
  }, [imageUploadUrl]);

  const [selectedInformationItemIndex, setSelectedInformationItemIndex] =
    useState<number>(0);

  const selectedItem = informationItems[selectedInformationItemIndex];

  if (frontlayerConfig._id !== frontlayerId) {
    setFrontlayerState({
      ...initialState,
    });
    setFrontlayerId(frontlayerConfig._id);
    setInformationItems([
      ...JSON.parse(JSON.stringify(initialState.information.items)),
    ]);
  }

  //Handlers
  const handleAddItem = (newName: string) => {
    const newInformationItem: FrontLayerInformationImageItem = {
      name: newName,
      sourceType: FrontLayerInformationSource.IMAGE,
      content: {
        images: [],
      },
    };
    const newInformationItems = [...informationItems, newInformationItem];

    setInformationItems(newInformationItems);

    // Select the new item
    setSelectedInformationItemIndex(newInformationItems.length - 1);
  };

  const handleChangeItemName = (newName: string, index: number) => {
    const newInformationItems = [...informationItems];
    newInformationItems[index].name = newName;
    setInformationItems(newInformationItems);
  };

  const handleRemoveItem = (index: number) => {
    const newInformationItems = [...informationItems];
    newInformationItems.splice(index, 1);
    setInformationItems(newInformationItems);
    setSelectedInformationItemIndex(0);
  };

  const handleTypeChange = (type: FrontLayerInformationSource) => {
    const newInformationItems = [...informationItems];
    const selectedItem = newInformationItems[selectedInformationItemIndex];
    selectedItem.sourceType = type;
    const oldContent = selectedItem.content;

    switch (type) {
      case FrontLayerInformationSource.IMAGE:
        selectedItem.content = {
          images: [],
        };
        break;
      case FrontLayerInformationSource.URL:
        selectedItem.content = {
          locator: "",
        };
        break;
      default:
        selectedItem.content = oldContent;
    }

    if (oldContent) {
      selectedItem.content = { ...selectedItem.content, ...oldContent };
    }

    setInformationItems(newInformationItems);
  };

  const handleUrlChange = (url: string) => {
    const newInformationItems = [...informationItems];
    (
      newInformationItems[
        selectedInformationItemIndex
      ] as FrontLayerInformationURLItem
    ).content.locator = url;
    setInformationItems(newInformationItems);
  };

  const handleImagesChange = (
    images: IFrontLayerInformationImageContent["images"]
  ) => {
    const newInformationItems = [...informationItems];
    (
      newInformationItems[
        selectedInformationItemIndex
      ] as FrontLayerInformationImageItem
    ).content.images = images;
    setInformationItems(newInformationItems);
  };

  const combinedWorkingState = () => {
    const singleContentItems = informationItems.map((item: any) => {
      if (item.sourceType === FrontLayerInformationSource.IMAGE) {
        return {
          ...item,
          content: {
            images: item.content.images,
          },
        };
      } else {
        return {
          ...item,
          content: {
            locator: item.content.locator,
          },
        };
      }
    });
    const combinedState: IFrontlayerConfiguration = {
      ...frontlayerState,
      _id: frontlayerId,
      name: frontlayerConfig.name,
      company: frontlayerConfig.company,
      premise: frontlayerConfig.premise,
      information: {
        ...frontlayerState?.information,
        items: singleContentItems || undefined,
      },
    };

    return combinedState;
  };

  const getPreviewUrl = () => {
    store.dispatch(
      StoreFrontlayerConfigPreview(premiseId, combinedWorkingState())
    );
  };

  const previewUrl = useSelector(selectFrontlayerPreviewLink);

  useEffect(() => {
    if (previewUrl) {
      window.open(previewUrl, "_blank");
      store.dispatch(ClearFrontlayerPreviewLink());
    }
  }, [previewUrl]);

  const saveChanges = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const combinedState = combinedWorkingState();

    if (!isDefaultConfig && frontlayerState) {
      store.dispatch(
        UpdateFrontlayerConfig(premise._id, frontlayerId, combinedState)
      );
    } else {
      store.dispatch(
        CreateFrontlayerConfig(
          {
            ...combinedState,
            name: `${premiseId}-${nanoid(8)}`,
            _id: undefined,
          },
          premiseId
        )
      );
    }
  };

  const navigate = useNavigate();
  const refresh = () => {
    navigate(0);
  };

  const handleToggleTransferConfigDialog = () => {
    setShowTransferConfigDialog(!showTransferConfigDialog);
  };

  const actionList: ActionButtonProps[] = [
    {
      label: t("customerportal.pages.frontlayer.preview_app"),
      onClick: getPreviewUrl,
      disabled: false,
      variant: "outlined",
    },
    {
      label: t("common.cancel"),
      onClick: refresh,
      disabled: false,
      variant: "outlined",
    },
    {
      label: t("common.save"),
      onClick: saveChanges,
      disabled: false,
      variant: "contained",
    },
  ];
  const secondaryActionList: MenuOption[] = [
    {
      label: t("customerportal.pages.frontlayer.import_config_from") || "",
      action: handleToggleTransferConfigDialog,
    },
  ];

  const handleOnImportConfig = (configId: string) => {
    store.dispatch(GetCopyFromConfig(configId));
  };

  const onCloseImportConfigDialog = ({
    isConfirmed,
    selectedConfigId,
  }: {
    isConfirmed: boolean;
    selectedConfigId?: string;
  }) => {
    if (isConfirmed && selectedConfigId) {
      handleOnImportConfig(selectedConfigId);
    }
    setShowTransferConfigDialog(false);
  };

  return (
    <Paper>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            width: "30%",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        >
          <InformationItems
            onAddItem={handleAddItem}
            onItemNameChange={handleChangeItemName}
            onRemoveItem={handleRemoveItem}
            informationItems={informationItems}
            setSelectedInformationItemIndex={setSelectedInformationItemIndex}
            selectedInformationItemIndex={selectedInformationItemIndex}
            setInformationItems={setInformationItems}
          />
        </Box>

        <Box
          sx={{
            width: "70%",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        >
          <EditPreviewInformationItem
            configActions={actionList}
            informationItem={selectedItem}
            onTypeChange={handleTypeChange}
            onUrlChange={handleUrlChange}
            onImagesChange={handleImagesChange}
          />
        </Box>
      </Box>
      <Box sx={{ p: "1rem" }}>
        {premise && frontlayerState && (
          <PermissionsGate
            restriction={{
              scopes: [Scope.CanAdministrate],
              resource: Resource.FrontlayerConfig,
            }}
          >
            <FrontlayerConfig
              frontlayerConfigId={frontlayerId}
              initialFrontlayerConfig={copyFromConfig || frontlayerConfig}
              setFrontlayerState={setFrontlayerState}
              premiseId={premise._id || ""}
              config={frontlayerState}
              isDefaultConfig={isDefaultConfig}
              frontLayerConfigOptions={defualtFrontLayerOptions}
            />
          </PermissionsGate>
        )}
        <PermissionsGate
          restriction={{
            scopes: [Scope.CanAdministrate],
            resource: Resource.FrontlayerConfig,
          }}
        >
          <>
            <ButtonOverlayList
              actions={actionList}
              secondaryActions={secondaryActionList}
              position={{ bottom: 0, right: 0 }}
            />
            <ImportConfigModal
              open={showTransferConfigDialog}
              onClose={onCloseImportConfigDialog}
            />
          </>
        </PermissionsGate>
      </Box>
    </Paper>
  );
};

export default FrontlayerSettings;
