import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "../../components/Grid/Grid";
import { Modal } from "../../components/Modal/Modal";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { SectionWrapper } from "../../components/UI/SectionWrapper/SectionWrapper";
import { CreateCarousel } from "../../redux/carousels.redux";
import { store } from "../../redux/store";
import { AppState } from "../../types/redux";

interface ICarouselsOverviewProps {}

export const CarouselsOverview: React.FC<ICarouselsOverviewProps> = () => {
  const [showCreateForm, setShowCreateForm] = React.useState(false);
  const [createState, setCreateState] = React.useState({ name: "" });
  const carousels = useSelector((state: AppState) => state.carousels.carousels);
  const submit = () => store.dispatch(CreateCarousel(createState));
  return (
    <>
      <PageHeader>
        <h2>Carousels</h2>
        <button
          onClick={() => {
            setShowCreateForm(true);
          }}
          type="button"
          className="btn btn-secondary"
        >
          Create a new carousel
        </button>
      </PageHeader>
      <Modal
        visible={showCreateForm}
        onClose={() => setShowCreateForm(false)}
        title={"Create new carousel"}
        onSubmit={submit}
      >
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">Name</span>
          </div>
          <input
            value={createState.name}
            onChange={(e) =>
              setCreateState({ ...createState, name: e.target.value })
            }
            type="text"
            className="form-control"
          />
        </div>
      </Modal>
      <SectionWrapper>
        <Grid
          items={carousels.map((c) => ({
            name: c.name,
            id: c._id,
            link: `/components/carousels/carousel/${c._id}`,
          }))}
        />
      </SectionWrapper>
    </>
  );
};
