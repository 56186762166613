import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import React from "react";
import { ensureArray } from "@shared/arrayUtils";
import {
  FrontLayerBackgroundType,
  IApplicationsPage,
  IFrontLayerApplication,
} from "../../../../../../../types/NendaTypes";
import InputSelect from "../../../../ui-components/input/InputSelect";
import TextInput from "../../../../ui-components/input/TextInput";
import ColorPicker from "../../../ColorPicker";
import BackgroundSelect from "../BackgroundSelect";

interface IApplicationProps {
  config: IApplicationsPage;
  availableApps: IFrontLayerApplication[];
  onConfigChange: (config: IApplicationsPage) => void;
  onUpload: (file: File) => void;
}

const Applications: React.FC<IApplicationProps> = ({
  config,
  availableApps,
  onConfigChange,
  onUpload,
}) => {
  const handleBackgroundTypeChange = (
    type: FrontLayerBackgroundType,
    isEnabled: boolean
  ) => {
    const backgroundTypes = [...ensureArray(config.backgroundTypes)];
    if (isEnabled) {
      backgroundTypes.push(type);
    } else {
      const index = backgroundTypes.indexOf(type);
      backgroundTypes.splice(index, 1);
    }

    onConfigChange({
      ...config,
      backgroundTypes,
    });
  };

  const handleAppSelectChange = (event: any) => {
    const {
      target: { value },
    } = event;

    const selectedApps: string[] =
      typeof value === "string" ? value.split(",") : value;

    const newApps = availableApps.filter((x) => selectedApps.includes(x.id));

    onConfigChange({
      ...config,
      items: newApps,
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "2rem",
          width: "100%",
        }}
      >
        <FormControl
          sx={{
            width: "100%",
            gridColumn: "span 2",
            display: "flex",
            flexWrap: "wrap",
            gap: "2rem",
          }}
        >
          <Box>
            <Typography variant="body2">
              {t(
                "customerportal.settings.premise_settings.frontlayer.select_applications"
              )}
            </Typography>
            <InputSelect
              labelId="app-select-label"
              id="app-select"
              name="applications"
              multiple
              value={(config.items || []).map((x) => x.id)}
              onChange={handleAppSelectChange}
              slot={{ textField: TextInput }}
              slotProps={{
                textFieldProps: {
                  id: "select-multiple-chip",
                  label: "Chip",
                  variant: "standard",
                },
              }}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {availableApps.map((app) => (
                <MenuItem key={app.id} value={app.id}>
                  {app.id}
                </MenuItem>
              ))}
            </InputSelect>
          </Box>
          <Box>
            <Typography variant="body2">
              {t(
                "customerportal.settings.premise_settings.frontlayer.background"
              )}
            </Typography>
            <BackgroundSelect
              canReset={true}
              onColorChange={(value) =>
                onConfigChange({
                  ...config,
                  backgroundColor: value,
                })
              }
              onTypeChange={handleBackgroundTypeChange}
              onUpload={onUpload}
              activeTypes={config.backgroundTypes || []}
              selectedColor={config.backgroundColor}
              selectedImageUrl={config.backgroundImageUrl}
            />
          </Box>
        </FormControl>
        <Box>
          <Typography variant="body2">Color</Typography>
          <ColorPicker
            color={config.defaultItemColor || ""}
            canReset={true}
            onChange={(value) =>
              onConfigChange({
                ...config,
                defaultItemColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Selected color</Typography>
          <ColorPicker
            color={config.defaultSelectedItemColor || ""}
            canReset={true}
            onChange={(value) =>
              onConfigChange({
                ...config,
                defaultSelectedItemColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Border color</Typography>
          <ColorPicker
            color={config.defaultItemBorderColor || ""}
            canReset={true}
            onChange={(value) =>
              onConfigChange({
                ...config,
                defaultItemBorderColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Selected border color</Typography>
          <ColorPicker
            color={config.defaultSelectedItemBorderColor || ""}
            canReset={true}
            onChange={(value) =>
              onConfigChange({
                ...config,
                defaultSelectedItemBorderColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Font color</Typography>
          <ColorPicker
            color={config.fontColor}
            canReset={true}
            onChange={(value) =>
              onConfigChange({
                ...config,
                fontColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">Selected font color</Typography>
          <ColorPicker
            color={config.selectedFontColor}
            canReset={true}
            onChange={(value) =>
              onConfigChange({
                ...config,
                selectedFontColor: value,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="body2">
            Change border color on selection
          </Typography>
          <RadioGroup
            row
            name="borderSelectionEnabled"
            value={config.isBorderSelectionEnabled ? "enabled" : "disabled"}
            onChange={(event) => {
              const value = (event.target as HTMLInputElement).value;
              onConfigChange({
                ...config,
                isBorderSelectionEnabled: value === "enabled" ? true : false,
              });
            }}
          >
            <FormControlLabel
              value={"enabled"}
              control={<Radio />}
              label="Enabled"
            />
            <FormControlLabel
              value={"disabled"}
              control={<Radio />}
              label="Disabled"
            />
          </RadioGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default Applications;
