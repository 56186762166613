import { httpClient, Service } from "./client";
import {
  FrontLayerInformationItem,
  IFrontlayerConfiguration,
} from "../../types/NendaTypes";
import { PatchRequest } from "../../types/RequestTypes";

class FrontlayerService extends Service {
  listFrontlayerConfigs() {
    return httpClient.get("/frontlayerconfig", this.authHeader());
  }

  getFrontlayerConfig(id: string) {
    return httpClient.get(`/frontlayerconfig/${id}`, this.authHeader());
  }

  getDefaultFrontlayerConfig() {
    return httpClient.get("/frontlayerconfig/default", this.authHeader());
  }

  createFrontlayerConfig(data: IFrontlayerConfiguration) {
    return httpClient.post("/frontlayerconfig", data, this.authHeader());
  }

  getInfoItemRender(data: FrontLayerInformationItem) {
    return httpClient.post(
      "/frontlayerconfig/renderedInfoItem",
      data,
      this.authHeader()
    );
  }

  updateFrontlayerConfig(
    id: string,
    request: PatchRequest<IFrontlayerConfiguration>
  ) {
    return httpClient.patch(
      `/frontlayerconfig/${id}`,
      request,
      this.authHeader()
    );
  }

  storeFrontlayerConfigPreview(
    premiseId: string,
    data: IFrontlayerConfiguration
  ) {
    return httpClient.post(
      `/organizationunits/${premiseId}/frontlayerConfig/preview`,
      data,
      this.authHeader()
    );
  }

  deleteFrontlayerConfig(id: string) {
    return httpClient.delete(`/frontlayerconfig/${id}`, this.authHeader());
  }
}

export const frontlayerService = new FrontlayerService();
