import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { CustomerPortalState, store } from "../store";
import { SetNotification } from "../store/reducers/notificationReducer";

export const Notification: React.FC = () => {
  const notification = useSelector(
    (state: CustomerPortalState) => state.notification
  );

  function close() {
    store.dispatch(SetNotification(undefined));
  }

  return notification.currentNotification ? (
    <Snackbar
      open
      autoHideDuration={6000}
      onClose={close}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={close}
        severity={notification.severity}
        sx={{ width: "100%" }}
      >
        {notification.currentNotification}
      </Alert>
    </Snackbar>
  ) : null;
};
