export function findClosestColor(targetColor, predefinedColors) {
  let minDifference = Number.MAX_VALUE;
  let closestColor = null;

  for (let i = 0; i < predefinedColors.length; i++) {
    const difference = colorDifference(targetColor, predefinedColors[i]);
    if (difference < minDifference) {
      minDifference = difference;
      closestColor = predefinedColors[i];
    }
  }

  return closestColor;
}

// Calculate color difference
export function colorDifference(color1, color2) {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const rDiff = rgb1.r - rgb2.r;
  const gDiff = rgb1.g - rgb2.g;
  const bDiff = rgb1.b - rgb2.b;

  return Math.sqrt(rDiff * rDiff + gDiff * gDiff + bDiff * bDiff);
}

export const baseColors = [
  "#836FFF",
  "#15F5BA",
  "#E26EE5",
  "#7E30E1",
  "#49108B",
  "#B6FFFA",
  "#98E4FF",
  "#80B3FF",
  "#687EFF",
  "#9400FF",
  "#AED2FF",
  "#FFE79B",
  "#DD58D6",
  "#9336B4",
  "#B3005E",
  "#0096FF",
  "#00D7FF",
  "#ACFADF",
  "#94ADD7",
  "#7C73C0",
  "#FF6868",
  "#FFBB64",
  "#7579E7",
  "#A3D8F4",
  "#B9FFFC",
  "#93329E",
  "#C3F584",
  "#F65C78",
  "#F57D1F",
  "#900C3F",
  "#6528F7",
  "#A91079",
  "#FFCA03",
  "#5717bf",
  "#5a56db",
];

const definedColors = () => {
  const colors: string[] = [];
  for (let i = 0; i < 200; i++) {
    const h = Math.random(); // Random hue value between 0 and 1
    const s = Math.random() * 0.5 + 0.25; // Random saturation value between 0.25 and 0.75
    const l = Math.random() * 0.5 + 0.25; // Random lightness value between 0.25 and 0.75

    const rgb = hslToRgb(h, s, l);
    const hex = rgbToHex(rgb[0], rgb[1], rgb[2]);

    colors.push(hex);
  }
  return colors;
};

export const preDefinedColors = definedColors();

// Convert HSL to RGB
export function hslToRgb(h, s, l) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

// Convert RGB to HEX
export function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
}
