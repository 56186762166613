import {
  CheckBox,
  CheckBoxOutlineBlank,
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { t } from "i18next";
import { useMemo } from "react";
import { ContentPackage } from "../../../../../../../../../../types/NendaTypes";
import { assetsToChannelsAndVODs } from "../../ContentManagerModal";
import PackageChannelImage from "./PackageChannelImage";

type PackageCardProps = {
  packageData: ContentPackage;
  selected: boolean;
  onSelect: (value: string) => void;
};

const PackageCard = ({ packageData, selected, onSelect }: PackageCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleSelect = () => {
    onSelect(packageData.id);
  };
  const assets = packageData.assets;
  const channels = useMemo(() => {
    return assetsToChannelsAndVODs(assets);
  }, [assets]);

  const packagePrice =
    packageData.price === 0 ? "Free" : packageData.price.toFixed(2);
  return (
    <Box
      onClick={handleSelect}
      sx={{
        background: selected ? theme.palette.transparent.blue : "white",
        cursor: "pointer",
        padding: "1rem",
        margin: "5px",
        position: "relative",
        borderRadius: "3px",
        border: "0.5px solid lightgray",
        outline: selected
          ? `3px solid ${theme.palette.primary.light}`
          : "3px solid transparent",
        boxShadow: theme.shadows[24],
        transition: "all 0.2s",
        "&:hover": {
          background: selected
            ? theme.palette.transparent.blue
            : theme.palette.grey[100],
        },
        "&:last-child": {
          marginBottom: "1rem",
        },
      }}
    >
      <Stack
        gap="1rem"
        direction={isMobile ? "column" : "row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            gap: "0.5rem",
            maxWidth: "600px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              flex: 1,
            }}
          >
            {/* {packageData.icon} */}
            <Typography variant="body2">{packageData.name}</Typography>
          </Box>
          <Stack direction="row" gap="0.3rem" flexWrap={"wrap"}>
            {channels.map((ch, i) => (
              <PackageChannelImage channel={ch} key={ch.id + ch.name} />
            ))}
          </Stack>
        </Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "2rem",
            px: "1rem",
          }}
        >
          <Typography variant="body2">
            {packagePrice}
            {packagePrice === "Free"
              ? ""
              : t(
                  "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.sek_short_per_month"
                )}
          </Typography>
          <Box>
            {packageData.isBaseProduct ? (
              <PackageCardRadioButton selected={selected} />
            ) : (
              <PackageCardCheckBox selected={selected} />
            )}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

const PackageCardCheckBox = ({ selected }: { selected: boolean }) => {
  const theme = useTheme();
  return (
    <>
      {selected ? (
        <CheckBox sx={{ color: theme.palette.primary.light }} />
      ) : (
        <CheckBoxOutlineBlank sx={{ color: theme.palette.primary.main }} />
      )}
    </>
  );
};

const PackageCardRadioButton = ({ selected }: { selected: boolean }) => {
  const theme = useTheme();
  return (
    <>
      {selected ? (
        <RadioButtonCheckedOutlined
          sx={{ color: theme.palette.primary.light }}
        />
      ) : (
        <RadioButtonUncheckedOutlined
          sx={{ color: theme.palette.primary.main }}
        />
      )}
    </>
  );
};

export default PackageCard;
