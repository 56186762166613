import {
  ISignagePromotion,
  LoadingStatus,
} from "../../../../../types/NendaTypes";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { catchError, filter, mergeMap, switchMap } from "rxjs/operators";
import { companyService } from "../../../../http/companyService";
import { of } from "rxjs";
import { SetError, handleError } from "./errorReducer";
import { organizationUnitService } from "../../../../http/organizationUnit.service";
import { SetNotification } from "./notificationReducer";
import { CustomerPortalEpic, CustomerPortalState } from "..";
import { batchAssignPromotionToScreenState } from "./screenReducer";

export interface PromotionState {
  companyPromotions: ISignagePromotion[];
  premisePromotions: ISignagePromotion[];
  companyPromotionsStatus: LoadingStatus;
  premisePromotionsStatus: LoadingStatus;
}

export const initialState: PromotionState = {
  companyPromotions: [],
  premisePromotions: [],
  companyPromotionsStatus: LoadingStatus.IDLE,
  premisePromotionsStatus: LoadingStatus.IDLE,
};

interface CreatePayload {
  companyOrPremiseId: string;
  promotion: ISignagePromotion;
  applyToScreens: string[];
}

type UpdatePayload = Omit<CreatePayload, "promotion"> & {
  promotionId: string;
  promotionData: Partial<ISignagePromotion>;
};

interface DeletePayload {
  companyOrPremiseId: string;
  promotionId: string;
}

const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    getCompanyPromotions(state, _action: PayloadAction<string>) {
      state.companyPromotionsStatus = LoadingStatus.LOADING;
    },
    getPremisePromotions(state, _action: PayloadAction<string>) {
      state.premisePromotionsStatus = LoadingStatus.LOADING;
    },
    getCompanyPromotionsSuccess(
      state,
      action: PayloadAction<ISignagePromotion[]>
    ) {
      state.companyPromotions = action.payload;
      state.companyPromotionsStatus = LoadingStatus.SUCCEEDED;
    },
    getPremisePromotionsSuccess(
      state,
      action: PayloadAction<ISignagePromotion[]>
    ) {
      state.premisePromotions = action.payload;
      state.premisePromotionsStatus = LoadingStatus.SUCCEEDED;
    },
    getCompanyPromotionsFailure(state, _action: PayloadAction<string>) {
      state.companyPromotionsStatus = LoadingStatus.FAILED;
    },
    getPremisePromotionsFailure(state, _action: PayloadAction<string>) {
      state.premisePromotionsStatus = LoadingStatus.FAILED;
    },
    createPremisePromotion(state, _action: PayloadAction<CreatePayload>) {
      state.premisePromotionsStatus = LoadingStatus.LOADING;
    },
    createCompanyPromotion(state, _action: PayloadAction<CreatePayload>) {
      state.companyPromotionsStatus = LoadingStatus.LOADING;
    },
    createCompanyPromotionSuccess(
      state,
      action: PayloadAction<ISignagePromotion>
    ) {
      state.companyPromotions.push(action.payload);
      state.companyPromotionsStatus = LoadingStatus.SUCCEEDED;
    },
    createPremisePromotionSuccess(
      state,
      action: PayloadAction<ISignagePromotion>
    ) {
      state.premisePromotions.push(action.payload);
      state.premisePromotionsStatus = LoadingStatus.SUCCEEDED;
    },
    createCompanyPromotionFailure(state, _action: PayloadAction<string>) {
      state.companyPromotionsStatus = LoadingStatus.FAILED;
    },
    createPremisePromotionFailure(state, _action: PayloadAction<string>) {
      state.premisePromotionsStatus = LoadingStatus.FAILED;
    },
    updateCompanyPromotion(state, _action: PayloadAction<UpdatePayload>) {
      state.companyPromotionsStatus = LoadingStatus.LOADING;
    },
    updatePremisePromotion(state, _action: PayloadAction<UpdatePayload>) {
      state.premisePromotionsStatus = LoadingStatus.LOADING;
    },
    updateCompanyPromotionSuccess(
      state,
      action: PayloadAction<ISignagePromotion>
    ) {
      state.companyPromotions = state.companyPromotions.map((promotion) => {
        if (promotion._id === action.payload._id) {
          return action.payload;
        }
        return promotion;
      });
      state.companyPromotionsStatus = LoadingStatus.SUCCEEDED;
    },
    updatePremisePromotionSuccess(
      state,
      action: PayloadAction<ISignagePromotion>
    ) {
      state.premisePromotions = state.premisePromotions.map((promotion) => {
        if (promotion._id === action.payload._id) {
          return action.payload;
        }
        return promotion;
      });
      state.premisePromotionsStatus = LoadingStatus.SUCCEEDED;
    },
    updateCompanyPromotionFailure(state, _action: PayloadAction<string>) {
      state.companyPromotionsStatus = LoadingStatus.FAILED;
    },
    updatePremisePromotionFailure(state, _action: PayloadAction<string>) {
      state.premisePromotionsStatus = LoadingStatus.FAILED;
    },
    deleteCompanyPromotion(state, _action: PayloadAction<DeletePayload>) {
      state.companyPromotionsStatus = LoadingStatus.LOADING;
    },
    deletePremisePromotion(state, _action: PayloadAction<DeletePayload>) {
      state.premisePromotionsStatus = LoadingStatus.LOADING;
    },
    deleteCompanyPromotionSuccess(
      state,
      action: PayloadAction<ISignagePromotion>
    ) {
      state.companyPromotions = state.companyPromotions.filter(
        (promotion) => promotion._id !== action.payload._id
      );
      state.companyPromotionsStatus = LoadingStatus.SUCCEEDED;
    },
    deletePremisePromotionSuccess(
      state,
      action: PayloadAction<ISignagePromotion>
    ) {
      state.premisePromotions = state.premisePromotions.filter(
        (promotion) => promotion._id !== action.payload._id
      );
      state.premisePromotionsStatus = LoadingStatus.SUCCEEDED;
    },
    deleteCompanyPromotionFailure(state, _action: PayloadAction<string>) {
      state.companyPromotionsStatus = LoadingStatus.FAILED;
    },
    deletePremisePromotionFailure(state, _action: PayloadAction<string>) {
      state.premisePromotionsStatus = LoadingStatus.FAILED;
    },
  },
});

export const {
  getCompanyPromotions,
  getPremisePromotions,
  getCompanyPromotionsSuccess,
  getPremisePromotionsSuccess,
  getCompanyPromotionsFailure,
  getPremisePromotionsFailure,
  createCompanyPromotion,
  createPremisePromotion,
  createCompanyPromotionSuccess,
  createPremisePromotionSuccess,
  createCompanyPromotionFailure,
  createPremisePromotionFailure,
  updateCompanyPromotion,
  updatePremisePromotion,
  updateCompanyPromotionSuccess,
  updatePremisePromotionSuccess,
  updateCompanyPromotionFailure,
  updatePremisePromotionFailure,
  deleteCompanyPromotion,
  deletePremisePromotion,
  deleteCompanyPromotionSuccess,
  deletePremisePromotionSuccess,
  deleteCompanyPromotionFailure,
  deletePremisePromotionFailure,
} = promotionSlice.actions;

export default promotionSlice.reducer;

// Selectors

export const selectCompanyPromotions = (
  state: CustomerPortalState
): ISignagePromotion[] => {
  return state.promotion.companyPromotions;
};

export const selectPremisePromotions = (
  state: CustomerPortalState
): ISignagePromotion[] => {
  return state.promotion.premisePromotions;
};

export const selectPromotionById = createSelector(
  (state: CustomerPortalState) => state.promotion.companyPromotions,
  (state: CustomerPortalState) => state.promotion.premisePromotions,
  (_state: CustomerPortalState, promotionId?: string) => promotionId,
  (companyPromotions, premisePromotions, promotionId) => {
    if (!promotionId) return undefined;
    return companyPromotions
      .concat(premisePromotions)
      .find((p) => p._id === promotionId);
  }
);

export const selectPromotions = createSelector(
  selectCompanyPromotions,
  selectPremisePromotions,
  (companyPromotions, premisePromotions) => {
    return companyPromotions.concat(premisePromotions);
  }
);

export const selectDailyPromotions = createSelector(
  selectPromotions,
  (promotions) => {
    // Might show memoized values if promtions doesnt change
    // while the day changes
    const today = new Date();
    return promotions.filter(
      (promotion) =>
        new Date(promotion.schedule.date.start) <= today &&
        new Date(promotion.schedule.date.end) >= today &&
        promotion.schedule.days.includes(today.getDay())
    );
  }
);

// Epics
const getCompanyPromotions$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(promotionSlice.actions.getCompanyPromotions.match),
    switchMap((action: PayloadAction<string>) => {
      return companyService.getPromotions(action.payload).pipe(
        mergeMap((promotions: ISignagePromotion[]) => {
          return [
            promotionSlice.actions.getCompanyPromotionsSuccess(promotions),
          ];
        }),
        catchError((error: any) => {
          return of([
            promotionSlice.actions.getCompanyPromotionsFailure(error),
            handleError(error),
          ]);
        })
      );
    })
  );
};

const getPremisePromotions$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(promotionSlice.actions.getPremisePromotions.match),
    switchMap((action: PayloadAction<string>) => {
      return organizationUnitService.getPromotions(action.payload).pipe(
        mergeMap((promotions: ISignagePromotion[]) => {
          return [
            promotionSlice.actions.getPremisePromotionsSuccess(promotions),
          ];
        }),
        catchError((error: any) => {
          return [
            promotionSlice.actions.getPremisePromotionsFailure(error),
            SetError("Failed to fetch promotions: " + error.message),
          ];
        })
      );
    })
  );
};

const createCompanyPromotion$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(createCompanyPromotion.match),
    switchMap((action: PayloadAction<CreatePayload>) => {
      return companyService
        .createPromotion(
          action.payload.companyOrPremiseId,
          action.payload.promotion,
          action.payload.applyToScreens
        )
        .pipe(
          mergeMap((promotion: ISignagePromotion) => {
            return [
              batchAssignPromotionToScreenState({
                promotionId: promotion._id,
                screens: action.payload.applyToScreens,
              }),
              createCompanyPromotionSuccess(promotion),
              SetNotification("Promotion created"),
            ];
          }),
          catchError((error: any) => {
            return [
              createCompanyPromotionFailure(error),
              SetError("Failed to create promotion: " + error.message),
            ];
          })
        );
    })
  );
};

const createPremisePromotion$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(createPremisePromotion.match),
    switchMap((action: PayloadAction<CreatePayload>) => {
      return organizationUnitService
        .createPromotion(
          action.payload.companyOrPremiseId,
          action.payload.promotion,
          action.payload.applyToScreens
        )
        .pipe(
          mergeMap((promotion: ISignagePromotion) => {
            return [
              createPremisePromotionSuccess(promotion),
              batchAssignPromotionToScreenState({
                promotionId: promotion._id,
                screens: action.payload.applyToScreens,
              }),
              SetNotification("Promotion created"),
            ];
          }),
          catchError((error: any) => {
            return [
              createPremisePromotionFailure(error),
              SetError("Failed to create promotion: " + error.message),
            ];
          })
        );
    })
  );
};

const updateCompanyPromotion$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(updateCompanyPromotion.match),
    switchMap((action: PayloadAction<UpdatePayload>) => {
      return companyService
        .updatePromotion(
          action.payload.companyOrPremiseId,
          action.payload.promotionId,
          action.payload.promotionData,
          action.payload.applyToScreens
        )
        .pipe(
          mergeMap((promotion: ISignagePromotion) => {
            return [
              updateCompanyPromotionSuccess(promotion),
              batchAssignPromotionToScreenState({
                promotionId: promotion._id,
                screens: action.payload.applyToScreens,
              }),
              SetNotification("Promotion updated"),
            ];
          }),
          catchError((error: any) => {
            return [
              updateCompanyPromotionFailure("Failed to update promotion"),
              SetError("Failed to update promotion: " + error.message),
            ];
          })
        );
    })
  );
};

const updatePremisePromotion$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(updatePremisePromotion.match),
    switchMap((action: PayloadAction<UpdatePayload>) => {
      return organizationUnitService
        .updatePromotion(
          action.payload.companyOrPremiseId,
          action.payload.promotionId,
          action.payload.promotionData,
          action.payload.applyToScreens
        )
        .pipe(
          mergeMap((promotion: ISignagePromotion) => {
            return [
              updatePremisePromotionSuccess(promotion),
              batchAssignPromotionToScreenState({
                promotionId: promotion._id,
                screens: action.payload.applyToScreens,
              }),
              SetNotification("Promotion updated"),
            ];
          }),
          catchError((error: any) => {
            return [
              updatePremisePromotionFailure(error),
              SetError("Failed to update promotion: " + error.message),
            ];
          })
        );
    })
  );
};

const deleteCompanyPromotion$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(deleteCompanyPromotion.match),
    switchMap((action: PayloadAction<DeletePayload>) => {
      return companyService
        .deletePromotion(
          action.payload.companyOrPremiseId,
          action.payload.promotionId
        )
        .pipe(
          mergeMap((promotion: ISignagePromotion) => {
            return [
              deleteCompanyPromotionSuccess(promotion),
              SetNotification("Promotion deleted"),
            ];
          }),
          catchError((error: any) => {
            return [
              deleteCompanyPromotionFailure(error),
              SetError("Failed to delete promotion: " + error.message),
            ];
          })
        );
    })
  );
};

const deletePremisePromotion$: CustomerPortalEpic = (action$: any) => {
  return action$.pipe(
    filter(deletePremisePromotion.match),
    switchMap((action: PayloadAction<DeletePayload>) => {
      return organizationUnitService
        .deletePromotion(
          action.payload.companyOrPremiseId,
          action.payload.promotionId
        )
        .pipe(
          mergeMap((promotion: ISignagePromotion) => {
            return [
              deletePremisePromotionSuccess(promotion),
              SetNotification("Promotion deleted"),
            ];
          }),
          catchError((error: any) => {
            return [
              deletePremisePromotionFailure(error),
              SetError("Failed to delete promotion: " + error.message),
            ];
          })
        );
    })
  );
};

export const promotionEpics = [
  getCompanyPromotions$,
  getPremisePromotions$,
  createCompanyPromotion$,
  createPremisePromotion$,
  updateCompanyPromotion$,
  updatePremisePromotion$,
  deleteCompanyPromotion$,
  deletePremisePromotion$,
];
