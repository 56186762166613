import React from "react";
import { useSelector } from "react-redux";
import { OrganizationUnitType } from "../../../types/NendaTypes";
import {
  getAllOrganizationUnitsSelector,
  GetRoomsForHotel,
} from "../../redux/organizationUnit.redux";
import { store } from "../../redux/store";
import { Hotel } from "./Hotel";
import { HotelChain } from "./HotelChain";
import { OrganizationUnit as OrganizationUnitModel } from "../../models/organizationUnit";
import { useParams } from "react-router-dom";

interface IOrganizationUnitProps {
  match?: any;
}

export const OrganizationUnit: React.FC<IOrganizationUnitProps> = () => {
  const params = useParams();
  const organizationUnits = useSelector(getAllOrganizationUnitsSelector);
  const ou: OrganizationUnitModel = organizationUnits.find((o) => {
    return o._id === params.id;
  }) as OrganizationUnitModel;
  React.useEffect(() => {
    if (params.hotelId) {
      store.dispatch(GetRoomsForHotel(params.hotelId));
    }
  }, [params.hotelId]);
  if (!ou || !ou.type) return null;
  switch (ou.type) {
    case OrganizationUnitType.HOTEL_CHAIN:
      return <HotelChain ou={ou} />;
    default:
      return <Hotel key={ou._id} ou={ou} />;
  }
};
