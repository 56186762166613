import { Select, MenuItem, Button } from "@mui/material";
import React, { ReactNode, useEffect } from "react";
import { FaArrowsAlt, FaPlus, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./StringArrayEdit.scss";

export enum StringArrayEditTypes {
  COUNTRIES = "countries",
  ROOM_TYPES = "roomTypes",
}

interface IStringArrayEditProps {
  values: Array<any>;
  options?: any;
  onChange(values: Array<any>): void;
  maxCount?: number;
  draggable?: boolean;
  type?: StringArrayEditTypes;
  showPlus?: boolean;
  showDelete?: boolean;
}

export const StringArrayEdit: React.FC<IStringArrayEditProps> = ({
  values,
  options,
  onChange,
  maxCount,
  draggable,
  type,
  showPlus = true,
  showDelete = true,
}) => {
  values ||= [];
  const [filteredOptions, setFilteredOptions] = React.useState(options);

  useEffect(() => {
    filterOptions();
  }, [options, values]);

  const filterOptions = () => {
    if (options && options.length > 0) {
      /* TODO: not currently sure why we don't always filter the options / Simon */
      if (
        type === StringArrayEditTypes.COUNTRIES ||
        type === StringArrayEditTypes.ROOM_TYPES
      ) {
        const filtered = options.filter((option) => {
          const filteredCountries = values.filter((value) => {
            return value.value === option.value;
          });
          return filteredCountries.length <= 0;
        });
        setFilteredOptions(filtered);
      } else {
        setFilteredOptions(options);
      }
    }
  };

  function add() {
    onChange([...values.map((v) => v.value), currentlySelectedItem]);
  }
  const [currentlySelectedItem, setCurrentlySelectedItem] = React.useState("");
  const [currentlyDraggedItem, setCurrentlyDragggedItem] = React.useState(null);
  const [currentlyHoveredItem, setCurrentlyHoveredItem] = React.useState(null);
  React.useEffect(() => {
    if (currentlyDraggedItem && currentlyHoveredItem) {
      const mapped = values.map((v) => v.value);
      const newIndex = mapped.indexOf(currentlyHoveredItem);
      const remainingItems = mapped.filter((v) => v != currentlyDraggedItem);
      onChange([
        ...remainingItems.slice(0, newIndex),
        currentlyDraggedItem,
        ...remainingItems.slice(newIndex),
      ]);
    }
  }, [currentlyHoveredItem]);
  function onDragStart(itemValue) {
    setCurrentlyDragggedItem(itemValue);
  }
  function onDragOver(e, item) {
    e.preventDefault();
    setCurrentlyHoveredItem(item);
  }
  function onDragEnd() {
    setCurrentlyDragggedItem(null);
    setCurrentlyHoveredItem(null);
  }

  return (
    <div className="col-md-6 p-0">
      {showPlus && filteredOptions && filteredOptions.length > 0 ? (
        <div className="input-group">
          {
            <Select
              disabled={values?.length === maxCount}
              value={currentlySelectedItem}
              onChange={(e) => setCurrentlySelectedItem(e.target.value)}
              className="form-control col-md-10"
            >
              {filteredOptions.map((o) => (
                <MenuItem key={o.value} value={o.value}>
                  {o.name}
                </MenuItem>
              ))}
            </Select>
          }
          <Button
            variant="contained"
            color="secondary"
            onClick={add}
            disabled={values?.length === maxCount}
            className="col-md-2"
          >
            <FaPlus />
          </Button>
        </div>
      ) : null}

      <div>
        {values.map((v, index) => (
          <span key={v.value + index} className={"my-2 d-block"}>
            {v.link ? (
              <Link to={v.link}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={"col-md-10 " + (v.className || "")}
                  onDragStart={() => onDragStart(v.value)}
                  onDragOver={(e) => onDragOver(e, v.value)}
                  onDragEnd={() => onDragEnd()}
                  draggable={draggable}
                >
                  {draggable && (
                    <FaArrowsAlt
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "10px",
                      }}
                    />
                  )}
                  {v.name}
                </Button>
              </Link>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                className={"col-md-10 " + v.className}
                onDragStart={() => onDragStart(v.value)}
                onDragOver={(e) => onDragOver(e, v.value)}
                onDragEnd={() => onDragEnd()}
                draggable={draggable}
              >
                {draggable && (
                  <FaArrowsAlt
                    style={{ position: "absolute", left: "10px", top: "10px" }}
                  />
                )}
                {v.name}
              </Button>
            )}
            {showDelete && (
              <Button
                variant="contained"
                color="error"
                onClick={() =>
                  onChange(
                    values
                      .filter((value) => value.value != v.value)
                      .map((v) => v.value)
                  )
                }
                style={{ height: 36 }}
              >
                <FaTrash />
              </Button>
            )}
          </span>
        ))}
      </div>
    </div>
  );
};
