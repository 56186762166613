import {
  Components,
  InputLabel,
  Theme,
  autocompleteClasses,
  inputBaseClasses,
  inputClasses,
  inputLabelClasses,
  paperClasses,
  speedDialActionClasses,
  speedDialClasses,
} from "@mui/material";
import {
  buttonContainedStyles,
  buttonDangerStyles,
  buttonOutlinedStyles,
} from "./button";
import linkStyles from "./link";
import { selectStyles } from "./select";
import tableStyles from "./table";
import { textFieldStyles } from "./textField";
import { gridClasses } from "@mui/x-data-grid";
import { isMobile } from "react-device-detect";

// type for component overrides
export interface ComponentOverrides {
  MuiTable?: Components["MuiTable"];
  MuiTextField?: Components["MuiTextField"];
  MuiLink?: Components["MuiLink"];
  MuiButton?: Components["MuiButton"];
  MuiSelect?: Components["MuiSelect"];
  MuiMenu?: Components["MuiMenu"];
  MuiDialog?: Components["MuiDialog"];
  MuiCheckbox?: Components["MuiCheckbox"];
  MuiAccordion?: Components["MuiAccordion"];
  MuiSpeedDial?: Components["MuiSpeedDial"];
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    danger: true;
  }
}

export interface ThemeOptions {}

const componentOverrides: ComponentOverrides = {
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }: { theme: any }) => ({
        color: theme.palette.primary.main,
      }),
    },
  },
  MuiTable: {
    styleOverrides: {
      root: ({ theme }) => tableStyles(theme as Theme),
    },
  },
  MuiSpeedDial: {
    styleOverrides: {
      fab: ({ theme }: { theme: any }) => ({
        backgroundColor: theme.palette.primary.light,
      }),
      actions: ({ theme }: { theme: any }) => ({
        [`& .${speedDialClasses.fab}`]: {
          color: theme.palette.common.white,
        },
        [`& .${speedDialActionClasses.fab}`]: {
          color: theme.palette.common.white,
          background: theme.palette.primary.light,
        },
      }),
    },
  },
  MuiPopover: {
    defaultProps: {
      elevation: 0,
      disableScrollLock: true,
    },
    styleOverrides: {
      paper: ({ theme }: { theme: Theme }) => ({
        background: theme.palette.grey[800],
        padding: "0.5rem",
        borderRadius: "0.2rem",
        fontSize: "0.8rem",
      }),
    },
  },
  MuiAccordion: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: ({ theme }: { theme: any }) => ({
        background: theme.palette.transparent.purple,
        fontFamily: "Gilroy-Regular",
        fontWeight: 200,
        borderBottom: `1px solid ${(theme) => theme.palette.primary.main}`,
        borderRadius: "0.7rem",
        marginTop: "0.25rem !important",
        marginBottom: "0.25rem !important",
        "&.MuiAccordion-root:before": {
          backgroundColor: "transparent",
        },
        "& .MuiAccordionDetails-root": {
          backgroundColor: theme.palette.background.paper + " !important",
        },
      }),
    },
  },
  MuiTextField: {
    defaultProps: {
      InputProps: {
        sx: (theme: Theme) => {
          return {
            [`&.${inputClasses.focused}`]: {
              borderRadius: "0.7rem",
            },
          };
        },
      },
      InputLabelProps: {
        shrink: true,
        sx: (theme: Theme) => {
          return {
            [`&.${inputLabelClasses.root}`]: {
              fontSize: "0.9rem !important",
              top: "-0.3rem !important",
            },
            [`&.${inputLabelClasses.shrink}`]: {
              borderRadius: "5px",
              fontSize: "0.9rem",
              top: "-0.3rem !important",
              padding: "2px 8px !important",
            },
            [`&.${inputLabelClasses.outlined}`]: {
              background: theme.palette.gradient.darkBlue,
              fontSize: "0.9rem",
              top: "-0.3rem !important",
              color: `white !important`,
            },
            [`&.${inputLabelClasses.standard}`]: {
              background: "transparent",
              fontSize: "0.9rem",
              color: `${theme.palette.text.primary}`,
              position: "absolute !important",
              top: "-0.3rem !important",
            },
          };
        },
      },
    },
    styleOverrides: {
      root: ({ theme }: { theme: any }) => textFieldStyles(theme),
    },
  },
  MuiLink: {
    styleOverrides: {
      root: ({ theme }: { theme: any }) => linkStyles(theme),
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      size: isMobile ? "large" : "medium",
    },
    styleOverrides: {
      outlinedSizeLarge: {
        fontSize: "1rem",
      },
      outlinedSizeMedium: {
        fontSize: "0.9rem",
      },
      outlinedSizeSmall: {
        fontSize: "0.8rem",
      },
      containedSizeLarge: {
        fontSize: "1rem",
      },
      containedSizeMedium: {
        fontSize: "0.9rem",
      },
      containedSizeSmall: {
        fontSize: "0.8rem",
      },
      textSizeLarge: {
        fontSize: "1rem",
      },
      textSizeMedium: {
        fontSize: "0.9rem",
      },
      textSizeSmall: {
        fontSize: "0.8rem",
      },
      root: ({ ownerState, theme }: { ownerState: any; theme: any }) => ({
        ...(ownerState.variant === "contained" && buttonContainedStyles(theme)),
        ...(ownerState.variant === "outlined" && buttonOutlinedStyles(theme)),
        ...(ownerState.variant === "danger" && buttonDangerStyles(theme)),
      }),
    },
    variants: [
      {
        props: { variant: "contained" },
        style: ({ theme }: { theme: any }) => buttonContainedStyles(theme),
      },
      {
        props: { variant: "outlined" },
        style: ({ theme }: { theme: any }) => buttonOutlinedStyles(theme),
      },
    ],
  },
  MuiSelect: {
    variants: [
      {
        props: { variant: "outlined" },
        style: ({ theme }: { theme: any }) => selectStyles(theme),
      },
      {
        props: { variant: "standard" },
        style: ({ theme }: { theme: any }) => textFieldStyles(theme),
      },
    ],
  },
  MuiMenu: {
    styleOverrides: {
      paper: ({ theme }: { theme: any }) => ({
        background: theme?.palette.common.white,
        boxShadow: "10px 10px 88px 0px rgba(0,0,0,0.1)",
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }: { theme: any }) => ({
        minHeight: "50vh",
        [theme.breakpoints.down("md")]: {
          minWidth: "100vw",
        },
        width: "100%",
        overflow: "auto",
        background: theme?.palette.common.white,
        color: theme?.palette.text.primary,
        "& .MuiDialogContentText-root": {
          color: theme?.palette.text.primary,
        },
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        margin: 0,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }: { theme: any }) => ({
        color: theme?.palette.primary.main,
      }),
    },
    //@ts-ignore
    MuiPickersLayout: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          background: theme?.palette.common.white,
        }),
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        "& .MuiTabs-indicator": {
          backgroundColor: theme.palette.primary.dark,
        },
        "& [aria-selected=false]": {
          color: theme.palette.primary.dark,
        },
        "& [aria-selected=true]": {
          color: theme.palette.primary.dark,
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        padding: "0 !important",
      }),
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        // backgroundColor: theme.palette.common.white + " !important",
        [`&.${autocompleteClasses.paper}`]: {
          backgroundColor: theme.palette.common.white + " !important",
        },
        [`&.${paperClasses.outlined}`]: {
          backgroundColor: theme.palette.common.white + " !important",
        },
      }),
    },
  },
  MuiStepContent: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        padding: "0",
      }),
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        "& .MuiDataGrid-cell": {
          backgroundColor: theme.palette.common.white,
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
          display: "flex",
          alignItems: "center",
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: theme.palette.common.white,
          fontWeight: 500,
          borderBottom: `5px solid ${theme.palette.grey[200]}`,
          color: theme.palette.grey[800],
        },
        [`&.${gridClasses.root}`]: {
          border: "none !important",
        },
        [`& .MuiToolbar-root`]: {
          display: "flex",
          alignItems: "baseline",
        },
        [`& .MuiInputBase-root`]: {
          "&:before": {
            display: "none",
          },
        },
        "& .MuiDataGrid-toolbarContainer": {
          marginBottom: "0.5rem",
        },
        "& .MuiDataGrid-overlay": {
          minHeight: "50vh",
        },
      }),
    },
  },
};

export default componentOverrides;
