import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { t } from "i18next";
import RadioStations from "./Radio";
import {
  IFrontlayerConfigValues,
  IFrontlayerConfiguration,
  IRadioPage,
} from "../../../../../../../types/NendaTypes";
import { useEffect } from "react";

const RadioPageConfig = ({
  state,
  handleChange,
  upload,
  config,
}: {
  state: IFrontlayerConfiguration["radio"];
  handleChange: (mutatedState: IRadioPage) => void;
  upload: (file: File, imageCategory: string) => void;
  config: IFrontlayerConfigValues;
}) => {
  const radioPageBackgroundUpload = (file: File) => {
    upload(file, "radioPageBackground");
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t("customerportal.settings.premise_settings.frontlayer.radio")}
      </AccordionSummary>
      <AccordionDetails>
        <RadioStations
          config={state}
          onConfigChange={handleChange}
          onUpload={radioPageBackgroundUpload}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default RadioPageConfig;
