import { AddOutlined, CloseOutlined } from "@mui/icons-material";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import React, { ReactElement, useCallback } from "react";
import { IRoomType } from "../../../../../../types/NendaTypes";
import InputSelect from "../../../ui-components/input/InputSelect";
import TextInput from "../../../ui-components/input/TextInput";
import { CreateRoomsRangeParameters } from "../../../store/reducers/customerSetupReducer";

interface CreateRoomsFormProps {
  roomTypes: IRoomType[];
  rooms: CreateRoomsRangeParameters[];
  onRoomsChange: (rooms: CreateRoomsRangeParameters[]) => void;
}

const CreateRoomsForm: React.FC<CreateRoomsFormProps> = ({
  roomTypes,
  rooms,
  onRoomsChange,
}): React.ReactNode => {
  const handleRoomTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    const { value } = event.target;
    const newRooms = [...rooms];
    newRooms[i].roomType = value;
    onRoomsChange(newRooms);
  };

  const deleteRow = (i: number) => {
    const newRooms = [...rooms];
    newRooms.splice(i, 1);
    onRoomsChange(newRooms);
  };
  const addRow = () => {
    onRoomsChange([...rooms, { from: 0, to: 0, roomType: "" }]);
  };

  const columns: GridColDef[] = [
    {
      field: "from",
      headerName: "From",
      type: "number",
      width: 150,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        const handleFromChange = (value: number) => {
          const newRooms = [...rooms];
          newRooms[params.row.id].from = value;
          onRoomsChange(newRooms);
        };
        return (
          <TextInput
            type="number"
            variant="standard"
            error={!!params.row.errors?.from}
            placeholder={"0"}
            min={0}
            value={rooms[params.row.id].from || ""}
            onChange={(e) => handleFromChange(e.target.value)}
          />
        );
      },
    },
    {
      field: "to",
      headerName: "To",
      width: 150,
      type: "number",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        const handleToChange = (value: number) => {
          const newRooms = [...rooms];

          newRooms[params.row.id].to = value;
          onRoomsChange(newRooms);
        };
        return (
          <TextInput
            type="number"
            variant="standard"
            error={!!params.row.errors?.to}
            placeholder={"0"}
            min={0}
            value={rooms[params.row.id].to || ""}
            onChange={(e) => handleToChange(e.target.value)}
          />
        );
      },
    },
    {
      field: "room_type",
      headerName: "Room Type",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "right",
      flex: 1,
      align: "right",
      renderCell: (params) => {
        return (
          <InputSelect
            variant="standard"
            defaultValue={roomTypes || "Care"}
            displayEmpty
            error={!!params.row.errors?.roomType}
            id="roomType-select"
            value={rooms[params.row.id].roomType || ""}
            sx={{
              mt: "-1rem",
            }}
            onChange={(e) => handleRoomTypeChange(e, params.row.id)}
            renderValue={(value) => {
              const roomName = roomTypes?.find((c) => c._id === value)?.name;
              if (!value)
                return (
                  <Typography
                    variant="body1"
                    sx={{ opacity: !value ? 0.5 : 1 }}
                  >
                    {t("customerportal.pages.premises.create_modal.room_type")}
                  </Typography>
                );
              return roomName;
            }}
          >
            <MenuItem disabled value="">
              <strong>
                {t("customerportal.pages.premises.create_modal.room_type")}
              </strong>
            </MenuItem>
            {roomTypes?.map((c) => (
              <MenuItem
                key={c._id}
                value={c._id}
                sx={{ textTransform: "capitalize" }}
              >
                {c.name}
              </MenuItem>
            ))}
          </InputSelect>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 10,
      sortable: false,
      align: "center",
      renderCell: (params) => {
        return params.value as ReactElement;
      },
    },
  ];

  const rows = useCallback(
    () =>
      rooms?.map((room, index) => {
        return {
          id: index,
          from: room.from,
          to: room.to,
          room_type: room.roomType,
          errors: room.errors,
          actions: (
            <Button variant="text" onClick={() => deleteRow(index)}>
              <CloseOutlined />
            </Button>
          ),
        };
      }),
    [rooms]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "12rem" }}>
      <DataGrid
        rows={rows()}
        autoHeight
        columns={columns}
        hideFooter
        columnVisibilityModel={{
          id: false,
          errors: false,
        }}
      />
      <Button
        onClick={addRow}
        endIcon={<AddOutlined />}
        variant="outlined"
        sx={{ mb: "1rem" }}
      >
        Add row
      </Button>
    </Box>
  );
};

export default CreateRoomsForm;
