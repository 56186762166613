import {
  MobileStepper,
  Step,
  StepButton,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { t } from "i18next";

const ContentManagerStepper = ({ activeStep, completedSteps, steps }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {isMobile ? (
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          backButton={null}
          nextButton={null}
          sx={{
            background: "transparent",
            "& .MuiMobileStepper-positionStatic": {
              background: "transparent",
            },
          }}
        />
      ) : (
        <Stepper
          nonLinear
          activeStep={activeStep}
          sx={{ ml: isMobile ? "0" : "2rem" }}
        >
          {steps.map((label, index) => (
            <Step key={label} completed={completedSteps[index]}>
              <StepButton
                disabled={true}
                sx={{
                  "& .MuiStepLabel-label": {
                    color: theme.palette.grey[600],
                  },
                }}
              >
                {t(label)}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      )}
    </>
  );
};

export default ContentManagerStepper;
