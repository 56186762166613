// --- Tags ---
export interface ITagList {
  items: Array<ITagType>;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}

export interface ITagType {
  children: Array<ITagType>;
  localized: Array<ILocalizedTag>;
  parents: Array<string>;
  scheme: string;
  tagId: string;
}

export interface ILocalizedTag {
  description: string;
  locale: string;
  title: string;
  images: Array<ILocalizedImage>;
}

export interface ILocalizedImage {
  height: number;
  orientation: "PORTRAIT" | "LANDSCAPE" | "SQUARE" | "UNKNOWN";
  type: string;
  url: string;
  width: number;
}

// --- ProductOfferings ---
export interface ILocalizedMetadata {
  name: string;
  description: string;
  locale: string;
}

export interface ILabelValue {
  key: string;
  value: string;
}

export interface ILabelRule {
  and?: Array<ILabelRule>;
  or?: Array<ILabelRule>;
  eq?: ILabelValue;
  ne?: ILabelValue;
}

export interface IProductOffering {
  recurrence: string;
  businessUnit: string;
  rentalLength: string;
  localizedMetadata: Array<ILocalizedMetadata>;
  productIds: Array<string>;
  offeringPrice: any;
  salesStop: string;
  externalId: string;
  productOfferingId: string;
  entitlementStart: string;
  salesStart: string;
  customer: string;
  labelRule: ILabelRule;
  labelRuleHumanReadable: string;
  productRequiresSelectAsset: boolean;
  productOfferingType: string;
}

export interface IPricing {
  price: number;
  currency: string;
  showPrice: boolean;
}

// --- Assets ---
export interface IAssetList {
  items: Array<IAsset>;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}

export interface IAsset {
  assetId: string;
  audioTracks: Array<string>;
  changed: string;
  created: string;
  customData: any;
  defaultAudioTrack: string;
  episode: string;
  expires: string;
  externalReferences: Array<any>;
  linkedEntities: Array<any>;
  live: boolean;
  localized?: Array<ILocalizedData>;
  markers: Array<any>;
  medias: Array<any>;
  originalTitle: string;
  parentalRatings: Array<any>;
  participants: Array<any>;
  popularityScores: any;
  productionCountries: Array<string>;
  productionYear: number;
  publications: Array<IPublication>;
  rating: number;
  studio: string;
  releaseDate: string;
  runtime: number;
  season: string;
  seasonId: string;
  seasons: Array<any>;
  spokenLanguages: Array<string>;
  subtitles: Array<string>;
  tags: RedbeeTags;
  tvShowId: string;
  tvShow: any;
  type: RedbeeAssetType;
  userData: any;
  trackSizes: any;
  materialType: string;
  duration: number;
  channelFeatures: Array<string>;
  overlayWidgets: Array<any>;
  pricing?: IPricing;
}

type RedbeeTags = RedbeeTag[];

type RedbeeTag = {
  tagValues: { tagId: string }[];
  type: RedbeeTagType;
};

export enum RedbeeTagType {
  OTHER = "other",
  GENRE = "genre",
  LOGO = "logo",
  PROVIDER = "provider",
}

export enum RedbeeAssetType {
  MOVIE = "MOVIE",
  TV_SHOW = "TV_SHOW",
  EPISODE = "EPISODE",
  CLIP = "CLIP",
  TV_CHANNEL = "TV_CHANNEL",
  AD = "AD",
  LIVE_EVENT = "LIVE_EVENT",
  COLLECTION = "COLLECTION",
  PODCAST = "PODCAST",
  PODCAST_EPIDSODE = "PODCAST_EPISODE",
  OTHER = "OTHER",
}

export interface ILocalizedData {
  description?: string;
  images?: Array<any>;
  locale?: string;
  longDescription?: string;
  shortDescription?: string;
  sortingTitle?: string;
  tinyDescription?: string;
  title?: string;
}

export interface IPublication {
  countries: Array<string>;
  customData: any;
  devices: Array<any>;
  fromDate: string;
  products: Array<string>;
  availabilityKeys: Array<string>;
  publicationDate: string;
  publicationId: string;
  rights: any;
  services: Array<string>;
  toDate: string;
}

// --- CONFIG ---
export interface IBUConfig {
  customer: string;
  businessUnit: string;
  fileName: string;
  version: number;
  labels: Array<string>;
  config: INendaBUConfig;
}

export interface INendaBUConfig {
  productOfferings: Record<string, string>;
}

export interface ICUConfig {
  customer: string;
  businessUnit: string;
  fileName: string;
  version: number;
  labels: Array<string>;
  config: INendaBUConfig;
}

export interface INendaCUConfig {
  logos: Record<string, string>;
}

export interface IErrorEntity {
  PartitionKey: string;
  RowKey: string;
  Timestamp: Date;
  StartTime: Date;
  ErrorTime: Date;
  Status: string;
  EndTime?: number;
  BusinessUnit?: string;
  AccountId?: string;
  AssetOrChannel?: string;
  AssetName?: string;
  ProgramId?: string;
  ProgramName?: string;
  DeviceType?: string;
  ClientIp?: string;
  City?: string;
  Message?: string;
}

/*{
  "customer": "Nenda",
  "businessUnit": "Hotels",
  "fileName": "base.json",
  "version": 7,
  "labels": [],
  "config": {
    "productOfferings": {
      "cmore-sport-daypass-offering_87D54E": "https://www.nenda.com/wp-content/uploads/2021/05/Daypass-Cmoresport-Package-e1623678533149.jpg",
      "business-daypass-offering_87D54E": "https://www.nenda.com/wp-content/uploads/2021/05/Daypass-Business-Package-e1623678383926.jpg",
      "entertain-daypass-offering_87D54E": "https://www.nenda.com/wp-content/uploads/2021/05/Daypass-Entertain-Package-e1623678477945.jpg",
      "business-daypass-room-SE-offering_87D54E": "https://www.nenda.com/wp-content/uploads/2021/05/Daypass-Business-Package-e1623678383926.jpg",
      "entertain-daypass-room-SE-offering_87D54E": "https://www.nenda.com/wp-content/uploads/2021/05/Daypass-Entertain-Package-e1623678477945.jpg",
      "cmoresport-daypass-room-SE-offering_87D54E": "https://www.nenda.com/wp-content/uploads/2021/05/Daypass-Cmoresport-Package-e1623678533149.jpg"
    }
  }
}*/
