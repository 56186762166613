import { Box, Typography } from "@mui/material";
import TextInput from "../../../../../../../ui-components/input/TextInput";
import { t } from "i18next";

const ContentManagerForm = ({ userEmail }: { userEmail: string }) => {
  return (
    <Box
      sx={{
        minWidth: "300px",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ py: "0.5rem" }} variant="body2">
          {t("form.address.email")}
        </Typography>
        <Typography sx={{ py: "0.5rem" }} variant="body1">
          {userEmail}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2">
          {t(
            "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.duration_of_plan"
          )}
        </Typography>
        <Typography variant="body1">
          {t(
            "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.duration_of_plan_description"
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default ContentManagerForm;
