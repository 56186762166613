import { httpClient, Service } from "./client";

class AuthService extends Service {
  login(username: string, password: string) {
    return httpClient.post("/auth/login", { email: username, password });
  }
  customerLogin(username: string, password: string) {
    return httpClient.post("/auth/customer/login", {
      email: username,
      password,
    });
  }
  getPermissions() {
    return httpClient.get("/permissions");
  }

  sendPasswordReset(emailAddress: string) {
    return httpClient.post("/auth/sendpasswordreset", {
      username: emailAddress,
    });
  }

  resetPasswordUsingHash(newPassword: string, hash: string) {
    return httpClient.post(`/auth/resetpassword/${hash}`, {
      newPassword,
    });
  }

  resetPasswordLoggedIn(newPassword: string) {
    return httpClient.post(
      `/auth/resetpassword`,
      {
        newPassword,
      },
      this.authHeader()
    );
  }
}

export const authService = new AuthService();
