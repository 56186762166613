import { Select, MenuItem, Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { NendaProduct } from "../../../types/NendaTypes";
import { CreateArea } from "../../redux/organizationUnit.redux";
import { store } from "../../redux/store";
import { AppState } from "../../types/redux";
import { Modal } from "../Modal/Modal";

interface ICreateAreaButtonProps {
  hotelId: string;
  hotelChainId: string;
  roomType: string;
}

export const CreateAreaButton: React.FC<ICreateAreaButtonProps> = ({
  hotelId,
  hotelChainId,
  roomType,
}) => {
  const premise = useSelector((state: AppState) =>
    state.organizationUnits.organizationUnits.find(({ _id }) => _id === hotelId)
  );
  const hotelChain = useSelector((state: AppState) =>
    state.organizationUnits.organizationUnits.find(
      ({ _id }) => _id === hotelChainId
    )
  );

  const [showForm, setShowForm] = React.useState(false);
  const [state, setState] = React.useState({
    roomIdentifier: "",
    hotelId,
    hotelChainId,
    roomType,
    status: "active",
    nendaProducts: premise?.nendaProducts || [],
  });

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    let updatedProducts = [...state.nendaProducts];
    if (checked) {
      updatedProducts.push(name as NendaProduct);
    } else {
      updatedProducts = updatedProducts.filter((x) => x != name);
    }
    setState({ ...state, nendaProducts: [...new Set(updatedProducts)] });
  };

  function submitRoom() {
    store.dispatch(CreateArea(state));
  }
  return (
    <>
      <Button
        onClick={() => setShowForm(true)}
        variant="contained"
        color="secondary"
      >
        Add area
      </Button>
      <Modal
        visible={showForm}
        onClose={() => setShowForm(false)}
        title={"Add area"}
        onSubmit={submitRoom}
      >
        <>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">Area name</span>
            </div>
            <input
              value={state.roomIdentifier}
              type="text"
              className="form-control"
              onChange={(e) =>
                setState({ ...state, roomIdentifier: e.target.value })
              }
            />
          </div>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">Room type</span>
            </div>
            <Select
              onChange={(e) => setState({ ...state, roomType: e.target.value })}
              value={state.roomType}
              className="form-control"
            >
              {!roomType && <option value=""></option>}
              {hotelChain?.roomTypes.map((roomType) => (
                <MenuItem key={roomType._id} value={roomType._id}>
                  {roomType.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="input-group my-1">
            <div className="input-group-prepend">
              <span className="input-group-text">Status</span>
            </div>
            <Select
              onChange={(e) => setState({ ...state, status: e.target.value })}
              value={state.status}
              className="form-control"
            >
              <MenuItem value={"inactive"}>Inactive</MenuItem>
              <MenuItem value={"active"}>Active</MenuItem>
            </Select>
          </div>
          <div>
            {premise?.nendaProducts.includes(NendaProduct.STREAMING_APP) && (
              <div className="custom-control custom-switch">
                <input
                  name={NendaProduct.STREAMING_APP}
                  id={`area-switch-${NendaProduct.STREAMING_APP}`}
                  type="checkbox"
                  className="custom-control-input"
                  checked={state.nendaProducts.includes(
                    NendaProduct.STREAMING_APP
                  )}
                  onChange={handleProductChange}
                />
                <label
                  htmlFor={`area-switch-${NendaProduct.STREAMING_APP}`}
                  className={`custom-control-label ${NendaProduct.STREAMING_APP}-label`}
                >
                  Streaming
                </label>
              </div>
            )}
            {premise?.nendaProducts.includes(NendaProduct.FRONTLAYER) && (
              <div className="custom-control custom-switch">
                <input
                  name={NendaProduct.FRONTLAYER}
                  id={`area-switch-${NendaProduct.FRONTLAYER}`}
                  type="checkbox"
                  className="custom-control-input"
                  checked={state.nendaProducts.includes(
                    NendaProduct.FRONTLAYER
                  )}
                  onChange={handleProductChange}
                />
                <label
                  htmlFor={`area-switch-${NendaProduct.FRONTLAYER}`}
                  className={`custom-control-label ${NendaProduct.FRONTLAYER}-label`}
                >
                  Frontlayer
                </label>
              </div>
            )}
            {premise?.nendaProducts.includes(NendaProduct.SIGNAGE) && (
              <div className="custom-control custom-switch">
                <input
                  name={NendaProduct.SIGNAGE}
                  id={`area-switch-${NendaProduct.SIGNAGE}`}
                  type="checkbox"
                  className="custom-control-input"
                  checked={state.nendaProducts.includes(NendaProduct.SIGNAGE)}
                  onChange={handleProductChange}
                />
                <label
                  htmlFor={`area-switch-${NendaProduct.SIGNAGE}`}
                  className={`custom-control-label ${NendaProduct.SIGNAGE}-label`}
                >
                  Signage
                </label>
              </div>
            )}
            {premise?.nendaProducts.includes(NendaProduct.SIGNAL_DELIVERY) && (
              <div className="custom-control custom-switch">
                <input
                  name={NendaProduct.SIGNAL_DELIVERY}
                  id={`area-switch-${NendaProduct.SIGNAL_DELIVERY}`}
                  type="checkbox"
                  className="custom-control-input"
                  checked={state.nendaProducts.includes(
                    NendaProduct.SIGNAL_DELIVERY
                  )}
                  onChange={handleProductChange}
                />
                <label
                  htmlFor={`area-switch-${NendaProduct.SIGNAL_DELIVERY}`}
                  className={`custom-control-label ${NendaProduct.SIGNAL_DELIVERY}-label`}
                >
                  Signal delivery
                </label>
              </div>
            )}
          </div>
        </>
      </Modal>
    </>
  );
};
