import { Theme } from "@mui/material";

export const buttonContainedStyles = (theme: Theme) => {
  return {
    background: theme.palette.primary.light,
    color: theme.palette.text.secondary,
    padding: "0.4rem 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiTypography-root": {
      transform: "translateY(0.04rem)",
    },
    "&:disabled": {
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.grey[300],
      outline: `none`,
      opacity: 0.6,
    },
    "& .MuiLoadingButton-loadingIndicator": {
      color: theme.palette.primary.light,
    },
  };
};

export const buttonOutlinedStyles = (theme: Theme) => {
  return {
    background: theme.palette.transparent.purple,
    color: theme.palette.text.primary,
    border: "none",
    padding: "0.4rem 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      border: "none",
    },
    "& .MuiTypography-root": {
      transform: "translateY(0.04rem) !important",
    },
    "&:disabled": {
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[100],
      opacity: 0.6,
    },
    "& .MuiLoadingButton-loadingIndicator": {
      color: theme.palette.primary.light,
    },
  };
};

export const buttonDangerStyles = (theme: Theme) => {
  return {
    background: "transparent",
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    "&:hover": {
      background: theme.palette.error.main,
      color: theme.palette.common.white,
      borderColor: "transparent",
    },
  };
};
