import React, { useState } from "react";
import { Box, Paper, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FontSelect from "../FontSelect";

interface LocalizationProps {
  locale: string;
  font: string;
  iconName: string;
  name: string;
  type: string;
  description: string;
  onChange: (
    local: string,
    name: string,
    description: string,
    font: string,
    iconName: string
  ) => void;
  onClose: () => void;
}

export const Localization: React.FC<LocalizationProps> = ({
  locale,
  type,
  name,
  description,
  font,
  iconName,
  onChange,
  onClose,
}) => {
  const [nameValue, setNameValue] = useState(name);
  const [descriptionValue, setDescriptionValue] = useState(description);
  const [fontValue, setFontValue] = useState(font);
  const [iconNameValue, setIconNameValue] = useState(iconName);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value);
  };
  const handleFontChange = (font: string) => {
    setFontValue(font);
  };
  const handleIconNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIconNameValue(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionValue(e.target.value);
  };

  const handleClose = () => {
    onChange(locale, nameValue, descriptionValue, fontValue, iconNameValue);
    onClose();
  };

  const displayVal = type === "text" ? "block" : "none";

  return (
    <Paper
      sx={(theme) => ({
        display: displayVal,
        backgroundColor: "#FFF",
        p: "20px",
        zIndex: 9999,
        position: "absolute",

        borderRadius: "0.5rem",

        border: `1px solid ${theme.palette.grey[300]}}`,

        minWidth: "400px",
      })}
    >
      <Typography variant="body2" margin={1}>
        Name
      </Typography>
      <TextField id="name" value={nameValue} onChange={handleNameChange} />

      <Typography variant="body2" margin={1}>
        Description
      </Typography>
      <TextField
        id="name"
        value={descriptionValue}
        multiline
        onChange={handleDescriptionChange}
      />
      <Typography variant="body2" margin={1}>
        Font family
      </Typography>
      <FontSelect selectedFont={fontValue} onSelect={handleFontChange} />
      <Typography variant="body2" margin={1}>
        Icon
      </Typography>
      <TextField
        id="name"
        value={iconNameValue}
        onChange={handleIconNameChange}
      />
      <CloseIcon
        sx={{ position: "absolute", top: 5, right: 5, cursor: "pointer" }}
        onClick={handleClose}
      />
    </Paper>
  );
};
