import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { ChangeEvent } from "react";

interface PrioritySelectProps {
  isSelected: boolean;
  onChange: (checked: boolean) => void;
}

const PrioritySelect: React.FC<PrioritySelectProps> = ({
  isSelected,
  onChange,
}) => {
  const handleCheckboxChange = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    return onChange(checked);
  };

  return (
    <Stack
      sx={{
        pr: "1rem",
        width: "100%",
        pb: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <FormControlLabel
          sx={{ alignSelf: "start", ml: 0 }}
          value="high_priority"
          control={
            <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
          }
          label={
            <Typography variant="body1">
              {t("customerportal.pages.promotions.high_priority_promotion")}
            </Typography>
          }
          labelPlacement="start"
        />
      </Box>
      <Typography sx={{ fontSize: "0.8rem" }}>
        {t(
          "customerportal.pages.promotions.high_priority_promotion_description"
        )}
      </Typography>
    </Stack>
  );
};

export default PrioritySelect;
