import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { IContactPerson } from "../../../../../../types/NendaTypes";
import TextInput from "../../../ui-components/input/TextInput";
import { t } from "i18next";

const style = {
  textField: {
    maxWidth: "50ch",
  },
};

interface ContactPersonProps {
  contactPerson: IContactPerson;
  onChange: (contactPerson: IContactPerson) => void;
}

const ContactPerson: React.FC<ContactPersonProps> = ({
  contactPerson,
  onChange,
}) => {
  const [currentContactPerson, setCurrentContactPerson] =
    useState(contactPerson);

  React.useEffect(() => {
    onChange(currentContactPerson);
  }, [currentContactPerson]);

  React.useEffect(() => {
    setCurrentContactPerson(contactPerson);
  }, [contactPerson]);

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ p: 1 }}>
      <Grid container spacing={"0.5rem"} columns={2}>
        <Grid item xs={2}>
          <TextInput
            variant={"standard"}
            fullWidth
            id="fullName"
            label={t("form.address.full_name")}
            value={currentContactPerson.fullName}
            autoComplete="off"
            onChange={(e) =>
              setCurrentContactPerson({
                ...currentContactPerson,
                fullName: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={1}>
          <TextInput
            variant={"standard"}
            fullWidth
            id="phoneNumber"
            label={t("form.address.phone_number")}
            type="tel"
            value={currentContactPerson.phoneNumber}
            autoComplete="off"
            onChange={(e) =>
              setCurrentContactPerson({
                ...currentContactPerson,
                phoneNumber: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={1}>
          <TextInput
            variant={"standard"}
            fullWidth
            id="email"
            label={t("form.address.email")}
            type="email"
            value={currentContactPerson.emailAddress}
            autoComplete="off"
            onChange={(e) =>
              setCurrentContactPerson({
                ...currentContactPerson,
                emailAddress: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactPerson;
