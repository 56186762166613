import { Theme } from "@mui/material";

export const selectStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.background.paper,
  fontSize: "0.8rem",
  borderRadius: "0.4rem",
  "& .MuiSelect-outlined": {
    padding: "0.5rem 1rem",
  },
  border: `0.5px solid ${theme.palette.grey[300]}`,
  "& .MuiOutlinedInput-notchedOutline": {
    border: `none`,
    opacity: 1,
    visibility: "visible",
    "& legend": {
      overflow: "visible",
      "& span": {
        opacity: 1,
        visibility: "visible",
        color: theme.palette.text.primary,
        borderRadius: "0.3rem",
        fontSize: "0.6rem",
      },
    },
  },
});
