import { Box, useMediaQuery, useTheme } from "@mui/material";
import PackageCard from "./PackageCard";
import { IAsset } from "../../../../../../../../../../shared/types/RedBee.types";
import { ContentPackage } from "../../../../../../../../../../types/NendaTypes";
import { AddOutlined, PlusOneOutlined } from "@mui/icons-material";

export type Package = {
  _id: string;
  name: string;
  price: number;
  currency: string;
  image?: string;
  channels: IAsset[];
  isDefault: boolean;
  icon?: React.ReactNode;
};

type PackageSelectorProps = {
  packages: ContentPackage[] | [];
  selectedPackages: string[];
  onSelectPackage: (packageId: string) => void;
};

export default function PackageSelector({
  packages,
  selectedPackages,
  onSelectPackage,
}: PackageSelectorProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const baseProductPackages = packages.filter((p) => p.isBaseProduct);
  const addOnPackages = packages.filter((p) => !p.isBaseProduct);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        minHeight: "200px",
        maxHeight: "500px",
        px: isMobile ? "0.2rem" : "1rem",
        pt: "1rem",
        minWidth: isMobile ? "100%" : "800px",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: "0.5rem",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.palette.grey[300],
          borderRadius: "0.5rem",
        },
      }}
    >
      <Box>
        {baseProductPackages &&
          baseProductPackages.map((packageData: ContentPackage) => (
            <PackageCard
              key={packageData.id + packageData.name}
              packageData={packageData}
              onSelect={onSelectPackage}
              selected={selectedPackages.includes(packageData.id)}
            />
          ))}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <AddOutlined />
        </Box>
      </Box>
      {addOnPackages &&
        addOnPackages.map((packageData: ContentPackage) => (
          <PackageCard
            key={packageData.id + packageData.name}
            packageData={packageData}
            onSelect={onSelectPackage}
            selected={selectedPackages.includes(packageData.id)}
          />
        ))}
    </Box>
  );
}
