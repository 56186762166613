import { Epic, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { CacheLevel } from "../../types/NendaTypes";
import { utilService } from "../http/util.service";
import { ClearCacheAction, UtilsActions, UTILS_ACTIONS } from "../types/redux";
import { handleError } from "./error.redux";
import { SetNotification } from "./notification.redux";

export function ClearCache(
  cacheLevel: CacheLevel,
  id: string
): ClearCacheAction {
  return {
    type: UTILS_ACTIONS.CLEAR_CACHE,
    cacheLevel,
    id,
  };
}

export default function utilReducer(state = {}, action: UtilsActions) {
  switch (action.type) {
    default:
      return state;
  }
}

const clearCache$: Epic = (action$) => {
  return action$.pipe(
    ofType(UTILS_ACTIONS.CLEAR_CACHE),
    switchMap((a) => {
      return utilService.clearCache(a.cacheLevel, a.id).pipe(
        map((result) => {
          return SetNotification({
            message: `Cleared ${result.clearedKeys} entries in cache`,
            bgClass: "bg-success",
          });
        }),
        catchError(handleError)
      );
    })
  );
};

export const utilEpics = [clearCache$];
