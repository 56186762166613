import React from "react";
import { FaSave } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CacheLevel, IOrganizationUnit } from "../../../types/NendaTypes";
import { OrganizationUnit } from "../../models/organizationUnit";
import { UpdateOrganizationUnit } from "../../redux/organizationUnit.redux";
import { store } from "../../redux/store";
import { AppState } from "../../types/redux";
import { CreatePremiseButton } from "../CreatePremiseButton/CreatePremiseButton";
import { CreateRoomTypeButton } from "../CreateRoomTypeButton/CreateRoomTypeButton";
import { DeleteOUWidget } from "../DeleteOuWidget/DeleteOuWidget";
import { PageHeader } from "../PageHeader/PageHeader";
import { RoomTypeCategoryBadge } from "../RoomTypeCategory/RoomTypeCategoryBadge";
import { StringArrayEdit } from "../StringArrayEdit/StringArrayEdit";
import { SectionWrapper } from "../UI/SectionWrapper/SectionWrapper";
import "./HotelChain.scss";
import { ProductOfferingsTable } from "./ProductOffering/ProductOfferingsTable";
import { ChannelMapList } from "./ChannelMap";
import { organizationUnitService } from "../../http/organizationUnit.service";
import { BaseConfigEditor } from "./BaseConfigEditor";
import { ClearCacheButton } from "../Buttons/ClearCacheButton/ClearCacheButton";
import { Button } from "@mui/material";
import { isUrl } from "../../utils/url";

interface IHotelChainProps {
  ou: OrganizationUnit;
}

interface IHotelChainState extends IOrganizationUnit {
  logoUrl?: string;
}

export const HotelChain: React.FC<IHotelChainProps> = ({ ou }) => {
  const roomTypes = ou.roomTypes || [];
  const hotels = useSelector((state: AppState) =>
    state.organizationUnits.organizationUnits.filter(
      (hotel) => hotel.type === "hotel" && hotel.parent === ou._id
    )
  );
  const [state, setState] = React.useState<IHotelChainState>(ou);
  const [preview, setPreview] = React.useState(false);
  const [baseConfig, setBaseConfig] = React.useState("");

  React.useEffect(() => {
    setState(ou);
  }, [ou]);

  React.useEffect(() => {
    organizationUnitService
      .getBusinessUnitBaseConfig(ou._id)
      .toPromise()
      .then((config) => setBaseConfig(JSON.stringify(config, null, 2)))
      .catch(() => setBaseConfig("INVALID CONFIG"));
  }, [state._id]);

  const changeLogoUrl = (e) => {
    setState({ ...state, logoUrl: e.target.value });
    if (isUrl(e.target.value)) {
      setPreview(true);
    }
  };

  // ! Not in use
  // const setDefaultLogo = () => {
  //   setState({
  //     ...state,
  //     logoUrl: "https://noriginmedia.com/wp-content/uploads/2019/08/Nenda-1.png",
  //   });
  //   setPreview(true);
  // };

  function update() {
    setPreview(false);
    const { logoUrl, apiKey, channelMap } = state;
    store.dispatch(
      UpdateOrganizationUnit(ou._id, { logoUrl, apiKey, channelMap })
    );
  }

  const updateApiKey = (apiKey) => {
    setState({ ...state, apiKey });
  };

  const updateHotelChainRoomTypes = (roomTypes) => {
    store.dispatch(UpdateOrganizationUnit(ou._id, { roomTypes }));
  };

  const setChannelList = (channelList: Record<number, string>) => {
    setState({
      ...state,
      channelMap: { ...state.channelMap, channels: channelList },
    });
  };

  const saveConfig = async () => {
    try {
      await organizationUnitService
        .updateBusinessUnitBaseConfig(ou._id, JSON.parse(baseConfig))
        .toPromise();
      organizationUnitService
        .getBusinessUnitBaseConfig(ou._id)
        .toPromise()
        .then((config) => setBaseConfig(JSON.stringify(config, null, 2)))
        .catch(() => setBaseConfig("INVALID CONFIG"));
    } catch (e) {
      console.error(e);
    }
  };

  const hasInvalidChild = hotels.some((h) => h.isInvalid());
  return (
    <>
      <PageHeader>
        {state.logoUrl && state.logoUrl.length > 0 && (
          <div className="logo-wrapper">
            <img className="logo" src={state.logoUrl} />
            {preview && <div className="logo-preview">(Preview)</div>}
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center">
          <h2>{ou.name}</h2>
          <div>
            {!ou.apiKey && (
              <span
                className="badge badge-danger mx-1"
                style={{ margin: "auto" }}
              >
                Api Key
              </span>
            )}
            {!ou.logoUrl && (
              <span
                className="badge badge-danger mx-1"
                style={{ margin: "auto" }}
              >
                Logo
              </span>
            )}
            {hasInvalidChild && (
              <span
                className="badge badge-warning mx-1"
                style={{ margin: "auto" }}
              >
                Invalid child
              </span>
            )}
          </div>
        </div>
        <CreatePremiseButton hotelChainId={ou._id} />
        <CreateRoomTypeButton hotelChainId={ou._id} />
        <DeleteOUWidget ouId={ou._id}>Delete Hotel Chain</DeleteOUWidget>
        <Link to={`/organizationunits/hotelchain/${ou._id}/report`}>
          <Button variant="outlined" color="secondary">
            Report
          </Button>
        </Link>
        <ClearCacheButton cacheLevel={CacheLevel.PremiseGroup} id={ou._id} />
      </PageHeader>
      <SectionWrapper>
        <h4>Logo url</h4>
        <input
          className="form-control"
          value={state.logoUrl || ""}
          onChange={(e) => changeLogoUrl(e)}
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>API Key</h4>
        <input
          className="form-control"
          value={state.apiKey || ""}
          onChange={({ target: { value } }) => updateApiKey(value)}
        />
      </SectionWrapper>
      <SectionWrapper>
        <h4>Red Bee Name</h4>
        <span>{ou.redBeeName || ""}</span>
      </SectionWrapper>
      <BaseConfigEditor
        configContent={baseConfig}
        handleChange={setBaseConfig}
        handleSave={saveConfig}
      />
      <SectionWrapper>
        <h4>Room types</h4>
        <StringArrayEdit
          showPlus={false}
          values={roomTypes.map((roomType) => ({
            name: (
              <div className="d-flex justify-content-between">
                <span>
                  {roomType.name}({roomType.numberOfRooms})
                </span>
                <RoomTypeCategoryBadge roomType={roomType} border={true} />
              </div>
            ),
            value: roomType,
            link: `roomType/${roomType._id}`,
          }))}
          options={roomTypes.map(({ name, _id }) => ({ name, value: _id }))}
          onChange={(values) => updateHotelChainRoomTypes(values)}
        />
      </SectionWrapper>
      <SectionWrapper>
        <ProductOfferingsTable hotelChainId={ou._id} />
      </SectionWrapper>
      <SectionWrapper>
        <h4>Channel Map</h4>
        <div className="custom-control custom-switch">
          <input
            name="channelMapEnabled"
            id="channelMapEnabled"
            type="checkbox"
            className="custom-control-input"
            checked={state.channelMap.enabled}
            onChange={({ target: { checked } }) =>
              setState({
                ...state,
                channelMap: { ...state.channelMap, enabled: checked },
              })
            }
          />
          <label
            htmlFor="channelMapEnabled"
            className="custom-control-label allowContinuousStreaming-label"
          >
            Enabled
          </label>
        </div>
        <ChannelMapList
          setParentChannelList={setChannelList}
          channels={state.channelMap.channels}
        />
      </SectionWrapper>
      <Button
        disabled={state === ou}
        onClick={update}
        className="mr-2 my-2"
        variant="contained"
        color="secondary"
        endIcon={<FaSave />}
      >
        Save
      </Button>
    </>
  );
};
