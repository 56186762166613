import * as React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { IRoom } from "../../../../../types/NendaTypes";
import { GetScreensResponse } from "../../../../../server/api/internal/ad";

interface ScreenSelectProps {
  screens: Record<string, GetScreensResponse>;
  selected: Record<string, boolean>;
  onGroupSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onScreenSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ScreenSelect: React.FC<ScreenSelectProps> = ({
  screens,
  selected,
  onScreenSelectChange,
  onGroupSelectChange,
}) => {
  const screenGroup = (screens: GetScreensResponse, group: string) => {
    const isGroupSelected = () => {
      return selected[group] || screens.every((screen) => selected[screen._id]);
    };

    if (screens.length === 0) {
      return null;
    }

    return (
      <Box key={group}>
        <FormControlLabel
          sx={{ m: 0, p: 0 }}
          label={group}
          name={group}
          control={
            <Checkbox
              sx={{ m: 0, p: 0 }}
              size="medium"
              checked={isGroupSelected()}
              indeterminate={false}
              onChange={onGroupSelectChange}
            />
          }
        />
        <Box
          sx={{
            display: "flex",
            width: "fit-content",
            flexDirection: "column",
            ml: 3,
          }}
        >
          {screens.map((screen) => (
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              key={screen._id}
              label={screen.name}
              name={screen._id}
              control={
                <Checkbox
                  sx={{ m: 0, p: 0 }}
                  size="medium"
                  checked={selected[screen._id] || false}
                  onChange={onScreenSelectChange}
                />
              }
            />
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Typography sx={{ color: "#000", fontWeight: 700, mb: 1 }}>
        Apply to screens
      </Typography>
      {Object.keys(screens).map((key) => {
        return screenGroup(screens[key], key);
      })}
    </Box>
  );
};
