import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import React from "react";
import { FaHouseUser, FaSave } from "react-icons/fa";

interface BaseConfigEditorProps {
  configContent: string;
  handleChange: (configContent: string) => void;
  handleSave: () => void;
}

export const BaseConfigEditor: React.FC<BaseConfigEditorProps> = ({
  configContent,
  handleChange,
  handleSave,
}) => {
  const [baseConfig, setBaseConfig] = React.useState(configContent);

  React.useEffect(() => {
    if (configContent) setBaseConfig(configContent);
  }, [configContent]);

  const validateConfig = () => {
    try {
      JSON.parse(baseConfig);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        className="accordion-summary-gray"
      >
        <FaHouseUser className="mr-2" />
        RedBee config
      </AccordionSummary>
      <AccordionDetails>
        base.json
        <textarea
          className="form-control ml-2"
          style={{ color: validateConfig() ? "black" : "red" }}
          rows={10}
          value={baseConfig}
          onChange={({ target: { value } }) => handleChange(value)}
        />
        <Button
          disabled={!validateConfig()}
          onClick={() => handleSave()}
          className="mr-2 my-2"
          variant="contained"
          color="secondary"
          endIcon={<FaSave />}
        >
          Save
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};
