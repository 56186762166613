import React from "react";
import "./Loader.scss";

interface ILoaderProps {
  loading?: boolean;
}

export const Loader: React.FC<ILoaderProps> = ({ loading }) => {
  if (!loading) return null;
  return (
    <div className="loader-overlay">
      <span className="loader"></span>
    </div>
  );
};
