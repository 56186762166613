import { Box, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { t } from "i18next";
import {
  FrontLayerInformationItem,
  FrontLayerInformationSource,
  IFrontLayerInformationImageContent,
} from "../../../../../../types/NendaTypes";
import InputSelect from "../../../ui-components/input/InputSelect";
import ImageInformationItem from "./ImageInformationItem";
import URLInformationItem from "./URLInformationItem";

const EditInformationItem = ({
  onTypeChange,
  onUrlChange,
  onImagesChange,
  informationItem,
}: {
  onTypeChange: (type: FrontLayerInformationSource) => void;
  onUrlChange: (url: string) => void;
  onImagesChange: (
    images: IFrontLayerInformationImageContent["images"]
  ) => void;
  informationItem: FrontLayerInformationItem;
}) => {
  const type = informationItem?.sourceType;
  const options = [
    FrontLayerInformationSource.IMAGE,
    FrontLayerInformationSource.URL,
  ];
  const handleTypeChange = (
    event: SelectChangeEvent<FrontLayerInformationSource>
  ) => {
    const value = event.target.value;
    onTypeChange(value as FrontLayerInformationSource);
  };

  const handleURLChange = (value: string) => {
    onUrlChange(value);
  };

  return (
    <Box sx={{ py: "1rem" }}>
      <Box>
        {type && (
          <Box
            sx={{
              display: "flex",
              gap: "2rem",
              alignItems: "center",
              borderBottom: "1px solid #eee",
              pb: "1rem",
            }}
          >
            <Typography variant="body2">{t("common.type")}</Typography>
            <InputSelect
              variant="outlined"
              defaultValue={type || ""}
              id="information-type-select"
              value={type}
              sx={{
                textTransform: "capitalize",
              }}
              onChange={handleTypeChange}
            >
              {options.map((c) => (
                <MenuItem
                  key={c}
                  value={c}
                  sx={{ textTransform: "capitalize" }}
                >
                  {t(`customerportal.pages.frontlayer.media_type.${c}`)}
                </MenuItem>
              ))}
            </InputSelect>
          </Box>
        )}
      </Box>
      <Box sx={{ py: "1rem" }}>
        {type === FrontLayerInformationSource.IMAGE && (
          <ImageInformationItem
            item={informationItem}
            onImagesChange={onImagesChange}
          />
        )}
        {type === FrontLayerInformationSource.URL && (
          <URLInformationItem
            item={informationItem.content}
            setItem={handleURLChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default EditInformationItem;
