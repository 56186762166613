import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Modal,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import {
  IAsset,
  RedbeeAssetType,
  RedbeeTagType,
} from "../../../../../../../../shared/types/RedBee.types";
import {
  ContentManagmentData,
  RoomTypeCategory,
} from "../../../../../../../../types/NendaTypes";
import {
  ContentManagerState,
  RoomWithContentAndType,
} from "../../PremiseDashboard";
import ContentManagerStepper from "./ContentManagerStepper";
import RoomCategorySelect from "./components/RoomCategorySelect";
import RoomSelect from "./components/RoomSelect";
import PackageSelector from "./components/packageSelector/PackageSelector";
import AdditionalInformation from "./components/summary/AdditionalInformation";
import ModalFlowSummary from "./components/summary/ModalFlowSummary";
import { CustomerPortalState, store } from "../../../../../store";
import { useSelector } from "react-redux";
import CircularLoadingIndicator from "../../../../../ui-components/loading/CircularLoadingIndicator";
import OrderConfirmation from "./components/OrderConfirmation";
import { getUserSession } from "../../../../../../../redux/auth.redux";

export type RoomContent = {
  roomId: string;
  roomTypeId: string;
  roomTypeCategory: RoomTypeCategory;
  currentContent: string[];
};

type ContentManagerModalProps = {
  open?: boolean;
  onClose: () => void;
  rooms: RoomWithContentAndType[];
  roomTypeCategories: RoomTypeCategory[];
  content: ContentManagmentData;
  contentManagerState: ContentManagerState;
  handleSetSelectedRoomCategory: (selectedCategory: RoomTypeCategory) => void;
  handleSetSelectedPackage: (selectedPackage: string) => void;
  handleSetAcceptTerms: (accept: boolean) => void;
  handleClearContentManagerState?: () => void;
  handleSetSelectedRooms: (selectedRooms: string[]) => void;
  onSubmit?: () => void;
};

const UNSORTED_CHANNEL_SORTING_TITLE = "998";
const VOD_PROVIDER_SORTING_TITLE = "999";

export type PackageChannel = {
  id: string;
  name: string;
  sortingTitle: string;
  imageSrc: string;
  providerTag?: string;
  description?: string;
  type: RedbeeAssetType;
};

export const assetsToChannelsAndVODs = (assets: IAsset[]) => {
  const uniformAssets = assets.map((a) => convertAssetToPackageChannel(a));

  const tvChannels = uniformAssets?.filter(
    (a) => a.type === RedbeeAssetType.TV_CHANNEL
  );

  const vods = uniformAssets?.filter((a) => a.type === RedbeeAssetType.MOVIE);

  const stingrayVODs = vods.filter((a) => a.providerTag?.includes("stingray"));
  const scanboxVODs = vods.filter((a) => a.providerTag?.includes("scanbox"));

  const channels: PackageChannel[] = tvChannels;

  if (scanboxVODs.length > 0) {
    channels.push({
      id: "scanbox",
      name: "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.vod_movies_title",
      sortingTitle: VOD_PROVIDER_SORTING_TITLE,
      type: RedbeeAssetType.MOVIE,
      imageSrc: "scanbox_vod",
      providerTag: "scanbox",
      description:
        "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.vod_movies_description",
    });
  }
  if (stingrayVODs.length > 0) {
    channels.push({
      id: "stingray",
      name: "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.vod_music_title",
      sortingTitle: VOD_PROVIDER_SORTING_TITLE,
      type: RedbeeAssetType.MOVIE,
      imageSrc: "stingray_vod",
      providerTag: "stingray",
      description:
        "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.vod_music_description",
    });
  }

  channels.sort((a, b) => {
    if (a.sortingTitle === b.sortingTitle) {
      return a.name.localeCompare(b.name);
    }
    return a.sortingTitle.localeCompare(b.sortingTitle);
  });

  return channels;
};

export const convertAssetToPackageChannel = (asset: IAsset): PackageChannel => {
  const locale = asset.localized?.find((l) => l.locale === "sv") ? "sv" : "en";
  const localizedContent = asset.localized?.find((l) => l.locale === locale);
  const provider =
    asset.tags?.find((t) => t.type === RedbeeTagType.PROVIDER)?.tagValues[0]
      .tagId || "";

  return {
    id: asset.assetId || "",
    name: localizedContent?.title || "",
    sortingTitle:
      localizedContent?.sortingTitle || UNSORTED_CHANNEL_SORTING_TITLE,
    providerTag: provider,
    type: asset.type,
    description: localizedContent?.shortDescription || "",
    imageSrc:
      localizedContent?.images?.find((i) => i.type === RedbeeTagType.OTHER)
        ?.url ||
      localizedContent?.images?.[0]?.url ||
      "",
  };
};

const steps = [
  "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.room_category",
  "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.rooms",
  "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.select_packages",
  "common.confirm",
  "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.order_confirmation",
];

const ContentManagerModal = ({
  open,
  onClose,
  onSubmit,
  content,
  rooms,
  contentManagerState,
  roomTypeCategories,
  handleSetAcceptTerms,
  handleSetSelectedRooms,
  handleSetSelectedPackage,
  handleSetSelectedRoomCategory,
  handleClearContentManagerState,
}: ContentManagerModalProps) => {
  const contentIsLoading = useSelector(
    (state: CustomerPortalState) => state.organizationUnits.isLoading
  );

  const handleClearState = () => {
    onClose();
    handleClearContentManagerState && handleClearContentManagerState();
  };

  const dialogProps = {
    onClose: handleClearState,
    open: open || false,
  };

  return (
    <Modal
      {...dialogProps}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: "1rem",
        "&:focus-visible": {
          outline: "none",
        },
      }}
    >
      <>
        {contentIsLoading ? (
          <CircularLoadingIndicator color="white" />
        ) : (
          <ModalContent
            contentManagerState={contentManagerState}
            onClose={onClose}
            content={content}
            rooms={rooms}
            roomTypeCategories={roomTypeCategories}
            handleSetAcceptTerms={handleSetAcceptTerms}
            handleSetSelectedRooms={handleSetSelectedRooms}
            handleSetSelectedPackage={handleSetSelectedPackage}
            handleSetSelectedRoomCategory={handleSetSelectedRoomCategory}
            onSubmit={onSubmit}
          />
        )}
      </>
    </Modal>
  );
};

const ModalContent = ({
  onClose,
  content,
  onSubmit,
  rooms,
  contentManagerState,
  roomTypeCategories,
  handleSetAcceptTerms,
  handleSetSelectedRooms,
  handleSetSelectedPackage,
  handleSetSelectedRoomCategory,
}: ContentManagerModalProps) => {
  const {
    selectedRooms,
    selectedRoomCategory,
    selectedPackages,
    acceptTerms,
    initialStep,
  } = contentManagerState;

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(initialStep || 0);
  const contentManagerCreateOrderLoadingStatus = useSelector(
    (state: CustomerPortalState) => state.organizationUnits.contentOrder.status
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleRoomCategoryChange = (category: RoomTypeCategory) => {
    const categoryHasChanged = selectedRoomCategory !== category;

    handleSetSelectedRoomCategory(category);
    if (categoryHasChanged) {
      handleSetSelectedRooms([]);
    }
    setActiveStep(1);
    setCompleted({
      ...completed,
      0: true,
    });
  };

  const handleRoomSelect = (roomIds: string[]) => {
    handleSetSelectedRooms(roomIds);
  };

  const handlePackageSelect = (packageId: string) => {
    handleSetSelectedPackage(packageId);
  };

  const handleAcceptTerms = (accept: boolean) => {
    handleSetAcceptTerms(accept);
  };

  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  if (initialStep > 0 && Object.keys(completed).length === 0) {
    const initialStepsCompleted = {};
    for (let i = 0; i < initialStep; i++) {
      initialStepsCompleted[i] = true;
    }
    setCompleted(initialStepsCompleted);
  }
  const retractButtonText =
    activeStep === 0 || activeStep >= 4 ? t("common.close") : t("common.back");

  const stepIsValid = (step: number) => {
    switch (step) {
      case 0:
        return !!selectedRoomCategory;
      case 1:
        return selectedRooms.length > 0;
      case 2:
        return selectedPackages.length > 0;
      case 3:
        return true;
      default:
        return false;
    }
  };
  const selectedPacks = content.availablePackages[
    selectedRoomCategory || RoomTypeCategory.ROOM
  ]?.filter((p) => selectedPackages.includes(p.id));

  const selectedScreens = rooms.filter((r) => selectedRooms.includes(r._id));

  const selectedAssets = selectedPacks?.flatMap((p) => p.assets);

  const packagesByCategory =
    content.availablePackages[selectedRoomCategory || ""] || [];

  const channels = assetsToChannelsAndVODs(selectedAssets || []);

  const userSession = useSelector(getUserSession);

  const stepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <RoomCategorySelect
            selectedRoomCategory={selectedRoomCategory}
            setSelectedRoomCategory={handleRoomCategoryChange}
            roomCategories={roomTypeCategories}
          />
        );
      case 1:
        return (
          <RoomSelect
            rooms={rooms}
            selectedRooms={selectedRooms}
            setSelectedRooms={handleRoomSelect}
            selectedRoomTypeCategory={selectedRoomCategory}
          />
        );
      case 2:
        return (
          <>
            {selectedRoomCategory && (
              <PackageSelector
                packages={packagesByCategory || []}
                selectedPackages={selectedPackages}
                onSelectPackage={handlePackageSelect}
              />
            )}
          </>
        );
      case 3:
        return (
          <AdditionalInformation
            screens={selectedScreens}
            packages={selectedPacks || []}
            selectedChannels={channels}
            userEmail={userSession?.username || ""}
            acceptedTerms={acceptTerms}
            setAcceptedTerms={handleAcceptTerms}
          />
        );
      case 4:
        return (
          <OrderConfirmation
            loadingStatus={contentManagerCreateOrderLoadingStatus}
          />
        );
      default:
        return null;
    }
  };

  const totalSteps = () => {
    return steps.length;
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const handleNext = () => {
    if (stepIsValid(activeStep)) {
      setCompleted({
        ...completed,
        [activeStep]: true,
      });
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };
  const handleBack = () => {
    setCompleted({
      ...completed,
      [activeStep]: false,
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const retractButtonAction =
    activeStep === 0 || activeStep >= 4 ? onClose : handleBack;

  const nextButtonAction = () => {
    const missingFields: string[] = [];

    if (activeStep === 3) {
      if (!acceptTerms) {
        missingFields.push(
          t(
            "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.missing_accept_terms"
          )
        );
      }
    }
    if (missingFields.length > 0) {
      alert(missingFields.join("\n"));
      return;
    } else {
      handleNext();
      if (activeStep === 3 && onSubmit) {
        onSubmit();
      }
    }
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  return (
    <Paper
      sx={{
        px: isMobile ? "0rem" : "1.5rem",
        pt: "2.5rem",
        pb: "1rem",
        backgroundColor: theme.palette.secondary.gray,
        position: "relative",
        "&:focus-visible": {
          outline: "none",
        },
      }}
    >
      <CloseOutlined
        onClick={onClose}
        sx={{
          position: "absolute",
          top: "1rem",
          right: "1rem",
          cursor: "pointer",
        }}
      />
      <Stack
        direction={isMobile ? "column" : "row"}
        px="1rem"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant={"h6"} textAlign={isMobile ? "center" : "left"}>
          {t(
            "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.modal_title"
          )}
        </Typography>
        {activeStep < 4 && (
          <ContentManagerStepper
            activeStep={activeStep}
            completedSteps={completed}
            steps={steps.slice(0, -1)}
          />
        )}
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          p: "1.5rem",
        }}
      >
        {activeStep !== 0 && (
          <Typography variant={"body2"}>
            {activeStep < 4 && t(steps[activeStep])}
          </Typography>
        )}
      </Stack>
      <Box
        sx={{
          px: "1rem",
        }}
      >
        {stepContent(activeStep)}
      </Box>
      <Stack
        direction={"row"}
        p="1rem"
        alignItems={"center"}
        sx={{ width: "100%" }}
      >
        <Button variant="outlined" onClick={retractButtonAction}>
          {retractButtonText}
        </Button>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            px: "1rem",
          }}
        >
          {[2].includes(activeStep) && (
            <ModalFlowSummary
              rooms={selectedScreens}
              packages={selectedPacks}
            />
          )}
        </Box>

        <Button
          variant="outlined"
          onClick={nextButtonAction}
          disabled={!stepIsValid(activeStep)}
        >
          {activeStep === 3 ? t("common.confirm") : t("common.next")}
        </Button>
      </Stack>
    </Paper>
  );
};

export default ContentManagerModal;
