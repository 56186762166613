import {
  NotificationsActions,
  NOTIFICATION_ACTIONS,
  SetNotificationAction,
} from "../../../../types/redux";

type NotificationSeverity = "error" | "warning" | "info" | "success";
export interface NotificationState {
  currentNotification?: string;
  severity?: NotificationSeverity;
}

export const initialState: NotificationState = {
  currentNotification: undefined,
  severity: undefined,
};

export function SetNotification(
  message?: string,
  severity?: NotificationSeverity
): SetNotificationAction {
  return {
    type: NOTIFICATION_ACTIONS.SET_NOTIFICATION,
    notification: {
      message,
      bgClass: undefined,
      severity: severity || undefined,
    },
  };
}

export default function notificationReducer(
  state: NotificationState = initialState,
  action: NotificationsActions
): NotificationState {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.SET_NOTIFICATION:
      return {
        ...state,
        currentNotification: action.notification && action.notification.message,
        severity: action.notification && action.notification.severity,
      };
    default:
      return state;
  }
}
