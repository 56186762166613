import { Box, Container, Fade, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Main from "./Main";
import Sidebar from "./Sidebar";
import LayoutBackground from "./assets/images/svg/background.svg";

import { CustomerPortalState } from "./store";
import { closedDrawerWidth, drawerWidth } from "./store/constant";

const MainLayout = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const leftDrawerOpened = useSelector<CustomerPortalState, boolean>(
    (state) => state.customization.opened
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SET_MENU", opened: !isMobile });
  }, [dispatch, isMobile]);

  const closedMobile = isMobile && !leftDrawerOpened;
  const openMobile = isMobile && leftDrawerOpened;

  const closedDesktop = !isMobile && !leftDrawerOpened;

  const margin = () => {
    if (closedMobile) return 0;
    if (openMobile) return 0;
    if (closedDesktop) return closedDrawerWidth;
    return drawerWidth;
  };
  return (
    <>
      <Fade in={true}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            backgroundColor: "white",
            position: "relative",
          }}
        >
          <Box sx={{ width: "100%", height: "100%" }}>
            <Sidebar drawerOpen={leftDrawerOpened} />
          </Box>
          <Box
            sx={{
              position: "relative",
              transition: (theme) =>
                theme.transitions.create("margin", {
                  easing: leftDrawerOpened
                    ? theme.transitions.easing.easeOut
                    : theme.transitions.easing.sharp,
                  duration: leftDrawerOpened
                    ? theme.transitions.duration.enteringScreen
                    : theme.transitions.duration.leavingScreen,
                }),
              marginLeft: leftDrawerOpened ? `${margin()}px` : 0,
              width: leftDrawerOpened ? `calc(100% - ${margin()}px)` : "100%",
            }}
          >
            <LayoutBackground
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                minHeight: "100vh",
              }}
            />
            <Header />
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                zIndex: 1,
                paddingTop: "2rem",
              }}
            >
              <Container maxWidth={"xl"}>
                <Main />
              </Container>
            </Box>
          </Box>
        </Box>
      </Fade>
    </>
  );
};

export default MainLayout;
