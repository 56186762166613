import { Button } from "@mui/material";
import React from "react";
import { CreateOrganizationUnit } from "../../redux/organizationUnit.redux";
import { store } from "../../redux/store";
import { Modal } from "../Modal/Modal";

interface ICreateHotelChainButtonProps {}

export const CreatePremiseGroupButton: React.FC<
  ICreateHotelChainButtonProps
> = () => {
  const [showCreatePremiseGroupForm, setShowCreatePremiseGroupForm] =
    React.useState(false);
  const [premiseGroupName, setPremiseGroupName] = React.useState("");
  const [redBeeName, setRedBeeName] = React.useState("");
  function submitNewPremiseGroup() {
    store.dispatch(
      CreateOrganizationUnit({
        name: premiseGroupName,
        type: "hotelChain",
        redBeeName: redBeeName,
      })
    );
  }
  return (
    <>
      <Button
        onClick={() => setShowCreatePremiseGroupForm(true)}
        variant="contained"
        color="secondary"
      >
        Add a premise group
      </Button>
      <Modal
        visible={showCreatePremiseGroupForm}
        onClose={() => setShowCreatePremiseGroupForm(false)}
        title={"Create new premise group"}
        onSubmit={submitNewPremiseGroup}
      >
        <>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Name</span>
            </div>
            <input
              value={premiseGroupName}
              onChange={(e) => setPremiseGroupName(e.target.value)}
              type="text"
              className="form-control"
              placeholder="The name of the premise group"
            />
          </div>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">RedBee name</span>
            </div>
            <input
              value={redBeeName}
              onChange={(e) => setRedBeeName(e.target.value)}
              type="text"
              className="form-control"
              placeholder="The name of a business unit in the red bee platform"
            />
          </div>
        </>
      </Modal>
    </>
  );
};
