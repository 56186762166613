import { Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import assetUtils from "@shared/assetUtils";
import { assetService } from "../../../http/asset.service";
import {
  ConditionalSectionWrapper,
  SectionWrapper,
} from "../../UI/SectionWrapper/SectionWrapper";

interface IChannelSelectorProps {
  currentValue?: string;
  onChange(newValue: string): void;
}

export const ChannelSelector: React.FC<IChannelSelectorProps> = ({
  currentValue,
  onChange,
}) => {
  const [channels, setChannels] = useState<any[]>([]);
  useEffect(() => {
    const sub = assetService
      .getAssetsByQuery("type:TV_CHANNEL", "-created")
      .subscribe((channels) => {
        setChannels(channels);
        sub.unsubscribe();
      });
  }, []);
  return (
    <ConditionalSectionWrapper>
      <SectionWrapper>
        <h4>Channel</h4>
        <div className="input-group">
          <Select
            value={currentValue || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            className="form-control"
          >
            <MenuItem value=""></MenuItem>
            {channels
              .map((c) => ({
                name: assetUtils.getTitle(c, ["en", "sv"]),
                id: c.assetId,
              }))
              .map((c) => (
                <MenuItem value={c.id} key={c.id}>
                  {c.name}
                </MenuItem>
              ))}
          </Select>
        </div>
      </SectionWrapper>
    </ConditionalSectionWrapper>
  );
};
