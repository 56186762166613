import { ArrowDropDown, Person, Settings } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";

const ProfileSettingsButton = ({ onClick }) => {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        background: "transparent",
        cursor: "pointer",
        transition: "background 1s ease-in-out",
        padding: "0.3rem 0.5rem",
        borderRadius: "0.7rem",
        "&:hover": {
          background: theme.palette.transparent.blue,
        },
      }}
    >
      <LinearGradientBase />
      <Person sx={{ fill: "url(#linearColors)" }} />
      <Settings sx={{ fill: "url(#linearColors)" }} />
      <ArrowDropDown sx={{ fill: "url(#linearColors)" }} />
    </Box>
  );
};

export default ProfileSettingsButton;

const LinearGradientBase = () => {
  const theme = useTheme();

  return (
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor={theme.palette.primary.light} />
        <stop offset={0.5} stopColor={theme.palette.primary.light} />
        <stop offset={1} stopColor={theme.palette.primary.main} />
      </linearGradient>
    </svg>
  );
};
