import { IUser } from "../../types/NendaTypes";
import { IUserListRequest } from "../../types/RequestTypes";
import { httpClient, Service } from "./client";

export type CreateUserPayload = Pick<
  IUser,
  "email" | "password" | "type" | "company"
> & {
  shouldSendEmail: boolean;
  premises: string[];
};

class UserService extends Service {
  getUsers(listRequest?: IUserListRequest) {
    const query = httpClient.listRequestToQuery(listRequest);
    return httpClient.get("/user" + query, this.authHeader());
  }
  createUser(data: CreateUserPayload) {
    return httpClient.post("/user", data, this.authHeader());
  }
  deleteUser(id: string) {
    return httpClient.delete(`/user/${id}`, this.authHeader());
  }
  updateUser(id: string, data: Partial<IUser>) {
    return httpClient.put(`/user/${id}`, data, this.authHeader());
  }
}

export const userService = new UserService();
