import { UserSession } from "../../types/NendaTypes";
import { Buffer } from "buffer";

export const decodeBase64 = (base64String) => {
  const decodedString = Buffer.from(base64String, "base64").toString("utf-8");
  return decodedString;
};

export const extractSessionTokenData = (
  sessionToken: string
): UserSession | undefined => {
  if (sessionToken === "") {
    return undefined;
  }

  const decodedSession = decodeBase64(sessionToken);
  const sessionData = JSON.parse(decodedSession.split("}")[1] + "}");

  const session = {
    role: sessionData.type,
    expires: sessionData.exp,
    username: sessionData.user,
    company: sessionData.company,
    premises: sessionData.premises,
    issuedAt: sessionData.iat,
  };
  return session;
};
