import React from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  FaLink,
  FaSave,
  FaHouseUser,
  FaPlug,
  FaHotel,
  FaCoins,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { findRoomTypeOnRules } from "@shared/productOfferingsUtils";
import {
  CacheLevel,
  IOrganizationUnit,
  RoomStatus,
} from "../../../types/NendaTypes";
import { SubscriptionData } from "@shared/types/Upsales.types";
import { OrganizationUnit } from "../../models/organizationUnit";
import {
  GetRoomsForHotel,
  UpdateOrganizationUnit,
  UpdateRoomTypeAllRooms,
} from "../../redux/organizationUnit.redux";
import { GetProductOfferings } from "../../redux/productOfferings.redux";
import { store } from "../../redux/store";
import { AppState } from "../../types/redux";
import { isUrl } from "../../utils/url";
import { CreateAreaButton } from "../CreateAreaButton/CreateAreaButton";
import { CreateRoomButton } from "../CreateRoomButton/CreateRoomButton";
import { DeleteOUWidget } from "../DeleteOuWidget/DeleteOuWidget";
import { Grid } from "../Grid/Grid";
import { PageHeader } from "../PageHeader/PageHeader";
import { RoomTypeCategoryBadge } from "../RoomTypeCategory/RoomTypeCategoryBadge";
import { SectionWrapper } from "../UI/SectionWrapper/SectionWrapper";
import { upsalesService } from "../../http/upsales.service";
import "./Hotel.scss";
import { ClearCacheButton } from "../Buttons/ClearCacheButton/ClearCacheButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import { GetCompanies, GetSubscriptions } from "../../redux/upsales.redux";

const roomPreviewValue = "105";
const areaPreviewValue = "Area1";

interface IHotelProps {
  ou: OrganizationUnit;
}

interface IHotelState extends IOrganizationUnit {
  logoUrl?: string;
}

export const Hotel: React.FC<IHotelProps> = ({ ou }) => {
  const rooms = useSelector((state: AppState) =>
    state.organizationUnits.hotelRooms.find((r) => r.hotelId === ou._id)
  );
  const [state, setState] = React.useState<IHotelState>(ou);
  const [preview, setPreview] = React.useState(false);
  const [regexPreview, setRegexPreview] = React.useState({
    areaRegex: areaPreviewValue,
    roomRegex: roomPreviewValue,
  });
  const [filteredUpsalesSubscriptionList, setFilteredUpsalesSubscriptionList] =
    React.useState<SubscriptionData[]>([]);

  const hotelChain = useSelector((state: AppState) =>
    state.organizationUnits.organizationUnits.find(
      (organizationUnit) => organizationUnit._id === ou.parent
    )
  );
  const productOfferings = useSelector(
    (state: AppState) => state.productOfferings.productOfferings
  );
  const upsalesCompanies = useSelector(
    (state: AppState) => state.upsales.companies
  );
  const upsalesSubscriptions = useSelector(
    (state: AppState) => state.upsales.subscriptions
  );

  React.useEffect(() => {
    setState(ou);
    store.dispatch(GetRoomsForHotel(ou._id));
    store.dispatch(GetProductOfferings(ou.parent));
  }, [ou]);

  React.useEffect(() => {
    store.dispatch(GetCompanies());
    store.dispatch(GetSubscriptions());
  }, []);

  React.useEffect(() => {
    if (
      upsalesSubscriptions &&
      upsalesSubscriptions.length > 0 &&
      state.upsalesCompanyId
    )
      setFilteredUpsalesSubscriptionList(
        upsalesSubscriptions.filter(
          (sub) => sub.client.id == state.upsalesCompanyId
        )
      );
  }, [upsalesSubscriptions, state.upsalesCompanyId]);

  const changeLogoUrl = (e) => {
    setState({ ...state, logoUrl: e.target.value });
    if (isUrl(e.target.value)) {
      setPreview(true);
    }
  };

  const setDefaultLogo = () => {
    setState({
      ...state,
      logoUrl:
        "https://noriginmedia.com/wp-content/uploads/2019/08/Nenda-1.png",
    });
    setPreview(true);
  };

  const changePassword = () => {
    const providedPassword = (
      document.querySelector("input#hotelPassword") as HTMLInputElement
    ).value;
    store.dispatch(
      UpdateOrganizationUnit(ou._id, { password: providedPassword })
    );
  };

  const applyRegexMemoized = React.useCallback(applyRegex, []);

  const updateRegex = (key, regex = ["", ""]) => {
    // Reset regex preview
    setRegexPreview({
      ...regexPreview,
      [key]: applyRegexMemoized(
        key === "areaRegex" ? areaPreviewValue : roomPreviewValue,
        regex
      ),
    });
    // Update model
    setState({ ...state, regex: { ...state.regex, [key]: regex } });
  };

  React.useEffect(() => {
    // Preset regex previews
    setRegexPreview({
      ...regexPreview,
      areaRegex: applyRegex(roomPreviewValue, [
        ...(state.regex.areaRegex || []),
      ]),
      roomRegex: applyRegex(roomPreviewValue, [
        ...(state.regex.roomRegex || []),
      ]),
    });
  }, []);

  const [upsalesCompanyIdFetch, setUpsalesCompanyId] = React.useState(
    ou.upsalesCompanyId || -1
  );
  const [upsalesCompanyInfo, setUpsalesCompanyInfo] = React.useState("");
  React.useEffect(() => {
    const timeOutId = setTimeout(
      () => fetchUpsalesCompanyInfo(upsalesCompanyIdFetch),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [upsalesCompanyIdFetch]);

  function fetchUpsalesCompanyInfo(id: number) {
    setState({ ...state, upsalesCompanyId: id });
    if (id > 0) {
      upsalesService
        .getCompany(id)
        .toPromise()
        .then((company) => {
          setUpsalesCompanyInfo(`${company.response.name}`);
        })
        .catch((error) => {
          setUpsalesCompanyInfo(`Error: ${error}`);
        });
    } else {
      setUpsalesCompanyInfo("Invalid company ID");
    }
  }

  const updateRoomTypeForAllScreens = () => {
    store.dispatch(UpdateRoomTypeAllRooms(state._id, state.defaultRoomType));
  };

  function update() {
    const {
      logoUrl,
      defaultRoomType,
      upsalesCompanyId,
      upsalesSubscriptionId,
      pmsHotelId,
      pmsToken,
      regex,
      allowContinuousStreaming,
      showNonPurchasedContent,
    } = state;
    const payload = {
      logoUrl,
      defaultRoomType,
      upsalesCompanyId,
      upsalesSubscriptionId,
      pmsHotelId,
      pmsToken,
      regex,
      allowContinuousStreaming,
      showNonPurchasedContent,
    };
    if (ou.defaultRoomType === defaultRoomType) {
      delete payload.defaultRoomType;
    }
    store.dispatch(UpdateOrganizationUnit(ou._id, payload));
    setPreview(false);
  }

  const relatedProductOfferings = productOfferings.filter((po) =>
    findRoomTypeOnRules(po, state.defaultRoomType)
  );

  return (
    <>
      <PageHeader>
        {state.logoUrl && state.logoUrl.length > 0 && isUrl(state.logoUrl) && (
          <div className="logo-wrapper">
            <img className="logo" src={state.logoUrl} />
            {preview && <div className="logo-preview">(Preview)</div>}
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center">
          <h2>{ou.name}</h2>
          <div>
            {!ou.hash && (
              <span
                className="badge badge-danger mx-1"
                style={{ margin: "auto" }}
              >
                Password
              </span>
            )}
            {!ou.defaultRoomType && (
              <span
                className="badge badge-danger mx-1"
                style={{ margin: "auto" }}
              >
                Room Type
              </span>
            )}
            {(!ou.regex.roomRegex[0] || !ou.regex.roomRegex[1]) && (
              <span
                className="badge badge-danger mx-1"
                style={{ margin: "auto" }}
              >
                Room regex
              </span>
            )}
            {ou.numberOfRooms === 0 && (
              <span className="badge badge-warning" style={{ margin: "auto" }}>
                No rooms
              </span>
            )}
            {!ou.upsalesCompanyId && ou.isCustomer() && (
              <span className="badge badge-warning" style={{ margin: "auto" }}>
                Upsales
              </span>
            )}
          </div>
        </div>
        <CreateRoomButton
          hotelId={ou._id}
          hotelChainId={ou.parent}
          roomType={ou.defaultRoomType}
        />
        <CreateAreaButton
          hotelId={ou._id}
          hotelChainId={ou.parent}
          roomType={ou.defaultRoomType}
        />
        <DeleteOUWidget ouId={ou._id}>Delete Hotel</DeleteOUWidget>
        <Link
          to={`/organizationunits/hotelchain/${ou.parent}/hotel/${ou._id}/report`}
        >
          <button className="btn btn-secondary">Report</button>
        </Link>
        <ClearCacheButton cacheLevel={CacheLevel.Premise} id={ou._id} />
      </PageHeader>
      <SectionWrapper>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="accordion-summary-gray"
          >
            <FaHouseUser className="mr-2" />
            General
          </AccordionSummary>
          <AccordionDetails>
            <SectionWrapper>
              <div className="row">
                <div className="col-md-6">
                  <h4>Area Regex</h4>
                  <div className="d-flex mb-2">
                    <input
                      className="form-control mr-2"
                      value={
                        state.regex.areaRegex ? state.regex.areaRegex[0] : ""
                      }
                      onChange={({ target: { value } }) =>
                        updateRegex("areaRegex", [
                          value,
                          state.regex.areaRegex[1],
                        ])
                      }
                    />
                    <input
                      className="form-control ml-2"
                      value={
                        state.regex.areaRegex ? state.regex.areaRegex[1] : ""
                      }
                      onChange={({ target: { value } }) =>
                        updateRegex("areaRegex", [
                          state.regex.areaRegex[0],
                          value,
                        ])
                      }
                    />
                  </div>
                  <span>
                    Preview: "{areaPreviewValue}" -&gt; "
                    {regexPreview["areaRegex"]}"
                  </span>
                </div>
                <div className="col-md-6">
                  <h4>Room Regex</h4>
                  <div className="d-flex mb-2">
                    <input
                      className="form-control mr-2"
                      value={
                        state.regex.roomRegex ? state.regex.roomRegex[0] : ""
                      }
                      onChange={({ target: { value } }) =>
                        updateRegex("roomRegex", [
                          value,
                          state.regex.roomRegex[1],
                        ])
                      }
                    />
                    <input
                      className="form-control ml-2"
                      value={
                        state.regex.roomRegex ? state.regex.roomRegex[1] : ""
                      }
                      onChange={({ target: { value } }) =>
                        updateRegex("roomRegex", [
                          state.regex.roomRegex[0],
                          value,
                        ])
                      }
                    />
                  </div>
                  <span>
                    Preview: "{roomPreviewValue}" -&gt; "
                    {regexPreview["roomRegex"]}"
                  </span>
                </div>
              </div>
            </SectionWrapper>
            <SectionWrapper>
              <h4>Password for setup</h4>
              <div className="input-group mb-3">
                <input
                  id="hotelPassword"
                  type="password"
                  className="form-control"
                  autoComplete="new-password"
                  placeholder={
                    state.hash
                      ? "Password has been set"
                      : "Hotel device setup password"
                  }
                  aria-label={
                    state.hash
                      ? "Password has been set"
                      : "Hotel device setup password"
                  }
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={(e) => changePassword()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </SectionWrapper>
            <SectionWrapper>
              <h4>Default room type(for new rooms)</h4>
              <div className="d-flex">
                <Select
                  onChange={(e) =>
                    setState({ ...state, defaultRoomType: e.target.value })
                  }
                  value={state.defaultRoomType}
                  className="form-control col-md-6"
                >
                  <MenuItem value={undefined}></MenuItem>
                  {(hotelChain?.roomTypes || []).map((roomType) => (
                    <MenuItem key={roomType._id} value={roomType._id}>
                      {roomType.name} ({roomType.numberOfRooms})
                    </MenuItem>
                  ))}
                </Select>
                {state.defaultRoomType && (
                  <>
                    <Link
                      to={`/admin/organizationunits/hotelchain/${ou.parent}/roomType/${state.defaultRoomType}`}
                    >
                      <button className="btn btn-secondary">
                        <FaLink />
                      </button>
                    </Link>
                    <RoomTypeCategoryBadge
                      roomType={
                        hotelChain
                          ? hotelChain.getRoomType(state.defaultRoomType)
                          : undefined
                      }
                    />
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => updateRoomTypeForAllScreens()}
                    >
                      Change ALL screens
                    </Button>
                  </>
                )}
              </div>
              <p>
                Product offerings:{" "}
                {relatedProductOfferings.length
                  ? relatedProductOfferings
                      .map((po) => po.localizedMetadata?.[0].name)
                      .join(", ")
                  : "<no product offering>"}
              </p>
            </SectionWrapper>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="accordion-summary-gray"
          >
            <FaPlug className="mr-2" />
            Features
          </AccordionSummary>
          <AccordionDetails>
            <SectionWrapper>
              <div className="logo-url-header">
                <h4>Logo url</h4>{" "}
                <button
                  className="btn btn-secondary ml-2"
                  onClick={setDefaultLogo}
                >
                  Set default
                </button>
              </div>
              <input
                className="form-control"
                autoComplete="off"
                value={state.logoUrl || ""}
                onChange={(e) => changeLogoUrl(e)}
              />
            </SectionWrapper>
            <SectionWrapper>
              <div className="row">
                <div className="col-md-6">
                  <h4>Display non-purchased content</h4>
                  <div className="custom-control custom-switch">
                    <input
                      name="showNonPurchasedContent"
                      id="showNonPurchasedContent"
                      type="checkbox"
                      className="custom-control-input"
                      checked={state.showNonPurchasedContent}
                      onChange={({ target: { checked } }) =>
                        setState({
                          ...state,
                          showNonPurchasedContent: checked,
                        })
                      }
                    />
                    <label
                      htmlFor="showNonPurchasedContent"
                      className="custom-control-label showNonPurchasedContent-label"
                    >
                      Display
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <h4>Allow continuous streaming</h4>
                  <div className="custom-control custom-switch">
                    <input
                      name="allowContinuousStreaming"
                      id="allowContinuousStreaming"
                      type="checkbox"
                      className="custom-control-input"
                      checked={state.allowContinuousStreaming}
                      onChange={({ target: { checked } }) =>
                        setState({
                          ...state,
                          allowContinuousStreaming: checked,
                        })
                      }
                    />
                    <label
                      htmlFor="allowContinuousStreaming"
                      className="custom-control-label allowContinuousStreaming-label"
                    >
                      Allow continuous streaming
                    </label>
                  </div>
                </div>
              </div>
            </SectionWrapper>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="accordion-summary-gray"
          >
            <FaHotel className="mr-2" />
            PMS
          </AccordionSummary>
          <AccordionDetails>
            <SectionWrapper>
              <div className="row">
                <div className="col-md-6">
                  <h4>Hotel ID in the PMS system</h4>
                  <input
                    className="form-control"
                    value={state.pmsHotelId || ""}
                    onChange={({ target: { value } }) =>
                      setState({ ...state, pmsHotelId: value })
                    }
                  />
                </div>
                <div className="col-md-6">
                  <h4>Customer Unit(PMS Token)</h4>
                  <input
                    className="form-control"
                    value={state.pmsToken || ""}
                    onChange={({ target: { value } }) =>
                      setState({ ...state, pmsToken: value })
                    }
                  />
                </div>
              </div>
            </SectionWrapper>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="accordion-summary-gray"
          >
            <FaCoins />
            Upsales
          </AccordionSummary>
          <AccordionDetails>
            <SectionWrapper>
              <div className="row">
                <div className="col-md-6">
                  <h4>Upsales Company ID</h4>
                  <div className="d-flex">
                    <Select
                      fullWidth
                      onChange={(e) =>
                        setState({
                          ...state,
                          upsalesCompanyId: e.target.value
                            ? parseInt(e.target.value as string)
                            : undefined,
                        })
                      }
                      value={state.upsalesCompanyId}
                    >
                      <MenuItem value={""}>[ None ]</MenuItem>
                      {upsalesCompanies
                        ? upsalesCompanies
                            .filter(
                              (company) => company.journeyStep == "customer"
                            )
                            .map((company) => (
                              <MenuItem key={company.id} value={company.id}>
                                {company.name} ({company.id})
                              </MenuItem>
                            ))
                        : "Loading..."}
                    </Select>
                  </div>
                </div>
                <div className="col-md-6">
                  <h4>Upsales Subscription</h4>
                  <div className="d-flex">
                    <Select
                      fullWidth
                      onChange={(e) =>
                        setState({
                          ...state,
                          upsalesSubscriptionId: e.target.value
                            ? parseInt(e.target.value as string)
                            : undefined,
                        })
                      }
                      value={state.upsalesSubscriptionId}
                    >
                      <MenuItem value={""}>[ None ]</MenuItem>
                      {filteredUpsalesSubscriptionList
                        ? filteredUpsalesSubscriptionList.map((sub) => (
                            <MenuItem key={sub.id} value={sub.id}>
                              {sub.description} ({sub.id})
                            </MenuItem>
                          ))
                        : "Loading..."}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Category</th>
                        <th scope="col">Name</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Unit price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {upsalesSubscriptions &&
                      upsalesSubscriptions.length > 0 ? (
                        upsalesSubscriptions
                          .find((sub) => sub.id == state.upsalesSubscriptionId)
                          ?.orderRow.map((row) => {
                            return (
                              <tr>
                                <td>{row.product?.category?.name}</td>
                                <td>{row.product?.name}</td>
                                <td>{row.quantity}</td>
                                <td>{row.price} SEK</td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={4}>Loading...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </SectionWrapper>
          </AccordionDetails>
        </Accordion>
        <Button
          disabled={state === ou}
          onClick={update}
          className="mr-2 my-2"
          variant="contained"
          color="secondary"
          endIcon={<FaSave />}
        >
          Save
        </Button>
        <SectionWrapper>
          <h4>Rooms ({rooms?.rooms?.length})</h4>
          {rooms && (
            <Grid
              items={rooms.rooms.map((room) => {
                const roomType = hotelChain?.getRoomType(room.roomType);
                return {
                  name: room.name,
                  label: { text: room.description },
                  id: room._id,
                  link: `/organizationunits/hotelchain/${room.hotelChain}/hotel/${room.hotel}/room/${room._id}`,
                  sortIndex: room.roomIdentifier,
                  className: room.status === "active" ? "" : "bg-dark",
                  badge:
                    room.status === RoomStatus.ACTIVE ? (
                      <span
                        className="badge badge-success ml-2"
                        style={{ margin: "auto" }}
                      >
                        Active
                      </span>
                    ) : (
                      <span
                        className="badge badge-danger ml-2"
                        style={{ margin: "auto" }}
                      >
                        Inactive
                      </span>
                    ),
                  labelTag: roomType ? (
                    <RoomTypeCategoryBadge roomType={roomType} border={true}>
                      {roomType.name}
                    </RoomTypeCategoryBadge>
                  ) : (
                    room.roomType && (
                      <span className="badge mx-1 text-white rounded text-break bg-danger">
                        Invalid room type
                      </span>
                    )
                  ),
                };
              })}
            />
          )}
        </SectionWrapper>
      </SectionWrapper>
    </>
  );
};

function applyRegex(str, regex) {
  try {
    return str.match(new RegExp(regex[0]))
      ? str.replace(new RegExp(regex[0]), regex[1])
      : "<no matches>";
  } catch (e) {
    return "<invalid regex>";
  }
}
