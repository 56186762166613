import { Button, Paper, useMediaQuery, useTheme } from "@mui/material";
import DropdownMenu, { MenuOption } from "../DropdownMenu";

export type ActionButtonProps = {
  label: string;
  onClick: (value: any) => void;
  disabled: boolean;
  variant: "contained" | "outlined" | "text";
};

type ButtonOverlayListProps = {
  actions: ActionButtonProps[];
  secondaryActions: MenuOption[];
  position: { top?: number; left?: number; bottom?: number; right?: number };
};

const ButtonOverlayList = ({
  actions,
  secondaryActions,
  position = { top: 0, left: 0 },
}: ButtonOverlayListProps) => {
  return (
    <Paper
      sx={{
        position: "fixed",
        top: position.top,
        bottom: position.bottom,
        right: position.right,
        left: position.left,
        display: "flex",
        gap: "1rem",
        p: "1rem",
        m: "1rem",
        boxShadow: 20,
      }}
    >
      <DropdownMenu options={secondaryActions} />
      {actions &&
        actions.map((action, index) => (
          <Button
            variant={action.variant}
            key={index}
            onClick={action.onClick}
            disabled={action.disabled}
          >
            {action.label}
          </Button>
        ))}
    </Paper>
  );
};

export default ButtonOverlayList;
