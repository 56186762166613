import {
  ajaxDelete,
  ajaxGet,
  ajaxPatch,
  ajaxPost,
  ajaxPut,
} from "rxjs/internal-compatibility";
import { map } from "rxjs/operators";
import { IListRequest } from "../../types/RequestTypes";

const internalApiBaseUrl = "/api/internal";

const defaultHeaders = (enabled: boolean) => {
  return enabled
    ? {
        "Content-Type": "application/json",
      }
    : {};
};

class NendaApiClient {
  get(url: string, headers = {}) {
    return ajaxGet(internalApiBaseUrl + url, {
      ...headers,
    }).pipe(map((res) => res.response));
  }

  post(url: string, body = {}, headers = {}, useDefaultHeaders = true) {
    return ajaxPost(internalApiBaseUrl + url, body, {
      ...defaultHeaders(useDefaultHeaders),
      ...headers,
    }).pipe(map((res) => res.response));
  }

  delete(url: string, headers = {}) {
    return ajaxDelete(internalApiBaseUrl + url, {
      ...headers,
    }).pipe(map((res) => res.response));
  }

  put(url: string, body = {}, headers = {}, useDefaultHeaders = true) {
    return ajaxPut(internalApiBaseUrl + url, body, {
      ...defaultHeaders(useDefaultHeaders),
      ...headers,
    }).pipe(map((res) => res.response));
  }

  patch(url: string, body = {}, headers = {}, useDefaultHeaders = true) {
    return ajaxPatch(internalApiBaseUrl + url, body, {
      ...defaultHeaders(useDefaultHeaders),
      ...headers,
    }).pipe(map((res) => res.response));
  }

  listRequestToQuery(listRequest?: IListRequest<any, any>) {
    if (!listRequest) return "";
    const { pagination, filter, sort } = listRequest;

    let paginationQuery = "";
    let filterQuery = "";
    let sortQuery = "";

    if (pagination) {
      paginationQuery = `page=${pagination.page}&pageSize=${pagination.pageSize}`;
    }
    if (filter) {
      filterQuery = Object.keys(filter)
        .map((key) => `filter=${key}:${filter[key]}`)
        .join("&");
    }
    if (sort) {
      sortQuery = `sortBy=${sort.sortBy}&sortOrder=${sort.sortOrder}`;
    }

    if (!paginationQuery && !filterQuery && !sortQuery) {
      return "";
    }

    return (
      "?" + [paginationQuery, filterQuery, sortQuery].filter((x) => x).join("&")
    );
  }
}

export class Service {
  authHeader() {
    return { Authorization: `Bearer ${localStorage.getItem("sessionToken")}` };
  }
}

export const httpClient = new NendaApiClient();
