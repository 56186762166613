import { ImageNotSupported, LiveTvOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

export type PlaceholderImageTypes =
  | PlaceholderImageType.CHANNEL_LOGO
  | PlaceholderImageType.POSTER_IMAGE;

export enum PlaceholderImageType {
  CHANNEL_LOGO = "contentChannel",
  POSTER_IMAGE = "posterImage",
}

const PlaceholderImage = ({ type }: { type: PlaceholderImageType }) => {
  switch (type) {
    case PlaceholderImageType.CHANNEL_LOGO:
      return (
        <Box
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={"black"}
        >
          <LiveTvOutlined sx={{ fill: "white", objectFit: "cover" }} />
        </Box>
      );
    case PlaceholderImageType.POSTER_IMAGE:
      return (
        <Box
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={"black"}
        >
          <ImageNotSupported
            sx={{
              fill: "white",
              objectFit: "cover",
            }}
          />
        </Box>
      );
  }
};

export default PlaceholderImage;
