import { Epic, ofType } from "redux-observable";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { CustomerPortalState } from "..";
import {
  Company,
  IAsset,
  LoadingStatus,
  NendaProduct,
  Status,
} from "../../../../../types/NendaTypes";
import { companyService } from "../../../../http/companyService";
import {
  UpdateAssetProps,
  organizationUnitService,
} from "../../../../http/organizationUnit.service";
import { SetError } from "./errorReducer";
import { SetNotification } from "./notificationReducer";
import {
  IAssetListRequest,
  IListResponse,
} from "../../../../../types/RequestTypes";
import { t } from "i18next";
import { createSelector } from "@reduxjs/toolkit";
import { selectCurrentNavigationLevel } from "./workspaceReducer";

type PaginationState = IListResponse<IAsset> & { status: Status };

const initPaginationState: PaginationState = {
  status: LoadingStatus.IDLE,
  data: [],
  page: 1,
  pageSize: 0,
  filteredCount: 0,
  totalCount: 0,
};

export interface AssetState {
  companyAssets: Record<string, Record<NendaProduct, IAsset[]>>;
  paginatedCompanyAssets: PaginationState;
  premiseAssets: Record<string, Record<NendaProduct, IAsset[]>>;
  paginatedPremiseAssets: PaginationState;
  showCreateAssetDialog: boolean;
}

export const initialState: AssetState = {
  companyAssets: {},
  paginatedCompanyAssets: {
    ...initPaginationState,
  },
  premiseAssets: {},
  paginatedPremiseAssets: {
    ...initPaginationState,
  },
  showCreateAssetDialog: false,
};

enum ASSET_ACTION {
  GET_COMPANY_ASSETS = "GET_COMPANY_ASSETS",
  GET_COMPANY_ASSETS_SUCCESS = "GET_COMPANY_ASSETS_SUCCESS",
  GET_COMPANY_ASSETS_FAILURE = "GET_COMPANY_ASSETS_FAILURE",
  CREATE_COMPANY_ASSET = "CREATE_COMPANY_ASSET",
  CREATE_COMPANY_ASSET_SUCCESS = "CREATE_COMPANY_ASSET_SUCCESS",
  CREATE_COMPANY_ASSET_FAILURE = "CREATE_COMPANY_ASSET_FAILURE",
  UPDATE_COMPANY_ASSET = "UPDATE_COMPANY_ASSET",
  UPDATE_COMPANY_ASSET_SUCCESS = "UPDATE_COMPANY_ASSET_SUCCESS",
  UPDATE_COMPANY_ASSET_FAILURE = "UPDATE_COMPANY_ASSET_FAILURE",
  DELETE_COMPANY_ASSET = "DELETE_COMPANY_ASSET",
  DELETE_COMPANY_ASSET_SUCCESS = "DELETE_COMPANY_ASSET_SUCCESS",
  DELETE_COMPANY_ASSET_FAILURE = "DELETE_COMPANY_ASSET_FAILURE",
  GET_PREMISE_ASSETS = "GET_PREMISE_ASSETS",
  GET_PREMISE_ASSETS_SUCCESS = "GET_PREMISE_ASSETS_SUCCESS",
  GET_PREMISE_ASSETS_FAILURE = "GET_PREMISE_ASSETS_FAILURE",
  CREATE_PREMISE_ASSET = "CREATE_PREMISE_ASSET",
  CREATE_PREMISE_ASSET_SUCCESS = "CREATE_PREMISE_ASSET_SUCCESS",
  CREATE_PREMISE_ASSET_FAILURE = "CREATE_PREMISE_ASSET_FAILURE",
  UPDATE_PREMISE_ASSET = "UPDATE_PREMISE_ASSET",
  UPDATE_PREMISE_ASSET_SUCCESS = "UPDATE_PREMISE_ASSET_SUCCESS",
  UPDATE_PREMISE_ASSET_FAILURE = "UPDATE_PREMISE_ASSET_FAILURE",
  DELETE_PREMISE_ASSET = "DELETE_PREMISE_ASSET",
  DELETE_PREMISE_ASSET_SUCCESS = "DELETE_PREMISE_ASSET_SUCCESS",
  DELETE_PREMISE_ASSET_FAILURE = "DELETE_PREMISE_ASSET_FAILURE",
  TOGGLE_CREATE_ASSET_DIALOG = "TOGGLE_CREATE_ASSET_DIALOG",
}

export interface AssetAction {
  type: ASSET_ACTION;
  payload?: any;
}

// -- Action Creators --
export function GetCompanyAssets(
  companyId: string,
  payload?: IAssetListRequest
): AssetAction {
  return {
    type: ASSET_ACTION.GET_COMPANY_ASSETS,
    payload: { companyId, query: payload },
  };
}

function GetCompanyAssetsSuccess(
  companyId: string,
  payload: IAssetListRequest & IListResponse<IAsset>
): AssetAction {
  return {
    type: ASSET_ACTION.GET_COMPANY_ASSETS_SUCCESS,
    payload: { companyId, ...payload },
  };
}

export function GetPremiseAssets(
  premiseId: string,
  payload?: IAssetListRequest
): AssetAction {
  return {
    type: ASSET_ACTION.GET_PREMISE_ASSETS,
    payload: { premiseId, query: payload },
  };
}

function GetPremiseAssetsSuccess(
  premiseId: string,
  payload: IAssetListRequest
): AssetAction {
  return {
    type: ASSET_ACTION.GET_PREMISE_ASSETS_SUCCESS,
    payload: { premiseId, ...payload },
  };
}

function GetCompanyAssetsFailure(error: any): AssetAction {
  return { type: ASSET_ACTION.GET_COMPANY_ASSETS_FAILURE, payload: error };
}

function GetPremiseAssetsFailure(error: any): AssetAction {
  return { type: ASSET_ACTION.GET_PREMISE_ASSETS_FAILURE, payload: error };
}

export function CreateCompanyAsset(
  companyId: string,
  asset: IAsset
): AssetAction {
  return {
    type: ASSET_ACTION.CREATE_COMPANY_ASSET,
    payload: { companyId, asset },
  };
}

function CreateCompanyAssetSuccess(response: IAsset): AssetAction {
  return {
    type: ASSET_ACTION.CREATE_COMPANY_ASSET_SUCCESS,
    payload: response,
  };
}

function CreateCompanyAssetFailure(error: any): AssetAction {
  return {
    type: ASSET_ACTION.TOGGLE_CREATE_ASSET_DIALOG,
    payload: error.message,
  };
}

export function ToggleCreateAssetDialog(): AssetAction {
  return {
    type: ASSET_ACTION.TOGGLE_CREATE_ASSET_DIALOG,
  };
}

export function UpdateCompanyAsset({
  companyId,
  assetId,
  product,
  asset,
}: UpdateAssetProps): AssetAction {
  return {
    type: ASSET_ACTION.UPDATE_COMPANY_ASSET,
    payload: {
      companyId,
      assetId,
      product,
      asset,
    },
  };
}

function UpdateCompanyAssetSuccess(response: IAsset): AssetAction {
  return {
    type: ASSET_ACTION.UPDATE_COMPANY_ASSET_SUCCESS,
    payload: response,
  };
}

function UpdateCompanyAssetFailure(error: any): AssetAction {
  return {
    type: ASSET_ACTION.UPDATE_COMPANY_ASSET_FAILURE,
    payload: error.response ? error.response.message : error.message,
  };
}

export function DeleteCompanyAsset({
  companyId,
  product,
  assetId,
}: {
  companyId: Company["_id"];
  product: NendaProduct;
  assetId: IAsset["_id"];
}): AssetAction {
  return {
    type: ASSET_ACTION.DELETE_COMPANY_ASSET,
    payload: { companyId, product, assetId },
  };
}

function DeleteCompanyAssetSuccess(response: IAsset): AssetAction {
  return {
    type: ASSET_ACTION.DELETE_COMPANY_ASSET_SUCCESS,
    payload: response,
  };
}

function DeleteCompanyAssetFailure(error: any): AssetAction {
  return {
    type: ASSET_ACTION.DELETE_COMPANY_ASSET_FAILURE,
    payload: error.response ? error.response.message : error.message,
  };
}

export function CreatePremiseAsset(
  premiseId: string,
  asset: Partial<IAsset>
): AssetAction {
  return {
    type: ASSET_ACTION.CREATE_PREMISE_ASSET,
    payload: { premiseId, asset },
  };
}

function CreatePremiseAssetSuccess(response: IAsset): AssetAction {
  return {
    type: ASSET_ACTION.CREATE_PREMISE_ASSET_SUCCESS,
    payload: response,
  };
}

function CreatePremiseAssetFailure(error: any): AssetAction {
  return {
    type: ASSET_ACTION.CREATE_PREMISE_ASSET_FAILURE,
    payload: error.message,
  };
}

export function UpdatePremiseAsset({
  premiseId,
  assetId,
  product,
  asset,
}: UpdateAssetProps): AssetAction {
  return {
    type: ASSET_ACTION.UPDATE_PREMISE_ASSET,
    payload: {
      premiseId,
      assetId,
      product,
      asset,
    },
  };
}

function UpdatePremiseAssetSuccess(response: IAsset): AssetAction {
  return {
    type: ASSET_ACTION.UPDATE_PREMISE_ASSET_SUCCESS,
    payload: response,
  };
}

function UpdatePremiseAssetFailure(error: any): AssetAction {
  return {
    type: ASSET_ACTION.UPDATE_PREMISE_ASSET_FAILURE,
    payload: error.response ? error.response.message : error.message,
  };
}

export function DeletePremiseAsset({
  premiseId,
  product,
  assetId,
}): AssetAction {
  return {
    type: ASSET_ACTION.DELETE_PREMISE_ASSET,
    payload: { premiseId, product, assetId },
  };
}

function DeletePremiseAssetSuccess(response: IAsset): AssetAction {
  return {
    type: ASSET_ACTION.DELETE_PREMISE_ASSET_SUCCESS,
    payload: response,
  };
}

function DeletePremiseAssetFailure(error: any): AssetAction {
  return {
    type: ASSET_ACTION.DELETE_PREMISE_ASSET_FAILURE,
    payload: error.response ? error.response.message : error.message,
  };
}

// -- Selectors --
export function getCompanyAssets(
  state: CustomerPortalState,
  companyId: string,
  product: NendaProduct
): IAsset[] {
  return state.asset.companyAssets[companyId]?.[product] || [];
}

export function getPaginatedCompanyAssets(
  state: CustomerPortalState
): IListResponse<IAsset> {
  return state.asset.paginatedCompanyAssets;
}

export function getPremiseAssets(
  state: CustomerPortalState,
  premiseId: string,
  product: NendaProduct
): IAsset[] {
  return state.asset.premiseAssets[premiseId]?.[product] || [];
}

export function getPaginatedPremiseAssets(
  state: CustomerPortalState
): IListResponse<IAsset> {
  return state.asset.paginatedPremiseAssets;
}

export const selectAllAssets = createSelector(
  [(state: CustomerPortalState) => state.asset],
  (assets) => {
    return [
      assets.paginatedCompanyAssets.data,
      assets.paginatedPremiseAssets.data,
    ].flat();
  }
);

export const selectAssetsBasedOnNavigationScope = createSelector(
  getPaginatedPremiseAssets,
  getPaginatedCompanyAssets,
  selectCurrentNavigationLevel,
  (premiseAssets, companyAssets, level) => {
    if (level === "company") return companyAssets.data;
    if (level === "premise")
      return premiseAssets.data.concat(companyAssets.data);
    return [];
  }
);

// -- Reducer --
export default function assetReducer(
  state = initialState,
  action: AssetAction
): AssetState {
  switch (action.type) {
    case ASSET_ACTION.GET_COMPANY_ASSETS:
      return {
        ...state,
        paginatedCompanyAssets: {
          ...state.paginatedCompanyAssets,
          status: LoadingStatus.LOADING,
        },
      };
    case ASSET_ACTION.GET_COMPANY_ASSETS_SUCCESS:
      return {
        ...state,
        paginatedCompanyAssets: {
          ...action.payload,
          status: LoadingStatus.SUCCEEDED,
          data: [
            ...((action.payload.page > 1 &&
              state.paginatedCompanyAssets.data) ||
              []),
            ...action.payload?.data,
          ],
        },
      };
    case ASSET_ACTION.GET_PREMISE_ASSETS:
      return {
        ...state,
        paginatedPremiseAssets: {
          ...state.paginatedPremiseAssets,
          status: LoadingStatus.LOADING,
        },
      };
    case ASSET_ACTION.GET_PREMISE_ASSETS_SUCCESS:
      return {
        ...state,
        paginatedPremiseAssets: {
          ...action.payload,
          status: LoadingStatus.SUCCEEDED,
          data: [
            ...((action.payload.page > 1 &&
              state.paginatedPremiseAssets.data) ||
              []),
            ...action.payload?.data,
          ],
        },
      };
    case ASSET_ACTION.CREATE_COMPANY_ASSET_SUCCESS:
      return {
        ...state,
        showCreateAssetDialog: false,
        paginatedCompanyAssets: {
          ...state.paginatedCompanyAssets,
          status: LoadingStatus.SUCCEEDED,
          data: [...state.paginatedCompanyAssets.data, action.payload],
        },
      };
    case ASSET_ACTION.UPDATE_COMPANY_ASSET_SUCCESS:
      return {
        ...state,
        companyAssets: {
          ...state.companyAssets,
          [action.payload.company]: {
            ...state.companyAssets[action.payload.company],
            [action.payload.product]: state.companyAssets[
              action.payload.company
            ][action.payload.product].map((asset: IAsset) => {
              if (asset._id === action.payload._id) {
                return action.payload;
              }
              return asset;
            }),
          },
        },
      };

    case ASSET_ACTION.DELETE_COMPANY_ASSET_SUCCESS:
      return {
        ...state,
        paginatedCompanyAssets: {
          ...state.paginatedCompanyAssets,
          status: LoadingStatus.SUCCEEDED,
          data: {
            ...state.paginatedCompanyAssets.data.filter(
              (asset: IAsset) => asset._id !== action.payload._id
            ),
          },
        },
      };

    case ASSET_ACTION.CREATE_PREMISE_ASSET_SUCCESS:
      return {
        ...state,
        showCreateAssetDialog: false,
        paginatedPremiseAssets: {
          ...state.paginatedPremiseAssets,
          status: LoadingStatus.SUCCEEDED,
          data: [...state.paginatedPremiseAssets.data, action.payload],
        },
      };
    case ASSET_ACTION.UPDATE_PREMISE_ASSET_SUCCESS:
      return {
        ...state,
        paginatedPremiseAssets: {
          ...state.paginatedPremiseAssets,
          status: LoadingStatus.SUCCEEDED,
          data: state.paginatedPremiseAssets.data.map((asset: IAsset) => {
            if (asset._id === action.payload._id) {
              return action.payload;
            }
            return asset;
          }),
        },
      };

    case ASSET_ACTION.DELETE_PREMISE_ASSET_SUCCESS:
      return {
        ...state,
        paginatedPremiseAssets: {
          ...state.paginatedPremiseAssets,
          status: LoadingStatus.SUCCEEDED,
          data: {
            ...state.paginatedPremiseAssets.data.filter(
              (asset: IAsset) => asset._id !== action.payload._id
            ),
          },
        },
      };
    case ASSET_ACTION.TOGGLE_CREATE_ASSET_DIALOG:
      return {
        ...state,
        showCreateAssetDialog: !state.showCreateAssetDialog,
      };
    case ASSET_ACTION.DELETE_COMPANY_ASSET:
    case ASSET_ACTION.DELETE_COMPANY_ASSET_FAILURE:
    default:
      return state;
  }
}

// -- Epics --
const getCompanyAssets$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.GET_COMPANY_ASSETS),
    switchMap((action) => {
      return companyService
        .getAssets(action.payload.companyId, action.payload.query)
        .pipe(
          map((payload) => {
            return GetCompanyAssetsSuccess(action.payload.companyId, payload);
          }),
          catchError((error) => of(GetCompanyAssetsFailure(error)))
        );
    })
  );
const getPremiseAssets$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.GET_PREMISE_ASSETS),
    switchMap((action) =>
      organizationUnitService
        .getAssets(action.payload.premiseId, action.payload.query)
        .pipe(
          map((payload) => {
            return GetPremiseAssetsSuccess(action.payload.premiseId, payload);
          }),
          catchError((error) => of(GetPremiseAssetsFailure(error)))
        )
    )
  );

const getAssetFailure$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(
      ASSET_ACTION.GET_COMPANY_ASSETS_FAILURE,
      ASSET_ACTION.GET_PREMISE_ASSETS_FAILURE
    ),
    map((action) => SetError(action.payload))
  );

const createCompanyAsset$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.CREATE_COMPANY_ASSET),
    switchMap((action) =>
      companyService
        .createAsset(action.payload.companyId, action.payload.asset)
        .pipe(
          mergeMap((asset) => {
            // appInsights.trackEvent(
            //   { name: "Create Company Asset" },
            //   { type: asset.type, source: asset.source }
            // );
            return [
              CreateCompanyAssetSuccess(asset),
              SetNotification(
                t("customerportal.notifications.asset_created") ||
                  "Asset created!"
              ),
            ];
          }),
          catchError((error) => of(CreateCompanyAssetFailure(error)))
        )
    )
  );

const updateCompanyAsset$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.UPDATE_COMPANY_ASSET),
    switchMap((action) =>
      companyService
        .updateAsset({
          companyId: action.payload.companyId,
          assetId: action.payload.assetId,
          product: action.payload.product,
          asset: action.payload.asset,
        })
        .pipe(
          mergeMap((asset) => [
            UpdateCompanyAssetSuccess(asset),
            SetNotification(
              t("customerportal.notifications.asset_updated") ||
                "Asset updated!"
            ),
          ]),
          catchError((error) => of(UpdateCompanyAssetFailure(error)))
        )
    )
  );

const deleteCompanyAsset$: Epic = (action$: Observable<AssetAction>) => {
  return action$.pipe(
    ofType(ASSET_ACTION.DELETE_COMPANY_ASSET),
    switchMap((action) => {
      return companyService
        .deleteAsset(
          action.payload.companyId,
          action.payload.product,
          action.payload.assetId
        )
        .pipe(
          mergeMap((asset) => {
            return [
              DeleteCompanyAssetSuccess(asset),
              SetNotification(
                t("customerportal.notifications.asset_deleted") ||
                  "Asset deleted!"
              ),
            ];
          }),
          catchError((error) => of(DeleteCompanyAssetFailure(error)))
        );
    })
  );
};

const createCompanyAssetFailure$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.CREATE_COMPANY_ASSET_FAILURE),
    map((action) => SetError(action.payload))
  );

const updateCompanyAssetFailure$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.UPDATE_COMPANY_ASSET_FAILURE),
    map((action) => SetError(action.payload))
  );

const deleteCompanyAssetFailure$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.DELETE_COMPANY_ASSET_FAILURE),
    map((action) => SetError(action.payload))
  );

const createPremiseAsset$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.CREATE_PREMISE_ASSET),
    switchMap((action) =>
      organizationUnitService
        .createAsset(action.payload.premiseId, action.payload.asset)
        .pipe(
          mergeMap((asset) => {
            return [
              CreatePremiseAssetSuccess(asset),
              SetNotification(
                t("customerportal.notifications.asset_created") ||
                  "Asset created!"
              ),
            ];
          }),
          catchError((error) => of(CreatePremiseAssetFailure(error)))
        )
    )
  );

const updatePremiseAsset$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.UPDATE_PREMISE_ASSET),
    switchMap((action) =>
      organizationUnitService
        .updateAsset({
          premiseId: action.payload.premiseId,
          assetId: action.payload.assetId,
          product: action.payload.product,
          asset: action.payload.asset,
        })
        .pipe(
          mergeMap((asset) => [
            UpdatePremiseAssetSuccess(asset),
            SetNotification(
              t("customerportal.notifications.asset_updated") ||
                "Asset updated!"
            ),
          ]),
          catchError((error) => of(UpdatePremiseAssetFailure(error)))
        )
    )
  );

const deletePremiseAsset$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.DELETE_PREMISE_ASSET),
    switchMap((action) =>
      organizationUnitService
        .deleteAsset(
          action.payload.premiseId,
          action.payload.product,
          action.payload.assetId
        )
        .pipe(
          mergeMap((asset) => [
            DeletePremiseAssetSuccess(asset),
            SetNotification(
              t("customerportal.notifications.asset_deleted") ||
                "Asset deleted!"
            ),
          ]),
          catchError((error) => of(DeletePremiseAssetFailure(error)))
        )
    )
  );

const createPremiseAssetFailure$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.CREATE_PREMISE_ASSET_FAILURE),
    map((action) => SetError(action.payload))
  );

const updatePremiseAssetFailure$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.UPDATE_PREMISE_ASSET_FAILURE),
    map((action) => SetError(action.payload))
  );

const deletePremiseAssetFailure$ = (action$: Observable<AssetAction>) =>
  action$.pipe(
    ofType(ASSET_ACTION.DELETE_PREMISE_ASSET_FAILURE),
    map((action) => SetError(action.payload))
  );

export const assetEpics = [
  getCompanyAssets$,
  getPremiseAssets$,
  getAssetFailure$,
  createCompanyAsset$,
  updateCompanyAsset$,
  deleteCompanyAsset$,
  createCompanyAssetFailure$,
  updateCompanyAssetFailure$,
  deleteCompanyAssetFailure$,
  createPremiseAsset$,
  updatePremiseAsset$,
  deletePremiseAsset$,
  createPremiseAssetFailure$,
  updatePremiseAssetFailure$,
  deletePremiseAssetFailure$,
];
