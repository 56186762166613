import { Box, Button, Typography, capitalize } from "@mui/material";
import { RoomTypeCategory } from "../../../../../../../../../types/NendaTypes";
import { t } from "i18next";

type RoomCategorySelectProps = {
  selectedRoomCategory: RoomTypeCategory | undefined;
  setSelectedRoomCategory: (category: RoomTypeCategory) => void;
  roomCategories: RoomTypeCategory[];
};

export default function RoomCategorySelect({
  selectedRoomCategory,
  setSelectedRoomCategory,
  roomCategories,
}: RoomCategorySelectProps) {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      gap="1rem"
      minHeight="200px"
    >
      <Box
        display="flex"
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant={"body2"}>
          {t(
            "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.room_category"
          )}
        </Typography>
        <Typography variant="body1" align="center">
          {t(
            "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.select_room_category_description"
          )}
        </Typography>
      </Box>
      <Box display={"flex"} gap="1rem" justifyContent={"center"}>
        {roomCategories.map((category) => {
          const handleRoomCategoryChange = () => {
            setSelectedRoomCategory(category);
          };
          return (
            <Button
              key={category}
              onClick={handleRoomCategoryChange}
              variant={
                selectedRoomCategory === category ? "contained" : "outlined"
              }
              size="large"
            >
              {capitalize(category.replace("_", " "))}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
}
