/* 
    typescript enums with Number values (e.g enum SomeEnum {One = 1; Two = 2}) 
    returns both the key and value when using Object.keys or Object.values 
    unlike enums with string values (SomeEnum {One = "Uno"; Two = "Dos"})
    that returns the expected results. These two helper methods extracts
    either keys or values based on simple int parsing
*/
export function getKeysFromNumericEnum(enumerator: any) {
  return Object.keys(enumerator).filter((key) => {
    return !(parseInt(key) >= 0);
  });
}

export function getValuesFromNumericEnum(enumerator: any) {
  return Object.values(enumerator).filter((value) => {
    return parseInt(value as any) >= 0;
  });
}
