import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getFinalPrice } from "@shared/productOfferingsUtils";
import {
  GetProductOfferings,
  SetProductOfferings,
} from "../../../redux/productOfferings.redux";
import { store } from "../../../redux/store";
import { AppState } from "../../../types/redux";

interface IProductOfferingsTableProps {
  hotelChainId: string;
}

export const ProductOfferingsTable: React.FC<IProductOfferingsTableProps> = ({
  hotelChainId,
}) => {
  useEffect(() => {
    store.dispatch(SetProductOfferings([]));
    store.dispatch(GetProductOfferings(hotelChainId));
  }, [hotelChainId]);

  const location = useLocation();

  const productOfferings = useSelector(
    (state: AppState) => state?.productOfferings?.productOfferings
  );

  const showColorHint = productOfferings?.some(
    (po) => (po.productIds || []).length === 0 || po.numberOfAssets === 0
  );
  return (
    <div className="product-offerings">
      <h4>Product offerings</h4>
      {showColorHint && (
        <p>
          These problems need to be fixed on the Red Bee platform
          <br />
          <span className="bg-danger text-white">Red:</span> Offering without
          products
          <br />
          <span className="bg-warning text-white">Orange:</span> Offering
          without assets
        </p>
      )}
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Price</th>
            <th>Label</th>
            <th scope="col" className="align-middle text-center">
              Number of assets
            </th>
          </tr>
        </thead>
        <tbody>
          {productOfferings?.map((po) => {
            const bgClass =
              (po.productIds || []).length === 0
                ? "bg-danger"
                : po.numberOfAssets === 0
                ? "bg-warning"
                : "";
            return (
              <tr key={po.externalId} className={`${bgClass}`}>
                <td>
                  <Link
                    className="nav-link"
                    to={`${location.pathname}/productoffering/${po.productOfferingId}`}
                  >
                    {po.localizedMetadata[0].name}
                  </Link>
                </td>
                <td>
                  <Link
                    className="nav-link"
                    to={`${location.pathname}/productoffering/${po.productOfferingId}`}
                  >
                    {po.offeringPrice &&
                      `${getFinalPrice(po)} ${po.offeringPrice.price.currency}`}
                  </Link>
                </td>
                <td>
                  <Link
                    className="nav-link"
                    to={`${location.pathname}/productoffering/${po.productOfferingId}`}
                  >
                    <span>{po.type}</span>
                  </Link>
                </td>
                <td className="align-middle text-center">
                  <Link
                    className="nav-link"
                    to={`${location.pathname}/productoffering/${po.productOfferingId}`}
                  >
                    {po.numberOfAssets}
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
