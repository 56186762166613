import { KeyboardArrowDown } from "@mui/icons-material";
import { Button, ButtonProps, Typography, useTheme } from "@mui/material";
import { t } from "i18next";

const ShowMoreButton = (props: ButtonProps) => {
  const { palette } = useTheme();
  return (
    <Button
      {...props}
      sx={{
        backgroundColor: palette.background.paper,
        "&:hover": { backgroundColor: palette.common.white },
        marginTop: "0.5rem",
      }}
      fullWidth
    >
      <Typography variant="caption">
        {props.disabled
          ? t("common.nothing_more_to_show")
          : t("common.show_more")}
        <KeyboardArrowDown />
      </Typography>
    </Button>
  );
};

export default ShowMoreButton;
