import { isBefore } from "date-fns";
import { ITimeLineItem } from "../../../../../../types/NendaTypes";

export const sortTimeLineItems = (timeLineItems: ITimeLineItem[]) => {
  return timeLineItems.sort((a, b) => {
    const startDateA = new Date(
      0,
      0,
      0,
      a.interval.start.hour,
      a.interval.start.minute
    );
    const startDateB = new Date(
      0,
      0,
      0,
      b.interval.start.hour,
      b.interval.start.minute
    );

    return isBefore(startDateA, startDateB) ? -1 : 1;
  });
};
